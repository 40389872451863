import { Injectable } from '@angular/core';
import { csx } from 'services/api/urls';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class ServiceCentreLockdownService {
  private overrideUpn: string = null;

  constructor(private http: HttpClient) {

  }

  async lockdownOffice()  {
    return this.http
      .post<void>(csx.serviceCentreLockdown({ }), null)
      .toPromise();
  }

  async safeToReturn() {
    return this.http.post<void>(csx.serviceCentreSafeToReturn({}), null)
      .asJsonResponse<void>()
      .toPromise();
  }
}