import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { FilterSettings, MultiChoiceSearch } from '../app/dashboard/home-visits/filter-settings';

@Injectable()
export class HomeVisitFilterService {

  constructor() {}

  // This is called after the filter is closed
  filterVisitsToShow(filterSettings: FilterSettings, visits: Api.ChildHomeVisit[]) : Api.ChildHomeVisit[] 
  {
    var visitsToShow = filterSettings.nameSearch.getMatchingChildren(visits);
    visitsToShow = visitsToShow.filter(o => this.includeHomeVisitByChildName(filterSettings, o.subjectChildName));
    visitsToShow = visitsToShow.filter(o => this.includeInterventionType(filterSettings, o.currentInterventionType));
    visitsToShow = visitsToShow.filter(o => this.includeHomeVisitFrequency(filterSettings, o.frequency));
    visitsToShow = visitsToShow.filter(o => this.includePriority(filterSettings, filterSettings.getPriorityLabel(o.isOverdueWithHighPriority, o.isOverdueWithModeratePriority)));
    return visitsToShow;
  }

  // This is called after the filter is closed
  filterVisitsToShowByDateRange(filterSettings: FilterSettings, visits: Api.ChildHomeVisit[]) {
    return visits.filter(o => this.includeDate(filterSettings, o.dueDtm));
  }

  private includeDate(filterSettings: FilterSettings, dueDtm: string) {
    var taskDueDtm = moment(dueDtm)
      .local()
      .startOf('day');
    var searchStart = moment(filterSettings.effectiveStartDate());
    var searchEnd = moment(filterSettings.effectiveEndDate());
    return taskDueDtm.isSameOrAfter(searchStart) && taskDueDtm.isSameOrBefore(searchEnd);
  }

  private includeInterventionType(filterSettings: FilterSettings, interventionType: string) {
    return filterSettings.interventionSelect.allSelected() || filterSettings.interventionSelect.getOrderedOptions().filter(o => o.selected && o.displayName.toLocaleLowerCase() === interventionType.toLocaleLowerCase()).length > 0;
  }

  private includeHomeVisitByChildName(filterSettings: FilterSettings, childName: String)
  {
    // If the person has not defined a child name then do not filter results; this is why we return true when none are selected
    //return this.filterSettings.nameSelect.allSelected() || this.filterSettings.nameSelect.noneSelected() || this.filterSettings.nameSelect.selectedChildren.filter(o => o == childName).length > 0;
    return filterSettings.nameSelect.allSelected() || filterSettings.nameSelect.getOrderedOptions().filter(o => o.selected === true && o.displayName.toLocaleLowerCase() === childName.toLocaleLowerCase()).length > 0;
  }

  private includeHomeVisitFrequency(filterSettings: FilterSettings, visitFrequency: string)
  {
    return filterSettings.visitFrequencySelect.allSelected() || filterSettings.visitFrequencySelect.getOrderedOptions().filter(o => o.selected && o.displayName.toLocaleLowerCase() === visitFrequency.toLocaleLowerCase()).length > 0;
  }

  private includePriority(filterSettings: FilterSettings, priority: string) {
    return filterSettings.priorityLevels.allSelected() || filterSettings.priorityLevels.getOrderedOptions().filter(o => o.selected && o.displayName.toLocaleLowerCase() === priority.toLocaleLowerCase()).length > 0;
  }

  formatDateForFilterRange(date: Date): string {
    if (date.getFullYear() != new Date().getFullYear()) {
      return moment(date)
        .local()
        .format('ddd Do MMM YYYY');
    }
    else {
      return moment(date)
        .local()
        .format('ddd Do MMM');
    }
  }

  getFilterDescription(options: MultiChoiceSearch, prefix: string): string {
    let response = prefix;

    options.getOrderedOptions().forEach(r => {
      if (r.selected == true) {
        response += `'${r.displayName}', `;
      }
    });

    return response.replace(/,\s*$/, '');
  }

  getChildNameFilterDescription(filterSettings: FilterSettings, prefix: string) : string {
    return prefix + filterSettings.nameSearch.childNameSearchValue;
  }
}
