<section role="alert">
  <div>
    <h3>Sorry, something went wrong :(</h3>
    <p>An error occurred retrieving the latest data for {{appSection}}.</p>
    <ng-container *ngIf="includeTroubleshooting">
      <h4>Troubleshooting steps:</h4>
      <ol>
        <li>Try refreshing the page.</li>
        <li>Check your internet connectivity.</li>
        <li>Contact <a href="mailto:CSPortal@csyw.qld.gov.au" aria-label="Email support.">CSPortal@csyw.qld.gov.au</a> for further assistance.</li>
      </ol>
    </ng-container>
  </div>
</section>
