<div class="details" #renderArea>
  <loading-pane [tracker]="tracker" [enabled]="showUpdateGraphic(homeVisit)">
    <div #divToResize>
      <app-error-message *ngIf="hasError"></app-error-message>
      <span class="restricted-message" *ngIf="dataLoaded && !hasError && homeVisitDetail.isSensitive">
        <i class="fas fa-user-shield"></i> Sensitive User
      </span>
      <ng-container *ngIf="dataLoaded && !hasError">
        <div class="row" #headerRow role="row">
          <div class="th" role="columnheader" title="Age" aria-hidden="true"><span>Age</span></div>
          <div class="th" role="columnheader" title="Scheduled date for next visit" aria-hidden="true">Next Visit Due</div>
          <div class="th" role="columnheader" title="Current Intervention Type" aria-hidden="true">Current Intervention Type</div>
          <div class="th" role="columnheader" title="Current Child Protection Alerts" aria-hidden="true">Current CP Alerts</div>
        </div>
        <div class="row" role="row">
          <div class="td" role="cell" data-title="Age">
            <div class="visually-hidden" title="Age">Age</div>
            <span *ngIf="homeVisitDetail.isCensoredDueToSensitivity; else ageSection">***</span>
            <ng-template #ageSection>
              <span>{{ homeVisitDetail.age | formatAge }}</span>
            </ng-template>
          </div>
          <div class="td" role="cell" data-title="Next Visit Due">
            <div class="visually-hidden" title="Scheduled date for next visit">Next Visit Due</div>
            <span>{{ formatShortDate(homeVisit.dueDtm) }}</span>
          </div>
          <div class="td" role="cell" data-title="Current Intervention Type">
            <div class="visually-hidden" title="Current Intervention Type">Current Intervention Type</div>
            <span>{{ homeVisit.currentInterventionType ? homeVisit.currentInterventionType : 'n/a' }}</span>
          </div>
          <div class="td alert-col" role="cell" data-title="Current CP Alerts">
            <div class="visually-hidden" title="Current Child Protection Alerts">Current CP Alerts</div>
            <span *ngIf="homeVisitDetail.isCensoredDueToSensitivity; else cpAlertsSection">***</span>
            <ng-template #cpAlertsSection>
              <ul class="alert-list" *ngIf="homeVisitDetail.hasRedAlert || homeVisitDetail.hasAmberAlert" #riskArea>
                <li *ngFor="let alert of redAlerts;">
                  <i class="icon-alert icon-red-alert" aria-label="Red Alert icon" title="Red Alert icon"></i>
                  <span>{{ alert.description }}</span>
                  <span *ngIf="alert.count > 1">({{ alert.count }})</span>
                </li>
                <li *ngFor="let alert of amberAlerts;">
                  <i class="icon-alert icon-amber-alert" aria-label="Amber Alert icon" title="Amber Alert icon"></i>
                  <span>{{ alert.description }}</span>
                  <span *ngIf="alert.count > 1">({{ alert.count }})</span>
                </li>
              </ul>
              <span *ngIf="!homeVisitDetail.hasRedAlert && !homeVisitDetail.hasAmberAlert">No alerts recorded</span>
            </ng-template>
          </div>
        </div>
        <div class="row" role="row">
          <div class="th" role="columnheader" title="Indigenous Status" aria-hidden="true"><span>Indigenous Status</span></div>
          <div class="th" role="columnheader" title="Address" aria-hidden="true"><span>Address</span></div>
          <div class="th" role="columnheader" aria-hidden="true"></div>
          <div class="th" role="columnheader" aria-hidden="true"></div>
        </div>
        <div class="row" role="row">
          <div class="td" role="cell" data-title="Indigenous Status">
            <div class="visually-hidden" title="Indigenous Status">Indigenous Status</div>
            <div *ngIf="homeVisitDetail.isCensoredDueToSensitivity; else indigenousStatusSection">***</div>
            <ng-template #indigenousStatusSection>
              <div>{{ homeVisitDetail.indigenousStatus ? homeVisitDetail.indigenousStatus : 'Unknown' }}</div>
            </ng-template>
          </div>
          <div class="td" role="cell" data-title="Address">
            <div class="visually-hidden" title="Address">Address</div>
            <div *ngIf="homeVisitDetail.isCensoredDueToSensitivity; else addressSection">***</div>
            <ng-template #addressSection>
              <div>
                <div *ngFor="let addressLine of addressLines(homeVisitDetail.address)">{{ addressLine }}</div>
                <div *ngIf="homeVisitDetail.address && homeVisitDetail.address.length > 0"><a class="google-maps-link"
                    [attr.tabindex]="dataLoaded ? null : -1"
                    href="https://www.google.com/maps/search/?api=1&query={{homeVisitDetail.address}}"
                    aria-label="Navigate to Google Maps to view address." target="_blank">Link to Google Maps <i
                      class="fas fa-external-link-alt fa-sm"></i></a></div>
                <div *ngIf="!homeVisitDetail.address || homeVisitDetail.address.length === 0">No address recorded</div>
              </div>
            </ng-template>
          </div>
          <div class="td" role="cell" data-title=""></div>
          <div class="td" role="cell" data-title=""></div>
        </div>
      </ng-container>
    </div>
  </loading-pane>
</div>
