<div class="container">
  <loading-pane [tracker]="tracker">
    <div id="upcoming-tab" role="tabpanel" class="upcoming-visits table-layout">
      <div role="table">
        <div class="table-sub-heading">
          <div>
            <i *ngIf="showFilterIcon()" class="fas fa-filter fa-lg filter-icon"></i> 
            <span class="filtered-by">{{ formatDateForFilterRange(filterSettings.effectiveStartDate()) }} to
              {{ formatDateForFilterRange(filterSettings.effectiveEndDate()) }}</span>
            <span class="filtered-by"
              *ngIf="!this.filterSettings.interventionSelect.allSelected()">{{ getFilterDescription(this.filterSettings.interventionSelect, 'Interventions: ') }} </span>
            <span class="filtered-by"
              *ngIf="!this.filterSettings.visitFrequencySelect.allSelected()">{{ getFilterDescription(this.filterSettings.visitFrequencySelect, 'Frequency: ') }} </span>
            <span class="filtered-by"
              *ngIf="!this.filterSettings.nameSelect.allSelected()">{{ getFilterDescription(this.filterSettings.nameSelect, 'Children: ') }} </span>
            <span class="filtered-by"
              *ngIf="!this.filterSettings.nameSearch.isEmpty()">{{ getChildNameFilterDescription('Child: ') }} </span>
          </div>
          <span class="ordered-by">Ordered by next visit due date</span>
        </div>
        <div [ngClass]="{ 'top-level': true, 'team-view': !!haveStaff() }">
          <div class="row" role="row">
            <div class="th" role="columnheader" title="Child's preferred name" aria-hidden="true"><span>Name</span></div>
            <div class="th" role="columnheader" title="Last visit date" aria-hidden="true"><span>Last Visit Date</span></div>
            <div class="th" role="columnheader" title="Current location (either Care Arrangement or In Home)" aria-hidden="true"><span>Current Location Type</span></div>
            <div class="th" role="columnheader" title="Calculated minimum contact requirement for visit to child" aria-hidden="true"><span>Visit Frequency *</span></div>
            <div *ngIf="!!haveStaff()" class="th" role="columnheader" title="Allocated To" aria-hidden="true"><span>Allocated To</span></div>
          </div>
          <app-error-message *ngIf="upcomingVisitData.visitsHasError" appSection="upcoming home visits"></app-error-message>
          <app-no-results-message
            *ngIf="!upcomingVisitData.visitsHasError && upcomingVisitData.visitsLoaded && (!visitsToShow || visitsToShow.length === 0)"
            customHeader="No upcoming home visits were found!" [includeTroubleshooting]="true"
            [includeFilterTroubleshooting]="true">
          </app-no-results-message>
          <ng-container *ngFor="let homeVisit of pagedVisitsToShow; let i = index; trackBy: trackBy">
            <loading-pane [enabled]="showUpdateGraphic(homeVisit)">
              <div class="row" role="row" [attr.data-person-id]="homeVisit.subjectChildId" [ngClass]="{ 'even-row': isEven(homeVisit) }">
                <div class="td emphasised" data-title="Name" role="cell">
                  <div class="visually-hidden" title="Child's preferred name">Name</div>
                  <div>
                    <i class="far fa-user fa-lg"></i>
                    <div>
                      <a *ngIf="hasDestinationPath(homeVisit)" href="{{ getDestinationPath(homeVisit) }}"
                        [attr.aria-label]="'ICMS event documents for child, ' + homeVisit.subjectChildName"
                        [title]="'ICMS event documents for child, ' + homeVisit.subjectChildName"
                        target="_blank">{{ homeVisit.subjectChildName }}</a>
                      <span *ngIf="!hasDestinationPath(homeVisit)">{{ homeVisit.subjectChildName }}</span>
                    </div>
                  </div>
                </div>
                <div class="td" data-title="Last Visit Date" role="cell">
                  <div class="visually-hidden" title="Last visit date">Last Visit Date</div>
                  <span title="{{ homeVisit.lastVisitDtm ? 'Date recorded in last prescribed home visit case note' : 'No date recorded in a prescribed home visit case note' }}">{{
                homeVisit.lastVisitDtm ? formatShortDate(homeVisit.lastVisitDtm) : 'n/a'
                }}</span>
                </div>
                <div class="td" data-title="Current Location Type" role="cell">
                  <div class="visually-hidden" title="Current location (either Care Arrangement or In Home)">Current Location Type</div>
                  <span>{{ homeVisit.currentLocationType }}</span>
                </div>
                <div class="td" data-title="Visit Frequency" role="cell">
                  <div class="visually-hidden" title="Calculated minimum contact requirement for visit to child">Visit Frequency</div>
                  <span title="Recommended frequency based on ICMS data & SDM">{{ homeVisit.frequency }} *</span>
                </div>
                <div *ngIf="!!haveStaff()" class="td" data-title="Allocated To" role="cell">
                  <div class="visually-hidden" title="Allocated To">Allocated To</div>
                  <span>{{ homeVisit.ownerName && homeVisit.ownerName.length > 0 ? homeVisit.ownerName : 'Me' }}</span>
                </div>
                <div class="td" role="cell">
                  <div>
                    <button [ngClass]="{ 'toggle-details': true, 'highlight-button': isVisitSelected(homeVisit) }" (click)="toggleSelectVisit(homeVisit)"
                      [title]="isVisitSelected(homeVisit) ? 'View less child detail for ' + homeVisit.subjectChildName : 'View more child detail for ' + homeVisit.subjectChildName"
                      [attr.aria-label]="isVisitSelected(homeVisit) ? 'View less child detail for ' + homeVisit.subjectChildName : 'View more child detail for ' + homeVisit.subjectChildName">
                      <span *ngIf="!isVisitSelected(homeVisit)">View More <i class="fas fa-angle-down"></i></span>
                      <span *ngIf="isVisitSelected(homeVisit)">View Less <i class="fas fa-angle-up"></i></span>
                    </button>
                  </div>
                </div>
              </div>
            </loading-pane>
            <app-home-visit-detail [homeVisit]="homeVisit" [isExpanded]="isVisitSelected(homeVisit)"
              [ngClass]="{ 'even-row': isEven(homeVisit), 'reveal-details': isVisitSelected(homeVisit) }">
            </app-home-visit-detail>
          </ng-container>
        </div>
      </div>
    </div>
  </loading-pane>
  <app-pagination (pageChangedEvent)="onPageChangedEvent($event)" (pageSizeChangedEvent)="onPageSizeChangedEvent($event)" [recordCount]="upcomingVisitCount" [style]="homeVisitsStyle"></app-pagination>
</div>

  
  

