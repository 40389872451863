<span [ngClass]="{ 'paginate-area': !isHidden() , 'hide': isHidden() }">
    <div class="paginate-area-1">
        <span>Rows per page: </span>
        <select name="CountToShowPerPage" [ngModel]="selectedPageSize" (ngModelChange)="onPageSizeChange($event)" class="filter-select">
            <option *ngFor="let option of pageSizeOptions" [ngValue]="option">{{option}}</option>
        </select>
    </div>
    <div class="paginate-area-2">
        <div class="pr-4">{{ showingFromRecord }} - {{ showingToRecord }} of {{ recordCount }}</div>

        <nav *ngIf="pageCount > 1">
            <ol [className]="'' + style">
                <li role="link" tabindex="0" aria-label="Link to first page" *ngIf="pager().showLast" (click)="firstPage()"
                (keyup.Enter)="firstPage()"><i class="fas fa-angle-double-left"></i></li>
                <li role="link" tabindex="0" *ngIf="pager().showLast" aria-label="Link to previous page"
                (click)="prevPage()" (keyup.Enter)="prevPage()"><i class="fas fa-angle-left"></i>
                <li role="link" tabindex="0" *ngIf="pager().showLast" aria-label="Link to previous 5 pages"
                (click)="prevPageGroup()" (keyup.Enter)="prevPageGroup()">...</li>
                <li role="link" tabindex="0" *ngFor="let page of pager().pages" [attr.aria-label]="'Link to page ' + (page+1)"
                (click)="selectPage(page)" (keyup.Enter)="selectPage(page)" 
                [class.active]="currentPage === page">{{page+1}}</li>
                <li role="link" tabindex="0" *ngIf="pager().showNext" aria-label="Link to next 5 pages"
                (click)="nextPageGroup()" (keyup.Enter)="nextPageGroup()">...</li>
                <li role="link" tabindex="0" *ngIf="pager().showNext" aria-label="Link to next page" (click)="nextPage()"
                (keyup.Enter)="nextPage()"><i class="fas fa-angle-right"></i></li>
                <li role="link" tabindex="0" *ngIf="pager().showNext" aria-label="Link to last page" (click)="lastPage()"
                (keyup.Enter)="lastPage()"><i class="fas fa-angle-double-right"></i></li>
            </ol>
        </nav>
    </div>
</span>
