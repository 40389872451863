<div class="details">
    <loading-pane [tracker]="tracker">
      <div>
        <app-error-message *ngIf="hasError"></app-error-message>
        <span class="restricted-message" *ngIf="dataLoaded && !hasError && courtOrderDetail.isSensitive">
          <i class="fas fa-user-shield"></i> Sensitive User
        </span>
        <ng-container *ngIf="dataLoaded && !hasError">
          <div class="large-view">
            <div class="row" role="row">
              <div class="th" role="columnheader" title="Header Column Age" aria-hidden="true">
                <div class="inner-header" title="Header Column Age" aria-hidden="true"><span>Age</span></div>
                <div class="inner-cell" data-title="Age">
                  <div class="visually-hidden" title="Header Column Age">Age</div>
                  <span *ngIf="courtOrderDetail.isCensoredDueToSensitivity; else ageSection">***</span>
                  <ng-template #ageSection>
                    <span>{{ courtOrderDetail.age | formatAge }}</span>
                  </ng-template>
                </div>
              </div>
              <div *ngFor="let mstone of firstThreeMilestones" [ngClass]="{ 'th': true, 'next-milestone': isTheNext(mstone) }" role="columnheader" attr.title="Header Column {{mstone.milestoneName}}" aria-hidden="true">
                <div class="inner-header" attr.title="Header Column {{mstone.milestoneName}}" aria-hidden="true"><span>{{mstone.milestoneName}}</span></div>
                <div app-court-order-milestone [isNext]="isTheNext(mstone)" [milestone]="mstone"  role="cell" attr.data-title="{{mstone.milestoneName}}"></div>
              </div>
              <div class="td" role="columnheader" attr.title="Spacing" aria-hidden="true"></div>
            </div>
            <div class="row" role="row">
              <div class="th" role="columnheader" title="Header Column Indigenous Status" aria-hidden="true">
                <div class="inner-header" title="Header Column Indigenous Status" aria-hidden="true"><span>Indigenous Status</span></div>
                <div class="inner-cell" data-title="Indigenous Status">
                  <div class="visually-hidden" title="Header Column Indigenous Status">Indigenous Status</div>
                  <span *ngIf="courtOrderDetail.isCensoredDueToSensitivity; else indigenousStatusSection">***</span>
                  <ng-template #indigenousStatusSection>
                    <div>{{ courtOrderDetail.indigenousStatus ? courtOrderDetail.indigenousStatus : 'Unknown' }}</div>
                  </ng-template>
                </div>
              </div>
              <div *ngFor="let mstone of nextThreeMilestones" [ngClass]="{ 'th': true, 'next-milestone': isTheNext(mstone) }" role="columnheader" attr.title="Header Column {{mstone.milestoneName}}" aria-hidden="true">
                <div class="inner-header" attr.title="Header Column {{mstone.milestoneName}}" aria-hidden="true"><span>{{mstone.milestoneName}}</span></div>
                <div app-court-order-milestone [isNext]="isTheNext(mstone)" [milestone]="mstone"  role="cell" attr.data-title="{{mstone.milestoneName}}"></div>
              </div>
              <div class="td" role="columnheader" attr.title="Spacing" aria-hidden="true"></div>
            </div>
            <div class="row" role="row">
              <div class="th" role="columnheader" aria-hidden="true"><span>&nbsp;</span></div>
              <div *ngFor="let mstone of lastThreeMilestones" [ngClass]="{ 'th': true, 'next-milestone': isTheNext(mstone) }" role="columnheader" attr.title="Header Column {{mstone.milestoneName}}" aria-hidden="true">
                <div class="inner-header" attr.title="Header Column {{mstone.milestoneName}}" aria-hidden="true"><span>{{mstone.milestoneName}}</span></div>
                <div app-court-order-milestone [isNext]="isTheNext(mstone)" [milestone]="mstone"  role="cell" attr.data-title="{{mstone.milestoneName}}"></div>
              </div>
              <div class="td" role="cell" attr.title="Spacing" aria-hidden="true"></div>
            </div>
          </div>
          
          <div class="mobile-view">
            <div class="row" role="row">
              <div class="th" role="columnheader" title="Header Column Age" aria-hidden="true"><span>Age</span></div>
              <div class="td" role="cell" data-title="Age">
                  <div class="visually-hidden" title="Header Column Age">Age</div>
                  <span *ngIf="courtOrderDetail.isCensoredDueToSensitivity; else ageSection">***</span>
                  <ng-template #ageSection>
                    <span>{{ courtOrderDetail.age | formatAge }}</span>
                  </ng-template>
                </div>
            </div>
            <div class="row" role="row">
              <div class="th" role="columnheader" title="Header Column Indigenous Status" aria-hidden="true"><span>Indigenous Status</span></div>
              <div class="td" role="cell" data-title="Indigenous Status">
                <div class="visually-hidden" title="Header Column Indigenous Status">Indigenous Status</div>
                <div *ngIf="courtOrderDetail.isCensoredDueToSensitivity; else indigenousStatusSection">***</div>
                <ng-template #indigenousStatusSection>
                  <div>{{ courtOrderDetail.indigenousStatus ? courtOrderDetail.indigenousStatus : 'Unknown' }}</div>
                </ng-template>
              </div>
            </div>
            <div *ngFor="let mstone of milestones" class="row" role="row">
              <div class="th" role="columnheader" attr.title="Header Column {{mstone.milestoneName}}" aria-hidden="true"><span>{{mstone.milestoneName}}</span></div>
              <div app-court-order-milestone [isNext]="isTheNext(mstone)" [milestone]="mstone" [ngClass]="{ 'td': true, 'next-milestone': isTheNext(mstone) }" role="cell" attr.data-title="{{mstone.milestoneName}}"></div>
            </div>
          </div>
        </ng-container>
      </div>
    </loading-pane>
  </div>
  