import { Injectable, OnDestroy } from '@angular/core';
import { Subject ,  Subscription ,  fromEvent } from 'rxjs';
import { throttleTime } from 'rxjs/operators';

@Injectable()
export class IdleDetectionService implements OnDestroy {
  private isIdle = new Subject<boolean>();
  public isIdleChanged = this.isIdle.asObservable();
  private idleTimerHandle: any;
  private subscription: Subscription;

  public reset(): void {
    this.ngOnDestroy();

    this.subscription =
      fromEvent(window, 'mousemove')
        .pipe(
          throttleTime(1000)
        )
        .subscribe(e => {
          this.changeIsIdleChanged(false);
          this.resetTimer();
        });

    this.resetTimer();
  }

  public ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }

    clearTimeout(this.idleTimerHandle);
  }

  private changeIsIdleChanged(idle: boolean) {
    this.isIdle.next(idle);
  }

  private resetTimer() {
    clearTimeout(this.idleTimerHandle);

    const timeoutInterval = 900000; // 15 minutes

    this.idleTimerHandle = setTimeout(() => {
      console.warn('User idle period detected ');
      this.changeIsIdleChanged(true);
    }, timeoutInterval);
  }
}