import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { appRoutes } from './app.routes';
import { StickyDirective } from './shared/directives/Sticky.directive';
import 'services/api/utils';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { ToastrModule } from 'ngx-toastr';
import { AngularMyDatePickerModule } from 'angular-mydatepicker';

import {
  AuthenticationGuard,
  MsalService,
  UserInfoService,
  StaffMemberService,
  StaffWhereaboutsService,
  ActivityFeedService,
  TaskSubsystemService,
  CaseSummaryService,
  IdleDetectionService,
  FeatureLoggerService,
  PersonaSwitcherService, 
  OfficeLockdownStatusService, 
  CourtOrderService, 
  HomeVisitFilterService, 
  ServiceCentreLockdownService, 
  ModalService, 
  LocalStorageService, 
  PriorityIconService, 
  RecordChangeDetectionService
} from '../services';
import { LoadingPaneModule } from './shared/loading-pane';
import { UnsafeHtmlPipe, SanitizeHtmlPipe, FormatAgePipe, NoDataPipe } from './../pipes';
import { AppComponent } from './app.component';
import { AuthComponent } from './authentication/auth.component';
import { UnauthorisedComponent } from './unauthorised/unauthorised.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { HeaderComponent, FooterComponent } from './page-layout';
import { MainMenuComponent } from './page-layout/header/main-menu/main-menu.component';
import { HeroComponent, HomeVisitsSummaryComponent, CourtOrderSummaryComponent, StaffWhereaboutsSummaryComponent, ActivityFeedComponent } from './hero';
import { NoResultsMessageComponent, ErrorMessageComponent } from './shared';
import { SiteConfigurationComponent } from './site-configuration/site-configuration.component';
import { HomeVisitsComponent, HomeVisitDetailComponent } from './dashboard/home-visits';
import { DashboardComponent } from './dashboard';
import { TabNotificationService, FilterNotificationService, UpcomingVisitsNotificationService, OfficeLockdownNotifyService, 
    CourtOrderPromiseNotifyService, CourtOrderNotifyService, HomeVisitUpcomingCountNotifyService, HomeVisitOverdueCountNotifyService, 
    ShowPriorityNotifyService, PromiseNotifyService } from '../services/notifications/';
import { CourtOrderComponent, CourtOrderDetailComponent, CourtOrderMilestoneComponent } from './dashboard/court-order';
import { StaffWhereaboutsComponent } from './staff-whereabouts/staff-whereabouts.component';
import { ModalComponent } from './modal/modal.component';
import { CaseSummaryComponent, CaseSummaryDetailComponent } from './case-summary';
import { FailedAuthorizationInterceptor } from './interceptors/authorization.interceptor';
import { TokenInterceptor } from './interceptors/token.interceptor';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DateTimeInputComponent } from './shared/date-time-input/date-time-input.component';
import { CheckOutComponent } from './staff-whereabouts/check-out/check-out.component';
import { DateInputComponent } from './shared/date-input/date-input.component';
import { HappyDayMotifComponent } from './shared/happy-day-motif/happy-day-motif.component';
import { OverdueVisitsComponent } from './dashboard/home-visits/overdue-visits/overdue-visits.component';
import { UpcomingVisitsComponent } from './dashboard/home-visits/upcoming-visits/upcoming-visits.component';
import { PaginationComponent } from './shared/pagination/pagination.component';
import { HomeVisitsService } from './dashboard/home-visits/home-visits.service';
import { OverdueFilterComponent } from './dashboard/home-visits/overdue-visits/overdue-filter/overdue-filter.component';
import { UpcomingFilterComponent } from './dashboard/home-visits/upcoming-visits/upcoming-filter/upcoming-filter.component';

@NgModule({
    declarations: [
        AppComponent,
        AuthComponent,
        UnauthorisedComponent,
        PageNotFoundComponent,
        FooterComponent,
        HeaderComponent,
        MainMenuComponent,
        SiteConfigurationComponent,
        UnsafeHtmlPipe,
        SanitizeHtmlPipe,
        FormatAgePipe,
        NoDataPipe,
        StickyDirective,
        ErrorMessageComponent,
        HappyDayMotifComponent,
        NoResultsMessageComponent,
        HeroComponent,
        HomeVisitsSummaryComponent,
        CourtOrderSummaryComponent,
        StaffWhereaboutsSummaryComponent,
        ActivityFeedComponent,
        CaseSummaryComponent,
        CaseSummaryDetailComponent,
        HomeVisitsComponent,
        HomeVisitDetailComponent,
        StaffWhereaboutsComponent,
        CourtOrderComponent,
        CourtOrderDetailComponent,
        CourtOrderMilestoneComponent,
        DashboardComponent,
        ModalComponent, DateTimeInputComponent, CheckOutComponent, DateInputComponent, HappyDayMotifComponent, OverdueVisitsComponent, UpcomingVisitsComponent, PaginationComponent, OverdueFilterComponent, UpcomingFilterComponent
    ],
    imports: [
        BrowserModule,
        FormsModule,
        NgSelectModule,
        HttpClientModule,
        LoadingPaneModule,
        ReactiveFormsModule,
        RouterModule.forRoot(appRoutes, { relativeLinkResolution: 'legacy' }),
        NgxMaterialTimepickerModule,
        BrowserAnimationsModule,
        ToastrModule.forRoot(),
        AngularMyDatePickerModule
    ],
    exports: [
        StickyDirective
    ],
    providers: [
        MsalService,
        UserInfoService,
        AuthenticationGuard,
        StaffMemberService,
        StaffWhereaboutsService,
        ActivityFeedService,
        TaskSubsystemService,
        CaseSummaryService,
        FeatureLoggerService,
        PersonaSwitcherService,
        OfficeLockdownStatusService,
        TabNotificationService,
        FilterNotificationService,
        UpcomingVisitsNotificationService,
        OfficeLockdownNotifyService,
        CourtOrderPromiseNotifyService,
        CourtOrderNotifyService,
        HomeVisitUpcomingCountNotifyService,
        HomeVisitOverdueCountNotifyService,
        ShowPriorityNotifyService,
        PromiseNotifyService,
        IdleDetectionService,
        CourtOrderService,
        HomeVisitFilterService,
        RecordChangeDetectionService,
        HomeVisitsService,
        ServiceCentreLockdownService,
        ModalService,
        LocalStorageService,
        PriorityIconService,
        SanitizeHtmlPipe,
        FormatAgePipe,
        NoDataPipe,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: FailedAuthorizationInterceptor,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: TokenInterceptor,
            multi: true
        }
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }