import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'noData' })
export class NoDataPipe implements PipeTransform {
  public transform(
    data: string | number | Date,
    customMessage: string = null): string | number | Date {

    if (data == undefined || data == null || (typeof data === 'string' && ((<string>data) || '').trim().length === 0)) {

      if (customMessage) {
        return customMessage;
      } else {
        return "Unknown"
      }
    }

    return data;
  }
}
