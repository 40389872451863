import * as moment from 'moment';
import { IMyDateModel, IMyDate } from 'angular-mydatepicker';
import { LocalStorageService } from 'services/local-storage.service';
import { FilterDateRange } from '../../../api/website_enum';

export interface MultiChoiceOption {
  value: string;
  displayName: string;
  selected: boolean;
}

export class SelectedDateRange {
  storagePrefix: string = '';
  dateRangeStorageName: string = 'dateRangeEnumValues';
  dateRangeType: FilterDateRange;
  customDateRange: IMyDateModel;

  constructor(prefix: string) {
    this.storagePrefix = prefix;

    this.seedValues.bind(this);
    this.setCustomRange.bind(this);
    this.setThisMonth.bind(this);
    this.setThisWeek.bind(this);
    this.effectiveStartDate.bind(this);
    this.effectiveEndDate.bind(this);
    this.dateFilterChanged.bind(this);
  }

  private getDatePickerModelFromDate(date: Date): IMyDate {
    if (!date) return null;
    return { year: date.getFullYear(), month: date.getMonth() + 1, day: date.getDate() };
  }

  useCustomDateValue() : Boolean 
  {
    return (this.dateRangeType === FilterDateRange.Custom);
  }

  haveDateValue() : Boolean 
  {
    if (this.dateRangeType !== FilterDateRange.Custom) {
      return true;
    }
    return !!this.customDateRange && 
    (
      (!!this.customDateRange.isRange && !!this.customDateRange.dateRange && !!this.customDateRange.dateRange.beginDate && this.customDateRange.dateRange.beginDate.year > 0) || 
      (!this.customDateRange.isRange && !!this.customDateRange.singleDate && !!this.customDateRange.singleDate.date && this.customDateRange.singleDate.date.year > 0)
    );
  }

  setCustomRange(startDate: Date, endDate: Date) {
    this.dateRangeType = FilterDateRange.Custom;
    this.customDateRange = {
      isRange: true,
      dateRange: {
        beginDate: this.getDatePickerModelFromDate(startDate),
        //beginJsDate: startDate,
        endDate: this.getDatePickerModelFromDate(endDate)
        //endJsDate: endDate
      }/*,
      singleDate: {
        date: this.getDatePickerModelFromDate(startDate)
      }*/
    };
  }

  setThisWeek() {
    this.dateRangeType = FilterDateRange.ThisWeek;
    this.customDateRange = null;
  }

  setThisMonth() {
    this.dateRangeType = FilterDateRange.Next4Weeks;
    this.customDateRange = null;
  }

  effectiveStartDate(): Date {
    if (this.dateRangeType === FilterDateRange.Next4Weeks) {
      return moment()
        .local()
        .startOf('day')
        .toDate();
    }

    if (this.dateRangeType === FilterDateRange.ThisWeek) {
      return moment()
        .local()
        .startOf('isoWeek')
        .toDate();
    }

    if(this.customDateRange.dateRange && this.customDateRange.dateRange.beginDate && 
      this.customDateRange.dateRange.beginDate.year && this.customDateRange.dateRange.beginDate.year > 0)
    {
      var returnValue = moment(`${this.customDateRange.dateRange.beginDate.year}-${this.customDateRange.dateRange.beginDate.month}-${this.customDateRange.dateRange.beginDate.day}`, "YYYY-MM-DD")
        .local()
        .startOf('day')
        .toDate();
      return returnValue;
    }

    if(!this.customDateRange || !this.customDateRange.dateRange || !this.customDateRange.dateRange.beginJsDate) 
    {
      var response = moment()
      .local()
      .startOf('day')
      .toDate();

      return response;
    }

    return this.customDateRange.dateRange.beginJsDate; // This is not present when an invalid date, like with a year of '202' is defined.
  }

  effectiveEndDate(): Date {
    if (this.dateRangeType === FilterDateRange.Next4Weeks) {
      return moment()
        .local()
        .endOf('day')
        .add(4, 'week')
        .toDate();
    }

    if (this.dateRangeType === FilterDateRange.ThisWeek) {
      return moment()
        .local()
        .endOf('isoWeek')
        .toDate();
    }

    if(this.customDateRange.dateRange && this.customDateRange.dateRange.endDate && 
      this.customDateRange.dateRange.endDate.year && this.customDateRange.dateRange.endDate.year > 0)
    {
      var returnValue = moment(`${this.customDateRange.dateRange.endDate.year}-${this.customDateRange.dateRange.endDate.month}-${this.customDateRange.dateRange.endDate.day}`, "YYYY-MM-DD")
        .local()
        .endOf('day')
        .toDate();
      return returnValue;
    }

    if(!this.customDateRange || !this.customDateRange.dateRange || !this.customDateRange.dateRange.endJsDate) return moment()
      .local()
      .endOf('day')
      .add(4, 'week')
      .toDate();

    return this.customDateRange.dateRange.endJsDate;
  }

  dateFilterChanged(newSettings: SelectedDateRange)
  {
    if (this.dateRangeType != newSettings.dateRangeType) {
      return true;
    }

    var start = this.effectiveStartDate().getTime();
    var newStart = newSettings.effectiveStartDate().getTime();

    if (start != newStart || this.effectiveEndDate().getTime() != newSettings.effectiveEndDate().getTime()) {
      return true;
    }

    return false;
  }

  // A pipe delimited value containing the date range type and the start and end dates 
  // where each date is in format: YYYY-MM-DD
  seedValues(localStorageService: LocalStorageService, defaultValues: boolean) {
    if(defaultValues)
    {
      this.setThisMonth();
      return;
    }

    var valuesSet = false;
    if(localStorageService.check(this.storagePrefix + this.dateRangeStorageName))
    {
      var val = localStorageService.get(this.storagePrefix + this.dateRangeStorageName);
      if(val && val.indexOf('|') >= 0)
      {
        var parts = val.split('|');
        if(parts && parts.length == 3)
        {
          this.dateRangeType = parseInt(parts[0]);
          if(this.dateRangeType == FilterDateRange.Custom)
          {
            var start = moment(parts[1], 'YYYY-MM-DD').toDate();
            var end = moment(parts[2], 'YYYY-MM-DD').toDate();
            this.setCustomRange(start, end);
          }
          valuesSet = true;
        }
      }
    }

    // There are no values set. Seed to the default 'in the next 4 weeks'.
    if(!valuesSet)
    {
      // Set values
      this.dateRangeType = FilterDateRange.Next4Weeks;
      this.customDateRange = null;

      // Seed storage key values
      this.storeStorageValue(localStorageService);
    }
  }

  storeStorageValue(localStorageService: LocalStorageService)
  {
    var storedValue = this.dateRangeType + '|';
    if(this.customDateRange == null)
    {
      storedValue += '|';
    }
    else
    {
      var startMoment = (!!this.customDateRange.dateRange.beginJsDate ? moment(this.customDateRange.dateRange.beginJsDate) : false);
      var endMoment = (!!this.customDateRange.dateRange.endJsDate ? moment(this.customDateRange.dateRange.endJsDate) : false);

      if(!startMoment && !!this.customDateRange.dateRange.beginDate)
      {
        startMoment = moment([
            this.customDateRange.dateRange.beginDate.year, 
            this.customDateRange.dateRange.beginDate.month -1, 
            this.customDateRange.dateRange.beginDate.day
        ]);
      }

      if(!endMoment && !!this.customDateRange.dateRange.endDate)
      {
        endMoment = moment([
            this.customDateRange.dateRange.endDate.year, 
            this.customDateRange.dateRange.endDate.month -1, 
            this.customDateRange.dateRange.endDate.day
        ]);
      }

      var startStr = !!startMoment ? startMoment.format('YYYY-MM-DD') : '';
      var endStr = !!endMoment ? endMoment.format('YYYY-MM-DD') : '';
      storedValue += startStr + '|' + endStr;
    }

    localStorageService.set(this.storagePrefix + this.dateRangeStorageName, storedValue);
  }
}

export class ChildNameSearch {
  storagePrefix: string = '';
  childNameSearchStorageName: string = 'childNameSearch';
  childNameSearchValue: string = '';

  constructor(prefix: string) {
    this.storagePrefix = prefix;
    this.clear.bind(this);
    this.getMatchingChildren.bind(this);
    this.isEmpty.bind(this);
    this.seedValues.bind(this);
  }

  getMatchingChildren(homeVisits: Api.ChildHomeVisit[]): Api.ChildHomeVisit[] {
    if(!this.childNameSearchValue || this.childNameSearchValue.length <= 0) return homeVisits;
    var regex = new RegExp(this.childNameSearchValue, "i");
    return homeVisits.filter(o => regex.test(o.subjectChildName));
  }

  isEmpty() : boolean {
    return (!this.childNameSearchValue || this.childNameSearchValue.length < 1);
  }

  // When there are is no data stored this will set the child name search key to the empty string.
  // When keys are present, this will set the value based on the existing value.
  seedValues(localStorageService: LocalStorageService, defaultValues: boolean)
  {
    if(defaultValues)
    {
      // Default: Seed everything to selected
      this.clear(localStorageService);
    }
    else
    {
      if(localStorageService.check(this.storagePrefix + this.childNameSearchStorageName))
      {
        this.childNameSearchValue = localStorageService.get(this.storagePrefix + this.childNameSearchStorageName);
      }
      else
      {
        // Seed value
        localStorageService.set(this.storagePrefix + this.childNameSearchStorageName, '');
      }
    }
  }

  clear(localStorageService: LocalStorageService) {
    this.childNameSearchValue = '';
    this.storeStorageValue(localStorageService);
  }

  storeStorageValue(localStorageService: LocalStorageService)
  {
    localStorageService.set(this.storagePrefix + this.childNameSearchStorageName, this.childNameSearchValue);
  }
}


export class MultiChoiceSearch {
  storagePrefix: string = '';
  referenceName: string; // Example: 'intervention'. Used in storage. eg. 'intervention_<unique name>'
  checkboxOptions: MultiChoiceOption[] = [];
  selectedOptions: string[] = [];
  sortField: string;

  constructor(referenceNm: string, sortField: string, prefix: string) {
    this.storagePrefix = prefix;
    this.referenceName = referenceNm;
    this.sortField = sortField;
    this.selectAll.bind(this);
    this.selectNone.bind(this);
    this.allSelected.bind(this);
    this.noneSelected.bind(this);
    this.getOrderedOptions.bind(this);
    this.seedValues.bind(this);
    this.optionChanged.bind(this);
    this.setOptions.bind(this);
    this.setSelections.bind(this);
    this.setCustomOptions.bind(this);
    this.selectAllOptions.bind(this);
  }

  selectAllOptions()
  {
    this.checkboxOptions.forEach(function(option) {
      option.selected = true
    });
  }

  // This can be used when interacting with a Drop Down list
  // This will only include selected options and they will always be true
  // Set matching values to selected and all others to false.
  setSelections(options: string[])
  {
    if(!options) return;
    this.checkboxOptions.forEach(function(option) {
      var selectedOption = options.find(x => x == option.displayName);
      option.selected = !!selectedOption; // True when not undefined.
    });

    this.bindSelectionsForDisplay();
  }

  getOrderedOptions(): MultiChoiceOption[] {
    return this.checkboxOptions.sort((x: MultiChoiceOption, y: MultiChoiceOption) => {
      return x[this.sortField].localeCompare(y[this.sortField]);
    });
  }

  setCustomOptions(options: MultiChoiceOption[])
  {
    this.checkboxOptions = options;
    this.bindSelectionsForDisplay();
  }
  
  // This should be called when data is retrieved from the backend. It will seed all values to checked.
  // Example parameter: 'currentInterventionType'. This is a parameter of the Api.ChildHomeVisit object
  setOptions(homeVisits: Api.ChildHomeVisit[], parameter: string, localStorageService: LocalStorageService)
  {
    // Clear any existing options
    this.checkboxOptions = [];
    if(!homeVisits || homeVisits.length < 1) return;
    var options = [];
    var refName = this.referenceName;
    var selectionFunction = this.getStoredValue;
    var prefix = this.storagePrefix;
    homeVisits.forEach(function(visit: Api.ChildHomeVisit) {
      var existing = options.find(x => x.displayName == visit[parameter]);
      if(!existing || existing == null)
      {
        options.push({
          value: visit[parameter],
          displayName: visit[parameter],
          selected: selectionFunction(localStorageService, visit[parameter], refName, prefix)
        });
      }
    });
    this.checkboxOptions = options;
    // Ensure the options are ordered before initial display
    this.checkboxOptions = this.getOrderedOptions();
    this.bindSelectionsForDisplay();
  }

  bindSelectionsForDisplay()
  {
    // Bind back to form for display purposes
    var selections = [];
    this.checkboxOptions.forEach(function(option) {
      if(option.selected) selections.push(option.value);
    });
    this.selectedOptions = selections;
  }

  // When there are no keys storage this will set all interventionTypes to true and store as individual storage values
  // When keys are present for these interventionTypes, this will set the value based on the existing value.
  seedValues(localStorageService: LocalStorageService, defaultValues: boolean)
  {
    if(defaultValues)
    {
      // Default: Seed everything to selected
      this.selectAll(localStorageService);
    }
    else
    {
      this.checkboxOptions.forEach(r => {
        var storageKeyName = this.storagePrefix + this.referenceName + '_' + r.value;
        if(localStorageService.check(storageKeyName))
        {
          var val = localStorageService.get(storageKeyName);
          r.selected = (val && val === 'true');
        }
        else
        {
          // Set value
          r.selected = true;
  
          // Seed storage key values
          localStorageService.set(storageKeyName, '' + r.selected);
        }
      });
      this.bindSelectionsForDisplay();
    }
  }

  optionChanged(newOptions: MultiChoiceSearch)
  {
    if(this.checkboxOptions.length != newOptions.checkboxOptions.length) return true;
    for (let r = 0; r < this.checkboxOptions.length; r++) {
      const option1 = this.checkboxOptions[r];
      const option2 = newOptions.checkboxOptions[r];
      if (option1.selected != option2.selected) {
        return true;
      }
    }
    return false;
  }

  selectAll(localStorageService: LocalStorageService) {
    this.checkboxOptions.map(o => (o.selected = true));
    this.storeStorageValue(localStorageService);
  }

  selectNone(localStorageService: LocalStorageService) {
    this.checkboxOptions.map(o => (o.selected = false));
    this.storeStorageValue(localStorageService);
  }

  allSelected(): boolean {
    return this.checkboxOptions.filter(o => o.selected === true).length === this.checkboxOptions.length;
  }

  noneSelected(): boolean {
    return this.checkboxOptions.filter(o => o.selected === true).length === 0;
  }

  getStoredValue(localStorageService: LocalStorageService, displayName: string, referenceName: string, prefix: string): boolean
  {
    // console.log('MultiChoice. prefix: ' + prefix + '. referenceName: ' + referenceName + '. displayName: ' + displayName);
    var response = true;
    var storageKeyName = prefix + referenceName + '_' + displayName;
    if(localStorageService.check(storageKeyName))
    {
      var value = localStorageService.get(storageKeyName);
      response = (value && value === 'true');
    }
    return response;
  }

  storeStorageValue(localStorageService: LocalStorageService)
  {
    this.checkboxOptions.forEach(r => {
      var storageKeyName = this.referenceName + '_' + r.value;
      localStorageService.set(this.storagePrefix + storageKeyName, '' + r.selected);
    });
    this.bindSelectionsForDisplay();
  }
}


export class FilterSettings {
  storagePrefix: string = '';
  selectedDateRange: SelectedDateRange;
  nameSearch: ChildNameSearch;
  nameSelect: MultiChoiceSearch;
  interventionSelect: MultiChoiceSearch;
  visitFrequencySelect: MultiChoiceSearch;
  priorityLevels: MultiChoiceSearch;

  constructor(
    private localStorageService: LocalStorageService,
    public defaultValues: boolean, 
    public prefix: string
  ) {
    this.initialiseValues(prefix);

    this.setCustomRange.bind(this);
    this.setThisMonth.bind(this);
    this.setThisWeek.bind(this);
    this.effectiveStartDate.bind(this);
    this.effectiveEndDate.bind(this);
    this.persistSessionFilterSettings.bind(this);
    this.selectAllOptions.bind(this);
  }

  selectAllOptions()
  {
    this.nameSelect.selectAllOptions();
    this.interventionSelect.selectAllOptions();
    this.visitFrequencySelect.selectAllOptions();
  }

  initialiseValues(prefix)
  {
    if(this.defaultValues)
    {
      this.localStorageService.deleteAllWithPrefix(prefix);
    }
    this.priorityLevels = new MultiChoiceSearch('priorityLevel', 'value', prefix);
    this.initialisePriorities();
    this.priorityLevels.seedValues(this.localStorageService, this.defaultValues);

    this.selectedDateRange = new SelectedDateRange(prefix);
    this.selectedDateRange.seedValues(this.localStorageService, this.defaultValues);

    this.nameSearch = new ChildNameSearch(prefix);
    this.nameSearch.seedValues(this.localStorageService, this.defaultValues);

    this.nameSelect = new MultiChoiceSearch('subjectChildName', 'displayName', prefix);
    this.nameSelect.seedValues(this.localStorageService, this.defaultValues);

    this.interventionSelect = new MultiChoiceSearch('currentInterventionType', 'displayName', prefix);
    this.interventionSelect.seedValues(this.localStorageService, this.defaultValues);

    this.visitFrequencySelect = new MultiChoiceSearch('frequency', 'displayName', prefix);
    this.visitFrequencySelect.seedValues(this.localStorageService, this.defaultValues);
  }

  getPriorityLabel(isHighPriority: boolean, isModeratePriority: boolean) : string 
  {
    if(!!isHighPriority) return 'Red';
    if(!!isModeratePriority) return 'Amber';
    return 'Normal';
  }

  initialisePriorities()
  {
    // Initialise hard-coded priority values
    this.priorityLevels.setCustomOptions([
      { value: '1', displayName: 'Normal', selected: true },
      { value: '2', displayName: 'Amber', selected: true },
      { value: '3', displayName: 'Red', selected: true }
    ]);
  }

  persistSessionFilterSettings(localStorageService: LocalStorageService)
  {
    this.selectedDateRange.storeStorageValue(localStorageService);
    this.nameSearch.storeStorageValue(localStorageService);
    this.nameSelect.storeStorageValue(localStorageService);
    this.interventionSelect.storeStorageValue(localStorageService);
    this.visitFrequencySelect.storeStorageValue(localStorageService);
    this.priorityLevels.storeStorageValue(localStorageService);
  }

  hasChanged(newSettings: FilterSettings) {
    if(this.dateFilterChanged(newSettings))
    {
      return true;
    }

    return (this.nameSearch.childNameSearchValue != newSettings.nameSearch.childNameSearchValue) || 
      this.optionsChanged(this.nameSelect, newSettings.nameSelect) || 
      this.optionsChanged(this.interventionSelect, newSettings.interventionSelect) || 
      this.optionsChanged(this.visitFrequencySelect, newSettings.visitFrequencySelect) || 
      this.optionsChanged(this.priorityLevels, newSettings.priorityLevels);
  }

  dateFilterChanged(newSettings: FilterSettings)
  {
    return this.selectedDateRange.dateFilterChanged(newSettings.selectedDateRange);
  }

  optionsChanged(currentOptions: MultiChoiceSearch, previousOptions: MultiChoiceSearch)
  {
    return currentOptions.optionChanged(previousOptions);
  }

  requireNewServerData(newSettings: FilterSettings, previousServerSearchStart: Date, previousServerSearchEnd: Date)
  {
    if(previousServerSearchStart == null || previousServerSearchEnd == null) return false;

    var response = this.dateFilterChanged(newSettings);
    if(!response) return false;

    // The dates have changed, but we only need new data if the new dates are outside
    // the bounds of the dates used to retrieve data
    return (newSettings.effectiveStartDate() < previousServerSearchStart || 
      newSettings.effectiveEndDate() > previousServerSearchEnd);
  }

  setThisWeek() {
    this.selectedDateRange.setThisWeek();
  }

  setThisMonth() {
    this.selectedDateRange.setThisMonth();
  }

  setCustomRange(startDate: Date, endDate: Date) {
    this.selectedDateRange.setCustomRange(startDate, endDate);
  }

  effectiveStartDate(): Date {
    return this.selectedDateRange.effectiveStartDate();
  }

  effectiveEndDate(): Date {
    return this.selectedDateRange.effectiveEndDate();
  }

  getDefaultDateRangeTypeAsString() : string {
    return 'In the next 4 weeks';
  }
}
