import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class LocalStorageService {
  localStorage: Storage;
  private allKeysKeyName: string = 'CSPortalAllKeysKeyName';

  changes$ = new Subject();

  constructor() {
    this.localStorage   = window.localStorage;
  }

  get(key: string): any {
    if (this.isLocalStorageSupported) {
      return JSON.parse(this.localStorage.getItem(key));
    }

    return null;
  }

  set(key: string, value: any): boolean {
    if (this.isLocalStorageSupported) {
      this.localStorage.setItem(key, JSON.stringify(value));
      this.changes$.next({
        type: 'set',
        key,
        value
      });
      this.trackKey(key);
      return true;
    }

    return false;
  }

  check(key: string) {
    var response = this.get(key);
    return response != null && response != undefined && response != 'undefined';
  }

  deleteAll(): boolean {
    var allKeyNames = this.get(this.allKeysKeyName);
    if(!allKeyNames)
    {
      // Nothing to delete
      return true;
    }
    else
    {
      // Remove all keys from storage
      allKeyNames.forEach((nextKey, index) => {
        this.remove(nextKey);
      });

      // Clear the collection of tracked key names
      this.set(this.allKeysKeyName, []);
    }
  }

  deleteAllWithPrefix(prefix): boolean {
    var allKeyNames = this.get(this.allKeysKeyName);
    if(!allKeyNames)
    {
      // Nothing to delete
      return true;
    }
    else
    {
      // Remove all keys from storage
      allKeyNames.forEach((nextKey, index) => {
        if(nextKey.startsWith(prefix))
        {
          this.remove(nextKey);
        }
      });

      // Clear the collection of tracked key names
      var allKeyNames = this.get(this.allKeysKeyName);
      for(var i = 0; i < allKeyNames.length; i++)
      {
        if(allKeyNames[i].startsWith(prefix))
        {
          allKeyNames.splice(i, 1);
          i--;
        }
      }
    }
  }

  // Track each unique key that is stored.
  // Enable the concept to 'delete all' related to this application
  private trackKey(key: string)
  {
    var allKeyNames = this.get(this.allKeysKeyName);
    if(!allKeyNames)
    {
      // Create the new entry with this initial key value
      this.set(this.allKeysKeyName, [ key ]);
    }
    else
    {
      // Check we don't already know of this key
      var index = allKeyNames.indexOf(key);
      if(index < 0)
      {
        // Add the key to the collection and update the value in local storage
        allKeyNames.push(key);
        this.set(this.allKeysKeyName, allKeyNames);
      }
    }
  }

  remove(key: string): boolean {
    if (this.isLocalStorageSupported) {
      this.localStorage.removeItem(key);
      this.changes$.next({
        type: 'remove',
        key
      });
      return true;
    }

    return false;
  }

  get isLocalStorageSupported(): boolean {
    return !!this.localStorage
  }
}