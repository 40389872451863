import { from, Observable } from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import { HttpResponse  } from '@angular/common/http';
import { IResponse } from './response-interface';

declare module 'rxjs' {
  interface Observable<T> {
    asJsonResponse<R>(): Observable<IResponse<R>>;
  }
}

Observable.prototype.asJsonResponse = function <T, R>(this: Observable<T>) {
  return this.pipe(
    map(r => <HttpResponse<any>>(<any>r)),
    map(r => <IResponse<any>>({ hasError: false, value: <any>r })),
    catchError(x => 
      from([{ hasError: true }])
    )
  );
};

