import { Component, OnInit, Input } from '@angular/core';


@Component({
  selector: '[app-court-order-milestone]',
  templateUrl: './court-order-milestone.component.html',
  styleUrls: ['./court-order-milestone.component.scss']
})
export class CourtOrderMilestoneComponent implements OnInit {
  @Input() milestone: Api.CourtOrderMilestone;
  @Input() isNext: boolean;

  constructor() {
  }

  ngOnInit() {
  }

}
