import { Injectable } from '@angular/core';
import { csx } from 'services/api/urls';
import * as moment from 'moment';
import { HttpClient } from '@angular/common/http';
import { PersonaSwitcherService } from 'services/api/persona-switcher.service';

@Injectable()
export class TaskSubsystemService {
  private overrideUpn: string = null;

  constructor(private http: HttpClient,
    private personaSwitcherService: PersonaSwitcherService) { }

  async getUpcomingChildHomeVisits(fromDateTimeUtc: Date, toDateTimeUtc: Date) {
    let headers = {};
    if (this.personaSwitcherService.getPersonaUpn() || this.overrideUpn) {
      headers = {
        'upn': btoa(this.personaSwitcherService.getPersonaUpn() || this.overrideUpn)
      };
    }

    return this.http
      .get<Api.ChildHomeVisit[]>(
        csx.upcomingChildHomeVisits({
          fromDateTimeUtc: moment(fromDateTimeUtc)
            .utc()
            .format(),
          toDateTimeUtc: moment(toDateTimeUtc)
            .utc()
            .format(), 
          page: '0', 
          pageSize: '10000'
        }),
        {
          headers: headers
        }
      )
      .asJsonResponse<Api.ChildHomeVisit[]>()
      .toPromise();
  }

  async getUpcomingChildHomeVisitDetail(personId: string) {
    let headers = {};
    if (this.personaSwitcherService.getPersonaUpn() || this.overrideUpn) {
      headers = {
        'upn': btoa(this.personaSwitcherService.getPersonaUpn() || this.overrideUpn)
      };
    }

    return this.http
      .get<Api.HomeVisitPersonDetail>(
        csx.upcomingChildHomeVisitDetail({
          personId: personId
        }),
        {
          headers: headers
        }
      )
      .asJsonResponse<Api.HomeVisitPersonDetail>()
      .toPromise();
  }

  async getOverdueChildHomeVisits(pageNumber: string, pageSize: string) {
    let headers = {};
    if (this.personaSwitcherService.getPersonaUpn() || this.overrideUpn) {
      headers = {
        'upn': btoa(this.personaSwitcherService.getPersonaUpn() || this.overrideUpn)
      };
    }

    return this.http
      .get<Api.ChildHomeVisit[]>(
        csx.overdueChildHomeVisits({
          page: pageNumber, 
          pageSize: pageSize
        }),
        {
          headers: headers
        }
      )
      .asJsonResponse<Api.ChildHomeVisit[]>()
      .toPromise();
  }
  
  async getCurrentCourts(fromDateTimeUtc: Date, toDateTimeUtc: Date) {
    let headers = {};
    if (this.personaSwitcherService.getPersonaUpn() || this.overrideUpn) {
      headers = {
        'upn': btoa(this.personaSwitcherService.getPersonaUpn() || this.overrideUpn)
      };
    }

    return this.http
    .get<Api.CourtOrder[]>(
      csx.currentCourtOrders({
        fromDateTimeUtc: moment(fromDateTimeUtc)
          .utc()
          .format(),
        toDateTimeUtc: moment(toDateTimeUtc)
          .utc()
          .format()
      }),
      {
        headers: headers
      }
    )
    .asJsonResponse<Api.CourtOrder[]>()
    .toPromise();
  }
}
