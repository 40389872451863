<div>
  <!-- A hidden modal -->
  <jw-modal id="check-out-modal">
    <div class="jw-modal-header container">
      <h2><span class="check-out centered-icon" aria-label="Check in out icon" title="Check in out icon"></span> Checking Out?</h2>
    </div>

    <div class="jw-modal-inner-body">
      <p>Looks like you're leaving your Service Centre. Tell your team your whereabouts.</p>

      <div class="md-form">
        <div class="input-group">
          <ng-select [items]="officeLocations" [ngClass]="{ 'select-grey-option-1': defaultLocationSelected() }" required 
            bindLabel="location" 
            bindValue="location"
            [(ngModel)]="selectedLocation" (ngModelChange)="onLocationChange($event)" (clear)="onLocationClear()">
          </ng-select>
          <div class="text-danger" *ngIf="!!showLocationError">
            <strong>Please select where you are going.</strong>
          </div>
        </div>

        <div class="input-group field-space-top-large">
          <p class="align-left">When will you be back?</p>
          <app-date-time-input class="field-space-top-small"></app-date-time-input>
        </div>

        <div class="input-group field-space-top-xlarge">
          <textarea class="form-control" rows="5" [(ngModel)]="optionalComment" placeholder="Comment (optional)" (ngModelChange)="onCommentChange($event)"></textarea>
          <div class="text-danger field-space-top-small" *ngIf="!!showOptionalCommentError">
            <strong>Please limit your comment to no more than 256 characters.</strong>
          </div>
        </div>

        <div class="flex field-space-top-large">
          <button aria-label="Cancel" title="Cancel" class="external-action flex-child btn btn-2 btn-2g modal-btn mr-1" 
            (click)="closeModal('check-out-modal');">Cancel</button>

          <button aria-label="Confirm" title="Confirm" class="external-action flex-child btn btn-2 btn-2g modal-btn color-invert " 
            (click)="setCheckedOut();">
            <h5><span class="check-out-white centered-icon" aria-label="Check in out icon" title="Check in out icon"></span> {{ editing ? 'Update' : 'Check Out' }}</h5>
          </button>
        </div>
      </div>
    </div>
  </jw-modal>
</div>