import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-no-results-message',
  templateUrl: './no-results-message.component.html',
  styleUrls: ['./no-results-message.component.scss']
})
export class NoResultsMessageComponent implements OnInit {

  @Input() customHeader: string;
  @Input() includeFilterTroubleshooting: boolean = false;
  @Input() includeTroubleshooting: boolean = true;

  constructor() {

  }

  ngOnInit(): void {
    if (!this.customHeader || this.customHeader.trim().length === 0) {
      this.customHeader = 'Sorry, no results were found';
    }

    if (this.includeFilterTroubleshooting === null || this.includeFilterTroubleshooting === undefined) {
      this.includeFilterTroubleshooting = false;
    }
  }
}
