import { Component, OnInit } from '@angular/core';
import { UserInfoService } from '../../../services/user-info.service';
import * as moment from 'moment';

@Component({
  selector: 'app-happy-day-motif',
  templateUrl: './happy-day-motif.component.html',
  styleUrls: ['./happy-day-motif.component.scss']
})
export class HappyDayMotifComponent implements OnInit {

  firstName: string;

  constructor(private userInfoService: UserInfoService) { 
    this.firstName = userInfoService.firstName();
  }

  ngOnInit(): void {
  }

  currentDay(): string {
    return moment().local().format('dddd');
  }
}
