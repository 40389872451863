import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { csx } from 'services/api/urls';
import * as Bowser from "bowser";
import { FeatureName } from "api/website_enum";

@Injectable()
export class FeatureLoggerService {

  constructor(private http: HttpClient) { }

  async logFeatureUsage(featureName: FeatureName) {
    const bowser = Bowser.getParser(window.navigator.userAgent);
    const browser = bowser.parseBrowser();
    const platform = bowser.parsePlatform();
    const os = bowser.parseOS();

    let featureLogParameters = <Api.PortalFeatureParameters>{
      featureName: featureName,
      browser: <Api.BrowserInfo>{
        language: window.navigator.language,
        name: browser.name,
        version: browser.version,
        platform: platform.type,
        operatingSystem: `${os.name} ${os.versionName || ''}`.trim(),
        screen: <Api.ScreenInfo>{
          height: window.screen.height,
          width: window.screen.width,
        },
        url: window.location.href
      }
    };

    let base64Params = <Api.Base64PortalFeatureParameters>{ "base64Parameters": btoa(JSON.stringify(featureLogParameters)) };

    let responsePromise: Promise<object>;
    try {
      responsePromise = this.http.post(csx.featureLogger({}), base64Params)
        .toPromise();
    } catch (error) {
      console.error("Failed to send feature log parameters.");
    }
    return responsePromise;
  }
}
