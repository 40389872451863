import * as settings from '../settings';
import {Injectable} from '@angular/core';
import {Router} from "@angular/router";
import {AuthenticationResult, Configuration, LogLevel, PublicClientApplication, SilentRequest} from "@azure/msal-browser";

const adalConfig: Configuration = {
  auth: {
    clientId: settings.appId,
    authority: `https://login.microsoftonline.com/${settings.tenantId}`,
    redirectUri: settings.base
  },
  cache: {
    cacheLocation: 'sessionStorage',
    storeAuthStateInCookie: false,
  },
  system: {
    loggerOptions: {
      loggerCallback: (level: LogLevel, message: string, containsPii: boolean): void => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case LogLevel.Error:
            console.error(message);
            return;
          case LogLevel.Info:
            console.info(message);
            return;
          case LogLevel.Verbose:
            console.debug(message);
            return;
          case LogLevel.Warning:
            console.warn(message);
            return;
        }
      },
      piiLoggingEnabled: false
    },
    windowHashTimeout: 60000,
    iframeHashTimeout: 6000,
    loadFrameTimeout: 0,
    asyncPopups: false
  }
};

const loginRequest = {
  scopes: [`${settings.appId}/.default`]
};


@Injectable()
export class MsalService {
  private context: PublicClientApplication;
  private handleRedirectPromise: Promise<AuthenticationResult>;

  constructor() {
    this.context = new PublicClientApplication(adalConfig);
    this.handleRedirectPromise = this.context.handleRedirectPromise()
    this,this.handleCallback()
  }

  accessIsDenied(): boolean {
    let location = window.location.hash;
    return (location !== undefined && location.indexOf("access_denied") >= 0);
  }

  login(returnRedirectPath: string) {
    localStorage.setItem('msal.angular.login.request', returnRedirectPath);
    this.context.loginRedirect();
  }

  logout() {
    this.clearCache();
    this.context.logoutPopup();
  }

  handleCallback() {
    this.handleRedirectPromise.then(authResult => {
      if (authResult === null) {
        // tell the app i.e. "we're NOT auth'd"
        console.log(`MsalService.handleCallback - notAuthenticated, need to login`);
      } else {
        this.context.setActiveAccount(this.context.getAllAccounts()[0])
        // tell the app i.e. "we're auth'd"
        console.log(`MsalService.handleCallback - authenticated`);
      }
    })
  }

  public get userInfo() {
    return this.context.getActiveAccount();
  }

  getAccessToken(): Promise<string> {
    return new Promise<string>((resolve, reject) => {
        this.context
          .acquireTokenSilent(loginRequest)
          .then((rs) => {
            if (rs && rs.accessToken) {
              resolve(rs.accessToken);
            } else {
              reject('failed to get token');
            }
          })
          .catch((err) => {
            reject(JSON.stringify(err));
          });
      });
  }

  public async isAuthenticated(): Promise<boolean> {
    await this.handleRedirectPromise;
    return !!this.context.getActiveAccount();
  }

  public clearCache(): void {
    localStorage.clear();
  }
}
