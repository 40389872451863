import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { FilterSettings } from '../../app/dashboard/home-visits/filter-settings';

@Injectable()
export class FilterNotificationService {

    constructor() { }

    private selectedFilters = new Subject<FilterSettings>();
    public selectedFilterChanged = this.selectedFilters.asObservable();

    ChangeSelectedFilters(newMessage: FilterSettings) {
        this.selectedFilters.next(newMessage);
    }
}