<nav>
  <ul class="officer-area">
    <li tabindex="0" class="current-persona" *ngIf="currentPersona" (click)="removePersona()">
      <i class="fas fa-user-shield fa-lg"></i>
      <div>
        <div>Viewing as {{currentPersona.firstName}} {{currentPersona.lastName}}</div>
        <div class="stop-persona-switch">Click to Stop</div>
      </div>
    </li>

    <li tabindex="0">
      <i class="fas fa-exchange-alt fa-lg"></i><span>Switch Application</span><i class="fas fa-angle-down"></i>
      <ul>
        <li>
          <a class="kicbox-url" href="{{applicationUrls.kicboxUrl}}" target="_blank" tabindex="0"
            aria-label="kicbox for staff" (focus)="onSubMenuItemFocus($event)" (blur)="onSubMenuItemBlur($event)"
            (click)="onSubMenuItemClick($event)">kicbox for staff</a>
        </li>
        <li>
          <a class="carer-connect-url" href="{{applicationUrls.carerConnectUrl}}" target="_blank"
            aria-label="Carer Connect admin" tabindex="0" (focus)="onSubMenuItemFocus($event)"
            (blur)="onSubMenuItemBlur($event)" (click)="onSubMenuItemClick($event)">Carer Connect admin</a>
        </li>
        <li>
          <a class="our-child-url" href="{{applicationUrls.ourChildUrl}}" target="_blank" tabindex="0"
            aria-label="Our Child" (focus)="onSubMenuItemFocus($event)" (blur)="onSubMenuItemBlur($event)"
            (click)="onSubMenuItemClick($event)">Our Child</a>
        </li>
        <li>
          <a class="cspm-url" href="{{applicationUrls.practiceManualUrl}}" target="_blank" aria-label="Practice Manual"
            tabindex="0" (focus)="onSubMenuItemFocus($event)" (blur)="onSubMenuItemBlur($event)"
            (click)="onSubMenuItemClick($event)">Practice Manual</a>
        </li>
        <li>
          <a class="icms-url" href="{{applicationUrls.icmsUrl}}" target="_blank" tabindex="0" aria-label="ICMS"
            (focus)="onSubMenuItemFocus($event)" (blur)="onSubMenuItemBlur($event)"
            (click)="onSubMenuItemClick($event)">ICMS</a>
        </li>
        <li>
          <a class="intranet-url" href="{{applicationUrls.intranetUrl}}" target="_blank" tabindex="0"
            aria-label="Intranet" (focus)="onSubMenuItemFocus($event)" (blur)="onSubMenuItemBlur($event)"
            (click)="onSubMenuItemClick($event)">Intranet</a>
        </li>
      </ul>
    </li>
    <li tabindex="0">
      <i class="far fa-user-circle fa-lg"></i><span>{{fullName}}</span><i class="fas fa-angle-down"></i>
      <ul class="nav-main-menu">
        <li>
          <a href="{{kicboxMyProfile.kicboxMyProfileUrl}}" class="kicbox-url" tabindex="0" (focus)="onSubMenuItemFocus($event)" aria-label="My Profile"
            (blur)="onSubMenuItemBlur($event)" (click)="onSubMenuItemClick($event)" target="_blank">My Profile</a>
        </li>

        <li *ngIf="showLockdownToggle">
          <a [ngClass]="{ 'faicon': true }" tabindex="0" (focus)="onSubMenuItemFocus($event)" aria-label="Office Health Icon" 
            (blur)="onSubMenuItemBlur($event)" (click)="toggleLockdown()" target="_blank">
            <i [ngClass]="{ 'fas': true, 'fa-check-circle': officeIsLockedDown, 'fa-exclamation-triangle': !officeIsLockedDown, 'fa-lg': true, 'priority-red': !officeIsLockedDown, 'ok-green': officeIsLockedDown }"></i> {{ getOfficeLockdownText }}</a>
        </li>

        <li *ngIf="!priorityIsGloballyDisabled">
          <a [ngClass]="{ 'faicon': true }" tabindex="0" (focus)="onSubMenuItemFocus($event)" aria-label="Toggle Priority Icon" 
            (blur)="onSubMenuItemBlur($event)" (click)="togglePriority()" target="_blank">
            <i [ngClass]="{ 'fas': true, 'fa-home': true, 'fa-lg': true, 'priority-coloured': !priorityIsEnabled, 'priority': priorityIsEnabled }"></i> {{ getDisablePriorityText }}</a>
        </li>

        <li>
          <a [ngClass]="{ 'faicon': true }" routerLink="/auth" tabindex="0" (focus)="onSubMenuItemFocus($event)" aria-label="Logout"
            (blur)="onSubMenuItemBlur($event)" (click)="onSubMenuItemClick($event)">
            <i [ngClass]="{ 'fas': true, 'fa-sign-out-alt': true, 'fa-lg': true, 'logout': true }"></i> Logout</a>
        </li>

        <ng-container *ngIf="isPersonaSwitchingEnabled && !hasTeamError && hasTeamDataLoaded">
          <ng-container *ngFor="let teamLeaderTeam of teams; trackBy: trackTeamBy">
            <li class="team-leader" *ngIf="teamLeaderTeam.team.length > 0">
              <ul>
                <li class="submenu larger">
                  <a class="team-member team" tabindex="0" (focus)="onSubMenuItemFocus($event)"
                    (blur)="onSubMenuItemBlur($event)" (click)="setSelectedTeamLeader(teamLeaderTeam)">
                    {{ (teamLeaderTeam.teamLeader ? 
                          (teamLeaderTeam.teamLeader.firstName == 'Team Leader' ? 
                            'Your Team' :
                            teamLeaderTeam.teamLeader.firstName + ' ' + teamLeaderTeam.teamLeader.lastName
                          )
                      : 'Not allocated to a team')
                    }}
                  </a>
                  <ul>
                    <li *ngFor="let teamMember of teamLeaderTeam.team; trackBy: trackTeamMemberBy">
                      <a class="team-member" tabindex="0" (focus)="onSubMenuItemFocus($event)"
                        (blur)="onSubMenuItemBlur($event)" (click)="setPersona(teamMember)">
                        <span>{{ teamMember.firstName + ' ' + teamMember.lastName }}</span>
                      </a>
                    </li>
                  </ul>
                </li>
              </ul>
            </li>
          </ng-container>
        </ng-container>
      </ul>
    </li>
  </ul>
</nav>