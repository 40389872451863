import { Routes } from '@angular/router';
import { AuthenticationGuard } from 'services/authentication-guard';
import { DashboardComponent } from './dashboard/dashboard.component';
import { StaffWhereaboutsComponent } from './staff-whereabouts/staff-whereabouts.component';
import { AuthComponent } from './authentication/auth.component';
import { UnauthorisedComponent } from './unauthorised/unauthorised.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { CaseSummaryComponent } from './case-summary/case-summary.component';
import { SiteConfigurationComponent } from './site-configuration/site-configuration.component';

export const appRoutes: Routes = [
  {
    path: 'dashboard',
    component: DashboardComponent,
    canActivate: [AuthenticationGuard]
  },
  {
    path: 'home',
    redirectTo: 'dashboard',
    pathMatch: 'full'
  },
  {
    path: '',
    redirectTo: 'dashboard',
    pathMatch: 'full'
  },
  {
    path: 'whereabouts',
    component: StaffWhereaboutsComponent,
    canActivate: [AuthenticationGuard]
  },
  {
    path: 'case-summary',
    component: CaseSummaryComponent,
    canActivate: [AuthenticationGuard]
  },
  {
    path: 'site-config',
    component: SiteConfigurationComponent,
    canActivate: [AuthenticationGuard]
  },
  {
    path: 'auth',
    component: AuthComponent
  },
  {
    path: 'unauthorised',
    component: UnauthorisedComponent
  },
  {
    path: '**',
    component: PageNotFoundComponent
  }
];
