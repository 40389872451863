import { Injectable } from '@angular/core';
import { csx } from 'services/api/urls';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { PersonaSwitcherService } from 'services/api/persona-switcher.service';
import { IResponse } from './response-interface';
import { from } from 'rxjs';
import {catchError, map} from 'rxjs/operators';

@Injectable()
export class OfficeLockdownStatusService {
  private overrideUpn: string = null;

  constructor(private http: HttpClient,
    private personaSwitcherService: PersonaSwitcherService) { }

  async get() : Promise<IResponse<Api.ServiceCentreViewModel>> {
    let headers = {};
    if (this.personaSwitcherService.getPersonaUpn() || this.overrideUpn) {
      headers = {
        'upn': btoa(this.personaSwitcherService.getPersonaUpn() || this.overrideUpn)
      };
    }

    return this.http
      .get<Api.ServiceCentreViewModel>(csx.officeStatus({ }), {
        headers: headers
      })
      .pipe(
        map(r => <HttpResponse<any>>(<any>r)),
        map(r => <IResponse<any>>({ hasError: false, value: <any>r })),
        catchError(x => 
          from([{ hasError: true }])
        )
      )
      .toPromise();
  }
}
