import { Component, Input, ViewChild, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Subscription } from 'rxjs';
import { FocusCheck } from '../../shared/FocusCheck';
import { FeatureName } from "api/website_enum";
import { OverdueVisitsComponent } from "./overdue-visits/overdue-visits.component";
import { UpcomingVisitsComponent } from "./upcoming-visits/upcoming-visits.component";
import { TabNotificationService } from '../../../services/notifications';
import { PersonaSwitcherService } from 'services/api/persona-switcher.service';

@Component({
  selector: 'app-home-visits',
  templateUrl: './home-visits.component.html',
  styleUrls: ['./home-visits.component.scss']
})
export class HomeVisitsComponent extends FocusCheck implements OnInit {
  @ViewChild('overdueVisitsContainer') overdueVisitsContainer: OverdueVisitsComponent;
  @ViewChild('upcomingVisitsContainer') upcomingVisitsContainer: UpcomingVisitsComponent;

  tabNotificationSubscription: Subscription;
  personaChangedSubscription: Subscription;
  selectedTab: FeatureName;
  @Input() homeVisitData: Api.HomeVisitData;

  get overdueTabSelected(): boolean {
    return !this.selectedTab || this.selectedTab === FeatureName.OverdueVisits
  }
  get upcomingTabSelected(): boolean {
    return this.selectedTab && this.selectedTab === FeatureName.UpcomingVisits
  }

  constructor(
    private tabNotificationService: TabNotificationService,
    private titleService: Title, 
    private personaSwitcherService: PersonaSwitcherService, 
  ) {
    super();
    this.titleService.setTitle('CS Portal - Dashboard');
  }

  async ngOnInit(): Promise<void> {
    this.tabNotificationSubscription = this.tabNotificationService.selectedTabChanged.subscribe((tab) => {
      if (this.selectedTab != tab) {
        this.selectedTab = tab;
      }
    });
    this.personaChangedSubscription = this.personaSwitcherService.currentPersonaUpnChanged.subscribe(async (upn) => {
      this.overdueVisitsContainer.personaChanged(upn);
      this.upcomingVisitsContainer.personaChanged(upn);
    });
  }

  ngOnDestroy() {
    // Make sure the periodic api call is stopped.
    if (this.personaChangedSubscription) {
      this.personaChangedSubscription.unsubscribe();
    }
    if (this.tabNotificationSubscription) {
      this.tabNotificationSubscription.unsubscribe();
    }
  }

  showFilter(evt: Event): void {
    if(!!this.overdueTabSelected)
    {
      this.overdueVisitsContainer.showFilter(evt);
    }
    else
    {
      this.upcomingVisitsContainer.showFilter(evt);
    }
  }
}
