import {
  Component,
  OnDestroy,
  HostListener, 
  ViewChild
} from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Subscription } from 'rxjs';
import { TabNotificationService } from '../../services/notifications/tab-notification.service';
import { FocusCheck } from '../shared/FocusCheck';
import { FeatureLoggerService } from 'services';
import { HomeVisitsComponent } from './home-visits';
import { FeatureName } from "api/website_enum";

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent extends FocusCheck implements OnDestroy {
  @ViewChild('homeVisitsContainer') homeVisitsContainer: HomeVisitsComponent;
  tabNotificationSubscription: Subscription;
  
  selectedTab: FeatureName = FeatureName.OverdueVisits;
  overdueVisits: FeatureName = FeatureName.OverdueVisits;
  upcomingVisits: FeatureName = FeatureName.UpcomingVisits;
  ordersNextThreeMonths: FeatureName = FeatureName.CourtOrders3Months;
  ordersNextSixMonths: FeatureName = FeatureName.CourtOrders6Months;

  courtOrdersToDisplay: Api.SelectedCourtOrderData;

  isVisible: boolean = false;

  get overdueTabSelected(): boolean {
    return !this.selectedTab || this.selectedTab === FeatureName.OverdueVisits
  }
  get upcomingTabSelected(): boolean {
    return this.selectedTab && this.selectedTab === FeatureName.UpcomingVisits
  }
  get expiryInThreeMonthsTabSelected(): boolean {
    return this.selectedTab && this.selectedTab === FeatureName.CourtOrders3Months
  }
  get expiryInSixMonthsTabSelected(): boolean {
    return this.selectedTab && this.selectedTab === FeatureName.CourtOrders6Months
  }

  constructor(
    private titleService: Title,
    private tabNotificationService: TabNotificationService, 
    private featureLogger: FeatureLoggerService
  ) {
    super();
    this.titleService.setTitle('CS Portal - Dashboard');
    this.tabNotificationSubscription = this.tabNotificationService.selectedTabChanged.subscribe((tab) => {
      if (this.selectedTab != tab) {
        this.selectedTab = tab;

        this.featureLogger.logFeatureUsage(this.selectedTab);
      }
    });
  }

  async ngOnInit(): Promise<void> {
    this.featureLogger.logFeatureUsage(this.selectedTab);
  }

  ngOnDestroy() {
    if (this.tabNotificationSubscription) {
      this.tabNotificationSubscription.unsubscribe();
    }
  }

  selectTab(tab: FeatureName) {
    this.tabNotificationService.ChangeSelectedTab(tab);
  }

  @HostListener('window:beforeunload ', ['$event'])
  beforeUnload(event: any): void {
    this.ngOnDestroy();
  }

  showFilter(evt: Event): void {
    this.homeVisitsContainer.showFilter(evt);
  }

  public updateCourtOrderDisplay(ordersToDisplay: Api.SelectedCourtOrderData)
  {
    this.courtOrdersToDisplay = ordersToDisplay;
  }

  public updateDashboardVisibility(isVisible: boolean)
  {
    this.isVisible = isVisible;
  }
}
