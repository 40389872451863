import { Component, OnInit, ViewChild, Output, EventEmitter } from '@angular/core';
import { ModalService } from '../../../services/modal.service';
import { DateTimeInputComponent } from './../../shared/date-time-input/date-time-input.component';

@Component({
  selector: 'app-check-out',
  templateUrl: './check-out.component.html',
  styleUrls: ['./check-out.component.scss']
})
export class CheckOutComponent implements OnInit {
  @ViewChild(DateTimeInputComponent)
  private dateTimeInputComponent: DateTimeInputComponent;

  @Output() checkOutClosed: EventEmitter<Api.CheckOutParameters> = new EventEmitter<Api.CheckOutParameters>();
  
  officeLocations: Location[];
  locationPrompt: string = 'Select where you will be';
  selectedLocation: string;
  optionalComment: string;

  showLocationError: boolean;
  showOptionalCommentError: boolean;

  editing: boolean;

  constructor(private modalService: ModalService) {
    this.editing = false;
  }

  ngOnInit() {
    this.officeLocations = this.getLocations();
    this.selectedLocation = this.locationPrompt;

    this.showLocationError = false;
    this.showOptionalCommentError = false;
    this.optionalComment = '';
  }

  // Reset the default value
  onLocationClear()
  {
    this.selectedLocation = this.locationPrompt;
  }

  defaultLocationSelected()
  {
    return this.selectedLocation === this.locationPrompt;
  }

  onLocationChange(newValue) : void 
  {
    this.showLocationError = false;

    if(!this.selectedLocation || this.selectedLocation.length < 1 || this.selectedLocation == this.locationPrompt)
    {
      this.showLocationError = true;
    }
  }

  onCommentChange(newValue) : void 
  {
    this.showOptionalCommentError = false;

    if(this.optionalComment && this.optionalComment.length > 256)
    {
      this.showOptionalCommentError = true;
    }
  }

  offerCheckoutForm(staffMember: Api.StaffLocation, withEdit: boolean)
  {
    // Return if we have no StaffLocation object, or
    // We are not editing and we have a location (should be creating, but already have a value)
    if(staffMember == null || 
      (!withEdit && staffMember.location !== null)) return;

    // If we are editing, seed values
    if(!!withEdit)
    {
      this.editing = true;
      this.selectedLocation = staffMember.location;
      this.optionalComment = staffMember.locationDetails;
      this.dateTimeInputComponent.seedValue(staffMember.expectedReturn);
    }
    else 
    {
      this.editing = false;
      this.dateTimeInputComponent.initialiseValues();
    }
    
    // Present check out dialog
    this.openModal('check-out-modal');
  }

  async setCheckedOut()
  {
    // It is possible to hit 'Check Out' without invoking the validator for location. Run it now.
    this.onLocationChange(this.selectedLocation);

    // If valid, proceed
    if(this.dateTimeInputComponent && this.dateTimeInputComponent.isValid() && !this.showLocationError && !this.showOptionalCommentError)
    {
      // Retrieve the values from the child component
      let expectedReturnToUse = this.dateTimeInputComponent.getValue();

      let locationToUse = this.selectedLocation;
      let locationDetailsToUse = this.optionalComment;

      this.closeModal('check-out-modal');

      this.checkOutClosed.emit({
        expectedReturn: expectedReturnToUse,
        location: locationToUse,
        locationDetails: locationDetailsToUse
      });
    }
  }

  openModal(id: string) {
    this.modalService.open(id);
  }

  closeModal(id: string) {
      this.clearModal();
      this.modalService.close(id);
  }

  clearModal() {
    this.dateTimeInputComponent.initialiseValues();

    this.showLocationError = false;
    this.showOptionalCommentError = false;

    this.selectedLocation = this.locationPrompt;
    this.optionalComment = '';
  }
  
  getLocations() {
    return [
      {
          'location': this.locationPrompt,
          'disabled': true
      },
      {
        'location': 'Supervise Family Contact',
        'disabled': false
      },
      {
        'location': 'Court',
        'disabled': false
      },
      {
        'location': 'Home visit',
        'disabled': false
      },
      {
        'location': 'I&A',
        'disabled': false
      },
      {
        'location': 'Stakeholder Meeting',
        'disabled': false
      },
      {
        'location': 'Placement Meeting',
        'disabled': false
      },
      {
        'location': 'FGM',
        'disabled': false
      },
      {
        'location': 'Training',
        'disabled': false
      },
      {
        'location': 'Lunch',
        'disabled': false
      },
      {
        'location': 'Leave',
        'disabled': false
      },
      {
        'location': 'Other',
        'disabled': false
      }
    ];
  }
}

export interface Location {
  location: string;
  disabled?: boolean;
}