<div class="container">
  <loading-pane [tracker]="tracker">
    <div id="overdue-tab" role="tabpanel" class="table-layout">
      <div role="table">
        <div class="table-sub-heading">
          <div>
            <i *ngIf="showFilterIcon()" class="fas fa-filter fa-lg filter-icon"></i> 
            <span class="filtered-by"
              *ngIf="!this.filterSettings.priorityLevels.allSelected()">{{ getFilterDescription(this.filterSettings.priorityLevels, 'Priority Levels: ') }} </span>
            <span class="filtered-by"
              *ngIf="!this.filterSettings.interventionSelect.allSelected()">{{ getFilterDescription(this.filterSettings.interventionSelect, 'Interventions: ') }} </span>
            <span class="filtered-by"
              *ngIf="!this.filterSettings.visitFrequencySelect.allSelected()">{{ getFilterDescription(this.filterSettings.visitFrequencySelect, 'Frequency: ') }} </span>
            <span class="filtered-by"
              *ngIf="!this.filterSettings.nameSelect.allSelected()">{{ getFilterDescription(this.filterSettings.nameSelect, 'Children: ') }} </span>
            <span class="filtered-by"
              *ngIf="!this.filterSettings.nameSearch.isEmpty()">{{ getChildNameFilterDescription('Child: ') }} </span>
          </div>
          <span class="ordered-by">Ordered by next visit due date</span>
        </div>
        <div [ngClass]="{ 'top-level': true, 'team-view': !!haveStaff(), 'show-priority': !!displayPriorityColours }">
          <div class="row" role="row">
            <div class="th name" role="columnheader" title="Child's preferred name" aria-hidden="true"><span>Name</span></div>
            <div *ngIf="!!displayPriorityColours" class="th priority" role="columnheader" title="Priority" aria-hidden="true"><span>&nbsp;</span></div>
            <div class="th visit-date" role="columnheader" title="Date of the oldest scheduled visit" aria-hidden="true"><span>Overdue Date</span></div>
            <div class="th location" role="columnheader" title="Current location (either Care Arrangement or In Home)" aria-hidden="true"><span>Current Location Type</span></div>
            <div class="th frequency" role="columnheader" title="Calculated minimum contact requirement for visit to child" aria-hidden="true"><span>Visit Frequency *</span></div>
            <div *ngIf="!!haveStaff()" class="th allocated-to" role="columnheader" title="Allocated To" aria-hidden="true"><span>Allocated To</span></div>
          </div>
          <app-error-message *ngIf="overdueVisitData.visitsHasError" appSection="overdue home visits"></app-error-message>
          <app-no-results-message
            *ngIf="!overdueVisitData.visitsHasError && overdueVisitData.visitsLoaded && (!overdueVisitData.visits || overdueVisitData.visits.length === 0)"
            customHeader="Awesome! It looks like you have no overdue visits to children or young people!"
            [includeTroubleshooting]="false" [includeFilterTroubleshooting]="false">
          </app-no-results-message>
          <ng-container *ngFor="let homeVisit of pagedVisitsToShow; trackBy: trackBy">
            <loading-pane [enabled]="showUpdateGraphic(homeVisit)">
            <div class="row" role="row" [attr.data-person-id]="homeVisit.subjectChildId" [ngClass]="{ 'even-row': isEven(homeVisit) }">

                <div class="td emphasised name" data-title="Name" role="cell">
                  <div class="visually-hidden" title="Child's preferred name">Name</div>
                  <div>
                    <i class="far fa-user fa-lg"></i>
                    <div>
                      <a *ngIf="hasDestinationPath(homeVisit)" href="{{ getDestinationPath(homeVisit) }}"
                        [attr.aria-label]="'ICMS event documents for child, ' + homeVisit.subjectChildName"
                        [title]="'ICMS event documents for child, ' + homeVisit.subjectChildName"
                        target="_blank">{{ homeVisit.subjectChildName }}</a>
                      <span *ngIf="!hasDestinationPath(homeVisit)">{{ homeVisit.subjectChildName }}</span>
                    </div>
                  </div>
                </div>
                <div *ngIf="!!displayPriorityColours" class="td priority" data-title="Visit Priority" role="cell">
                  <div class="visually-hidden" title="Priority"></div>
                  <span [title]="priorityTitle(homeVisit)"><i [ngClass]="{ 'fas': true, 'fa-home': true, 'fa-lg': true, 'priority-red': homeVisit.isOverdueWithHighPriority, 'priority-amber': homeVisit.isOverdueWithModeratePriority }"></i></span>
                </div>
                <div class="td visit-date" data-title="Visit Date" role="cell">
                  <div class="visually-hidden" title="Date of the oldest scheduled visit">Overdue Date</div>
                  <span title="Oldest scheduled visit">{{ formatShortDate(homeVisit.dueDtm) }}</span>
                </div>
                <div class="td location" data-title="Current Location Type" role="cell">
                  <div class="visually-hidden" title="Current location (either Care Arrangement or In Home)">Current Location Type</div>
                  <div>{{ homeVisit.currentLocationType }}</div>
                </div>
                <div class="td frequency" data-title="Visit Frequency" role="cell">
                  <div class="visually-hidden" title="Calculated minimum contact requirement for visit to child">Visit Frequency</div>
                  <div title="Recommended frequency based on ICMS data & SDM.">{{ homeVisit.frequency }} *</div>
                </div>
                <div *ngIf="!!haveStaff()" class="td allocated-to" data-title="Allocated To" role="cell">
                  <div class="visually-hidden" title="Allocated To">Allocated To</div>
                  <span>{{ homeVisit.ownerName && homeVisit.ownerName.length > 0 ? homeVisit.ownerName : 'Me' }}</span>
                </div>
                <div class="td case-note" role="cell">
                  <div><a *ngIf="hasDestinationPath(homeVisit)" href="{{ getDestinationPath(homeVisit) }}" target="_blank" 
                      (click)="logCaseNoteFeature()" 
                      [attr.aria-label]="'ICMS event documents for child, ' + homeVisit.subjectChildName"
                      [title]="'ICMS event documents for child, ' + homeVisit.subjectChildName" class="external-action">
                      Create Case Note <i class="fas fa-pen"></i></a>
                  </div>
                </div>
                
            </div>
          </loading-pane>
          </ng-container>
        </div>
      </div>
    </div>
  </loading-pane>
  <app-pagination (pageChangedEvent)="onPageChangedEvent($event)" (pageSizeChangedEvent)="onPageSizeChangedEvent($event)" [recordCount]="overdueVisitCount" [style]="homeVisitsStyle"></app-pagination>
</div>
