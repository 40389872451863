<section>
  <ng-container *ngIf="isLoggedIn$ | async">
    <p>
      You are currently logged in as <strong>{{ userName }}</strong>. Click the button below to log out.
      <button (click)="logout()">Logout</button>
    </p>
  </ng-container>
  <ng-container *ngIf="!(isLoggedIn$ | async)">
    <p>You have been logged out. Please click the button if you wish to log in again. <button
        (click)="login()">Login</button></p>
  </ng-container>
</section>