import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class CourtOrderPromiseNotifyService {

    constructor(
    ) { }

    private courtOrderPromise = new Subject<Promise<void | [void, void]>>();
    public courtOrdersPromiseChanged = this.courtOrderPromise.asObservable();

    ChangeCourtOrderPromise(promise: Promise<void | [void, void]>) {
        this.courtOrderPromise.next(promise);
    }
}
