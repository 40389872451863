import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import * as moment from 'moment';
import { WorkTracker } from 'app/shared/loading-pane';
import { CaseSummaryService, RecordChangeDetectionService } from 'services';

interface AlertCount {
  description: string;
  count: number;
}

@Component({
  selector: 'app-case-summary-detail',
  templateUrl: './case-summary-detail.component.html',
  styleUrls: ['./case-summary-detail.component.scss']
})
export class CaseSummaryDetailComponent implements OnChanges {
  @Input() ongoingIntervention: Api.OngoingIntervention;
  @Input() isExpanded: boolean;
  ongoingInterventionDetail: Api.OngoingInterventionDetail;
  tracker: WorkTracker;
  dataLoaded: boolean;
  hasError: boolean;
  amberAlerts: AlertCount[];
  redAlerts: AlertCount[];
  mood: string;

  recordChangeDetectionService: RecordChangeDetectionService;

  constructor(private caseSummaryService: CaseSummaryService) {
    this.tracker = new WorkTracker(true);

    this.recordChangeDetectionService = new RecordChangeDetectionService();
    this.recordChangeDetectionService.initialise(
      ['currentLocationType', 'frequency', 'amberAlerts', 'redAlerts', 'hasAmberAlert', 'hasRedAlert', 'latestKicboxMood'], 
      ['subjectId'], 'case-summary-detail'
    );
  }

  async ngOnChanges(changes: SimpleChanges) {
    if(changes['ongoingIntervention'] && !changes['ongoingIntervention'].isFirstChange())
    {
      this.applyNewDataSet();
    }
    else if (changes['isExpanded'] && changes['isExpanded'].currentValue === true) {
      this.hasError = false;
      this.dataLoaded = false;
      await this.tracker.track(this.getOngoingInterventionDetailFromServer(this.ongoingIntervention.subjectId));
    }
  }

  formatShortDate(date: string) {
    return !!date
      ? moment(date)
        .local()
        .format('DD/MM/YYYY')
      : 'n/a';
  }

  addressLines(address: string): string[] {
    let parts = (address || '').split(',');
    let partsLength = parts.length;
    if (partsLength === 0) return [];

    return parts.map((o, i) => (i < partsLength - 1) ? `${o},` : `${o}`);
  }

  private groupedAlerts(alerts: string[]): AlertCount[] {
    if (!alerts) return [];

    let grouped = alerts.reduce((g: any, alert: string) => {
      g[alert] = g[alert] || [];
      g[alert].push(alert);
      return g;
    }, {});

    return Object.keys(grouped).map(o => ({ count: grouped[o].length, description: o }));
  }

  private generateCurrentDataSet()
  {
    //if(!this.ongoingInterventionDetail || !this.ongoingIntervention) return null;
    return {
      currentLocationType: this.ongoingIntervention ? this.ongoingIntervention.currentLocationType : "",
      frequency: this.ongoingIntervention ? this.ongoingIntervention.frequency : "",
      amberAlerts: !!this.ongoingInterventionDetail ? this.ongoingInterventionDetail.amberAlerts : [],
      redAlerts: !!this.ongoingInterventionDetail ? this.ongoingInterventionDetail.redAlerts : [],
      hasAmberAlert: !!this.ongoingInterventionDetail ? this.ongoingInterventionDetail.hasAmberAlert : false,
      hasRedAlert: !!this.ongoingInterventionDetail ? this.ongoingInterventionDetail.hasRedAlert : false,
      latestKicboxMood: !!this.ongoingInterventionDetail ? this.ongoingInterventionDetail.latestKicboxMood : ""
    };
  }

  private applyNewDataSet()
  {
    var currentInstance = this.generateCurrentDataSet();
    if(!currentInstance) return;
    this.recordChangeDetectionService.applyNewDataSet([currentInstance]);
  }

  private getOngoingInterventionDetailFromServer(personId: string): Promise<void> {
    try {
      return this.caseSummaryService.getOngoingInterventionDetails(personId).then(result => {
        this.hasError = (!result || result === null);
        if (!result.hasError) {
          this.ongoingInterventionDetail = result.value;
          this.mood = this.setMoodSpan(this.ongoingInterventionDetail.latestKicboxMood, this.ongoingInterventionDetail.kicboxStatus);
          this.amberAlerts = this.groupedAlerts(result.value.amberAlerts);
          this.redAlerts = this.groupedAlerts(result.value.redAlerts);
          this.dataLoaded = true;

          this.applyNewDataSet();
        }
      });
    } catch (error) {
      console.error(error);
      this.hasError = true;
    }
  }

  showUpdateGraphic(): boolean {
    var currentData = this.generateCurrentDataSet();
    if(!currentData) return;
    return this.recordChangeDetectionService.showUpdateGraphic(currentData);
  }

  private setMoodSpan(item: string, status: string): string {
    if (item && item.trim().length > 0) {
      item = `:${item}:`;
      let moodRegEx = new RegExp(/:other:[^\:]+:|:[a-zA-Z-]+:/g);
      let result = moodRegEx.exec(item);

      if (result && result.length === 1) {
        let matchedText = result[0];

        // Remove mood if the status changes from 'Active' to anything else. Ref: Bug 12254
        if(!status || status !== 'Active')
        {
          return item.replace(`${matchedText}`, `No mood recorded`);
        }

        let parts = matchedText.split(':').filter(o => o && o.trim().length > 0);
        let moodText: string;
        let cssClassName: string;

        if (parts.length === 2) {
          // i.e. it's a 'custom' mood like :other:i've had enough:
          cssClassName = parts[0];
          moodText = parts[1];
        } else {
          // i.e. it's a pre-defined mood like :happy:
          cssClassName = parts[0];
          moodText = parts[0];
        }

        return item.replace(`${matchedText}`, `<span title="${moodText}"><i class="big-mood mood-${cssClassName}"></i></span>`);
      }
    }

    return item;
  }
}
