
import {interval as observableInterval,  Subscription, Observable } from 'rxjs';
import {
  Component,
  OnInit,
  OnDestroy,
  HostListener
} from '@angular/core';
import * as moment from 'moment';
import { CaseSummaryService } from '../../services/api/case-summary.service';
import { caseSummarySettings } from '../../settings';
import { Title } from '@angular/platform-browser';
import { WorkTracker } from 'app/shared/loading-pane';
import { IdleDetectionService, FeatureLoggerService, RecordChangeDetectionService } from 'services';
import { FocusCheck } from '../shared/FocusCheck';
import { PersonaSwitcherService } from 'services/api/persona-switcher.service';
import { FeatureName } from "api/website_enum";

@Component({
  selector: 'app-case-summary',
  templateUrl: './case-summary.component.html',
  styleUrls: ['./case-summary.component.scss']
})
export class CaseSummaryComponent extends FocusCheck implements OnInit, OnDestroy {
  tracker: WorkTracker;
  intervalSubscription: Subscription;
  idleDetectionSubscription: Subscription;
  personaChangedSubscription: Subscription;
  ongoingInterventions: Api.OngoingIntervention[];

  selectedOngoingInterventions: Api.OngoingIntervention[] = [];
  hasError: boolean;
  dataLoaded: boolean;
  userIsIdle: boolean = false;
  isDestroyed: boolean = false;
  refreshIntervalInMilliseconds: number;

  recordChangeDetectionService: RecordChangeDetectionService;

  // Pagination settings
  currentPage: number = 0;
  pageCount: number = 0;
  pageSize: number = 10; // This is communicated from the pagination component
  caseSummaryCount: number = 0;
  caseSummaryStyle: string = 'case-summary';

  constructor(
    private titleService: Title,
    private caseSummaryService: CaseSummaryService,
    private idleDetectionService: IdleDetectionService,
    private featureLogger: FeatureLoggerService,
    private personaSwitcherService: PersonaSwitcherService
  ) {
    super();
    this.tracker = new WorkTracker(true);
    this.titleService.setTitle('CS Portal - Case Summary');
    this.refreshIntervalInMilliseconds = caseSummarySettings.refreshIntervalInSeconds * 1000;
    this.subscribeToPersonaChangedNotifications();

    this.recordChangeDetectionService = new RecordChangeDetectionService();
    this.recordChangeDetectionService.initialise(
      ['interventionType', 'lastVisitDtm'], 
      ['subjectId'], 'case-summary'
    );
  }

  async ngOnInit(): Promise<void> {
    this.featureLogger.logFeatureUsage(FeatureName.OICaseSummary);

    this.idleDetectionService.reset();
    this.idleDetectionSubscription = this.idleDetectionService.isIdleChanged.subscribe(isIdle => this.userIsIdle = isIdle);

    await this.tracker.track(
      this.loadData().then(() => {
        if (!this.intervalSubscription && !this.isDestroyed) {
          this.intervalSubscription = observableInterval(this.refreshIntervalInMilliseconds).subscribe(
            async () => {
              await this.loadData();
            }
          );
        }
      })
    );
  }

  ngOnDestroy() {
    // Make sure the periodic api call is stopped.
    if (this.intervalSubscription) {
      this.intervalSubscription.unsubscribe();
    }

    if (this.idleDetectionSubscription) {
      this.idleDetectionSubscription.unsubscribe();
    }

    if (this.personaChangedSubscription) {
      this.personaChangedSubscription.unsubscribe();
    }

    this.idleDetectionService.ngOnDestroy();

    this.isDestroyed = true;
  }

  async onPageChangedEvent(pageNumber: number)
  {
    if(pageNumber != this.currentPage)
    {
      this.currentPage = pageNumber;
      await this.tracker.track(
        this.loadData()
      );
    }
  }

  async onPageSizeChangedEvent(pageSize: number)
  {
    if(pageSize != this.pageSize)
    {
      this.pageSize = pageSize;
      await this.tracker.track(
        this.loadData()
      );
    }
  }

  async loadData() {
    if (this.isDestroyed) {
      this.ngOnDestroy();
      return Promise.resolve();
    }

    return await Promise.all([
      this.getCaseSummariesFromServer(),
    ]);
  }

  trackOiBy(index: number, item: Api.OngoingIntervention) {
    return `${item.subjectId}-${item.subjectName}`;
  }

  private clearSelectedVisits() {
    this.selectedOngoingInterventions = [];
  }

  private getCaseSummariesFromServer(): Promise<void> {
    if (!this.shouldRefreshData()) {
      return Promise.resolve();
    }

    try {
      // Retrieve all summary data for the given 'currentPage' with the given 'pageSize' count of records, at most
      return this.caseSummaryService.getCaseSummaries(this.currentPage, this.pageSize).then(result => {
        this.hasError = result.hasError;
        if(!!result && !!result.value && !this.hasError)
        {
          this.ongoingInterventions = result.value.ongoingInterventions;
          this.caseSummaryCount = result.value.ongoingInterventionCount;

          // The server will always respond with the count of pages available based on the given 'pageSize' paramter
          this.pageCount = result.value.ongoingInterventionsPages;
        }
        else
        {
          this.ongoingInterventions = [];
          this.caseSummaryCount = 0;
        }
        this.recordChangeDetectionService.applyNewDataSet(this.ongoingInterventions);
        this.dataLoaded = true;
      });
    } catch (error) {
      console.error(error);
      this.hasError = true;
      this.ongoingInterventions = [];
      this.caseSummaryCount = 0;
    }
  }

  showUpdateGraphic(intervention: Api.OngoingIntervention): boolean {
    return this.recordChangeDetectionService.showUpdateGraphic(intervention);
  }

  private subscribeToPersonaChangedNotifications() {
    this.personaSwitcherService.enable();
    if (!this.personaChangedSubscription) {
      this.personaChangedSubscription = this.personaSwitcherService.currentPersonaUpnChanged.subscribe(async (upn) => {
        this.clearSelectedVisits();
        await this.tracker.track(this.loadData());
      });
    }
  }

  formatShortDate(date: string): string {
    return !!date
      ? moment(date)
        .local()
        .format('DD/MM/YYYY')
      : 'n/a';
  }

  isEven(item: Api.OngoingIntervention): boolean {
    if (!this.ongoingInterventions || !this.ongoingInterventions) return false;

    let index = this.ongoingInterventions.indexOf(item, 0);

    return index % 2 === 0;
  }

  selectOngoingIntervention(item: Api.OngoingIntervention): void {

    if (this.isOngoingInterventionSelected(item)) {
      let foundItem = this.selectedOngoingInterventions.filter(o => o.subjectId === item.subjectId)[0];
      if (foundItem) {
        const index = this.selectedOngoingInterventions.indexOf(foundItem, 0);
        if (index > -1) {
          this.selectedOngoingInterventions.splice(index, 1);
        }
        return;
      }
    }

    this.featureLogger.logFeatureUsage(FeatureName.OICaseSummaryShowMore);
    this.selectedOngoingInterventions.push(item);
  }

  isOngoingInterventionSelected(item: Api.OngoingIntervention): boolean {
    if (!item || !this.selectedOngoingInterventions) return false;

    return this.selectedOngoingInterventions.filter(o => o.subjectId === item.subjectId).length > 0;
  }

  private shouldRefreshData() {
    if (this.isDestroyed) return false;

    return !this.dataLoaded || (!this.userIsIdle && this.windowGotFocus && !document.hidden);
  }

  @HostListener('window:beforeunload ', ['$event'])
  beforeUnload(event: any): void {
    this.ngOnDestroy();
  }
}
