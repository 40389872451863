<div class="details">
  <loading-pane [tracker]="tracker" [enabled]="showUpdateGraphic()">
    <div>
      <app-error-message *ngIf="hasError"></app-error-message>
      <span class="restricted-message" *ngIf="dataLoaded && !hasError && ongoingInterventionDetail.isSensitive">
        <i class="fas fa-user-shield"></i> Sensitive User
      </span>
      <ng-container *ngIf="dataLoaded && !hasError">
        <div class="row" role="row">
          <div class="th" role="columnheader" title="Header Column Indigenous Status" aria-hidden="true"><span>Indigenous Status</span></div>
          <div class="th" role="columnheader" title="Header Column kicbox Status" aria-hidden="true"><span>kicbox Status</span></div>
          <div class="th" role="columnheader" title="Header Column Expiry of CPO" aria-hidden="true"><span>Expiry of CPO</span></div>
          <div class="th" role="columnheader" title="Header Column Location Type" aria-hidden="true"><span>Location Type</span></div>
          <div class="th" role="columnheader" title="Header Column OI Commencement" aria-hidden="true"><span>OI Commencement</span></div>
        </div>
        <div class="row" role="row">
          <div class="td" role="cell" data-title="Indigenous Status">
            <div class="visually-hidden" title="Header Column Indigenous Status">Indigenous Status</div>
            <div *ngIf="!ongoingInterventionDetail.isCensoredDueToSensitivity">{{ongoingInterventionDetail.indigenousStatus | noData}}</div>
            <div *ngIf="!!ongoingInterventionDetail.isCensoredDueToSensitivity">***</div>
          </div>
          <div class="td" role="cell" data-title="kicbox Status">
            <div class="visually-hidden" title="Header Column kicbox Status">kicbox Status</div>
            <span *ngIf="!ongoingInterventionDetail.isCensoredDueToSensitivity">{{ongoingInterventionDetail.kicboxStatus | noData}}</span>
            <span *ngIf="!!ongoingInterventionDetail.isCensoredDueToSensitivity">***</span>
          </div>
          <div class="td" role="cell" data-title="Expiry of CPO">
            <div class="visually-hidden" title="Header Column Expiry of CPO">Expiry of CPO</div>
            <span *ngIf="!ongoingInterventionDetail.isCensoredDueToSensitivity">{{formatShortDate(ongoingInterventionDetail.expiryOfCPODate)}}</span>
            <span *ngIf="!!ongoingInterventionDetail.isCensoredDueToSensitivity">***</span>
          </div>
          <div class="td" role="cell" data-title="Location Type">
            <div class="visually-hidden" title="Header Column Location Type">Location Type</div>
            <span>{{ongoingIntervention.currentLocationType | noData}}</span>
          </div>
          <div class="td" role="cell" data-title="OI Commencement">
            <div class="visually-hidden" title="Header Column OI Commencement">OI Commencement</div>
            <span>{{formatShortDate(ongoingIntervention.commencementDate)}}</span>
          </div>
        </div>
        <div class="row" role="row">
          <div class="th" role="columnheader" title="Header Column Ethnicity" aria-hidden="true"><div>Ethnicity</div></div>
          <div class="th" role="columnheader" title="Header Column Mood" aria-hidden="true"><span>Mood</span></div>
          <div class="th" role="columnheader" title="Header Column Initial CPO Application" aria-hidden="true"><span>Initial CPO Application</span></div>
          <div class="th" role="columnheader" title="Header Column Frequency" aria-hidden="true"><span>Frequency</span></div>
          <div class="th" role="columnheader" title="Header Column Address" aria-hidden="true"><span>Address</span></div>
        </div>
        <div class="row" role="row">
          <div class="td" role="cell" data-title="Ethnicity">
            <div class="visually-hidden" title="Header Column Ethnicity">Ethnicity</div>
            <span *ngIf="!ongoingInterventionDetail.isCensoredDueToSensitivity">{{ongoingInterventionDetail.ethnicity | noData}}</span>
            <span *ngIf="!!ongoingInterventionDetail.isCensoredDueToSensitivity">***</span>
          </div>
          <div class="td" role="cell" data-title="Mood">
            <div class="visually-hidden" title="Header Column Mood">Mood</div>
            <div *ngIf="!ongoingInterventionDetail.isCensoredDueToSensitivity" [innerHTML]="mood | noData:'No mood recorded' | unsafeHtml">

            </div>
            <div *ngIf="!!ongoingInterventionDetail.isCensoredDueToSensitivity">***</div>
          </div>
          <div class="td" role="cell" data-title="Initial CPO Application">
            <div class="visually-hidden" title="Header Column Initial CPO Application">Initial CPO Application</div>
            <span *ngIf="!ongoingInterventionDetail.isCensoredDueToSensitivity">{{formatShortDate(ongoingInterventionDetail.applicationOfCPODate)}}</span>
            <span *ngIf="!!ongoingInterventionDetail.isCensoredDueToSensitivity">***</span>
          </div>
          <div class="td" role="cell" data-title="Frequency">
            <div class="visually-hidden" title="Header Column Frequency">Frequency</div>
            <div>{{ ongoingIntervention.frequency }}</div>
          </div>
          <div class="td" role="cell" data-title="Address">
            <div class="visually-hidden" title="Header Column Address">Address</div>
            <div *ngIf="!ongoingInterventionDetail.isCensoredDueToSensitivity">
              <div>
                <div *ngFor="let addressLine of addressLines(ongoingInterventionDetail.address)">{{ addressLine }}</div>
                <div *ngIf="ongoingInterventionDetail.address && ongoingInterventionDetail.address.length > 0"><a
                    class="google-maps-link" [attr.tabindex]="dataLoaded ? null : -1"
                    href="https://www.google.com/maps/search/?api=1&query={{ongoingInterventionDetail.address}}"
                    target="_blank">Link to Google Maps <i class="fas fa-external-link-alt fa-sm"></i></a></div>
                <div *ngIf="!ongoingInterventionDetail.address || ongoingInterventionDetail.address.length === 0">No
                  address recorded
                </div>
              </div>
            </div>
            <span *ngIf="!!ongoingInterventionDetail.isCensoredDueToSensitivity">***</span>
          </div>
        </div>
        <div class="row" role="row">
          <div class="th" role="columnheader" title="Header Column Alerts" aria-hidden="true"><span>Alerts</span></div>
          <div class="th" role="columnheader" aria-hidden="true"></div>
          <div class="th" role="columnheader" aria-hidden="true"></div>
          <div class="th" role="columnheader" aria-hidden="true"></div>
          <div class="th" role="columnheader" aria-hidden="true"></div>
        </div>
        <div class="row" role="row">
          <div class="td alert-col" role="cell" data-title="Current CP Alerts">
            <div class="visually-hidden" title="Header Column Current CP Alerts">Alerts</div>
            <ul class="alert-list"
              *ngIf="!ongoingInterventionDetail.isCensoredDueToSensitivity && (ongoingInterventionDetail.hasRedAlert || ongoingInterventionDetail.hasAmberAlert)">
              <li *ngFor="let alert of redAlerts;">
                <i class="icon-alert icon-red-alert" aria-label="Red Alert icon" title="Red Alert icon"></i>
                <span>{{ alert.description }}</span>
                <span *ngIf="alert.count > 1">({{ alert.count }})</span>
              </li>
              <li *ngFor="let alert of amberAlerts;">
                <i class="icon-alert icon-amber-alert" aria-label="Amber Alert icon" title="Amber Alert icon"></i>
                <span>{{ alert.description }}</span>
                <span *ngIf="alert.count > 1">({{ alert.count }})</span>
              </li>
            </ul>
            <span *ngIf="!ongoingInterventionDetail.isCensoredDueToSensitivity && !ongoingInterventionDetail.hasRedAlert && !ongoingInterventionDetail.hasAmberAlert">No alerts
              recorded</span>
            <span *ngIf="!!ongoingInterventionDetail.isCensoredDueToSensitivity">***</span>
          </div>
          <div class="td" role="cell" data-title=""></div>
          <div class="td" role="cell" data-title=""></div>
          <div class="td" role="cell" data-title=""></div>
          <div class="td" role="cell" data-title=""></div>
        </div>
      </ng-container>
    </div>
  </loading-pane>
</div>