import { MsalService } from '../../services/msal.service';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { from, Observable } from 'rxjs';

@Component({
  selector: 'app-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.scss']
})
export class AuthComponent implements OnInit {
  redirectUri: string = null;
  public isLoggedIn$: Observable<boolean>

  constructor(private adalService: MsalService, private route: ActivatedRoute) {}

  async ngOnInit() {
    this.route.queryParams.subscribe(p => (this.redirectUri = p['redirectUrl']));
    this.isLoggedIn$ = from(this.adalService.isAuthenticated())
  }

  login() {
    this.adalService.login(this.redirectUri || '/');
  }

  logout() {
    this.adalService.logout();
  }


  get userName() {
    if (!this.adalService.userInfo || !this.adalService.userInfo.username) return null;

    return this.adalService.userInfo.username;
  }
}
