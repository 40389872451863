import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class CourtOrderNotifyService {

    constructor(
    ) { }

    private courtOrders = new Subject<Api.SelectedCourtOrderData>();
    public courtOrdersChanged = this.courtOrders.asObservable();

    ChangeCourtOrderData(data: Api.SelectedCourtOrderData) {
        this.courtOrders.next(data);
    }
}
