
import {throwError as observableThrowError,  Observable } from 'rxjs';

import {catchError} from 'rxjs/operators';
import { Injectable } from "@angular/core";
import { HttpEvent, HttpHandler, HttpInterceptor, HttpErrorResponse } from "@angular/common/http";
import { HttpRequest } from "@angular/common/http";
import { Router } from "@angular/router";
import { MsalService } from "services";

@Injectable()
export class FailedAuthorizationInterceptor implements HttpInterceptor {

  constructor(private router: Router, private adalService: MsalService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    return next.handle(request).pipe(catchError((err: any) => {
      if (err instanceof HttpErrorResponse) {
        if (err.status === 401 || err.status === 403 || err.message.indexOf("AADSTS50105") >= 0) {
          this.adalService.clearCache();
          this.router.navigate(['unauthorised']);
        };
      }

      return observableThrowError(err);
    }));
  }
}
