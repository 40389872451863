import { Injectable } from '@angular/core';
import { csx } from 'services/api/urls';
import { HttpClient  } from '@angular/common/http';
import { map, catchError }               from 'rxjs/operators';
import { PersonaSwitcherService } from 'services/api/persona-switcher.service';
import { IResponse } from './response-interface';
import { of } from 'rxjs';

@Injectable()
export class ActivityFeedService {
  private overrideUpn: string = null;

  constructor(private http: HttpClient,
    private personaSwitcherService: PersonaSwitcherService) { }

  async get() {
    let headers = {};
    if (this.personaSwitcherService.getPersonaUpn() || this.overrideUpn) {
      headers = {
        'upn': btoa(this.personaSwitcherService.getPersonaUpn() || this.overrideUpn)
      };
    }

    return this.http
      .get<Api.ActivityFeedItem[]>(csx.activityFeed({}), {
        headers: headers
      })
      .asJsonResponse<Api.ActivityFeedItem[]>()
      .toPromise();
  }
}
