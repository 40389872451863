export enum FeatureName {
    OverdueVisits = "Overdue Visits",
    UpcomingVisits = "Upcoming Visits",
    UpcomingVisitsShowMore = "Upcoming Visits - Show More",
    CourtOrders3Months = "CourtOrders - 3 Months",
    CourtOrders3MonthsShowMore = "CourtOrders - 3 Months - Show More",
    CourtOrders6Months = "CourtOrders - 6 Months", 
    CourtOrders6MonthsShowMore = "CourtOrders - 6 Months - Show More", 
    OICaseSummary = "Case Summary - OI", 
    OICaseSummaryShowMore = "Case Summary - OI - Show More", 
    StaffWhereabouts = "Staff Whereabouts", 
    CaseNote = "Visit ICMS Case Note", 
    LockDown = "Lock down",
    SafeToReturn = "Safe to return",
    PriorityIconsEnabled = "Priority Icons Enabled",
    PriorityIconsDisabled = "Priority Icons Disabled"
}

export enum HeroSection {
    HomeVisits = "Home Visits",
    CourtOrders = "Court Orders",
    CourtOrders_2 = "Court Orders 2",
    CourtOrders_3 = "Court Orders 3",
    CourtOrders_4 = "Court Orders 4",
    StaffWhereabouts = "Staff Whereabouts"
}
export enum FilterDateRange {
    ThisWeek = 0,
    Next4Weeks = 1,
    Custom = 2
}
export function filterDateRangeDescription(value: FilterDateRange) {
    switch (value) {
        case FilterDateRange.ThisWeek:
            return `This Week`;
        case FilterDateRange.Next4Weeks:
            return `In the next 4 weeks`;
        case FilterDateRange.Custom:
            return `Custom`;
        default:
            return `In the next 4 weeks`;
    }
}
export function filterDateRangeSummaryDescription(value: FilterDateRange) {
    switch (value) {
        case FilterDateRange.ThisWeek:
            return `this week`;
        case FilterDateRange.Next4Weeks:
            return `in the next 4 weeks`;
        case FilterDateRange.Custom:
            return `in your selected date range`;
    }
}