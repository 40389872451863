<app-happy-day-motif></app-happy-day-motif>
<app-hero (showMainDashboard)="updateDashboardVisibility($event)"></app-hero>
<div class="container">
  <loading-pane [ngClass]="{'home-visits': overdueTabSelected || upcomingTabSelected, 'court-orders': expiryInThreeMonthsTabSelected || expiryInSixMonthsTabSelected, 'hidden': !isVisible }">
    <div id="content" class="table-wrapper">
      <div [ngClass]="{'table-heading': true, 'home-visits': overdueTabSelected || upcomingTabSelected, 'court-orders': expiryInThreeMonthsTabSelected || expiryInSixMonthsTabSelected }" sticky>
        <nav>
          <ul role="tablist">
            <li *ngIf="overdueTabSelected || upcomingTabSelected" 
              role="tab" tabindex="0" title="What's Overdue" [ngClass]="{ 'selected': overdueTabSelected }"
              (click)="selectTab(overdueVisits)" (keyup.Enter)="selectTab(overdueVisits)"
              aria-label="What's Overdue tab" [attr.aria-selected]="overdueTabSelected"><i
                class="far fa-clock fa-lg"></i>
              <h1>What's Overdue</h1>
            </li>
            <li *ngIf="overdueTabSelected || upcomingTabSelected" 
              role="tab" tabindex="0" title="Upcoming Visits" [ngClass]="{ 'selected': upcomingTabSelected }"
              (click)="selectTab(upcomingVisits)" (keyup.Enter)="selectTab(upcomingVisits)"
              aria-label="Upcoming Visits tab" [attr.aria-selected]="upcomingTabSelected">
              <i class="fas fa-home fa-lg"></i>
              <h1>Upcoming Visits</h1>
            </li>
            <li *ngIf="overdueTabSelected || upcomingTabSelected" 
              role="tab" tabindex="0" title="Court Orders" [ngClass]="{ 'selected': expiryInThreeMonthsTabSelected, 'mobile-view': true }"
              (click)="selectTab(ordersNextThreeMonths)" (keyup.Enter)="selectTab(ordersNextThreeMonths)"
              aria-label="Court Orders tab" [attr.aria-selected]="expiryInThreeMonthsTabSelected">
              <i class="fas fa-stopwatch fa-lg"></i>
              <h1>Show Court Orders</h1>
            </li>
            <li *ngIf="expiryInThreeMonthsTabSelected || expiryInSixMonthsTabSelected" 
              role="tab" tabindex="0" title="Expiry in 3 months" [ngClass]="{ 'selected': expiryInThreeMonthsTabSelected }"
              (click)="selectTab(ordersNextThreeMonths)" (keyup.Enter)="selectTab(ordersNextThreeMonths)"
              aria-label="Expiry in 3 months tab" [attr.aria-selected]="expiryInThreeMonthsTabSelected"><i
                class="fas fa-stopwatch fa-lg"></i>
              <h1>Expiry in 3 months</h1>
            </li>
            <li *ngIf="expiryInThreeMonthsTabSelected || expiryInSixMonthsTabSelected" 
              role="tab" tabindex="0" title="Expiry in 6 months" [ngClass]="{ 'selected': expiryInSixMonthsTabSelected }"
              (click)="selectTab(ordersNextSixMonths)" (keyup.Enter)="selectTab(ordersNextSixMonths)"
              aria-label="Expiry in 6 months tab" [attr.aria-selected]="expiryInSixMonthsTabSelected">
              <i class="fas fa-calendar-alt fa-lg"></i>
              <h1>Expiry in 6 months</h1>
            </li>
            <li *ngIf="expiryInThreeMonthsTabSelected || expiryInSixMonthsTabSelected" 
              role="tab" tabindex="0" title="What's Overdue" [ngClass]="{ 'selected': overdueTabSelected, 'mobile-view': true }"
              (click)="selectTab(overdueVisits)" (keyup.Enter)="selectTab(overdueVisits)"
              aria-label="What's Overdue tab" [attr.aria-selected]="overdueTabSelected"><i
                class="far fa-clock fa-lg"></i>
              <h1>Show Home Visits</h1>
            </li>
          </ul>
        </nav>
        <button id="filter" *ngIf="overdueTabSelected || upcomingTabSelected" tabindex="0" (click)="showFilter($event)"
          (keyup.Enter)="showFilter($event)" aria-label="Show filter controls"><i
            class="fas fa-filter"></i>Filter</button>
      </div>
      <div id="upcoming-tab" role="tabpanel" class="upcoming-visits" *ngIf="overdueTabSelected || upcomingTabSelected || expiryInThreeMonthsTabSelected || expiryInSixMonthsTabSelected">
        <app-home-visits [hidden]="!overdueTabSelected && !upcomingTabSelected" #homeVisitsContainer></app-home-visits>
        <app-court-order [hidden]="!expiryInThreeMonthsTabSelected && !expiryInSixMonthsTabSelected"></app-court-order>
      </div>
    </div>
  </loading-pane>
</div>
<!-- Removed 5th August 2022 as per user story https://projectebook.visualstudio.com/Portal%20Phase%201/_workitems/edit/15874
<footer *ngIf="overdueTabSelected || upcomingTabSelected">
  <p>* Minimum contact requirements as defined in the <a
      href="https://cspm.csyw.qld.gov.au/resources/resource/Structured-decision-making-in-child-protection-ser/bcef926f-5514-4117-bd13-81bbe8741d13" 
      target="_blank">Structured Decision Making Manual</a>.</p>
  <p>These dates are based on the most recent risk assessment with a status of approved or awaiting approval.</p>
</footer>
-->

<ng-template #filterContainer></ng-template>