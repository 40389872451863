<div>
  <loading-pane [tracker]="tracker">
    <div>
      <div role="tabpanel" class="table-layout" *ngIf="orderData">
        <div role="table">
          <div class="top-level">
            <div class="row" role="row">
              <div class="th" role="columnheader" title="Header Column Name" aria-hidden="true"><span>Name</span></div>
              <div class="th" role="columnheader" title="Header Column Current Order Type" aria-hidden="true"><span>Current Order Type</span></div>
              <div class="th" role="columnheader" title="Header Column Expiry Date" aria-hidden="true"><span>Expiry Date</span></div>
              <div *ngIf="haveStaff()" class="th" role="columnheader" title="Header Column Allocated To" aria-hidden="true"><span>Allocated To</span></div>
              <div *ngIf="!haveStaff" class="th" role="columnheader" title="Header Column Current Step" aria-hidden="true">Current Step</div>
            </div>
            
            <!-- A message to show when we failed to load data -->
            <app-error-message *ngIf="orderData.ordersHasError" appSection="{{errorMsgAppSectionText}}"></app-error-message>

            <!-- A message to show when he do not have an error, we did load data successfully, but we have no orders to display -->
            <app-no-results-message
              *ngIf="!orderData.ordersHasError && orderData.ordersLoaded && (!orderData.groupedOrders || orderData.groupedOrders.length === 0)"
              customHeader="{{errorMsgHeaderText}}" [includeTroubleshooting]="true"
              [includeFilterTroubleshooting]="false">
            </app-no-results-message>
            
            <ng-container *ngFor="let courtOrder of ordersToShow; trackBy: nextOrderExpiryDtm">
              <ng-container *ngFor="let order of courtOrder.orders; let i = index; trackBy: subjectChildId">
                <div class="row" role="row" [attr.data-person-id]="order.subjectChildId"
                  [ngClass]="{ 'even-row': isEven(courtOrder) }">
                  <div class="td emphasised" data-title="Name" role="cell">
                    <div class="visually-hidden" title="Header Column Name">Name</div>
                    <div *ngIf="i < 1">
                      <i class="far fa-user fa-lg"></i>
                      <div>
                        <span>{{ order.subjectChildName }}</span>
                      </div>
                    </div>
                  </div>
                  <div class="td" data-title="Current Order Type" role="cell">
                    <div class="visually-hidden" title="Header Column Current Order Type">Current Order Type</div>
                    <span>{{ order.orderTypeDisplay }}</span>
                  </div>
                  <div class="td" data-title="Expiry Date" role="cell">
                    <div class="visually-hidden" title="Header Column Expiry Date">Expiry Date</div>
                    <span title="{{ order.orderExpiryDtm ? '' : 'No court order expiry date].' }}">{{
                      order.orderExpiryDtm ? formatShortDate(order.orderExpiryDtm) : 'n/a'
                }}</span>
                  </div>
                  <div *ngIf="haveStaff()" class="td" data-title="Allocated To" role="cell">
                    <div class="visually-hidden" title="Header Column Allocated To">Allocated To</div>
                    <span>{{ order.officerName && order.officerName.length > 0 ? order.officerName : 'Me' }}</span>
                  </div>
                  <div *ngIf="!haveStaff()" class="td" data-title="Current Step" role="cell">
                    <div class="visually-hidden" title="Header Column Current Step">Current Step</div>
                    <span>{{ nextMilestone(order) }}</span>
                  </div>
                  <div class="td" role="cell">
                    <div *ngIf="enableViewMore(order)">
                      <button [ngClass]="{ 'toggle-details': true, 'highlight-button': isOrderSelected(order) }" (click)="toggleSelectCourtOrder(order)"
                        [title]="isOrderSelected(order) ? 'View less order detail for ' + order.subjectChildName : 'View more order detail for ' + order.subjectChildName"
                        [attr.aria-label]="isOrderSelected(order) ? 'View less order detail for ' + order.subjectChildName : 'View more order detail for ' + order.subjectChildName">
                        <span *ngIf="!isOrderSelected(order)">View More <i class="fas fa-angle-down"></i></span>
                        <span *ngIf="isOrderSelected(order)">View Less <i class="fas fa-angle-up"></i></span>
                      </button>
                    </div>
                  </div>
                </div>
                <app-court-order-detail *ngIf="isOrderSelected(order)" [courtOrder]="order" [isExpanded]="isOrderSelected(order)" 
                  [ngClass]="{ 'even-row': isEven(courtOrder), 'reveal-details': isOrderSelected(order) }">
                </app-court-order-detail>
              </ng-container>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </loading-pane>
  <app-pagination [hidden]="!expiryInThreeMonthsTabSelected" (pageChangedEvent)="onPageChangedEvent($event)" (pageSizeChangedEvent)="onPageSizeChangedEvent($event)" [recordCount]="orderCountExpiryInThreeMonths" [style]="courtOrderStyle"></app-pagination>
  <app-pagination [hidden]="!expiryInSixMonthsTabSelected" (pageChangedEvent)="onPageChangedEvent($event)" (pageSizeChangedEvent)="onPageSizeChangedEvent($event)" [recordCount]="orderCountExpiryInSixMonths" [style]="courtOrderStyle"></app-pagination>
</div>
