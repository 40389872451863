import { Pipe, PipeTransform } from '@angular/core';
import * as moment from "moment/moment";

@Pipe({ name: 'formatAge' })
export class FormatAgePipe implements PipeTransform {
  public transform(age: Api.Age | null, format: "dob" | "yearsOld" | "age" | null): string {

    let formattedAge;
    let yearText = 'yrs';
    let mthText = 'mths';

    if (age) {
      yearText = age.yearsOld === 1 ? 'yr' : 'yrs';
      mthText = age.monthsOld === 1 ? 'mth' : 'mths';
    }

    switch (format) {
      case "dob":
        formattedAge = age ? formatDob(age.dateOfBirth) : "Unknown";
        break;
      case "yearsOld":
        formattedAge = (age && age.yearsOld > -1) ? `${age.yearsOld} ${yearText}` : "Unknown";
        break;
      case "age":
      default:
        formattedAge = age
          ? age.monthsOld === 0
            ? age.yearsOld === 0 ? 'Newborn' : `${age.yearsOld} ${yearText}`
            : `${age.yearsOld} ${yearText} ${age.monthsOld} ${mthText}`
          : "Unknown";
    }
    if (age && age.isEstimate) {
      formattedAge = `${formattedAge} (estimated)`;
    }
    return formattedAge;
  }
}

function formatDob(dob: string) {
  return dob
    ? moment(dob).format('D MMMM YYYY')
    : "Unknown"
}
