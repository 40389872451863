<section>
  <div>
    <h3>{{customHeader}}</h3>
    <ng-container *ngIf="includeTroubleshooting === true || includeFilterTroubleshooting === true">
      <h4>Doesn’t seem/look right?</h4>
      <ol>
        <li>Refresh the page.</li>
        <li *ngIf="includeFilterTroubleshooting === true">Adjust the filter.</li>
        <li>Check whether case management is correct.</li>
        <li>Contact <a href="mailto:CSPortal@csyw.qld.gov.au" aria-label="Email support.">CSPortal@csyw.qld.gov.au</a> for further assistance.</li>
      </ol>
    </ng-container>
    <ng-container *ngIf="!includeTroubleshooting && !includeFilterTroubleshooting">
      <h4>Contact <a href="mailto:CSPortal@csyw.qld.gov.au" aria-label="Email support.">CSPortal@csyw.qld.gov.au</a> for further assistance.</h4>
    </ng-container>
  </div>
</section>