<app-happy-day-motif></app-happy-day-motif>
<div class="container">
  <loading-pane [tracker]="tracker">
    <div id="content" class="table-wrapper" role="table">
      <div class="table-heading">
        <h1>Ongoing Interventions</h1>
      </div>
      <div class="ongoing-interventions">
        <div class="top-level">
          <div class="row" role="row">
            <div class="th" role="columnheader" title="Header Column Name" aria-hidden="true"><span>Name</span></div>
            <div class="th" role="columnheader" title="Header Column Age" aria-hidden="true"><span>Age</span></div>
            <div class="th" role="columnheader" title="Header Column Intervention Type" aria-hidden="true"><span>Intervention Type</span></div>
            <div class="th" role="columnheader" title="Header Column Last Visit" aria-hidden="true"><span>Last Visit</span></div>
            <div class="th" role="columnheader" title="Header Column View More or Less" aria-hidden="true"></div>
          </div>
          <app-error-message *ngIf="hasError" appSection="case summary"></app-error-message>
          <app-no-results-message
            *ngIf="!hasError && dataLoaded && (!ongoingInterventions || ongoingInterventions.length === 0)"
            customHeader="It looks like you don’t have any ongoing intervention cases allocated to you!"
            [includeTroubleshooting]="true" [includeFilterTroubleshooting]="false">
          </app-no-results-message>
          <ng-container *ngFor="let oi of ongoingInterventions; trackBy: trackOiBy">
            <loading-pane [enabled]="showUpdateGraphic(oi)">
              <div class="row" [attr.data-person-id]="oi.subjectId" [ngClass]="{ 'even-row': isEven(oi) }" role="row">
                <div class="td" data-title="Name" role="cell">
                  <div class="visually-hidden" title="Header Column Name">Name</div>
                  <div>{{ oi.subjectName }}</div>
                </div>
                <div class="td" data-title="Age" role="cell">
                    <div class="visually-hidden" title="Header Column Age">Age</div>
                  <span>{{ oi.age | formatAge }}</span>
                </div>
                <div class="td" data-title="Intervention Type" role="cell">
                  <div class="visually-hidden" title="Header Column Intervention Type">Intervention Type</div>
                  <span>{{ !oi.interventionType ? 'n/a' : oi.interventionType }}</span>
                </div>
                <div class="td" data-title="Last Visit" role="cell">
                  <div class="visually-hidden" title="Header Column Last Visit">Last Visit</div>
                  <span title="{{ oi.lastVisitDtm ? '' : 'No completed Prescribed Home Visit case note.' }}">{{
                    oi.lastVisitDtm ? formatShortDate(oi.lastVisitDtm) : 'n/a'}}</span>
                </div>
                <div class="td" role="cell">
                  <div>
                    <button class="toggle-details" (click)="selectOngoingIntervention(oi)"
                      [title]="isOngoingInterventionSelected(oi) ? 'View less child details' : 'View more child details'" 
                      [attr.aria-label]="isOngoingInterventionSelected(oi) ? 'View less child details' : 'View more child details'">
                      <span *ngIf="!isOngoingInterventionSelected(oi)">View More <i class="fas fa-angle-down"></i></span>
                      <span *ngIf="isOngoingInterventionSelected(oi)">View Less <i class="fas fa-angle-up"></i></span>
                    </button>
                  </div>
                </div>
              </div>
            </loading-pane>
            <app-case-summary-detail [ongoingIntervention]="oi" [isExpanded]="isOngoingInterventionSelected(oi)"
              [ngClass]="{ 'even-row': isEven(oi), 'reveal-details': isOngoingInterventionSelected(oi) }">
            </app-case-summary-detail>
          </ng-container>
        </div>
      </div>
    </div>
  </loading-pane>
  <app-pagination (pageChangedEvent)="onPageChangedEvent($event)" (pageSizeChangedEvent)="onPageSizeChangedEvent($event)" [recordCount]="caseSummaryCount" [style]="caseSummaryStyle"></app-pagination>
</div>