import { Directive, ElementRef, Renderer2, AfterViewInit, OnDestroy } from '@angular/core';
import * as Stickyfill from 'stickyfilljs';

@Directive({
    selector: '[sticky]'
})
export class StickyDirective implements AfterViewInit, OnDestroy {

    constructor(private elementRef: ElementRef, private renderer: Renderer2) {
        //
    }

    ngAfterViewInit(): void {
        this.renderer.setStyle(this.elementRef.nativeElement, 'opacity', 1);
        this.renderer.setStyle(this.elementRef.nativeElement, 'z-index', 1000000);
        this.renderer.setStyle(this.elementRef.nativeElement, 'position', 'sticky');
        this.renderer.setStyle(this.elementRef.nativeElement, 'top', 0);

        // https://github.com/wilddeer/stickyfill
        Stickyfill.add([this.elementRef.nativeElement]);
    }

    ngOnDestroy(): void {
        Stickyfill.removeAll();
    }
}