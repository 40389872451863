<header>
  <div id="skip-links">
    <ul>
      <li><a tabindex="0" (click)="scrollTo('content')" (keyup.Enter)="scrollTo('content')">Skip to content</a></li>
      <li><a tabindex="0" (click)="scrollTo('nav')" (keyup.Enter)="scrollTo('nav')">Skip to navigation</a></li>
      <li><a tabindex="0" (click)="scrollTo('footer')" (keyup.Enter)="scrollTo('footer')">Skip to footer</a></li>
      <li><a href="http://www.qld.gov.au/help/accessibility/keyboard.html#section-aria-keyboard-navigation" 
          tabindex="0">Use tab and cursor keys to move around the page (more information)</a></li>
    </ul>
  </div>
  <div [ngClass]="{ 'browserWarning': IsIE11 }">
    <div *ngIf="IsIE11">
      <div>
        <h2>Unsupported Browser</h2>
        <p>This browser is no longer supported for use with the CS Portal.</p>
        <p>Please use either Chrome or Edge. These are available from the Software Centre.</p>
      </div>
    </div>
  </div>
  <div>
    <loading-pane [tracker]="tracker" class="hidden-loader">
      <div [ngClass]="{ 'visually-hidden': !showLockdownChangingBanner, 'hideElement': !showLockdownChangingBanner }" id="scrollableLockdownArea" #lockdownArea>
        <div [ngClass]="{ 'office-lockdown': showOfficeIsLockedDown, 'lift-office-lockdown': showOfficeIsNoLongerLockedDown }">
          <div class='aligned-icon-container' *ngIf="!mobileView">
              <span *ngIf="showOfficeIsLockedDown" class="centered-icon centered-icon-large fas fa-exclamation-triangle" aria-label="Red Alert icon" title="Red Alert icon"></span>
              <span *ngIf="showOfficeIsNoLongerLockedDown" class="centered-icon centered-icon-large fas fa-check-circle" aria-label="Green Healthy icon" title="Green Healthy icon"></span>
              <span class='icon-text'>{{lockedDownTextFullScreen()}}</span>
          </div>
          <div class="alert-grid" *ngIf="mobileView">
            <div class="col-icon">
              <span *ngIf="showOfficeIsLockedDown" class="centered-icon centered-icon-large fas fa-exclamation-triangle alert-icon" aria-label="Red Alert icon" title="Red Alert icon"></span>
              <span *ngIf="showOfficeIsNoLongerLockedDown" class="centered-icon centered-icon-large fas fa-check-circle alert-icon" aria-label="Green Healthy icon" title="Green Healthy icon"></span>
            </div>
            <div class="col-alert">
              <span class='icon-text left-align'>{{lockedDownTextMobile(1)}}</span>
              <span class='left-align'>{{lockedDownTextMobile(2)}}</span>
            </div>
          </div>
        </div>
      </div>
    </loading-pane>
  </div>
  <div>
    <div>
      <div>Child Safety</div>
      <div>Portal</div>
    </div>
    <app-main-menu *ngIf="isAuthenticated$|async" [fullName]="userFullname()" 
      [officeIsLockedDown]="showOfficeIsLockedDown" (isTLOrManager)="updateTLManagerStatus($event)" 
      (lockdownHasBeenToggled)="toggleLockdown($event)" [showLockdownToggle]="showLockdownToggle()"></app-main-menu>
  </div>
  <div>
    <div>
      <nav id="nav">
        <ul>
          <li>
            <a routerLink="dashboard" routerLinkActive="sub-header-nav">
              <span aria-label="Dashboard">Dashboard</span></a>
          </li>
          <li>
            <a routerLink="case-summary" routerLinkActive="sub-header-nav">
              <span aria-label="Case Summary">Case Summary</span>
            </a>
          </li>
          <li>
            <a routerLink="whereabouts" routerLinkActive="sub-header-nav">
              <span aria-label="Whereabouts">Staff Whereabouts</span>
            </a>
          </li>
        </ul>
      </nav>
      <div class="officer-area">
        <span>{{ time }}</span>
        <span>{{ date }}</span>
      </div>
    </div>


    <!-- A hidden modal -->
    <jw-modal id="deactivate-lockdown-modal">
      <div class="jw-modal-header">
        <h2><span class="centered-icon fas fa-check-circle green-icon" aria-label="Green Healthy icon" title="Green Healthy icon"></span> &nbsp;Yes. It's safe to return</h2>
      </div>

      <div class="jw-modal-inner-body">
        <p>Send an alert to advise {{officeName}} staff that the security issue is no longer current and staff are able to return to the CSSC.</p>

        <div class="flex field-space-top-large">
          <button aria-label="Cancel" title="Cancel" class="external-action flex-child btn btn-2 btn-2g modal-btn mr-1" 
            (click)="closeModal('deactivate-lockdown-modal');">Cancel</button>
  
          <button aria-label="Confirm" title="Confirm" class="external-action color-invert flex-child btn btn-2 btn-2g modal-btn" 
            (click)="closeModal('deactivate-lockdown-modal');setOfficeSafeToReturn();">Confirm</button>
        </div>
      </div>
    </jw-modal>

    <!-- A hidden modal -->
    <jw-modal id="activate-lockdown-modal">
      <div class="jw-modal-header">
        <h2><span class="centered-icon fas fa-exclamation-triangle red-icon" aria-label="Red Alert icon" title="Red Alert icon"></span> &nbsp;Activate lock down</h2>
      </div>
  
      <div class="jw-modal-inner-body">
        <p>Send an alert to advise {{officeName}} staff there is a lock down at the CSSC due to a security issue.</p>

        <div class="flex field-space-top-large">
          <button aria-label="Cancel" title="Cancel" class="external-action flex-child btn btn-2 btn-2g modal-btn mr-1" 
            (click)="closeModal('activate-lockdown-modal');">Cancel</button>

          <button aria-label="Confirm" title="Confirm" class="external-action color-invert flex-child btn btn-2 btn-2g modal-btn" 
            (click)="closeModal('activate-lockdown-modal');lockdownOffice();">Confirm</button>
        </div>
      </div>
    </jw-modal>

    <!-- A hidden modal -->
    <jw-modal id="informational-lockdown-modal">
      <div class="jw-modal-header">
        <h2><span [ngClass]="{ 'centered-icon': true, 'fas': true, 'fa-exclamation-triangle': showOfficeIsLockedDown, 'red-icon': showOfficeIsLockedDown, 'fa-check-circle': !showOfficeIsLockedDown, 'green-icon': !showOfficeIsLockedDown }" aria-label="Status Icon" title="Status Icon"></span> &nbsp;Lock down conflict for {{officeName}}</h2>
      </div>
    
      <div class="jw-modal-inner-body">
        <p>{{informationalMessage}}</p>
  
        <div class="flex field-space-top-large">
          <button aria-label="OK" title="OK" class="external-action flex-child color-invert modal-btn" 
            (click)="closeModal('informational-lockdown-modal');">OK</button>
        </div>
      </div>
    </jw-modal>
  </div>
</header>