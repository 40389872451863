<section>
  <div>
    <span aria-label="Previous" title="Previous" (click)="pageLeft();"
      [ngClass]="{ 'mr-1': true, 'visible': showButtons(), 'hidden': !showButtons(), 'disabled': !leftButtonIsEnabled() }">
        <i class="fa fa-arrow-left"></i>
    </span>
  </div>

  <app-home-visits-summary [ngClass]="{ 'button-adjust': showButtons() }" [hidden]="!showTile(homeVisits)" (haveHomeVisitsToDisplay)="updateHaveHomeVisits($event)"></app-home-visits-summary>
  <app-court-order-summary [ngClass]="{ 'button-adjust': showButtons() }" [hidden]="!showTile(courtOrders)" (haveCourtOrdersToDisplay)="updateHaveCourtOrders($event)"></app-court-order-summary>
  <app-court-order-summary [ngClass]="{ 'button-adjust': showButtons() }" *ngIf="!!showTestTiles()" [hidden]="!showTile(courtOrders_2)" (haveCourtOrdersToDisplay)="updateHaveCourtOrders($event)"></app-court-order-summary>
  <app-court-order-summary [ngClass]="{ 'button-adjust': showButtons() }" *ngIf="!!showTestTiles()" [hidden]="!showTile(courtOrders_3)" (haveCourtOrdersToDisplay)="updateHaveCourtOrders($event)"></app-court-order-summary>
  <app-court-order-summary [ngClass]="{ 'button-adjust': showButtons() }" *ngIf="!!showTestTiles()" [hidden]="!showTile(courtOrders_4)" (haveCourtOrdersToDisplay)="updateHaveCourtOrders($event)"></app-court-order-summary>

  <!-- This allows for an opaque 'loading' pane to display while awaiting home visit and case note tiles to load -->
  <aside [ngClass]="{ 'selected': enableHomeVisitsLoading || enableCourtOrderLoading, 'showTileArea': enableHomeVisitsLoading || enableCourtOrderLoading }">
    <loading-pane [ngClass]="{ 'showTileArea': enableHomeVisitsLoading || enableCourtOrderLoading }" [tracker]="homeVisitsTracker" [tracker_2]="courtOrderTracker"></loading-pane>
  </aside>

  <app-staff-whereabouts-summary *ngIf="showTile(staffWhereabouts)" [staffCheckedOut]="staffCheckedOut" [staffOverdueForCheckIn]="staffOverdueForCheckIn"></app-staff-whereabouts-summary>

  <div>
    <span aria-label="Next" title="Next" (click)="pageRight();" 
      [ngClass]="{ 'ml-1': true, 'visible': showButtons(), 'hidden': !showButtons(), 'disabled': !rightButtonIsEnabled() }">
        <i class="fa fa-arrow-right"></i>
    </span>
  </div>
  <aside aria-hidden="true"></aside>
  <aside aria-hidden="true"></aside>
  <app-activity-feed (activityFeedExpanded)="updateActivityFeedExpanded($event)" [ngClass]="{ 'activity-feed-full-content': activityFeedIsExpanded }"></app-activity-feed>
</section>