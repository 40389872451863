import { Injectable } from '@angular/core';
import * as moment from 'moment';

@Injectable()
export class CourtOrderService {
  milestones: Api.CourtOrderType[];
  viewMoreHiddenOrderTypes: string[];

  constructor() {
    this.milestones = [
      {week: 24, milestoneName: 'File Review' },
      {week: 23, milestoneName: 'CAP completed' },
      {week: 22, milestoneName: 'Book 1st OCFOS appt' },
      {week: 13, milestoneName: 'Practice Panel' },
      {week: 12, milestoneName: 'Book 2nd OCFOS appt' },
      {week: 9, milestoneName: 'Draft to TL for QA' },
      {week: 7, milestoneName: 'Draft to OCFOS for QA' },
      {week: 5, milestoneName: 'Referral to DCPL' },
      {week: 0, milestoneName: 'Expiry of Order' }
    ];

    // Note, we only have the highest level of order type codes, such as '5' for TAO. There are more discerning values we don't have.
    // Many of the below are all code '15'. We do show View More for a lot of '15' types. For this reason we must resort to using
    // the textual value instead of the codes we have.
    this.viewMoreHiddenOrderTypes = [
      'tao',
      'tao - extend',
      'tco',
      'tco - extend',
      'cao',
      'cao - extend',
      'cao - interim order(s)',
      'cao - extend and vary',
      'cpo - interim order(s)',
      'cpo - interim and procedural order(s)',
      'cpo - section 99',
      'cpo - section 99 and procedural order(s)',
      'cpo - section 99 and interim order(s)',
      'cpo - section 99, interim and procedural order(s)'
    ];
  }

  // Return true when the given court order 'orderTypeDisplay' is NOT in the 
  // collection of viewMoreHiddenOrderTypes. Return false when it is present.
  // That is, hide the View More button when the court order type is in this list.
  enableViewMore(courtOrder: Api.CourtOrder): boolean {
    return this.viewMoreHiddenOrderTypes.indexOf(courtOrder.orderTypeDisplay.toLowerCase()) < 0;
  }

  getOrderType(week: number): Api.CourtOrderType {
    for(let i = 0; i < this.milestones.length; i++)
    {
      if (this.milestones[i].week === week) {
        return this.milestones[i];
      }
    }
    return null;
  }

  generateMilestones(orderExpiryDtm?: string)
  {
    if(!orderExpiryDtm) {
      return [];
    }

    
    moment.updateLocale('en', {
      calendar : {
          lastDay : '[Yesterday]',
          sameDay : '[Today]',
          nextDay : '[Tomorrow]'
      }
    });

    let response = [];
    let milestoneWeek = 24;

    // Allow generation of task at week 0. This is the expiry date of the court order
    while(milestoneWeek >= 0)
    {
      let milestone = this.getOrderType(milestoneWeek);
      if(!milestone) {
        milestoneWeek -= 1;
        continue;
      }

      let dueDt = moment(orderExpiryDtm).add((-7 * milestoneWeek), 'day');
      let isInThePast = dueDt.isBefore(moment());
      let dueDtDescription = dueDt.local().format('DD/MM/YYYY');
      //let readableDistanceToFromDueDt = dueDt.fromNow();
      let readableDistanceToFromDueDt = this.dateToFromNowDaily(dueDt);
      response.push({
        milestoneWeek: milestoneWeek,
        taskNumber: Math.abs(25 - milestoneWeek),
        milestoneDueDt: dueDtDescription,
        timeToDueDtDescription: readableDistanceToFromDueDt,
        milestoneName: milestone.milestoneName, 
        isInThePast: isInThePast
      });

      milestoneWeek -= 1;
    }

    return response;
  }

  getNextMilestone(courtOrder: Api.CourtOrder)
  {
    let milestones = this.generateMilestones(courtOrder.orderExpiryDtm);

    // If this court order has no view more option then the next milestone defaults to the last milestone
    if(!this.enableViewMore(courtOrder))
    {
      return milestones[milestones.length - 1].milestoneName;
    }

    // Find the first milestone that is not in the past, if any
    let response = '';
    for(let i = 0; i < milestones.length; i++)
    {
      response = milestones[i].milestoneName;
      if(!milestones[i].isInThePast)
      {
        break;
      }
    }

    return response;
  }

  // Courtesty Ritwick: https://stackoverflow.com/questions/35441820/moment-js-tomorrow-today-and-yesterday/35442124
  // call this function, passing-in your date
  dateToFromNowDaily( myDate ) {
  
      // get from-now for this date
      var fromNow = moment( myDate ).fromNow();
  
      // ensure the date is displayed with today and yesterday
      return moment( myDate ).calendar( null, {
          // when the date is closer, specify custom values
          lastWeek: '[Last] dddd',
          lastDay:  '[Yesterday]',
          sameDay:  '[Today]',
          nextDay:  '[Tomorrow]',
          nextWeek: 'dddd',
          // when the date is further away, use from-now functionality             
          sameElse: function () {
              return "[" + fromNow + "]";
          }
      });
  }
}
