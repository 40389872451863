<footer>
  <div>
    <div>
      <a href="https://www.csyw.qld.gov.au/" target="_blank" tabindex="0"
        aria-label="Navigate to the Child Safety Youth and Women homepage."> </a>
    </div>
    <div>
      <p>Internal Use Only. Not for redistribution.</p>
      <p>For assistance, please email <a href="mailto:CSPortal@csyw.qld.gov.au"
          aria-label="Email for further assistance.">CSPortal@csyw.qld.gov.au</a></p>
    </div>
    <div>
      <div *ngIf="isAuthenticated$|async">
        <div class="tr">
          <div class="th">Generated By:</div>
          <div class="td">{{ userFullname() }}</div>
        </div>
        <div class="tr">
          <div class="th">Generated On:</div>
          <div class="td">{{ time }} {{ date }}</div>
        </div>
      </div>
    </div>
  </div>
</footer>