<aside id="courtOrders" [ngClass]="{ 'selected': expiryInThreeMonthsTabSelected || expiryInSixMonthsTabSelected, 'not-selected': !expiryInThreeMonthsTabSelected && !expiryInSixMonthsTabSelected }">
  <h2>Court Orders</h2>
  <loading-pane [tracker]="tracker">
    <div class="panel">
      <div role="button" tabindex="0" [attr.aria-pressed]="expiryInThreeMonthsTabSelected"
        aria-label="Expiring Soon" title="Expiring Soon"
        [ngClass]="{ 'selected': expiryInThreeMonthsTabSelected }"
        (click)="selectInfoPanel(nextThreeMonths)" (keyup.Enter)="selectInfoPanel(nextThreeMonths)">
        <h3><i class="fas fa-stopwatch fa-lg"></i> Expiry in 3 months</h3>
        <p>
          <span *ngIf="courtOrdersInNextThreeMonthsHasError" class="text-danger" role="alert">Sorry, there was a problem retrieving the latest data.</span>
          <ng-container *ngIf="!courtOrdersInNextThreeMonthsHasError">{{  getCourtOrdersDueSoonSentence() }}</ng-container>
        </p>
      </div>
      <div role="button" tabindex="0" [attr.aria-pressed]="expiryInSixMonthsTabSelected"
        aria-label="Expiring Next 6 Months" title="Expiring Next 6 Months" (click)="selectInfoPanel(nextSixMonths)"
        (keyup.Enter)="selectInfoPanel(nextSixMonths)"
        [ngClass]="{ 'selected': expiryInSixMonthsTabSelected }">
        <h3><i class="fas fa-calendar-alt fa-lg"></i> Expiry in 6 months</h3>
        <p>
          <span *ngIf="courtOrdersInSubsequentThreeHasError" class="text-danger" role="alert">Sorry, there was a problem retrieving the latest data.</span>
          <ng-container *ngIf="!courtOrdersInSubsequentThreeHasError">{{  getCourtOrdersDueInSixMonthsSentence() }}</ng-container>
        </p>
      </div>
    </div>
  </loading-pane>
</aside>