import { HeroSection } from "api/website_enum";

/*
    Note, we cannot use a simple count of tiles, current index and tiles per page to implement this pagination.
    This pagination will dynamically show or hide a tile based on whether it has data to present or not.
    Due to this, we use a tile type; see 'HeroSection'. The UI calls 'showTile' with its enumeration value.
    Only if this is present in 'allTiles' will we return true.
    The population of allTiles is driven by calls to: ensureTile and removeTile and is based 
    on the results of calls to a backend for data.
*/
export class TilePagination {
    allTiles: HeroSection[];

    // Initial first page
    currentPageIndex: 0;

    // The count of tiles to display at any one time
    visibleTileCount: 4;

    constructor() {
      this.initialiseValues();
      this.currentPageIndex = 0;
      this.visibleTileCount = 4;
    }
  
    initialiseValues()
    {
        this.allTiles = [];
    }
  
    ensureTile(section: HeroSection, index: number) : void
    {
        var indexOffsetFromZero = index - 1;
        var indexOfSection = this.allTiles.indexOf(section);
        if(indexOfSection >= 0)
        {
            if(indexOfSection == index) return; // Nothing to do

            this.allTiles.splice(indexOfSection, 1); // Remove at current place
            this.allTiles.splice(indexOffsetFromZero, 0, section); // Add to desired place
        }
        else
        {
            // Not currently present. Add
            if(this.allTiles.length > indexOffsetFromZero)
            {
                this.allTiles.splice(indexOffsetFromZero, 0, section);
            }
            else
            {
                this.allTiles.push(section);
            }
        }
    }

    removeTile(section: HeroSection)
    {
        var indexOfSection = this.allTiles.indexOf(section);
        if(indexOfSection >= 0)
        {
            this.allTiles.splice(indexOfSection, 1);
        }
    }

    public showTile(section: HeroSection) : boolean 
    {
        var countOfGroupsOfData = this.countOfHeroSections();
        var indexOfSection = this.allTiles.indexOf(section);
        if(indexOfSection < 0) {
            //if(section == 'Home Visits')
            //    console.log('pagination.showTile. Returning false for section: ' + JSON.stringify(section));
            return false;
        }
  
        // Determine the last visible index
        var lastVisibleIndex = this.currentPageIndex + this.visibleTileCount - 1;
        if(lastVisibleIndex >= countOfGroupsOfData) lastVisibleIndex -=1;

        var response = indexOfSection >= this.currentPageIndex && indexOfSection <= lastVisibleIndex;
        //if(section == 'Home Visits')
        //    console.log('showTile: ' + section + '. response: ' + response + '. indexOfSection: ' + indexOfSection + '. this.currentPageIndex: ' +  this.currentPageIndex + '. lastVisibleIndex: ' + lastVisibleIndex);
        return response;
    }

    public countOfHeroSections() : number 
    {
      return (!!this.allTiles ? this.allTiles.length : 0);
    }

    // Logic to reflect:
    //  When there are less than 4 sections with data, return false. Do not show pagination buttons.
    //  When there are 4 or more sections, all with data. Show.
    public showButtons() : boolean
    {
        return this.countOfHeroSections() > this.visibleTileCount;
    }

    // Return true when currentPageIndex is > 0. Meaning: they have paged to the right and have at least one page to return to
    public leftButtonIsEnabled() : boolean 
    {
        return this.currentPageIndex > 0;
    }

    // Return true when showRight is true and currentPageIndex + 3 (max count of pages) < count of available groups of data.
    public rightButtonIsEnabled() : boolean 
    {
        // A sliding window returning true up until the window is displaying all remaining items
        var countOfGroupsOfData = this.countOfHeroSections();
        return countOfGroupsOfData > this.visibleTileCount && (this.currentPageIndex < (countOfGroupsOfData - this.visibleTileCount));
    }

    public pageLeft()
    {
        if(!this.leftButtonIsEnabled()) return;

        this.currentPageIndex -= 1;
    }

    public pageRight()
    {
        if(!this.rightButtonIsEnabled()) return;

        this.currentPageIndex += 1;
    }
}
