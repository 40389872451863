import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { TaskSubsystemService } from '../../../../services/api/task-subsystem.service';
import { CourtOrderService } from '../../../../services/court-order.service';
import { WorkTracker } from 'app/shared/loading-pane';

@Component({
  selector: 'app-court-order-detail',
  templateUrl: './court-order-detail.component.html',
  styleUrls: ['./court-order-detail.component.scss']
})
export class CourtOrderDetailComponent implements OnInit, OnChanges {
  @Input() shown: boolean;
  @Input() courtOrder: Api.CourtOrder;
  @Input() isExpanded: boolean;
  @Input() orderSelectionChanged: string;

  courtOrderDetail: Api.HomeVisitPersonDetail;
  tracker: WorkTracker;
  dataLoaded: boolean;
  hasError: boolean;
  milestones: Api.CourtOrderMilestone[];
  firstThreeMilestones: Api.CourtOrderMilestone[];
  nextThreeMilestones: Api.CourtOrderMilestone[];
  lastThreeMilestones: Api.CourtOrderMilestone[];
  milestoneNames: string[];
  nextMilestone: string;

  constructor(private taskSubsystem: TaskSubsystemService, private courtOrderService: CourtOrderService) {
    this.tracker = new WorkTracker(true);
  }

  async ngOnInit(): Promise<void> {

  }

  async ngOnChanges(changes: SimpleChanges) {
    if (changes['isExpanded'] && changes['isExpanded'].currentValue === true) {
      this.hasError = false;
      this.dataLoaded = false;

      this.milestones = this.courtOrderService.generateMilestones(this.courtOrder.orderExpiryDtm);
      this.firstThreeMilestones = this.milestones.slice(0, 3);
      this.nextThreeMilestones = this.milestones.slice(3, 6);
      this.lastThreeMilestones = this.milestones.slice(6, 9);
      this.nextMilestone =  this.courtOrderService.getNextMilestone(this.courtOrder);

      await this.tracker.track(this.getUpcomingVisitDetailFromServer(this.courtOrder.subjectChildId));
    }
  }

  isTheNext(milestone: Api.CourtOrderMilestone) : boolean 
  {
    return milestone.milestoneName == this.nextMilestone;
  }

  private getUpcomingVisitDetailFromServer(personId: string): Promise<void> {
    try {
      return this.taskSubsystem.getUpcomingChildHomeVisitDetail(personId).then(result => {
        this.hasError = (result.hasError);
        if (!this.hasError) {
          this.courtOrderDetail = result.value;
          this.dataLoaded = true;
        }
      });
    } catch (error) {
      console.error(error);
      this.hasError = true;
    }
  }
}

