import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { FeatureName } from "api/website_enum";

@Injectable()
export class TabNotificationService {

    constructor(
    ) { }

    private selectedTab = new Subject<FeatureName>();
    public selectedTabChanged = this.selectedTab.asObservable();

    ChangeSelectedTab(newMessage: FeatureName) {
        this.selectedTab.next(newMessage);
    }
}