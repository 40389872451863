
import {from as observableFrom,  Observable } from 'rxjs';

import {mergeMap} from 'rxjs/operators';
import { Injectable } from "@angular/core";
import { HttpEvent, HttpHandler, HttpInterceptor } from "@angular/common/http";
import { HttpRequest } from "@angular/common/http";
import { MsalService } from "services";

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

  constructor(private adalService: MsalService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    return this.getToken().pipe(mergeMap((token: string) => {

      request = request.clone({
        setHeaders: {
          Authorization: `bearer ${token}`,
          ApplicationName: 'CSPortal'
        }
      });

      return next.handle(request);
    }));
  }

  private getToken(): Observable<string> {
    return observableFrom(this.adalService.getAccessToken());
  }
}
