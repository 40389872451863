declare let require: any;
let settings = typeof window === 'undefined' ? {} : (<any>window || {}).appConfig;

export let base = loadSetting<string>('CSXpress.Url');
export let apiBase = loadSetting<string>('CSXpress.ApiUrl');

export let appId = loadSetting<string>('Authentication.AppId');
export let audience = loadSetting<string>('Authentication.Audience');
export let authority = loadSetting<string>('Authentication.Authority');
export let tenantId = loadSetting<string>('Authentication.TenantId');

let kicboxUrl = loadSetting<string>('SwitchApplicationUrl.Kicbox');
let carerConnectUrl = loadSetting<string>('SwitchApplicationUrl.CarerConnect');
let ourChildUrl = loadSetting<string>('SwitchApplicationUrl.OurChild');
let practiceManualUrl = loadSetting<string>('SwitchApplicationUrl.PracticeManual');
let icmsUrl = loadSetting<string>('SwitchApplicationUrl.ICMS');
let intranetUrl = loadSetting<string>('SwitchApplicationUrl.Intranet');

let activityFeedRefreshIntervalInSeconds: number = loadSetting<number>('ActivityFeed.RefreshIntervalInSeconds');
let homeVisitsRefreshIntervalInSeconds: number = loadSetting<number>('HomeVisits.RefreshIntervalInSeconds');
let caseSummaryRefreshIntervalInSeconds: number = loadSetting<number>('CaseSummary.RefreshIntervalInSeconds');
let staffWhereaboutsRefreshIntervalInSeconds: number = loadSetting<number>('StaffWhereabouts.RefreshIntervalInSeconds');
let officeLockdownStatusRefreshIntervalInSeconds: number = loadSetting<number>('OfficeLockdownStatus.RefreshIntervalInSeconds');
let kicboxMyProfileUrl: string = loadSetting<string>('CSXpress.KicboxMyProfileUrl');
let enableDestinationPathFix: boolean = loadSetting<boolean>('CSXpress.EnableDestinationPathFix');
let courtOrderRefreshIntervalInSeconds: number = loadSetting<number>('CourtOrders.RefreshIntervalInSeconds');

let paginationShowTestTiles: boolean = loadSetting<boolean>('Pagination.ShowTestTiles');
let enablePriorityIcons: boolean = loadSetting<boolean>('Pagination.EnablePriorityIcons');

export let switchApplicationSettings = {
  kicboxUrl: kicboxUrl,
  carerConnectUrl: carerConnectUrl,
  ourChildUrl: ourChildUrl,
  practiceManualUrl: practiceManualUrl,
  icmsUrl: icmsUrl,
  intranetUrl: intranetUrl
} as ApplicationUrls;

export let activityFeedSettings = {
  refreshIntervalInSeconds: activityFeedRefreshIntervalInSeconds
} as ActivityFeedSettings;

export let homeVisitsSettings = {
  refreshIntervalInSeconds: homeVisitsRefreshIntervalInSeconds
} as HomeVisitsSettings;

export let caseSummarySettings = {
  refreshIntervalInSeconds: caseSummaryRefreshIntervalInSeconds
} as CaseSummarySettings;

export let staffWhereaboutsSettings = {
  refreshIntervalInSeconds: staffWhereaboutsRefreshIntervalInSeconds
} as StaffWhereaboutsSettings;

export let officeLockdownStatusSettings = {
  refreshIntervalInSeconds: officeLockdownStatusRefreshIntervalInSeconds
} as OfficeLockdownStatusSettings;

export let kicboxMyProfileSettings = {
  kicboxMyProfileUrl: kicboxMyProfileUrl
} as KicboxMyProfileSettings;

export let csExpressSettings = {
    enableDestinationPathReplacement: enableDestinationPathFix
} as CsExpressSettings;

export let courtOrdersSettings = {
  refreshIntervalInSeconds: courtOrderRefreshIntervalInSeconds
} as CourtOrdersSettings;

export let paginationTestSettings = {
  enableTestPaginationTiles: paginationShowTestTiles,
  enablePriorityIcons: enablePriorityIcons
} as PaginationTestSettings;

function loadSetting<T>(key: string) {
  if (settings[key] === undefined) {
    if (typeof window === 'undefined') {
      return <any>'testSetting';
    } else {
      throw new Error(`Setting ${key} does not exist`);
    }
  }

  return <T>settings[key];
}

export interface ApplicationUrls {
  kicboxUrl: string;
  carerConnectUrl: string;
  ourChildUrl: string;
  practiceManualUrl: string;
  icmsUrl: string;
  intranetUrl: string;
}

export interface ActivityFeedSettings {
  refreshIntervalInSeconds: number;
}

export interface HomeVisitsSettings {
  refreshIntervalInSeconds: number;
}

export interface CaseSummarySettings {
  refreshIntervalInSeconds: number;
}

export interface StaffWhereaboutsSettings {
  refreshIntervalInSeconds: number;
}

export interface OfficeLockdownStatusSettings {
  refreshIntervalInSeconds: number;
}

export interface KicboxMyProfileSettings {
  kicboxMyProfileUrl: string;
}

export interface CsExpressSettings {
    enableDestinationPathReplacement: boolean;
}

export interface CourtOrdersSettings {
  refreshIntervalInSeconds: number;
}

export interface PaginationTestSettings {
  enableTestPaginationTiles: boolean;
  enablePriorityIcons: boolean;
}
