import { Subscription } from 'rxjs';
import { Component, EventEmitter, Output, OnDestroy } from '@angular/core';
import { HeroSection } from "api/website_enum";
import { TilePagination } from './tile-pagination';
import { paginationTestSettings } from '../../settings';
import { WorkTracker } from 'app/shared/loading-pane';
import { TabNotificationService, PromiseNotifyService, CourtOrderPromiseNotifyService } from '../../services/notifications';
import { FeatureName } from "api/website_enum";

@Component({
  selector: 'app-hero',
  templateUrl: './hero.component.html',
  styleUrls: ['./hero.component.scss']
})
export class HeroComponent implements OnDestroy {
  // Renamed for clarity as previously had same name as duplicate functionality in HomeVisitsSummary
  @Output() showMainDashboard: EventEmitter<boolean> = new EventEmitter<boolean>();
    
  staffCheckedOut: number;
  staffOverdueForCheckIn: number;
  activityFeedIsExpanded: boolean = false;

  // These two flags are used to instruct either the court order summary tile or the home visit summary tile
  // to trigger the tab notification service. We can't do it from here, otherwise an infinite recursion occurs.
  forceShowHomeVisits: boolean = false;
  forceShowCourtOrders: boolean = false;
  haveOrders: boolean = false;
  haveHomeVisits: boolean = false;
  overdueVisitsFinishedLoading: boolean = false;
  upcomingVisitsFinishedLoading: boolean = false;
  homeVisitsHasLoadedOnce: boolean = false;

  // Tile pagination
  tilePagination: TilePagination;

  homeVisitsTracker: WorkTracker;
  courtOrderTracker: WorkTracker;
  courtOrderNotifySubscription: Subscription;
  homeVisitsPromise: Promise<void | void[]>;
  courtOrdersPromise: Promise<void | [void, void]>;
  enableHomeVisitsLoading: boolean;
  enableCourtOrderLoading: boolean;

  selectedTab: FeatureName;
  tabNotificationSubscription: Subscription;
  homeVisitsPromiseSubscription: Subscription;

  constructor(private courtOrderPromiseNotifyService: CourtOrderPromiseNotifyService, 
    private tabNotificationService: TabNotificationService, 
    private promiseNotifyService: PromiseNotifyService)
  {
    this.tilePagination = new TilePagination();

    this.enableHomeVisitsLoading = true;
    this.enableCourtOrderLoading = true;
    this.homeVisitsTracker = new WorkTracker(true);
    this.courtOrderTracker = new WorkTracker(true);

    this.courtOrderNotifySubscription = this.courtOrderPromiseNotifyService.courtOrdersPromiseChanged.subscribe((courtOrdersPromise) => {
      if (this.courtOrdersPromise != courtOrdersPromise) {
        this.courtOrdersPromise = courtOrdersPromise;
        this.LoadCourtOrders();
      }
    });
    this.tabNotificationSubscription = this.tabNotificationService.selectedTabChanged.subscribe((tab) => {
      if (this.selectedTab != tab) {
        this.selectedTab = tab;
      }
    });
    this.subscribeToPromiseNotifications();
  }

  ngOnDestroy() {
    if (this.tabNotificationSubscription) {
      this.tabNotificationSubscription.unsubscribe();
    }
    if (this.homeVisitsPromiseSubscription) {
      this.homeVisitsPromiseSubscription.unsubscribe();
    }
  }

  // This method controls the display of a ghost loading panel tile.
  // This meets the requirement to not show a tile unless we have data, 
  // however we should still provide visual queues to the person that we are loading data.
  // The ghost-tile will display when any one or more tile is loading data.
  // Once all tiles have completed loading the ghost tile will be removed.
  // Additionally, if a tile is already visible (as it may have previously had data) 
  // then even if now loading new data, we will not show the ghost-tile.
  upcomingVisitsPromise: Api.HomeVisitPromise;
  overdueVisitsPromise: Api.HomeVisitPromise;
  private subscribeToPromiseNotifications()
  {
    if(!this.homeVisitsPromiseSubscription)
    {
      this.homeVisitsPromiseSubscription = this.promiseNotifyService.promiseTrackerChanged.subscribe(async (thePromise) => {
        if(thePromise.visitType == 'upcoming-visits')
        {
          // Do nothing if we are already showing the home visits tile
          if(!!this.showTile(this.homeVisits))
          {
            this.enableHomeVisitsLoading = false;
          }
          else if (this.upcomingVisitsPromise != thePromise) { // Do nothing if we have no promise
            this.upcomingVisitsPromise = thePromise;
            
            // Start showing the ghost tile
            this.enableHomeVisitsLoading = true;
            await this.homeVisitsTracker.track(this.upcomingVisitsPromise.thePromise).then(results => {
              this.enableHomeVisitsLoading = false;
              this.upcomingVisitsFinishedLoading = true;

              // We are now showing the home visits tile. Stop showing the ghost loading tile
              if(!this.overdueVisitsFinishedLoading)
              {
                this.homeVisitsTracker.cancel();
              }
            });
          }
        }
        else if(thePromise.visitType == 'overdue-visits')
        {
          // Do nothing if we are already showing the home visits tile
          if(!!this.showTile(this.homeVisits))
          {
            this.enableHomeVisitsLoading = false;
          }
          else if (this.overdueVisitsPromise != thePromise) { // Do nothing if we have no promise
            this.overdueVisitsPromise = thePromise;

            // Start showing the ghost tile
            this.enableHomeVisitsLoading = true;
            await this.homeVisitsTracker.track(this.overdueVisitsPromise.thePromise).then(results => {
              this.enableHomeVisitsLoading = false;
              this.overdueVisitsFinishedLoading = true;

              // We are now showing the home visits tile. Stop showing the ghost loading tile
              if(!this.upcomingVisitsFinishedLoading)
              {
                this.homeVisitsTracker.cancel();
              }
            });
          }
        }
      });
    }
  }

  public async LoadCourtOrders()
  {
    // Do nothing if we have no promise or we are already showing the court orders tile
    if(!this.courtOrdersPromise || !!this.showTile(this.courtOrders)) return;

    // Start showing the ghost tile
    this.enableCourtOrderLoading = true;
    await this.courtOrderTracker.track(
      this.courtOrdersPromise.then(() => {
        this.enableCourtOrderLoading = false;
      })
    );
  }

  // When new tiles are created, ensure a new entry is added to 'HeroSection' 
  // Add a new getter here, which is used by the UI (be sure to add to the component on the html page)
  // when calling 'showTile' to determine whether it should be shown or not based on present/absent data.
  public get homeVisits(): HeroSection {
    return HeroSection.HomeVisits; 
  }

  public get courtOrders(): HeroSection {
    return HeroSection.CourtOrders; 
  }

  public get courtOrders_2(): HeroSection {
    return HeroSection.CourtOrders_2; 
  }

  public get courtOrders_3(): HeroSection {
    return HeroSection.CourtOrders_3;
  }

  public get courtOrders_4(): HeroSection {
    return HeroSection.CourtOrders_4;
  }

  public get staffWhereabouts(): HeroSection {
    return HeroSection.StaffWhereabouts; 
  }

  public showTestTiles(): boolean {
    return !!paginationTestSettings.enableTestPaginationTiles;
  }

  public updateHaveCourtOrders(haveOrders: boolean) : void 
  {
    this.haveOrders = haveOrders;
    if(!!haveOrders && haveOrders === true)
    {
      this.tilePagination.ensureTile(HeroSection.CourtOrders, 2);
      if(!!paginationTestSettings.enableTestPaginationTiles)
      {
        this.tilePagination.ensureTile(HeroSection.CourtOrders_2, 3);
        this.tilePagination.ensureTile(HeroSection.CourtOrders_3, 4);
        this.tilePagination.ensureTile(HeroSection.CourtOrders_4, 5);
      }
    } 
    else 
    {
      this.tilePagination.removeTile(HeroSection.CourtOrders);
      if(!!paginationTestSettings.enableTestPaginationTiles)
      {
        this.tilePagination.removeTile(HeroSection.CourtOrders_2);
        this.tilePagination.removeTile(HeroSection.CourtOrders_3);
        this.tilePagination.removeTile(HeroSection.CourtOrders_4);
      }
    }

    this.updateSelectedFeature();

    this.showMainDashboard.emit((this.tilePagination.countOfHeroSections() > 0));
  }

  public updateHaveHomeVisits(haveVisits: boolean) : void
  {
    this.haveHomeVisits = haveVisits;
    if(!!haveVisits && haveVisits === true)
    {
      this.tilePagination.ensureTile(HeroSection.HomeVisits, 1);
    } 
    else 
    {
      this.tilePagination.removeTile(HeroSection.HomeVisits);
    }

    this.updateSelectedFeature();

    this.showMainDashboard.emit((this.tilePagination.countOfHeroSections() > 0));
  }

  private updateSelectedFeature()
  {
    // Special handling when home visits have not loaded once yet and we do have some
    if(!this.homeVisitsHasLoadedOnce && !!this.haveHomeVisits)
    {
      this.homeVisitsHasLoadedOnce = true;
      this.forceShowCourtOrders = false;
      if(!this.forceShowHomeVisits)
      {
        this.forceShowHomeVisits = true;
        this.tabNotificationService.ChangeSelectedTab(FeatureName.OverdueVisits);
      }
    }
    else if(!!this.haveHomeVisits && !!this.haveOrders) {
      this.forceShowHomeVisits = false;
      this.forceShowCourtOrders = false;
    }
    // This is not the first load. 
    // We do have some data.
    // We may have started/stopped persona switcher a number of times as well
    else if(!this.haveHomeVisits && !!this.haveOrders)
    {
      this.forceShowHomeVisits = false;
      if(!this.forceShowCourtOrders)
      {
        this.forceShowCourtOrders = true;
        this.tabNotificationService.ChangeSelectedTab(FeatureName.CourtOrders3Months);
      }
    }
    else if(!!this.haveHomeVisits && !this.haveOrders)
    {
      this.forceShowCourtOrders = false;
      if(!this.forceShowHomeVisits)
      {
        this.forceShowHomeVisits = true;
        this.tabNotificationService.ChangeSelectedTab(FeatureName.OverdueVisits);
      }
    }
  }

  public updateActivityFeedExpanded(isExpanded: boolean)
  {
    this.activityFeedIsExpanded = isExpanded;
  }

  public showTile(section: HeroSection) : boolean 
  {
    return this.tilePagination.showTile(section);
  }

  // Logic to reflect:
  //  When there are less than 4 sections with data, return false. Do not show pagination buttons.
  //  When there are 4 or more sections, all with data. Show.
  public showButtons() : boolean
  {
    return this.tilePagination.showButtons();
  }

  // Return true when currentPageIndex is > 0. Meaning: they have paged to the right and have at least one page to return to
  public leftButtonIsEnabled() : boolean 
  {
    return this.tilePagination.leftButtonIsEnabled();
  }

  // Return true when showRight is true and currentPageIndex + 3 (max count of pages) < count of available groups of data.
  public rightButtonIsEnabled() : boolean 
  {
    return this.tilePagination.rightButtonIsEnabled();
  }

  public pageLeft()
  {
    return this.tilePagination.pageLeft();
  }

  public pageRight()
  {
    return this.tilePagination.pageRight();
  }
}
