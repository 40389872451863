<div class="table">
    <div class="row" role="row">
      <div [ngClass]="{ 'td': true, 'date-full': fullWidth, 'date-small': !fullWidth, 'date': true }" role="cell" data-title="Selected Date Value">
        <input name="dateValue" [attr.disabled]="disableComponent ? '' : null" 
          [ngClass]="{ 'selection': true, 'small-font': !!smallFont, 'error': !!showRequireDateError || !!showPastDateError || !!showInvalidDateError || !valueValid }"
          angular-mydatepicker #dp="angular-mydatepicker" 
          [(ngModel)]="dateRangeModel" 
          [options]="myDpOptions" 
          (dateChanged)="onDateChanged($event)" 
          (inputFieldChanged)="onInputFieldChanged($event)" 
          (click)="dp.toggleCalendar()" 
          [placeholder]="placeholderText" />
        <div class="dateoverlay">
          <button type="button" class="btn btn-secondary input-icon" id="df_clear" *ngIf="haveDateValue()" (click)="clearDateInput(true)" [attr.disabled]="disableComponent ? '' : null">
            <i class="fas fa-times fa-lg"></i>
          </button>
          <button type="button" class="btn btn-secondary input-icon" id="df_calendar" (click)="dp.toggleCalendar()" [attr.disabled]="disableComponent ? '' : null">
            <i class="fas fa-calendar fa-lg"></i>
          </button>
        </div>
      </div>
    </div>
  </div>
  