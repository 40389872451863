import { Component, EventEmitter, OnInit, Input, Output, ViewChild } from '@angular/core';
import * as moment from 'moment';
import {IAngularMyDpOptions, IMyDateModel, IMyMarkedDate, IMySingleDateModel, IMyInputFieldChanged, CalAnimation, IMyDate, AngularMyDatePickerDirective } from 'angular-mydatepicker';

@Component({
  selector: 'app-date-input',
  templateUrl: './date-input.component.html',
  styleUrls: ['./date-input.component.scss']
})
export class DateInputComponent implements OnInit {
  @ViewChild('dp') mydp: AngularMyDatePickerDirective;

  // This is passed in from the parent page
  @Input() dateRangeModel: IMyDateModel = null;
  @Input() disableComponent: boolean = true;
  @Input() getDateRange: boolean;
  @Input() smallFont: boolean;
  @Input() placeholderText: string;
  @Input() disableUntil: IMyDate;
  @Input() fullWidth: boolean;

  @Output() dateRangeChangedEvent: EventEmitter<IMyDateModel> = new EventEmitter<IMyDateModel>();

  @Output() validationsReRunEvent: EventEmitter<boolean> = new EventEmitter<boolean>();

  // The above dateRangeModel tends to be one update behind. That is, when checked, it shows the prior value, not the current value
  // mostRecentValue is guaranteed to be the value as entered in the input field on screen.
  mostRecentValue: IMyInputFieldChanged;

  showRequireDateError: boolean;
  showInvalidDateError: boolean;
  showPastDateError: boolean;

  valueValid: boolean;

  myDpOptions: IAngularMyDpOptions = {
    dateRange: false,
    todayTxt: 'Today',
    showFooterToday: true,
    dateFormat: 'dd mmm yyyy',
    firstDayOfWeek: 'mo',
    sunHighlight: true,
    markCurrentDay: true,
    selectorHeight: '220px',
    alignSelectorRight: false,
    calendarAnimation: {in: CalAnimation.Fade, out: CalAnimation.ScaleTop},
    openSelectorTopOfInput: false,
    monthSelector: true,
    yearSelector: true,
    showSelectorArrow: true,
    disableHeaderButtons: false,
    showWeekNumbers: false,
    markDates: [],
    satHighlight: true,
    highlightDates: [],
    markWeekends: <IMyMarkedDate>{},
    monthLabels: {
        1: 'Jan',
        2: 'Feb',
        3: 'Mar',
        4: 'Apr',
        5: 'May',
        6: 'Jun',
        7: 'Jul',
        8: 'Aug',
        9: 'Sep',
        10: 'Oct',
        11: 'Nov',
        12: 'Dec'
    },
    disableWeekdays: [],
    inline: false,
    stylesData: {
      selector: 'angular-mydatepicker',
      styles: `
        .angular-mydatepicker .myDpIconLeftArrow,
        .angular-mydatepicker .myDpIconRightArrow,
        .angular-mydatepicker .myDpHeaderBtn {
            color: #3855c1;
        }
        .angular-mydatepicker .myDpHeaderBtn:focus,
        .angular-mydatepicker .myDpMonthLabel:focus,
        .angular-mydatepicker .myDpYearLabel:focus {
            color: #66afe9;
        }
        .angular-mydatepicker .myDpDaycell:focus,
        .angular-mydatepicker .myDpMonthcell:focus,
        .angular-mydatepicker .myDpYearcell:focus {
            box-shadow: inset 0 0 0 1px #66afe9;
        }
        .angular-mydatepicker .myDpSelector:focus {
            box-shadow: -1px 1px 6px 0px #ADD8E6;
        }
        .angular-mydatepicker .myDpSelectorArrow:focus:before {
            border-bottom-color: #ADD8E6;
        }
        .angular-mydatepicker .myDpCurrMonth,
        .angular-mydatepicker .myDpMonthcell,
        .angular-mydatepicker .myDpYearcell {
            color: #3855c1;
            font-weight: bold;
        }
        .angular-mydatepicker .myDpDaycellWeekNbr {
            color: #3855c1;
        }
        .angular-mydatepicker .myDpPrevMonth,
        .angular-mydatepicker .myDpNextMonth {
            color: #6495ED;
        }
        .angular-mydatepicker .myDpWeekDayTitle {
            background-color: transparent;
            color: #3855c1;
            font-weight: bold;
        }
        .angular-mydatepicker .myDpHeaderBtnEnabled:hover,
        .angular-mydatepicker .myDpMonthLabel:hover,
        .angular-mydatepicker .myDpYearLabel:hover,
        .angular-mydatepicker .myDpFooterBtn:hover {
            color: #add8e6;
        }
        .angular-mydatepicker .myDpMarkCurrDay, 
        .angular-mydatepicker .myDpMarkCurrMonth, 
        .angular-mydatepicker .myDpMarkCurrYear {
            border-bottom: 2px solid #3855c1;
        }
        .angular-mydatepicker .myDpDisabled {
            color: #999;
        }
        .angular-mydatepicker .myDpHighlight {
            color: #cd5c5c;
        }
        .angular-mydatepicker .myDpTableSingleDay:hover, 
        .angular-mydatepicker .myDpTableSingleMonth:hover, 
        .angular-mydatepicker .myDpTableSingleYear:hover {
            background-color: #add8e6;
            color: #3855c1;
        }
        .angular-mydatepicker .myDpRangeColor {
            background-color: #dbeaff;
        }
        .angular-mydatepicker .myDpSelectedDay,
        .angular-mydatepicker .myDpSelectedMonth,
        .angular-mydatepicker .myDpSelectedYear {
            background-color: #8EBFFF;
        }
       `
    }
  };

  constructor() { 
    this.valueValid = true;
  }

  ngOnInit() {
    this.myDpOptions.dateRange = this.getDateRange;

    if(!!this.disableUntil)
    {
        this.myDpOptions.disableUntil = this.disableUntil;
    }
    
    this.showRequireDateError = false;
    this.showPastDateError = false;
    this.showInvalidDateError = false;

    this.resetFlags();
    this.onDateChanged(this.dateRangeModel);
  }

  resetFlags()
  {
    this.showRequireDateError = false;
    this.showPastDateError = false;
    this.showInvalidDateError = false;
    this.valueValid = true;		
  }

  getMoment(value: IMySingleDateModel)
  {
    return moment({year: value.date.year, month: value.date.month - 1, day: value.date.day});
  }

  getMomentFromMyDate(value: IMyDate)
  {
    return moment({year: value.year, month: value.month - 1, day: value.day});
  }

  // When this is called it can be called with partial and invalid values as well as valid values.
  onDateChanged(transientValue: IMyDateModel) : void 
  {
    this.onInputFieldChanged(this.createIMyInputFieldChanged(transientValue));

    this.dateRangeChangedEvent.emit(transientValue);
  }

  createIMyInputFieldChanged(dateModel: IMyDateModel) : IMyInputFieldChanged
  {
    var val = (!!dateModel && !!dateModel.isRange && !!dateModel.dateRange.formatted ? dateModel.dateRange.formatted : 
            !!dateModel && !dateModel.isRange && !!dateModel.singleDate && !!dateModel.singleDate.formatted ? dateModel.singleDate.formatted : 
            '');
    var isValid = (!!dateModel && !!dateModel.isRange && !!dateModel.dateRange.beginJsDate && !!dateModel.dateRange.endJsDate) ||
        (!!dateModel && !dateModel.isRange && !!dateModel.singleDate && !!dateModel.singleDate.jsDate);
    return {
        value: val,
        dateFormat: this.myDpOptions.dateFormat,
        valid: isValid
    }
  }

  clearDateInput(componentIsEnabled: boolean)
  {
    if(!!this.dateRangeModel)
    {
      this.dateRangeModel.singleDate = null;
      this.dateRangeModel.dateRange = null;
    }
    this.mydp.clearDate();
    this.disableComponent = !componentIsEnabled;
    this.onInputFieldChanged(this.createIMyInputFieldChanged(null));
  }

  haveDateValue() : boolean 
  {
    return !!this.mostRecentValue && !!this.mostRecentValue.value && this.mostRecentValue.value.length > 0;
  }

  // The parent element can override this value until the next time it is checked.
  // This is useful when the parent wants to style as an error when there is no value.
  setValueValidFalse()
  {
    this.valueValid = false;
  }

  getRequireDateError()
  {
    return this.showRequireDateError;
  }

  getInvalidDateError()
  {
    return this.showInvalidDateError;
  }

  getPastDateError()
  {
    return this.showPastDateError;
  }

  getDateValueIsValid()
  {
    return this.valueValid;
  }

  // Returns true if the single date, or the begin date in a date range is today
  dateSelectedIsToday() : boolean
  {
    var valueAsMoment = this.getInputValueAsMoment(this.mostRecentValue.value);
    if(!valueAsMoment) return false;
    let currentTime = moment();
    return (valueAsMoment.year() == currentTime.year() && valueAsMoment.month() == currentTime.month() && valueAsMoment.date() == currentTime.date());
  }

  // This expects a single value of the format DD MMM YYYY
  getInputValueAsMoment(fieldValue) : moment.Moment
  {
    if(!fieldValue) return null;

    if(!this.dateStringIsValid(fieldValue)) return null;

    var theMoment = moment(fieldValue, "DD MMM YYYY");
    if(!!theMoment.isValid)
    {
      return theMoment;
    }
    return null;
  }

  getSelectedDateParts() : string[]
  {
    if(!this.mostRecentValue || !this.mostRecentValue.value || this.mostRecentValue.value.length < 1) return null;
    return (!!this.mostRecentValue && !!this.mostRecentValue.value ? this.mostRecentValue.value.split(' - ') : []);
  }

  getStartDate()
  {
    var dateParts = this.getSelectedDateParts();
    if(!dateParts || dateParts.length < 1) return null;
    return this.getInputValueAsMoment(dateParts[0]);
  }

  isValid() : Boolean
  {
    return !this.showRequireDateError && !this.showInvalidDateError && !this.showPastDateError && !!this.valueValid;
  }

  dateIsTooEarly(momentToTest: moment.Moment) : Boolean
  {
    if(!!this.disableUntil)
    {
        var startBoundary = this.getMomentFromMyDate(this.disableUntil);
        return (momentToTest.isSameOrBefore(startBoundary));
    }
    return false;
  }

  // Run validations
  // We use this event as the alternative 'onDateChanged' will contain null when the value is invalid for any reason.
  // This can include when the date is valid but outside the accepted date range.
  // The fieldData supplied here will include the raw value in the 'value' property. This then allows us to 
  // detect various explicit types of errors and set specific error flags as appropriate.
  onInputFieldChanged(fieldData: IMyInputFieldChanged)
  {
    this.mostRecentValue = fieldData;
    
    // Reset all flags so that they represent a success state / no error
    this.resetFlags();

    // If this component is disabled, inform the parent the value has changed and simply return.
    if(!!this.disableComponent)
    {
      this.dateRangeChangedEvent.emit(this.dateRangeModel);
      return;
    }
    
    var dateIsAValidDate = true;
    // Ignore if there is no value at all
    if(!fieldData.value || fieldData.value.length < 1)
    {
        // We are not alerting on empty values, just values that cannot be parsed to dates or date ranges
        this.showRequireDateError = true;
        dateIsAValidDate = false;
    }
    else if(!fieldData.valid)
    {
      var dateParts = this.getSelectedDateParts();
      if(!dateParts) dateIsAValidDate = false;

        // Attempt to create a date from the given value
        if(!!this.getDateRange && !!dateParts)
        {
            if(dateParts.length != 2)
            {
                dateIsAValidDate = false;
            }
            else
            {
              var beginMoment = this.getInputValueAsMoment(dateParts[0]);
              var endMoment = this.getInputValueAsMoment(dateParts[1]);
              if(!beginMoment || !endMoment)
              {
                this.showInvalidDateError = true;
                dateIsAValidDate = false;
              }
              else
              {
                var startDate = beginMoment.format("DD MMM YYYY");
                var endMomentStr = endMoment.format("DD MMM YYYY");
                dateIsAValidDate = !!startDate && startDate != 'Invalid date' && !!endMomentStr && endMomentStr != 'Invalid date';

                if(this.dateIsTooEarly(beginMoment))
                {
                    this.showPastDateError = true;
                    dateIsAValidDate = false;
                }

                if(endMoment.isBefore(beginMoment))
                {
                  this.showInvalidDateError = true;
                  dateIsAValidDate = false;
                }
              }
            }
        }
        else if (!!dateParts)
        {
            if(!!fieldData && !!fieldData.value)
            {
              var startMoment = this.getInputValueAsMoment(dateParts[0]);
              if(!startMoment)
              {
                dateIsAValidDate = false;
                this.showInvalidDateError = true;
              }
              else
              {
                var theDate = startMoment.format("DD MMM YYYY");
                dateIsAValidDate = !!theDate && theDate != 'Invalid date';

                if(this.dateIsTooEarly(startMoment))
                {
                    this.showPastDateError = true;
                    dateIsAValidDate = false;
                }
              }
            }
            else
            {
                dateIsAValidDate = false;
            }
        }
    }
    this.valueValid = dateIsAValidDate;
    this.sanitiseErrors();
    this.validationsReRunEvent.emit(true);
    //console.log('showRequireDateError: ' + this.showRequireDateError + '. this.showPastDateError: ' + this.showPastDateError + '. this.showInvalidDateError: ' + this.showInvalidDateError + '. this.valueValid: ' + this.valueValid);
  }

  // We only want to show one error message at a time
  sanitiseErrors()
  {
    // Show past should trump require as the date is probably a valid date, just outside the 
    // allowed bounds (showRequireDateError can be set to true when the date is too far in the past)
    if(!!this.showRequireDateError && !!this.showPastDateError)
    {
      this.showRequireDateError = false;
    }

    if(!!this.showInvalidDateError && !!this.showPastDateError)
    {
      this.showInvalidDateError = false;
    }
  }

  dateStringIsValid(dateString: string) : Boolean 
  {
    let re = /(\d{2}\s(Jan|Feb|Mar|Apr|May|Jun|Jul|Aug|Sep|Oct|Nov|Dec)\s{1}\d{4})$/;
    return (re.test(dateString));
  }
}