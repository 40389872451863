import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class HomeVisitUpcomingCountNotifyService {

    constructor(
    ) { }

    private upcomingVisitCountData = new Subject<Api.UpcomingHomeVisitCountData>();
    public upcomingVisitCountDataChanged = this.upcomingVisitCountData.asObservable();

    ChangeHomeVisitCounts(countData: Api.UpcomingHomeVisitCountData) {
        this.upcomingVisitCountData.next(countData);
    }
}
