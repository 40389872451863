import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-staff-whereabouts-summary',
  templateUrl: './staff-whereabouts-summary.component.html',
  styleUrls: ['./staff-whereabouts-summary.component.scss']
})
export class StaffWhereaboutsSummaryComponent  {
  @Input() staffCheckedOut: number;
  @Input() staffOverdueForCheckIn: number;

  getCheckedOutSentence(): string {
    if (this.staffCheckedOut === 0) {
      return 'There are no staff members currently checked out.';
    }
    else if (this.staffCheckedOut === 1) {
      return 'There is 1 staff member currently checked out.';
    }
    else if (this.staffCheckedOut > 1) {
      return `There are ${this.staffCheckedOut} staff members currently checked out.`;
    }
    else if (!this.staffCheckedOut) {
      return 'There are ? staff members currently checked out.';
    }
  }

  getOverdueForCheckinSentence(): string {
    if (this.staffCheckedOut === 0) {
      return 'There are no staff members currently overdue for check-in.';
    }
    else if (this.staffCheckedOut === 1) {
      return 'There is 1 staff member currently overdue for check-in.';
    }
    else if (this.staffCheckedOut > 1) {
      return `There are ${this.staffCheckedOut} staff members currently overdue for check-in.`;
    }
    else if (!this.staffCheckedOut) {
      return 'There are ? staff members currently overdue for check-in.';
    }
  }
}
