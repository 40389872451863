import { HostListener, Directive } from "@angular/core";

@Directive()
export abstract class FocusCheck {
  protected windowGotFocus = true;

  constructor() { }

  @HostListener('window:focus', ['$event'])
  @HostListener('window:resize', ['$event'])
  @HostListener('window:keyup', ['$event'])
  @HostListener('window:wheel', ['$event'])
  @HostListener('window:scroll', ['$event'])
  onFocus(event: any): void {
    this.windowGotFocus = true;
  }

  @HostListener('window:blur', ['$event'])
  onBlur(event: any): void {
    this.windowGotFocus = false;
  }
}