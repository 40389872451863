<app-happy-day-motif></app-happy-day-motif>
<div class="container">
  <loading-pane [tracker]="tracker">
    <div id="content" class="table-wrapper" role="table">
      <div class="table-heading">
        <h1>Staff members for {{ serviceCentreName || 'your service centre' }}</h1>
      </div>
      <div class="top-level">
        <div class="row" role="row">
          <div class="th" role="columnheader" title="Header Column Name" aria-hidden="true"><span>Name</span></div>
          <div class="th" role="columnheader" title="Header Column Mobile Phone" aria-hidden="true"><span>Mobile Phone</span></div>
          <div class="th" role="columnheader" title="Header Column Check In Out" aria-hidden="true">Check In / Out</div>
          <div class="th" role="columnheader" title="Header Column Location" aria-hidden="true"><span>Location</span></div>
          <div class="th" role="columnheader" title="Header Column Details" aria-hidden="true"><span>Details</span></div>
          <div class="th" role="columnheader" title="Header Column Expected Return" aria-hidden="true"><span>Expected Return</span></div>
          <div class="th" role="columnheader" title="Header Column Lockdown Alert Seen" aria-hidden="true"><span>Lockdown Alert Seen</span></div>
        </div>
        <app-error-message *ngIf="hasError" appSection="staff whereabouts"></app-error-message>
        <app-no-results-message *ngIf="!hasError && dataLoaded && (!staffMembers || staffMembers.length === 0)">
        </app-no-results-message>
        <ng-container *ngFor="let staffMember of pagedStaffMembers; trackBy: trackBy">
          <loading-pane [enabled]="showUpdateGraphic(staffMember)">
            <div class="row" role="row" [ngClass]="{ 'even-row': isEven(staffMember) }">
              <div class="td emphasised" role="cell" data-title="Name">
                  <div class="visually-hidden" title="Header Column Name">Name</div>
                  <div>{{ staffMember.firstName }} {{ staffMember.familyName }}</div>
              </div>
              <div class="td" role="cell" data-title="Mobile Phone">
                  <div class="visually-hidden" title="Header Column Mobile Phone">Mobile Phone</div>
                  <div>{{ staffMember.phoneNumber }}&nbsp;</div>
              </div>
              <div class="td check-in-out" role="cell" data-title="Check In / Out">
                  <button [ngClass]="{ 'check-out': staffMember.location === null, 'check-in': staffMember.location !== null, 'toggle-details': true }" (click)="toggleCheckedOutStatus(staffMember)"
                  [attr.aria-label]="staffMember.location === null ? 'Checked In' : staffMember.location">
                  </button>
              </div>
              <div class="td" role="cell" data-title="Location">
                  <div class="visually-hidden" title="Header Column Location">Location</div>
                  <div *ngIf="staffMember.location !== null && staffMember.location.length > 0" (click)="edit(staffMember)" class="link">{{ staffMember.location }}&nbsp;</div>
                  <div *ngIf="staffMember.location === null">Checked in&nbsp;</div>
              </div>
              <div class="td" role="cell" data-title="Details" [ngClass]="{ 'text-danger': isDueForCheckin(staffMember) }"
                  title="{{ staffMember.locationDetails }}">
                  <div class="visually-hidden" title="Header Column Details">Details</div>
                  <div class="dont-break-out">{{ staffMember.locationDetails }}&nbsp;</div>
              </div>
              <div class="td" role="cell" data-title="Expected Return" [ngClass]="{ 'text-danger': isDueForCheckin(staffMember) }">
                  <div class="visually-hidden" title="Header Column Expected Return">Expected Return</div>
                  <div>{{ formatDate(staffMember.expectedReturn, true) }}&nbsp;</div>
              </div>
              <div class="td" role="cell" data-title="Lockdown Alert Seen">
                  <div class="visually-hidden" title="Header Column Lockdown Alert Seen">Lockdown Alert Seen</div>
                  <div *ngIf="hasAcknowledgedSiteLockdown(staffMember)"><i class="fa fa-check" style="color:green"></i> &nbsp;@ {{ formatDate(staffMember.lockDownAlertAckTime) }}&nbsp;</div>
                  <div *ngIf="!hasAcknowledgedSiteLockdown(staffMember)">&nbsp;</div>
              </div>
            </div>
          </loading-pane>
        </ng-container>
      </div>
    </div>
    
    <app-check-out (checkOutClosed)="checkOutClosed($event)"></app-check-out>
  </loading-pane> 
  <app-pagination (pageChangedEvent)="onPageChangedEvent($event)" (pageSizeChangedEvent)="onPageSizeChangedEvent($event)" [recordCount]="staffWhereaboutsCount" [style]="staffWhereaboutsStyle"></app-pagination>
</div>


