import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { WorkTracker } from 'app/shared/loading-pane';

export type UpcomingVisitsTask = 'LoadData' | 'FilterData';

@Injectable()
export class UpcomingVisitsNotificationService {

    constructor() { }

    private upcomingTask = new Subject<{ 'message': UpcomingVisitsTask, 'tracker': WorkTracker }>();
    public upcomingTaskChanged = this.upcomingTask.asObservable();

    UpdateUpcomingTask(msg) {
        this.upcomingTask.next(msg);
        // console.log('UpcomingVisitsNotificationService.LoadUpcomingVisits: ' + newMessage);
    }
}