<aside>
  <h3>Staff Whereabouts</h3>

  <div class="panel">
    <div class="">
      <h4><i class="fas fa-sign-out-alt fa-lg"></i> Checked Out</h4>
      <p>
          <span *ngIf="staffCheckedOut === undefined" class="text-danger">Sorry, there was a problem retrieving the latest data.</span>
        <ng-container *ngIf="staffCheckedOut != undefined">{{ getCheckedOutSentence() }}</ng-container>
      </p>
    </div>

    <div class="">
      <h4><i class="far fa-bell fa-lg"></i> Overdue</h4>
      <p>
        <span *ngIf="staffCheckedOut === undefined" class="text-danger">Sorry, there was a problem retrieving the latest data.</span>
        <ng-container *ngIf="staffCheckedOut != undefined">{{ getOverdueForCheckinSentence() }}</ng-container>
      </p>
    </div>
  </div>

</aside>