import {from, interval as observableInterval,  Observable ,  Subscription } from 'rxjs';
import { Component, Input, OnInit, OnDestroy, HostListener, ViewChild, ElementRef, Renderer2 } from '@angular/core';
import { WorkTracker } from 'app/shared/loading-pane';
import { UserInfoService } from '../../../services/user-info.service';
import { FocusCheck } from '../../shared/FocusCheck';
import { OfficeLockdownStatusService } from '../../../services/api/office-lockdown-status.service';
import { ServiceCentreLockdownService } from '../../../services/api/service-centre-lockdown.service';
import { FeatureLoggerService } from 'services';
import { officeLockdownStatusSettings } from '../../../settings';
import * as moment from 'moment';
import { FeatureName } from "api/website_enum";
import { ModalService } from '../../../services/modal.service';
import { OfficeLockdownNotifyService } from '../../../services/notifications/office-lockdown-notify.service';
import * as Bowser from "bowser";

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent extends FocusCheck implements OnInit, OnDestroy {
  @Input() time: string;
  @Input() date: string;

  @ViewChild('lockdownArea') lockdownAreaContainer:ElementRef;

  tracker: WorkTracker;
  showLockdownChangingBanner: boolean = false;
  showOfficeIsLockedDown: boolean;
  showOfficeIsNoLongerLockedDown: boolean;
  isDestroyed: boolean = false;
  serviceCentreDetails: Api.ServiceCentreViewModel;
  isTLOrManager: boolean;
  dataLoaded: boolean;
  officeName: string;
  lockedDownByName: string;
  lockedDownByUpn: string;
  lockedDownAt: string;
  _renderer: Renderer2;
  mobileView: boolean;
  informationalMessage: string;
  enableAlertButton: boolean;
  IsIE11: boolean;
  
  private intervalSubscription: Subscription;
  public isAuthenticated$: Observable<boolean>

  constructor(private userInfoService: UserInfoService, private officeLockdownService: OfficeLockdownStatusService, 
    private serviceCentreLockdownService: ServiceCentreLockdownService, private renderer: Renderer2, private featureLogger: FeatureLoggerService, 
    private modalService: ModalService, 
    private officeLockdownNotifyService: OfficeLockdownNotifyService) {
    super();
    this.showOfficeIsLockedDown = false;
    this.showOfficeIsNoLongerLockedDown = false;
    this.showLockdownChangingBanner = false;
    this.dataLoaded = false;
    this.tracker = new WorkTracker(true);
    this.officeName = '';
    this.lockedDownByName = '';
    this.lockedDownByUpn = '';
    this.lockedDownAt = '';
    this._renderer = renderer;
    this.mobileView = document.documentElement.clientWidth <= 900;
    this.enableAlertButton = true;

    this.informationalMessage = '';

    const bowser = Bowser.getParser(window.navigator.userAgent);

    this.IsIE11 = bowser.satisfies({
      "internet explorer": "<=11"
    });
  }

  async ngOnInit(): Promise<void> {
    await this.tracker.track(
      this.getOfficeLockdownStatus().then(async () => {
        if (!this.intervalSubscription && !this.isDestroyed) {
          const refreshInterval = officeLockdownStatusSettings.refreshIntervalInSeconds * 1000;
          this.intervalSubscription = observableInterval(refreshInterval).subscribe(async () => {
            await this.getOfficeLockdownStatus();
          });
        }
      })
    );
    this.isAuthenticated$ = from(this.userInfoService.isAuthenticated());
  }

  ngOnDestroy() {
    // Make sure the periodic api call is stopped.
    if (this.intervalSubscription) {
      this.intervalSubscription.unsubscribe();
    }

    this.isDestroyed = true;
  }

  userFullname(): string {
    return this.userInfoService.fullName();
  }
  
  lockedDownTextFullScreen(): string {
    if(!!this.showOfficeIsLockedDown)
    {
      return this.officeName + ' is in lock down due to a security issue. Set by ' + this.lockedDownByName + ' at ' + this.lockedDownAt + '.';
    }
    else if (!!this.showOfficeIsNoLongerLockedDown)
    {
      return this.officeName + ' is no longer locked down due to a security issue.';
    }
    else
    {
      return '';
    }
  }

  lockedDownTextMobile(sentence: number): string {
    if(!!this.showOfficeIsLockedDown)
    {
      return (sentence == 1 ? 
        this.officeName + ' is in lock down due to a security issue.'
        :
        'Set by ' + this.lockedDownByName + ' at ' + this.lockedDownAt + '.');
    }
    else if (!!this.showOfficeIsNoLongerLockedDown)
    {
      return this.officeName + ' is no longer locked down due to a security issue.';
    }
    else
    {
      return '';
    }
  }

  /*
  Skip to the element and ensure that focus is moved there.
  */
  scrollTo(elementId) {
    let element = document.getElementById(elementId);
    if(element != null)
    {
      element.scrollIntoView();
      element.setAttribute('tabindex','-1');
      element.focus();
    }
  }

  public updateTLManagerStatus(isTLOrMngr: boolean)
  {
    this.isTLOrManager = isTLOrMngr;
  }

  // If the office is not locked down, anyone can see the 'activate' toggle
  // If the office is locked down, only the officer who activated the 
  // lockdown can see the 'Safe to return' option. Also, a T/L or Manager 
  // can see the option.
  showLockdownToggle()
  {
    // If the office is not locked down, anyone can see the 'activate' toggle 
    if(!this.showOfficeIsLockedDown) {
      return true;
    }

    // A T/L or Manager can see the option.
    if(this.isTLOrManager) 
    {
      return true;
    }
    
    // The officer who activated the lockdown can see the 'Safe to return' option
    // this.serviceCentreDetails.lockedDownByName
    if(this.lockedDownByUpn == this.userInfoService.upn())
    {
      return true;
    }
    
    return false;
  }

  toggleLockdown(lockdownHasBeenToggled: boolean)
  {
    // We don't do anything with the result so no reason to await
    // Some time later (within 1 minute) the office lockdown get will fire and update the UI 
    // if locked down or removed.
    return (this.showOfficeIsLockedDown === true) ?
      this.openModal('deactivate-lockdown-modal')
      :
      this.openModal('activate-lockdown-modal');
  }

  async lockdownOffice()
  {
    // Handle race condition: Officer asks to lock down as we receive a message saying it is locked down
    if(!!this.showOfficeIsLockedDown){
      this.presentAlreadyLockedDown();
    }
    else
    {
      this.showOfficeIsNoLongerLockedDown = false;
      this.showLockdownChangingBanner = false;
      if(this.enableAlertButton === false) return;
      this.enableAlertButton = false;
      this.featureLogger.logFeatureUsage(FeatureName.LockDown);
      await this.tracker.track(
        this.serviceCentreLockdownService.lockdownOffice().then(async () => {
          this.showOfficeIsNoLongerLockedDown = true;
          await this.getOfficeLockdownStatus();
        })
        .catch(err => {
            if (err.status === 409) {
              this.presentAlreadyLockedDown();
            }
        })
      );      
    }
  }

  presentAlreadyLockedDown()
  {
    this.informationalMessage = 'CSSC is already in lock down.';
    this.enableAlertButton = true;
    this.openModal('informational-lockdown-modal');
  }

  async setOfficeSafeToReturn() {
    // Handle race condition: Officer asks to lift lock down as we receive a message saying it is lifted
    if(!this.showOfficeIsLockedDown){
      this.presentAlreadyLifted();
    }
    else
    {
      this.showLockdownChangingBanner = true;
      if(this.enableAlertButton === false) return;
      this.enableAlertButton = false;
      this.featureLogger.logFeatureUsage(FeatureName.SafeToReturn);
      await this.tracker.track(
        this.serviceCentreLockdownService.safeToReturn().then(async () => {
            await this.getOfficeLockdownStatus();
        })
        .catch(err => {
            if (err.status === 409) {
              this.presentAlreadyLifted();
            }
        })
      );
    }
  }

  presentAlreadyLifted()
  {
    this.informationalMessage = 'CSSC is already safe to return.';
    this.enableAlertButton = true;
    this.openModal('informational-lockdown-modal');
  }

  private async getOfficeLockdownStatus(): Promise<void> {
    if (this.isDestroyed) {
      this.ngOnDestroy();
      return Promise.resolve();
    }

    if (!this.shouldRefreshData()) {
      return Promise.resolve();
    }

    try {
      let results = await this.officeLockdownService.get();
      if(!results.hasError) {
        this.serviceCentreDetails  = results.value;
        this.dataLoaded = true;
        this.officeName = !!this.serviceCentreDetails ? this.serviceCentreDetails.name : '';
        this.lockedDownByName = !!this.serviceCentreDetails ? this.serviceCentreDetails.lockedDownByName : '';
        this.lockedDownByUpn = !!this.serviceCentreDetails ? this.serviceCentreDetails.lockedDownByUpn : '';
        this.lockedDownAt = !!this.serviceCentreDetails ? this.formatDate(this.serviceCentreDetails.lockedDownDtm) : '';

        // Inform listeners of the current service centre details
        this.officeLockdownNotifyService.ChangeServiceCentre(this.serviceCentreDetails);

        // Add the 'load' class to the displayed alert area
        if(!!this.serviceCentreDetails && this.serviceCentreDetails.isCurrentlyLockedDown === true) {
          // This will cause the 'setContainer' setter to be executed and the container made available on creation
          // This will in turn invoke 'showLockdown'
          // this.officeLockdownNotifyService.ChangeServiceCentre(this.serviceCentreDetails);
          this.showOfficeIsLockedDown = true;
          this.showLockdown();

        } else {
          this.removeLockdown();
        }

      } else {
        this.removeLockdown();
      }

    } catch (error) {
      this.removeLockdown();
    }
    finally {
      this.enableAlertButton = true;
    }
  }

  // HH:MM PM/AM, DD MMM YYYY
  formatDate(date: string): string {
    return !!date
      ? moment(date)
        .local()
        .format('h:mm A, D MMM YYYY')
      : 'n/a';
  }

  showLockdown() {
    this.showLockdownChangingBanner = true;
    this.showOfficeIsLockedDown = true;
    this.showOfficeIsNoLongerLockedDown = false;
    this.scrollTo('scrollableLockdownArea');
  }

  removeLockdown() {
    // Only show removing if already showing locked down
    this.showLockdownChangingBanner = (!!this.showOfficeIsLockedDown);
    this.showOfficeIsNoLongerLockedDown = (!!this.showOfficeIsLockedDown);
    this.showOfficeIsLockedDown = false;

    this.scrollTo('scrollableLockdownArea');
    
    this.officeName = !!this.serviceCentreDetails ? this.serviceCentreDetails.name : '';
    this.lockedDownByName = '';
    this.lockedDownByUpn = '';
    this.lockedDownAt = '';

    const lockdownArea = this.lockdownAreaContainer;
    this.enableAlertButton = true;
    
    setTimeout(() => {
      // Allow the above animation to run, with a scroll-to-top then 'n' second fade-out animation 
      // before hard-removing the elements with this flag
      //this.renderer.addClass(lockdownArea.nativeElement, 'visually-hidden');
      this.showLockdownChangingBanner = false;
      this.showOfficeIsLockedDown = false;
      this.showOfficeIsNoLongerLockedDown = false;

    }, 8000);
  }
  
  private shouldRefreshData() {
    var shouldRefresh = (!this.isDestroyed && (!this.dataLoaded || (this.windowGotFocus && !document.hidden)));
    return shouldRefresh;
  }

  openModal(id: string) {
    this.modalService.open(id);
  }

  closeModal(id: string) {
      this.modalService.close(id);
  }

  @HostListener('window:beforeunload ', ['$event'])
  beforeUnload(event: any): void {
    this.ngOnDestroy();
  }

  @HostListener('window:resize', ['$event'])
  onWindowResize(event: Event): void {
    this.mobileView = document.documentElement.clientWidth <= 900;
  }
}