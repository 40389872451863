import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class ShowPriorityNotifyService {

    constructor(
    ) { }

    private showPriorities = new Subject<boolean>();
    public showPrioritiesChanged = this.showPriorities.asObservable();

    ChangeShowPriorities(showPriority: boolean) {
        this.showPriorities.next(showPriority);
    }
}
