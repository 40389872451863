import { Injectable } from '@angular/core';
import { csx } from 'services/api/urls';
import { HttpClient } from '@angular/common/http';
import { Subject } from 'rxjs';

@Injectable()
export class PersonaSwitcherService {
  constructor(private http: HttpClient) {
  }

  private personaSwitchingIsEnabled: boolean = false;
  private currentPersonaUpn = new Subject<string>();
  public currentPersonaUpnChanged = this.currentPersonaUpn.asObservable();

  private ChangeCurrentPersonaUpn(newMessage: string) {
    this.currentPersonaUpn.next(newMessage);
  }

  async getTeam() {
    return this.http
      .get<Api.PersonaTeam[]>(
        csx.personaSwitcher({})
      )
      .toPromise();
  }

  public disable(): void {
    this.personaSwitchingIsEnabled = false;
    this.clearPersonaUpn();
  }

  public enable(): void {
    this.personaSwitchingIsEnabled = true;
  }

  public isEnable(): boolean {
    return this.personaSwitchingIsEnabled === true;
  }

  public setPersonaUpn(upn: string): void {
    if (!this.personaSwitchingIsEnabled) return;

    localStorage.setItem('personaUpn', upn);
    this.ChangeCurrentPersonaUpn(upn);
  }

  public getPersonaUpn(): string {
    var response = localStorage.getItem('personaUpn');
    return !!response && response.length > 0 ? response : '';
  }

  public clearPersonaUpn(): void {
    localStorage.removeItem('personaUpn');
    this.ChangeCurrentPersonaUpn(null);
  }
}
