import { Component, OnInit, OnDestroy, Input, HostListener, ViewContainerRef, ComponentFactoryResolver, ComponentRef, ComponentFactory } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { UserInfoService } from '../../../../services/user-info.service';
import { homeVisitsSettings } from '../../../../settings';
import { TaskSubsystemService } from '../../../../services/api/task-subsystem.service';
import { FilterSettings, MultiChoiceSearch } from '../filter-settings';
import { interval as observableInterval,  Subscription } from 'rxjs';
import { WorkTracker } from 'app/shared/loading-pane';
import { IdleDetectionService, FeatureLoggerService, RecordChangeDetectionService } from 'services';
import { FocusCheck } from '../../../shared/FocusCheck';
import { FeatureName } from "api/website_enum";
import { HomeVisitUpcomingCountNotifyService, PromiseNotifyService } from '../../../../services/notifications';
import { FilterDateRange } from "api/website_enum";
import { HomeVisitsService } from "../home-visits.service";
import { LocalStorageService } from 'services/local-storage.service';
import { UpcomingFilterComponent } from './upcoming-filter/upcoming-filter.component';
import * as moment from 'moment';
import { HomeVisitFilterService } from '../../../../services/home-visit-filter.service';

@Component({
  selector: 'app-upcoming-visits',
  templateUrl: './upcoming-visits.component.html',
  styleUrls: ['./upcoming-visits.component.scss']
})
export class UpcomingVisitsComponent extends FocusCheck implements OnInit, OnDestroy {
  storagePrefix: string = 'upcoming-visits';

  filterComponent: ComponentRef<UpcomingFilterComponent>;
  searchStart: Date;
  searchEnd: Date;
  
  upcomingVisitData: Api.HomeVisitData;
  pagedVisitsToShow: Api.ChildHomeVisit[];

  // This is either all data, or the visits that have been filtered to be shown
  visitsToShow: Api.ChildHomeVisit[] = [];

  recordChangeDetectionService: RecordChangeDetectionService;

  tracker: WorkTracker;
  intervalSubscription: Subscription;
  idleDetectionSubscription: Subscription;
  
  filterSettings: FilterSettings;
  filterSettingsBackupWhileMasqueruading: FilterSettings;
  previousUpn: string;
  tabNotificationSubscription: Subscription;

  // A technique to track which items are selected to be expanded
  selectedVisits: Api.ChildHomeVisit[] = [];
  officersUpn: string = '';
  
  userIsIdle: boolean = false;
  isDestroyed: boolean = false;
  refreshIntervalInMilliseconds: number;

  // Pagination settings
  currentPage: number = 0;
  pageSize: number = 10; // This is communicated from the pagination component
  upcomingVisitCount: number = 0;
  homeVisitsStyle: string = 'home-visits';

  constructor(
    private userInfoService: UserInfoService, 
    private titleService: Title,
    private taskSubsystem: TaskSubsystemService,
    private idleDetectionService: IdleDetectionService,
    private featureLogger: FeatureLoggerService,
    private homeVisitUpcomingCountNotifyService: HomeVisitUpcomingCountNotifyService, 
    private homeVisitsService: HomeVisitsService, 
    private container: ViewContainerRef, 
    private localStorageService: LocalStorageService,
    private componentResolver: ComponentFactoryResolver, 
    private homeVisitFilterService: HomeVisitFilterService, 
    private promiseNotifyService: PromiseNotifyService
  ) {
    super();
    this.refreshIntervalInMilliseconds = homeVisitsSettings.refreshIntervalInSeconds * 1000;
    this.officersUpn = userInfoService.upn();
    this.upcomingVisitData = {
      visitsHasError: false,
      visitsLoaded: false,
      visits: [],
      isUpcoming: true
    };
    this.tracker = new WorkTracker(true);
    this.filterSettings = new FilterSettings(this.localStorageService, false, this.storagePrefix);

    this.titleService.setTitle('CS Portal - Dashboard');
    this.recordChangeDetectionService = new RecordChangeDetectionService();
    this.recordChangeDetectionService.initialise(
      ['lastVisitDtm', 'currentLocationType', 'frequency', 'ownerName'], 
      ['id'], 'upcoming-visits'
    );
  }

  async ngOnInit(): Promise<void> {
    this.idleDetectionService.reset();
    this.idleDetectionSubscription = this.idleDetectionService.isIdleChanged.subscribe(isIdle => this.userIsIdle = isIdle);

    let upcomingVisitsPromise = this.loadData();

    await this.tracker.track(
      upcomingVisitsPromise.then(() => {
        this.updatePagedVisitsToShow();

        if (!this.intervalSubscription && !this.isDestroyed) {
          this.intervalSubscription = observableInterval(this.refreshIntervalInMilliseconds).subscribe(
            async () => {
              upcomingVisitsPromise = this.loadData();

              // Inform the hero component to allow it to display 'loading' imagery
              await this.tracker.track(upcomingVisitsPromise);
              this.updatePagedVisitsToShow();
            }
          );
        }
      })
    );

    // Initialise the 'previousUpn' to this members actual upn. This is used 
    // to manage filter settings when masquerading and particularly when 
    // swapping between more than one other persona before returning to this 
    // member's profile.
    this.previousUpn = this.officersUpn;
  }

  ngOnDestroy() {
    // Make sure the periodic api call is stopped.
    if (this.intervalSubscription) {
      this.intervalSubscription.unsubscribe();
    }
    if (this.tabNotificationSubscription) {
      this.tabNotificationSubscription.unsubscribe();
    }
    if (this.idleDetectionSubscription) {
      this.idleDetectionSubscription.unsubscribe();
    }

    this.idleDetectionService.ngOnDestroy();
    
    this.destroyFilterComponent();

    this.isDestroyed = true;
  }

  onPageChangedEvent(pageNumber: number)
  {
    this.currentPage = pageNumber;
    this.updatePagedVisitsToShow();
  }

  onPageSizeChangedEvent(pageSize: number)
  {
    this.pageSize = pageSize;
    this.updatePagedVisitsToShow();
  }

  updatePagedVisitsToShow()
  {
    var firstElToShow = this.pageSize * this.currentPage;
    var lastElToShow = firstElToShow + this.pageSize;

    // Update the values that are displayed. All data is already pre-loaded. Just paginate in-memory
    setTimeout(() => {
      if(!this.visitsToShow || this.visitsToShow.length < 1) 
      {
        this.pagedVisitsToShow = [];  
      }
      else
      {
        this.pagedVisitsToShow = this.visitsToShow.slice(firstElToShow, lastElToShow);
      }
    });
  }

  trackBy(index: number, item: Api.ChildHomeVisit) {
    return item.id;
  }

  async loadData() {
    var promise = this.loadTheData();
    this.promiseNotifyService.TrackThisPromise({ 
      visitType: this.storagePrefix,
      thePromise: promise
    });
    return promise;
  }

  async loadTheData() {
    if (this.isDestroyed) {
      this.ngOnDestroy();
      return Promise.resolve();
    }

    return await Promise.all([
      this.getUpcomingVisitsFromServer()
    ]);
  }

  private getUpcomingVisitsFromServer(): Promise<void> {
    if (!this.shouldRefreshData()) {
      return Promise.resolve();
    }

    try {
      this.upcomingVisitData.visitsLoaded = false;

      this.searchStart = this.getEffectiveStartDate();
      this.searchEnd = this.getEffectiveEndDate();

      return this.taskSubsystem.getUpcomingChildHomeVisits(this.searchStart, this.searchEnd).then(result => {
        this.upcomingVisitData.visitsHasError = result.hasError;
        if (result.hasError) {
          this.upcomingVisitData.visits = [];
          this.upcomingVisitCount = 0;
          this.notifySummaryOfCountData(true, FilterDateRange.Next4Weeks);
        } else {
          this.upcomingVisitData.visits = result.value;

          this.applyUpcomingHomeVisitsFilters();
        }
        this.upcomingVisitData.visitsLoaded = true;
        this.recordChangeDetectionService.applyNewDataSet(this.upcomingVisitData.visits);
      });
    } catch (error) {
      this.upcomingVisitData.visitsHasError = true;
      this.upcomingVisitData.visitsLoaded = true;
      this.upcomingVisitData.visits = [];
      this.upcomingVisitCount = 0;
      this.notifySummaryOfCountData(true, FilterDateRange.Next4Weeks);
    }
  }

  private notifySummaryOfCountData(hasError: boolean, dateRangeType: FilterDateRange)
  {
    // The raw visits can include multiple instances per child.
    // The HV Tile wants to know the count of unique children.
    // Count unique entries by subjectChildId field.
    const children = {};

    // Cycle through all entries. Create an object with a property with each subject child id as the property name.
    if(!!this.visitsToShow)
    {
      this.visitsToShow.forEach(el => {
        children[el.subjectChildId] = null;
      });
    }
    
    // The count of children will be the count of properties on this object
    const uniqueChildren = Object.keys(children).length;

    this.homeVisitUpcomingCountNotifyService.ChangeHomeVisitCounts({
      recordCount: uniqueChildren,
      hasError: hasError, 
      dateRangeType: dateRangeType
    });
  }

  public async personaChanged(upn: string) 
  {
    this.selectedVisits = [];
    if(this.filterComponent && this.filterComponent.instance)
    {
      this.filterComponent.instance.hideFilter(false);
    }

    // We are commencing impersonation
    if(!!upn && upn.length > 0)
    {
      // When the T/L or Manager masquerades as one member then immediately another, 
      // this check will not pass. This is good as it ensures we retain the T/Ls settings in backup.
      if(this.previousUpn == this.officersUpn)
      {
        // Backup this member's filter settings
        this.filterSettingsBackupWhileMasqueruading = this.filterSettings;
      }
      this.previousUpn = upn;

      // Create a new filter settings for use as this impersonated staff member
      let personaUpn = !!upn && upn.length > 0 ? '_' + upn : '';
      this.filterSettings = new FilterSettings(this.localStorageService, true, this.storagePrefix + personaUpn);
    }
    else
    {
      // We are ceasing impersonation
      // Restore this member's filter settings
      this.filterSettings = this.filterSettingsBackupWhileMasqueruading;
      this.previousUpn = this.officersUpn;
    }

    let upcomingVisitsPromise = this.loadData();
    var vm = this;
    await this.tracker.track(
      upcomingVisitsPromise.then(() => {
        if(!!vm.filterComponent && !!vm.filterComponent.instance && !!vm.filterComponent.instance.formModel)
        {
          // Before 'closing the filter' we should set the filter to the previous settings so they are immediately applied.
          vm.filterComponent.instance.formModel = vm.filterSettings;
        }

        vm.handleFilterClosed(true);
      })
    );
  }

  weAreMasquerading() : boolean {
    return !!this.previousUpn && this.previousUpn != this.officersUpn;
  }
  
  haveStaff(): boolean {
    if(!!this.weAreMasquerading())
    {
      // We are masquerading. Even if masquerading as a team leader, return false
      return false;
    }
    return this.homeVisitsService.haveStaff(this.upcomingVisitData);
  }

  isEven(item: Api.ChildHomeVisit): boolean {
    if (!this.visitsToShow) return false;

    let index = this.visitsToShow.indexOf(item, 0);

    return index % 2 === 0;
  }

  logCaseNoteFeature()
  {
    this.featureLogger.logFeatureUsage(FeatureName.CaseNote);
  }

  formatShortDate(date: string): string {
    return this.homeVisitsService.formatShortDate(date);
  }

  getDestinationPath(item: Api.ChildHomeVisit): string {
    return this.homeVisitsService.getDestinationPath(item);
  }

  hasDestinationPath(item: Api.ChildHomeVisit): boolean {
    return this.homeVisitsService.hasDestinationPath(item);
  }

  private shouldRefreshData() {
    if (this.isDestroyed) return false;

    return !this.upcomingVisitData.visitsLoaded || (!this.userIsIdle && this.windowGotFocus && !document.hidden);
  }

  // #startregion - Filter
  filterAlreadyShown: boolean = false;
  showFilter(evt: Event): void {
    if(!!this.filterAlreadyShown) return;
    let filterButtonClientRect = (<HTMLButtonElement>evt.currentTarget).getBoundingClientRect();

    this.createFilterComponent();
    this.filterComponent.instance.filterClosed.subscribe(this.handleFilterClosed.bind(this));
    this.filterComponent.instance.open(filterButtonClientRect);
    this.seedFilterValues(this.filterComponent.instance.formModel);
    this.filterComponent.instance.dataLoaded();
    this.filterAlreadyShown = true;
  }

  // This is called after the filter is closed
  hasChanged(previousSettings: FilterSettings, newSettings: FilterSettings): boolean {
    return previousSettings.hasChanged(newSettings);
  }

  async handleFilterClosed(useNewFilters: boolean) {
    this.filterAlreadyShown = false;
    var previousSettings = this.filterSettings;

    // Update local settings by re-reading from storage
    var newSettings = !!useNewFilters && this.filterComponent && this.filterComponent.instance ? this.filterComponent.instance.formModel : previousSettings;

    // Before updating settings with these possibly new values by calling 'updateFilter'
    // determine whether the values have changed and whether we require new data from the API server
    let hasChanged = this.hasChanged(previousSettings, newSettings);
    let requireNewData = !!hasChanged && !!previousSettings.requireNewServerData(newSettings, this.searchStart, this.searchEnd);

    // Update our knowledge of the settings; required by the ui
    this.filterSettings = newSettings;

    this.destroyFilterComponent();

    if(!!useNewFilters)
    {
      this.applyUpcomingHomeVisitsFilters();

      // Limit hits to the server when we know the filter was an in-memory one only
      if(requireNewData)
      {
        let upcomingVisitsPromise = this.loadData();
        await this.tracker.track(upcomingVisitsPromise);
      }
    } else {
      // We need to restore the local storage to the previous values before ending
      previousSettings.persistSessionFilterSettings(this.localStorageService);
    }

    // Update UI
    window.scrollTo(0, 0);
    let element = document.getElementById('filter');
    if(!!element) element.focus();
  }

  // Update the collection from the previous server retrieval based on the name search filter
  // This will first reduce the upcomingHomeVisits collection and set on upcomingHomeVisitsFiltered
  // It will then further reduce this filtered collection by applying the set of defined filters.
  applyUpcomingHomeVisitsFilters()
  {
    // If we retrieve data, for example after tabbing back to the Dashboard, the FilterComponent 
    // will not exist. We use the local filterSettings. But, these may not have the selection 
    // items initialised. So, even though prior selections are saved in local storage, we require 
    // these collections to be initialised for 'filtering' to be applied correctly. Re-seed if required.
    if(!this.filterSettings.nameSelect.checkboxOptions || 
      this.filterSettings.nameSelect.checkboxOptions.length < 1)
    {
      this.seedFilterValues(this.filterSettings);
    }

    // Get the latest filter settings
    this.visitsToShow = this.homeVisitFilterService.filterVisitsToShow(this.filterSettings, this.upcomingVisitData.visits);
    this.visitsToShow = this.homeVisitFilterService.filterVisitsToShowByDateRange(this.filterSettings, this.visitsToShow);

    // If we are masquerading apply a final filter to just show visits assigned to the selected member
    if(!!this.weAreMasquerading())
    {
      // Reduce to visits where the owner name is the empty string (this occurs when this person is the Team Leader)
      this.visitsToShow = this.homeVisitsService.reduceVisitsToThisMembersVisits(this.visitsToShow, this.previousUpn);
    }

    this.upcomingVisitCount = (!!this.visitsToShow ? this.visitsToShow.length : 0);

    this.notifySummaryOfCountData(false, this.filterSettings.selectedDateRange.dateRangeType);
    this.updatePagedVisitsToShow();
  }

  formatDateForFilterRange(date: Date): string {
    return this.homeVisitFilterService.formatDateForFilterRange(date);
  }

  getFilterDescription(options: MultiChoiceSearch, prefix: string): string {
    return this.homeVisitFilterService.getFilterDescription(options, prefix);   // upcoming-visits
  }

  getChildNameFilterDescription(prefix: string) : string {
    return this.homeVisitFilterService.getChildNameFilterDescription(this.filterSettings, prefix);
  }

  getEffectiveStartDate(): Date {
    let now = moment().utc().toDate();
    let effectiveStartDate = this.filterSettings.effectiveStartDate();
    return (effectiveStartDate.getTime() < now.getTime()) ? now : effectiveStartDate;
  }

  getEffectiveEndDate(): Date {
    return this.filterSettings.effectiveEndDate();
  }

  createFilterComponent() {
    this.container.clear();
    const filterComponentFactory: ComponentFactory<UpcomingFilterComponent> = this.componentResolver.resolveComponentFactory(UpcomingFilterComponent);
    this.filterComponent = this.container.createComponent(filterComponentFactory);
  }

  showFilterIcon() : boolean {
    return this.filterSettings.selectedDateRange.dateRangeType != FilterDateRange.Next4Weeks || 
      !this.filterSettings.interventionSelect.allSelected() || 
      !this.filterSettings.visitFrequencySelect.allSelected() || 
      !this.filterSettings.nameSelect.allSelected() || 
      !this.filterSettings.nameSearch.isEmpty();
  }

  seedFilterValues(settings: FilterSettings)
  {
    // Initialise the collection of child names. This is only changed on data retrieval
    settings.nameSelect.setOptions(this.upcomingVisitData.visits, 'subjectChildName', this.localStorageService);

    // Initialise the collection of intervention types. This is only changed on data retrieval
    settings.interventionSelect.setOptions(this.upcomingVisitData.visits, 'currentInterventionType', this.localStorageService);

    // Initialise the collection of home visit types. This is only changed on data retrieval
    settings.visitFrequencySelect.setOptions(this.upcomingVisitData.visits, 'frequency', this.localStorageService);
  }

  destroyFilterComponent() {
    if (this.container) {
      this.container.clear();
    }

    if (this.filterComponent) {
      this.filterComponent.instance.filterClosed.unsubscribe();
      this.filterComponent.destroy();
    }
  }

  // #endregion - Filter

  // #startregion Selected Visits

  toggleSelectVisit(visit: Api.ChildHomeVisit): void {
    if (this.isVisitSelected(visit)) {
      let foundVisit = this.selectedVisits.filter(o => o.id === visit.id)[0];
      if (foundVisit) {
        const index = this.selectedVisits.indexOf(foundVisit, 0);
        if (index > -1) {
          this.selectedVisits.splice(index, 1);
        }
        return;
      }
    }

    this.featureLogger.logFeatureUsage(FeatureName.UpcomingVisitsShowMore);
    this.selectedVisits.push(visit);
  }

  isVisitSelected(visit: Api.ChildHomeVisit): boolean {
    if (!visit || !this.selectedVisits) return false;

    return this.selectedVisits.filter(o => o.id === visit.id).length > 0;
  }

  showUpdateGraphic(visit: Api.ChildHomeVisit): boolean {
    return this.recordChangeDetectionService.showUpdateGraphic(visit);
  }

  // #endregion Selected Visits

  @HostListener('window:beforeunload ', ['$event'])
  beforeUnload(event: any): void {
    this.ngOnDestroy();
  }
}
