import { Component, Input, OnInit } from '@angular/core';
import { from, Observable } from 'rxjs';
import { UserInfoService } from '../../../services/user-info.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  @Input() time: string;
  @Input() date: string;
  public isAuthenticated$: Observable<boolean>
  

  constructor(private userInfoService: UserInfoService) {
  }

  ngOnInit(): void {
    this.isAuthenticated$ = from(this.userInfoService.isAuthenticated());
  }

  userFullname(): string {
    return this.userInfoService.fullName();
  }
}
