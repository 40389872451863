
import {interval as observableInterval,  Observable ,  Subscription } from 'rxjs';
import { Component, OnInit, OnDestroy, HostListener, EventEmitter, Output } from '@angular/core';
import { ActivityFeedService } from 'services/api/activity-feed.service';
import * as moment from 'moment';
import { activityFeedSettings } from '../../../settings';
import { SanitizeHtmlPipe } from '../../../pipes/sanitize-html.pipe';
import { IResponse } from '../../../services/api/response-interface';
import { WorkTracker } from 'app/shared/loading-pane';
import { IdleDetectionService } from 'services';
import { FocusCheck } from '../../shared/FocusCheck';
import { PersonaSwitcherService } from 'services/api/persona-switcher.service';

@Component({
  selector: 'app-activity-feed',
  templateUrl: './activity-feed.component.html',
  styleUrls: ['./activity-feed.component.scss']
})
export class ActivityFeedComponent extends FocusCheck implements OnInit, OnDestroy {
  @Output() activityFeedExpanded: EventEmitter<boolean> = new EventEmitter<boolean>();

  tracker: WorkTracker;
  activityFeedItems: ActivityFeedItem[];
  intervalSubscription: Subscription;
  idleDetectionSubscription: Subscription;
  personaChangedSubscription: Subscription;
  refreshIntervalInMilliseconds: number;
  hasError: boolean;
  dataLoaded: boolean;
  userIsIdle: boolean = false;
  isDestroyed: boolean = false;
  activityFeedIsExpanded: boolean = false;

  constructor(
    private activityFeedService: ActivityFeedService,
    private htmlSanitizer: SanitizeHtmlPipe,
    private idleDetectionService: IdleDetectionService,
    private personaSwitcherService: PersonaSwitcherService
  ) {
    super();
    this.tracker = new WorkTracker(true);
    this.refreshIntervalInMilliseconds = activityFeedSettings.refreshIntervalInSeconds * 1000;
    this.subscribeToPersonaChangedNotifications();
  }

  async ngOnInit() {
    this.idleDetectionService.reset();
    this.idleDetectionSubscription = this.idleDetectionService.isIdleChanged.subscribe(isIdle => this.userIsIdle = isIdle);

    await this.tracker.track(
      this.getActivityFeedItems().then(() => {
        if (!this.intervalSubscription && !this.isDestroyed) {
          this.intervalSubscription = observableInterval(this.refreshIntervalInMilliseconds).subscribe(
            async () => {
              await this.getActivityFeedItems();
            }
          );
        }
      })
    );
  }

  ngOnDestroy() {
    // Make sure the periodic api call is stopped.
    if (this.intervalSubscription) {
      this.intervalSubscription.unsubscribe();
    }

    if (this.idleDetectionSubscription) {
      this.idleDetectionSubscription.unsubscribe();
    }

    if (this.personaChangedSubscription) {
      this.personaChangedSubscription.unsubscribe();
    }

    this.idleDetectionService.ngOnDestroy();

    this.isDestroyed = true;
  }

  public toggleActivityFeed() : void 
  {
    this.activityFeedIsExpanded = !this.activityFeedIsExpanded;
    this.activityFeedExpanded.emit(this.activityFeedIsExpanded);
  }

  private getActivityFeedItems(): Promise<void> {
    if (this.isDestroyed) {
      this.ngOnDestroy();
      return Promise.resolve();
    }

    if (!this.shouldRefreshData()) {
      return Promise.resolve();
    }

    try {
      return this.activityFeedService.get().then(result => {
        if (!result || result.hasError === true) {
          this.hasError = true;
        } else {
          try {
            this.handleActivityFeedItemFromServer(result);
            this.hasError = false;
            this.dataLoaded = true;
          } catch (ex) {
            this.hasError = true;
          }
        }
      });
    } catch (error) {
      console.error(error);
      this.hasError = true;
    }
  }

  private handleActivityFeedItemFromServer(result: IResponse<Api.ActivityFeedItem[]>) {
    let latestFromServer = result.value
      .map(o => ({
        ...o,
        isNew: this.isNewItem(o),
        appName: this.parseAppName(o)
      }))
      .map(o => {
        return this.sanitizeText(o);
      })
      .map(o => {
        return this.addMoodStyleIfRequired(o);
      });
    if (!this.hasAnyActivityFeedItems() || latestFromServer.length != this.activityFeedItems.length) {
      this.activityFeedItems = latestFromServer;
    }
  }

  private hasAnyActivityFeedItems(): boolean {
    return this.activityFeedItems && this.activityFeedItems.length > 0;
  }

  /**
   * Sanitizes the text property of the activity feed item.
   */
  private sanitizeText(item: ActivityFeedItem): ActivityFeedItem {
    item.text = this.htmlSanitizer.transform(item.text).toString();

    return item;
  }

  /**
   * If the activity feed item is a mood update then append mood emoji
   * after the mood text and underline the mood text.
   */
  private addMoodStyleIfRequired(item: ActivityFeedItem): ActivityFeedItem {
    if (item.text.indexOf('mood') > 0) {
      let moodRegEx = new RegExp(/:other:[^\:]+:|:[a-zA-Z-]+:/g);
      let result = moodRegEx.exec(item.text);

      if (result && result.length === 1) {
        let matchedText = result[0];
        let parts = matchedText.split(':').filter(o => o && o.trim().length > 0);
        let moodText: string;
        let cssClassName: string;

        if (parts.length === 2) {
          // i.e. it's a 'custom' mood like :other:i've had enough:
          cssClassName = parts[0];
          moodText = parts[1];
        } else {
          // i.e. it's a pre-defined mood like :happy:
          cssClassName = parts[0];
          moodText = parts[0];
        }

        item.text = item.text.replace(`${matchedText}.`, `<span class="mood-text">${moodText}</span><i class="mood-${cssClassName}"></i>`);
      }
    }

    return item;
  }

  private isNewItem(item: Api.ActivityFeedItem): boolean {
    return this.hasAnyActivityFeedItems() && this.activityFeedItems.filter(i => i.id === item.id).length === 0;
  }

  /**
   * For accessibility reasons we need the app name for build the link text.
   */
  private parseAppName(item: Api.ActivityFeedItem): string {
    const genericSourceAppName: string = 'source app';

    if (!item || !item.subject) {
      return genericSourceAppName;
    }
    else if (item.subject.toLocaleLowerCase().indexOf('kicbox') >= 0) {
      return 'kicbox';
    }
    else {
      return genericSourceAppName;
    }
  }

  formatDate(date: string) {
    // Thu, 14th Feb 2019, 8:46 am
    return !!date
      ? moment(date)
        .local()
        .format('ddd Do MMM YYYY, h:mm a')
      : '';
  }

  private subscribeToPersonaChangedNotifications() {
    if (!this.personaChangedSubscription) {
      this.personaChangedSubscription = this.personaSwitcherService.currentPersonaUpnChanged.subscribe(async (upn) => {
        await this.tracker.track(this.getActivityFeedItems());
      });
    }
  }

  private shouldRefreshData() {
    if (this.isDestroyed) return false;

    return !this.dataLoaded || (!this.userIsIdle && this.windowGotFocus && !document.hidden);
  }

  @HostListener('window:beforeunload ', ['$event'])
  beforeUnload(event: any): void {
    this.ngOnDestroy();
  }
}

interface ActivityFeedItem extends Api.ActivityFeedItem {
  isNew: boolean;
  appName: string;
}
