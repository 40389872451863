import { MsalService } from './msal.service';
import { Injectable } from '@angular/core';

@Injectable()
export class UserInfoService {
  constructor(private adalService: MsalService) {}

  isAuthenticated(): Promise<boolean> {
    return this.adalService.isAuthenticated();
  }

  fullName(): string {
    if (!this.adalService.userInfo || !this.adalService.userInfo.name) return null;

    return this.adalService.userInfo.name;
  }

  firstName(): string {
    if (!this.adalService.userInfo || !this.adalService.userInfo.name) return null;
    const names=this.adalService.userInfo.name.split(" ");
    return names.length>0?names[0]:null;
  }

  lastName(): string {
    if (!this.adalService.userInfo || !this.adalService.userInfo.name) return null;

    return this.adalService.userInfo.name
  }

  upn(): string {
    if (!this.adalService.userInfo || !this.adalService.userInfo.username) return null;

    return this.adalService.userInfo.username;
  }
}
