import { Component, OnInit, OnDestroy, HostListener, ViewContainerRef, ComponentFactoryResolver, ComponentRef, ComponentFactory } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { UserInfoService } from '../../../../services/user-info.service';
import { homeVisitsSettings } from '../../../../settings';
import { TaskSubsystemService } from '../../../../services/api/task-subsystem.service';
import { FilterSettings, MultiChoiceSearch } from '../filter-settings';
import { interval as observableInterval,  Subscription } from 'rxjs';
import { WorkTracker } from 'app/shared/loading-pane';
import { IdleDetectionService, FeatureLoggerService, RecordChangeDetectionService } from 'services';
import { FocusCheck } from '../../../shared/FocusCheck';
import { FeatureName } from "api/website_enum";
import { HomeVisitOverdueCountNotifyService, ShowPriorityNotifyService, PromiseNotifyService } from '../../../../services/notifications';
import { FilterDateRange } from "api/website_enum";
import { HomeVisitsService } from "../home-visits.service";
import { LocalStorageService } from 'services/local-storage.service';
import { OverdueFilterComponent } from './overdue-filter/overdue-filter.component';
import { HomeVisitFilterService } from '../../../../services/home-visit-filter.service';
import { PriorityIconService } from 'services/priority-icon.service';
import * as moment from 'moment';

@Component({
  selector: 'app-overdue-visits',
  templateUrl: './overdue-visits.component.html',
  styleUrls: ['./overdue-visits.component.scss']
})
export class OverdueVisitsComponent extends FocusCheck implements OnInit, OnDestroy {
  storagePrefix: string = 'overdue-visits';

  filterComponent: ComponentRef<OverdueFilterComponent>;

  overdueVisitData: Api.HomeVisitData;
  pagedVisitsToShow: Api.ChildHomeVisit[];

  // This is either all data, or the visits that have been filtered to be shown
  visitsToShow: Api.ChildHomeVisit[] = [];

  recordChangeDetectionService: RecordChangeDetectionService;

  officersUpn: string = '';

  tracker: WorkTracker;
  intervalSubscription: Subscription;
  idleDetectionSubscription: Subscription;
  showPrioritySubscription: Subscription;
  filterSettings: FilterSettings;
  filterSettingsBackupWhileMasqueruading: FilterSettings;
  previousUpn: string;
  tabNotificationSubscription: Subscription;

  userIsIdle: boolean = false;
  isDestroyed: boolean = false;
  refreshIntervalInMilliseconds: number;

  displayPriorityColours: boolean = true;

  // Pagination settings
  currentPage: number = 0;
  pageSize: number = 10; // This is communicated from the pagination component
  overdueVisitCount: number = 0;
  homeVisitsStyle: string = 'home-visits';

  constructor(
    private userInfoService: UserInfoService, 
    private titleService: Title,
    private taskSubsystem: TaskSubsystemService,
    private idleDetectionService: IdleDetectionService,
    private featureLogger: FeatureLoggerService,
    private homeVisitOverdueCountNotifyService: HomeVisitOverdueCountNotifyService, 
    private homeVisitsService: HomeVisitsService,
    private showPriorityNotifyService: ShowPriorityNotifyService, 
    private priorityIconService: PriorityIconService, 
    private container: ViewContainerRef, 
    private localStorageService: LocalStorageService,
    private componentResolver: ComponentFactoryResolver, 
    private homeVisitFilterService: HomeVisitFilterService, 
    private promiseNotifyService: PromiseNotifyService
  ) {
    super();
    this.refreshIntervalInMilliseconds = homeVisitsSettings.refreshIntervalInSeconds * 1000;
    this.officersUpn = userInfoService.upn();
    this.overdueVisitData = {
      visitsHasError: false,
      visitsLoaded: false,
      visits: [],
      isUpcoming: false
    };
    this.tracker = new WorkTracker(true);
    this.filterSettings = new FilterSettings(this.localStorageService, false, this.storagePrefix);

    this.titleService.setTitle('CS Portal - Dashboard');
    this.pagedVisitsToShow = [];

    this.subscribeToPriorityNotifications();
    this.displayPriorityColours = this.priorityIconService.priorityIconFeatureIsEnabled();

    this.recordChangeDetectionService = new RecordChangeDetectionService();
    this.recordChangeDetectionService.initialise(
      ['dueDtm', 'currentLocationType', 'frequency', 'ownerName'], 
      ['id'], 'overdue-visits'
    );
  }

  async ngOnInit(): Promise<void> {
    this.idleDetectionService.reset();
    this.idleDetectionSubscription = this.idleDetectionService.isIdleChanged.subscribe(isIdle => this.userIsIdle = isIdle);

    let overdueVisitsPromise = this.loadData();

    await this.tracker.track(
      overdueVisitsPromise.then(() => {
        this.updatePagedVisitsToShow();

        if (!this.intervalSubscription && !this.isDestroyed) {
          this.intervalSubscription = observableInterval(this.refreshIntervalInMilliseconds).subscribe(
            async () => {
              overdueVisitsPromise = this.loadData();

              // Inform the hero component to allow it to display 'loading' imagery
              await this.tracker.track(overdueVisitsPromise);
              this.updatePagedVisitsToShow();
            }
          );
        }
      })
    );

    // Initialise the 'previousUpn' to this members actual upn. This is used 
    // to manage filter settings when masquerading and particularly when 
    // swapping between more than one other persona before returning to this 
    // member's profile.
    this.previousUpn = this.officersUpn;
  }

  ngOnDestroy() {
    // Make sure the periodic api call is stopped.
    if (this.intervalSubscription) {
      this.intervalSubscription.unsubscribe();
    }
    if (this.tabNotificationSubscription) {
      this.tabNotificationSubscription.unsubscribe();
    }
    if (this.idleDetectionSubscription) {
      this.idleDetectionSubscription.unsubscribe();
    }
    if (this.showPrioritySubscription) {
      this.showPrioritySubscription.unsubscribe();
    }

    this.idleDetectionService.ngOnDestroy();
    
    this.destroyFilterComponent();

    this.isDestroyed = true;
  }

  onPageChangedEvent(pageNumber: number)
  {
    this.currentPage = pageNumber;
    this.updatePagedVisitsToShow();
  }

  onPageSizeChangedEvent(pageSize: number)
  {
    this.pageSize = pageSize;
    this.updatePagedVisitsToShow();
  }

  updatePagedVisitsToShow()
  {
    var firstElToShow = this.pageSize * this.currentPage;
    var lastElToShow = firstElToShow + this.pageSize;

    // Update the values that are displayed. All data is already pre-loaded. Just paginate in-memory
    setTimeout(() => {
      if(!this.visitsToShow || this.visitsToShow.length < 1) 
      {
        this.pagedVisitsToShow = [];  
      }
      else
      {
        this.pagedVisitsToShow = this.visitsToShow.slice(firstElToShow, lastElToShow);
      }
    });
  }

  showUpdateGraphic(visit: Api.ChildHomeVisit): boolean {
    var response = this.recordChangeDetectionService.showUpdateGraphic(visit);
    if(!response || response == undefined) return false;
    return response;
  }

  trackBy(index: number, item: Api.ChildHomeVisit) {
    return item.id;
  }

  async loadData() {
    var promise = this.loadTheData();
    this.promiseNotifyService.TrackThisPromise({ 
      visitType: this.storagePrefix,
      thePromise: promise
    });
    return promise;
  }

  async loadTheData() {
    if (this.isDestroyed) {
      this.ngOnDestroy();
      return Promise.resolve();
    }

    return await Promise.all([
      this.getOverdueVisitsFromServer()
    ]);
  }

  private getOverdueVisitsFromServer(): Promise<void> {
    if (!this.shouldRefreshData()) {
      return Promise.resolve();
    }

    try {
      this.overdueVisitData.visitsLoaded = false;

      // Due to the issue of filters and needing to display filtered counts in the summary tiles, we need to retrieve all data
      // The alternative would be server-side filtering and that would result in terrible performance
      // return this.taskSubsystem.getOverdueChildHomeVisits('' + this.currentPage, '10').then(result => {
      return this.taskSubsystem.getOverdueChildHomeVisits('0', '10000').then(result => {
        this.overdueVisitData.visitsHasError = result.hasError;
        if (result.hasError) {
          this.overdueVisitData.visits = [];
          this.overdueVisitCount = 0;
          this.notifySummaryOfCountData(0, true, FilterDateRange.Next4Weeks);
        } else {
          this.overdueVisitData.visits = result.value;

          this.applyOverdueHomeVisitsFilters();
        }
        this.overdueVisitData.visitsLoaded = true;
        this.recordChangeDetectionService.applyNewDataSet(this.overdueVisitData.visits);
      });
    } catch (error) {
      this.overdueVisitData.visitsHasError = true;
      this.overdueVisitData.visitsLoaded = true;
      this.overdueVisitData.visits = [];
      this.overdueVisitCount = 0;
      this.notifySummaryOfCountData(0, true, FilterDateRange.Next4Weeks);
    }
  }

  private notifySummaryOfCountData(recordCount: number, hasError: boolean, dateRangeType: FilterDateRange)
  {
    this.homeVisitOverdueCountNotifyService.ChangeHomeVisitCounts({
      recordCount: recordCount,
      hasError: hasError, 
      dateRangeType: dateRangeType
    });
  }

  public async personaChanged(upn: string) 
  {
    if(this.filterComponent && this.filterComponent.instance)
    {
      this.filterComponent.instance.hideFilter(false);
    }

    // We are commencing impersonation
    if(!!upn && upn.length > 0)
    {
      // When the T/L or Manager masquerades as one member then immediately another, 
      // this check will not pass. This is good as it ensures we retain the T/Ls settings in backup.
      if(this.previousUpn == this.officersUpn)
      {
        // Backup this member's filter settings
        this.filterSettingsBackupWhileMasqueruading = this.filterSettings;
      }
      this.previousUpn = upn;

      // Create a new filter settings for use as this impersonated staff member
      let personaUpn = !!upn && upn.length > 0 ? '_' + upn : '';
      this.filterSettings = new FilterSettings(this.localStorageService, true, this.storagePrefix + personaUpn);
    }
    else
    {
      // We are ceasing impersonation
      // Restore this member's filter settings
      this.filterSettings = this.filterSettingsBackupWhileMasqueruading;
      this.previousUpn = this.officersUpn;
    }

    let overdueVisitsPromise = this.loadData();
    var vm = this;
    await this.tracker.track(
      overdueVisitsPromise.then(() => {
        if(!!vm.filterComponent && !!vm.filterComponent.instance && !!vm.filterComponent.instance.formModel)
        {
          // Before 'closing the filter' we should set the filter to the previous settings so they are immediately applied.
          vm.filterComponent.instance.formModel = vm.filterSettings;
        }

        vm.handleFilterClosed(true);
      })
    );
  }

  private subscribeToPriorityNotifications() {
    if (!this.showPrioritySubscription) {
      this.showPrioritySubscription = this.showPriorityNotifyService.showPrioritiesChanged.subscribe(async (show) => {
        this.displayPriorityColours = show;
      });
    }
  }

  priorityTitle(homeVisit: Api.ChildHomeVisit) : string 
  {
    if(homeVisit.isOverdueWithHighPriority || homeVisit.isOverdueWithModeratePriority)
    {
      var currentTime = moment();
      var lastVisit = !!homeVisit.lastVisitDtm ? moment(homeVisit.lastVisitDtm, 'YYYY-MM-DD') : moment(homeVisit.homeVisitStateAnchorDtm, 'YYYY-MM-DD');
      var ageInDays = currentTime.diff(lastVisit, 'days');
      if(!!homeVisit.lastVisitDtm)
      {
        return `${ageInDays} days since last prescribed home visit`;
      }
      else
      {
        return `${ageInDays} days since the OI event was created (no Prescribed Home Visit case note recorded)`;
      }
    }
    return 'On track';
  }

  weAreMasquerading() : boolean {
    return !!this.previousUpn && this.previousUpn != this.officersUpn;
  }

  haveStaff(): boolean {
    if(!!this.weAreMasquerading())
    {
      // We are masquerading. Even if masquerading as a team leader, return false
      return false;
    }

    return this.homeVisitsService.haveStaff(this.overdueVisitData);
  }

  isEven(item: Api.ChildHomeVisit): boolean {
    if (!this.visitsToShow) return false;

    let index = this.visitsToShow.indexOf(item, 0);

    return index % 2 === 0;
  }

  logCaseNoteFeature()
  {
    this.featureLogger.logFeatureUsage(FeatureName.CaseNote);
  }

  formatShortDate(date: string): string {
    return this.homeVisitsService.formatShortDate(date);
  }

  getDestinationPath(item: Api.ChildHomeVisit): string {
    return this.homeVisitsService.getDestinationPath(item);
  }

  hasDestinationPath(item: Api.ChildHomeVisit): boolean {
    return this.homeVisitsService.hasDestinationPath(item);
  }

  private shouldRefreshData() {
    if (this.isDestroyed) return false;

    return !this.overdueVisitData.visitsLoaded || (!this.userIsIdle && this.windowGotFocus && !document.hidden);
  }

  // #startregion - Filter
  filterAlreadyShown: boolean = false;
  showFilter(evt: Event): void {
    if(!!this.filterAlreadyShown) return;
    let filterButtonClientRect = (<HTMLButtonElement>evt.currentTarget).getBoundingClientRect();

    this.createFilterComponent();
    this.filterComponent.instance.filterClosed.subscribe(this.handleFilterClosed.bind(this));
    this.filterComponent.instance.open(filterButtonClientRect);
    this.seedFilterValues(this.filterComponent.instance.formModel);
    this.filterComponent.instance.dataLoaded();
    this.filterComponent.instance.updateDisplayPriorityColours(this.displayPriorityColours);
    this.filterAlreadyShown = true;
  }

  // This is called after the filter is closed
  hasChanged(previousSettings: FilterSettings, newSettings: FilterSettings): boolean {
    return previousSettings.hasChanged(newSettings);
  }

  async handleFilterClosed(useNewFilters: boolean) {
    this.filterAlreadyShown = false;
    var previousSettings = this.filterSettings;

    // Update local settings by re-reading from storage
    var newSettings = !!useNewFilters && this.filterComponent && this.filterComponent.instance ? this.filterComponent.instance.formModel : previousSettings;

    // Before updating settings with these possibly new values by calling 'updateFilter'
    // determine whether the values have changed and whether we require new data from the API server
    let hasChanged = this.hasChanged(previousSettings, newSettings);
    let requireNewData = !!hasChanged && !!previousSettings.requireNewServerData(newSettings, null, null);

    // Update our knowledge of the settings; required by the ui
    this.filterSettings = newSettings;

    this.destroyFilterComponent();

    if(!!useNewFilters)
    {
      this.applyOverdueHomeVisitsFilters();

      // Limit hits to the server when we know the filter was an in-memory one only
      if(requireNewData)
      {
        let upcomingVisitsPromise = this.loadData();
        await this.tracker.track(upcomingVisitsPromise);
      }
    } else {
      // We need to restore the local storage to the previous values before ending
      previousSettings.persistSessionFilterSettings(this.localStorageService);
    }

    // Update UI
    window.scrollTo(0, 0);
    let element = document.getElementById('filter');
    if(!!element) element.focus();
  }

  // Update the collection from the previous server retrieval based on the name search filter
  // This will first reduce the upcomingHomeVisits collection and set on upcomingHomeVisitsFiltered
  // It will then further reduce this filtered collection by applying the set of defined filters.
  applyOverdueHomeVisitsFilters()
  {
    // If we retrieve data, for example after tabbing back to the Dashboard, the FilterComponent 
    // will not exist. We use the local filterSettings. But, these may not have the selection 
    // items initialised. So, even though prior selections are saved in local storage, we require 
    // these collections to be initialised for 'filtering' to be applied correctly. Re-seed if required.
    if(!this.filterSettings.nameSelect.checkboxOptions || 
      this.filterSettings.nameSelect.checkboxOptions.length < 1)
    {
      this.seedFilterValues(this.filterSettings);
    }

    // Get the latest filter settings
    this.visitsToShow = this.homeVisitFilterService.filterVisitsToShow(this.filterSettings, this.overdueVisitData.visits);

    // If we are masquerading apply a final filter to just show visits assigned to the selected member
    if(!!this.weAreMasquerading())
    {
      // Reduce to visits where the owner name is the empty string (this occurs when this person is the Team Leader)
      this.visitsToShow = this.homeVisitsService.reduceVisitsToThisMembersVisits(this.visitsToShow, this.previousUpn);
    }

    this.overdueVisitCount = (!!this.visitsToShow ? this.visitsToShow.length : 0);

    this.notifySummaryOfCountData(this.overdueVisitCount, false, this.filterSettings.selectedDateRange.dateRangeType);
    this.updatePagedVisitsToShow();
  }

  formatDateForFilterRange(date: Date): string {
    return this.homeVisitFilterService.formatDateForFilterRange(date);
  }

  getFilterDescription(options: MultiChoiceSearch, prefix: string): string {
    return this.homeVisitFilterService.getFilterDescription(options, prefix);
  }

  getChildNameFilterDescription(prefix: string) : string {
    return this.homeVisitFilterService.getChildNameFilterDescription(this.filterSettings, prefix);
  }

  createFilterComponent() {
    this.container.clear();
    const filterComponentFactory: ComponentFactory<OverdueFilterComponent> = this.componentResolver.resolveComponentFactory(OverdueFilterComponent);
    this.filterComponent = this.container.createComponent(filterComponentFactory);
  }

  showFilterIcon() : boolean {
    return !this.filterSettings.priorityLevels.allSelected() || 
      !this.filterSettings.interventionSelect.allSelected() || 
      !this.filterSettings.visitFrequencySelect.allSelected() || 
      !this.filterSettings.nameSelect.allSelected() || 
      !this.filterSettings.nameSearch.isEmpty();
  }

  seedFilterValues(settings: FilterSettings)
  {
    // Initialise the collection of child names. This is only changed on data retrieval
    settings.nameSelect.setOptions(this.overdueVisitData.visits, 'subjectChildName', this.localStorageService);

    // Initialise the collection of intervention types. This is only changed on data retrieval
    settings.interventionSelect.setOptions(this.overdueVisitData.visits, 'currentInterventionType', this.localStorageService);

    // Initialise the collection of home visit types. This is only changed on data retrieval
    settings.visitFrequencySelect.setOptions(this.overdueVisitData.visits, 'frequency', this.localStorageService);
  }

  destroyFilterComponent() {
    if (this.container) {
      this.container.clear();
    }

    if (this.filterComponent) {
      this.filterComponent.instance.filterClosed.unsubscribe();
      this.filterComponent.destroy();
    }
  }

  // #endregion - Filter

  @HostListener('window:beforeunload ', ['$event'])
  beforeUnload(event: any): void {
    this.ngOnDestroy();
  }
}
