import { Component, OnInit, Renderer2, ViewChild, AfterViewInit, ElementRef } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import * as moment from 'moment';
import { IMyDateModel, IMySingleDateModel, IMyDate } from 'angular-mydatepicker';
import { NgxMaterialTimepickerComponent } from 'ngx-material-timepicker';
import { DateInputComponent } from './../../shared/date-input/date-input.component';

@Component({
  selector: 'app-date-time-input',
  templateUrl: './date-time-input.component.html',
  styleUrls: ['./date-time-input.component.scss']
})
export class DateTimeInputComponent implements OnInit, AfterViewInit {
  @ViewChild(DateInputComponent)
  private dateInputComponent: DateInputComponent;

  @ViewChild('tpinput') tpinput: ElementRef;
  @ViewChild('timepicker') timepickerContainer: NgxMaterialTimepickerComponent;

  form: UntypedFormGroup;
  model: IMyDateModel = null;
  disabled: boolean = true;
  disableUntil: IMyDate;

  getDateRange: boolean = false;

  showRequireDateError: boolean;
  showInvalidDateError: boolean;
  showPastDateError: boolean;

  showRequireTimeError: boolean;
  showInvalidTimeError: boolean;
  showPastTimeError: boolean;

  currentTimeValue: string;

  constructor(private formBuilder: UntypedFormBuilder, private renderer: Renderer2) { 
    this.disableUntil = { year: moment().year(), month: moment().month() + 1, day: moment().date() -1 };
  }

  ngOnInit() {


    this.initialiseValues();
  }

  initialiseValues()
  {
    // Initialize to today with javascript date object
    let currentTime = this.getTimeAtNext15MinuteIncrement(moment());

    this.model = {isRange: false, singleDate: {date: { 
      year: currentTime.year(), 
      month: currentTime.month() + 1, 
      day: currentTime.date() 
    }}};
        
    this.showRequireTimeError = false;
    this.showPastTimeError = false;
    this.showInvalidTimeError = false;

    // Default time to be the next 15 minute increment. eg. If now is 10:01 then set to 10:15.
    this.currentTimeValue = currentTime.format('hh:mm A');

    if(!this.dateInputComponent) return;
    this.dateInputComponent.isValid();
  }

  ngAfterViewInit(): void {
    if(this.timepickerContainer == null || !this.timepickerContainer || !!this.timepickerContainer.disabled) return;

    let currentTime = this.getTimeAtNext15MinuteIncrement(moment());
    this.timepickerContainer.defaultTime =  currentTime.format('hh:mm A');
    // hack to make the selected time from above be shown in the input field associated with the component.
    document.getElementById("tp").click();
    this.timepickerContainer.close();
  }

  // Expect a string of format: YYYY-MM-DDTHH:mm:ss+10:00
  // Example value:             2021-05-10T10:00:00+10:00
  seedValue(value: string)
  {
    // If we have no value, change nothing. Can occur on clicking link when not already checked out
    // Also, validate the value can be parsed. If not, simply return
    if(!value || value.length < 1 || !moment(value, "YYYY-MM-DDThh:mm:ssZZ").isValid()) return;

    // Parse the given value
    var givenTimeAsMoment = moment(value, "YYYY-MM-DDThh:mm:ssZZ");

    // Seed the time value, but after rounding to a 15 minute increment
    givenTimeAsMoment = this.getTimeAtNext15MinuteIncrement(givenTimeAsMoment, false);
    this.currentTimeValue = givenTimeAsMoment.format('hh:mm A');

    // Seed the date value
    this.model = {isRange: false, singleDate: {date: { 
      year: givenTimeAsMoment.year(), 
      month: givenTimeAsMoment.month() + 1, 
      day: givenTimeAsMoment.date() 
    }}};
  }

  dateChangedEvent(newDateRange: IMyDateModel)
  {
    this.model = newDateRange;
    if(!this.dateInputComponent) return;
    this.updateValidations();
  }

  validationsReRunEvent(validationsRun: boolean)
  {
    if(!validationsRun || !this.dateInputComponent) return;
    this.updateValidations();
  }

  updateValidations()
  {
    this.showRequireDateError = this.dateInputComponent.getRequireDateError();
    this.showInvalidDateError = this.dateInputComponent.getInvalidDateError();
    this.showPastDateError = this.dateInputComponent.getPastDateError();

    // If the date was not today and the time has already been set to a time earlier than 'now'
    // and then the date is modified to 'today', we need to trigger time validation so that the 
    // 'this time is before now' error can be shown.
    this.validateTimeInput();
  }

  getDefaultTime() : string 
  {
    let currentTime = this.getTimeAtNext15MinuteIncrement(moment());
    return currentTime.format('hh:mm A');
  }

  openTimePicker() : void 
  {
    this.timepickerContainer.open();
  }

  // Example value: 03:15 pm
  timepickerValueSet(newValue : any) : void
  {
    this.currentTimeValue = newValue;
  }

  // Trigger validation based on most recent setting of value in currentTimeValue
  timepickerClosed() : void
  {
    this.validateTimeInput();
  }

  convertDateAndTimeToMoment()
  {
    var theDate = this.dateInputComponent.getStartDate();
    if(!theDate) return null;
    if(this.currentTimeValue.indexOf('Invalid') >= 0) return null;
    let fullDateTimeStr = theDate.format('DD MMM YYYY') + ' ' + this.currentTimeValue;
    return moment(fullDateTimeStr, "DD MMM YYYY hh:mm a");
  }

  getMoment(value: IMySingleDateModel)
  {
    return moment({year: value.date.year, month: value.date.month - 1, day: value.date.day});
  }

  // The time picker component will not stop a person from manually clearing the value and it will 
  // not update the model. Copy the value from the input field to this.currentTimeValue.
  public timepickerblur(evt: FocusEvent) {
    var val = this.tpinput.nativeElement.value;
    this.currentTimeValue = val;
    this.validateTimeInput();
  }

  // Guarantee the time is after 'now' when the date is today. Any time is OK on future dates
  validateTimeInput() 
  {
    this.showRequireTimeError = false;
    this.showInvalidTimeError = false;
    this.showPastTimeError = false;
    let response = null;

    if(!this.currentTimeValue || this.currentTimeValue == null || this.currentTimeValue.length < 1)
    {
      this.showRequireTimeError = true;
      response = false;
    }
    else
    {
      // Validate format. Caters to a person typing in random input
      let re = /(\d{1,2}:(00|15|30|45)\s{1}([AaPp][Mm]))$/;
      let thisIsAValidTime = (re.test(this.currentTimeValue));
      if(!thisIsAValidTime)
      {
        this.showInvalidTimeError = true;
        response = false;
      }

      // The date is today. We need to check time
      if(this.dateInputComponent.dateSelectedIsToday()) 
      {
        // Validate the time is after 'now'
        let selection = this.convertDateAndTimeToMoment();
        if(!!selection) {
          let currentTime = this.getTimeAtNext15MinuteIncrement(moment());
          if(selection.isBefore(currentTime))
          {
            this.showPastTimeError = true;
            response = false;
          }
        }
        else
        {
          response = false;
        }
      }
    }

    // The time picker component can set the value 'Invalid DateTime'. Remove this as we have our own error styling and message.
    // If it is not set to invalid, do not clear as it's a better experience for the user to adjust their existing entry instead of having it cleared.
    var val = this.tpinput.nativeElement.value;
    if(val.indexOf('Invalid') >= 0)
    {
      this.tpinput.nativeElement.value = '';
      this.currentTimeValue = '';
      this.showInvalidTimeError = true;
      response = false;
    }

    this.sanitiseErrors();

    return response;
  }

  // We only want to show one error message at a time
  sanitiseErrors()
  {
    // Show past should trump require as the date is probably a valid date, just outside the 
    // allowed bounds (showRequireDateError can be set to true when the date is too far in the past)
    if(!!this.showRequireTimeError && !!this.showPastTimeError)
    {
      this.showPastTimeError = false;
    }

    if(!!this.showInvalidTimeError && !!this.showPastTimeError)
    {
      this.showInvalidTimeError = false;
    }
  }

  getTimeAtNext15MinuteIncrement(currentTime, alwaysRollForward = true) 
  {
    const remainder = 15 - (currentTime.minute() % 15);

    // Only change the value if we are asked to always roll forward, 
    // or we are not already on a 15 minute period.
    if(!!alwaysRollForward || remainder != 15)
    {
      currentTime = currentTime.add(remainder, "minutes");
    }
    currentTime.seconds(0);
    currentTime.milliseconds(0);
    return currentTime;
  }

  isValid(): boolean 
  {
    return this.dateInputComponent.isValid() && !this.showRequireTimeError && !this.showPastTimeError && !this.showInvalidTimeError;
  }

  getValue() : string {
    let responseMoment = this.convertDateAndTimeToMoment();

    if(responseMoment && responseMoment != null && responseMoment.isValid())
    {
      return responseMoment.format();
    }
    return "";
  }
}
