export class CourtOrderTestData {
    testNextThreeMonthsData: Api.CourtOrder[];
    testSubsequentThreeMonthsData: Api.CourtOrder[];

    constructor(
    ) {
        this.testNextThreeMonthsData = [];
        this.testSubsequentThreeMonthsData = [];
    }

    // These scenarios reflect the permutations from spreadsheet CourtOrderWording.xslx attached 
    // to user story 13716
    // https://dev.azure.com/projectebook/Portal%20Phase%201/_workitems/edit/13716/
    initialiseTestData()
    {
        //this.initialiseTest_0_0_0_0_0_0_0();
        //this.initialiseTest_1_0_1_0_0_1_0();
        //this.initialiseTest_1_0_2_0_0_2_0();
        this.initialiseTest_1_1_1_1_1_2_0();
        //this.initialiseTest_1_1_1_2_2_3_0();
        //this.initialiseTest_2_0_2_0_0_2_0();
        //this.initialiseTest_2_1_2_1_1_3_0();
        //this.initialiseTest_2_2_2_2_2_4_0();
        //this.initialiseTest_0_1_0_1_0_0_1();
        //this.initialiseTest_0_1_0_2_0_0_2();
        //this.initialiseTest_1_1_1_2_1_2_1();
        //this.initialiseTest_1_2_1_2_1_2_1();
        //this.initialiseTest_0_2_0_3_0_0_3();
    }

    // Count of children 0-3 months: 0
    // Count of children 3-6 months: 0
    // Actual orders in 0-3 months: 0
    // Actual orders in 3-6 months: 0
    // Moved from 3-6 to 0-3 months: 0
    // Displayed in 0-3 months: 0
    // Displayed in 3-6 months: 0
    initialiseTest_0_0_0_0_0_0_0() 
    {
        this.testNextThreeMonthsData = [];
        this.testSubsequentThreeMonthsData = [];
    }

    // Count of children 0-3 months: 1
    // Count of children 3-6 months: 0
    // Actual orders in 0-3 months: 1
    // Actual orders in 3-6 months: 0
    // Moved from 3-6 to 0-3 months: 0
    // Displayed in 0-3 months: 1
    // Displayed in 3-6 months: 0
    initialiseTest_1_0_1_0_0_1_0() 
    {
        this.testNextThreeMonthsData = JSON.parse('[{"hasStaff": true,"officerUPN": "lruddick@communities.qld.gov.au","officerAlternateUPN": "","officerName": "","subjectChildId": "9303200N","subjectChildName": "JohnSmith","currentLocationType": "Care Arrangement","orderCourt": "Ayr CC","orderType": "15","orderTypeDisplay": "CPO - PSO","orderStartDtm": "2019-03-05T00:00:00+10:00","orderExpiryDtm": "2020-03-10T00:00:00+10:00"}]');
        this.testSubsequentThreeMonthsData = [];
    }

    // Count of children 0-3 months: 1
    // Count of children 3-6 months: 0
    // Actual orders in 0-3 months: 2
    // Actual orders in 3-6 months: 0
    // Moved from 3-6 to 0-3 months: 0
    // Displayed in 0-3 months: 2
    // Displayed in 3-6 months: 0
    initialiseTest_1_0_2_0_0_2_0() 
    {
        this.testNextThreeMonthsData = JSON.parse('[{"hasStaff": true,"officerUPN": "someone@communities.qld.gov.au","officerAlternateUPN": "","officerName": "","subjectChildId": "9303200N","subjectChildName": "John Smith","currentLocationType": "Care Arrangement","orderCourt": "Ayr CC","orderType": "15","orderTypeDisplay": "CPO - PSO","orderStartDtm": "2019-03-05T00:00:00+10:00","orderExpiryDtm": "2020-03-10T00:00:00+10:00"    },{"hasStaff": true,"officerUPN": "anotherperson@communities.qld.gov.au","officerAlternateUPN": "","officerName": "","subjectChildId": "9303200N","subjectChildName": "John Smith","currentLocationType": "Care Arrangement","orderCourt": "Ayr CC","orderType": "14","orderTypeDisplay": "CPO - DO - Other","orderStartDtm": "2019-03-04T00:00:00+10:00","orderExpiryDtm": "2020-03-09T00:00:00+10:00"    }]        ');
        this.testSubsequentThreeMonthsData = [];
    }

    // Count of children 0-3 months: 1
    // Count of children 3-6 months: 1
    // Actual orders in 0-3 months: 1
    // Actual orders in 3-6 months: 1
    // Moved from 3-6 to 0-3 months: 1
    // Displayed in 0-3 months: 2
    // Displayed in 3-6 months: 0
    initialiseTest_1_1_1_1_1_2_0() 
    {
        this.testNextThreeMonthsData = JSON.parse('[{"hasStaff": true,"officerUPN": "someone@communities.qld.gov.au","officerAlternateUPN": "","officerName": "","subjectChildId": "9303200N","subjectChildName": "John Smith","currentLocationType": "Care Arrangement","orderCourt": "Ayr CC","orderType": "15","orderTypeDisplay": "CPO - Interim Order(s)","orderStartDtm": "2019-03-05T00:00:00+10:00","orderExpiryDtm": "2020-03-10T00:00:00+10:00"}]');
        this.testSubsequentThreeMonthsData = JSON.parse('[{"hasStaff": true,"officerUPN": "anotherperson@communities.qld.gov.au","officerAlternateUPN": "","officerName": "","subjectChildId": "9303200N","subjectChildName": "John Smith","currentLocationType": "Care Arrangement","orderCourt": "Ayr CC","orderType": "14","orderTypeDisplay": "CPO - DO - Other","orderStartDtm": "2020-03-04T00:00:00+10:00","orderExpiryDtm": "2020-06-09T00:00:00+10:00"}]');
    }

    // Count of children 0-3 months: 1
    // Count of children 3-6 months: 1
    // Actual orders in 0-3 months: 1
    // Actual orders in 3-6 months: 2
    // Moved from 3-6 to 0-3 months: 2
    // Displayed in 0-3 months: 3
    // Displayed in 3-6 months: 0
    initialiseTest_1_1_1_2_2_3_0() 
    {
        this.testNextThreeMonthsData = JSON.parse('[{"hasStaff": true,"officerUPN": "someone@communities.qld.gov.au","officerAlternateUPN": "","officerName": "","subjectChildId": "9303200N","subjectChildName": "John Smith","currentLocationType": "Care Arrangement","orderCourt": "Ayr CC","orderType": "15","orderTypeDisplay": "CPO - PSO","orderStartDtm": "2019-03-05T00:00:00+10:00","orderExpiryDtm": "2020-03-10T00:00:00+10:00"}]');
        this.testSubsequentThreeMonthsData = JSON.parse('[{"hasStaff": true,"officerUPN": "anotherperson@communities.qld.gov.au","officerAlternateUPN": "","officerName": "","subjectChildId": "9303200N","subjectChildName": "John Smith","currentLocationType": "Care Arrangement","orderCourt": "Ayr CC","orderType": "14","orderTypeDisplay": "CPO - DO - Other","orderStartDtm": "2020-03-04T00:00:00+10:00","orderExpiryDtm": "2020-06-09T00:00:00+10:00"},{"hasStaff": true,"officerUPN": "anotherperson@communities.qld.gov.au","officerAlternateUPN": "","officerName": "","subjectChildId": "9303200N","subjectChildName": "John Smith","currentLocationType": "Care Arrangement","orderCourt": "Ayr CC","orderType": "12","orderTypeDisplay": "CPO - DO","orderStartDtm": "2020-03-03T00:00:00+10:00","orderExpiryDtm": "2020-06-08T00:00:00+10:00"}]');
    }

    // Count of children 0-3 months: 2
    // Count of children 3-6 months: 0
    // Actual orders in 0-3 months: 2
    // Actual orders in 3-6 months: 0
    // Moved from 3-6 to 0-3 months: 0
    // Displayed in 0-3 months: 2
    // Displayed in 3-6 months: 0
    initialiseTest_2_0_2_0_0_2_0() 
    {
        this.testNextThreeMonthsData = JSON.parse('[{"hasStaff": true,"officerUPN": "someone@communities.qld.gov.au","officerAlternateUPN": "","officerName": "","subjectChildId": "9303200N","subjectChildName": "John Smith","currentLocationType": "Care Arrangement","orderCourt": "Ayr CC","orderType": "15","orderTypeDisplay": "CPO - PSO","orderStartDtm": "2019-03-05T00:00:00+10:00","orderExpiryDtm": "2020-03-10T00:00:00+10:00"}, {"hasStaff": true,"officerUPN": "anotherperson@communities.qld.gov.au","officerAlternateUPN": "","officerName": "","subjectChildId": "9301700N","subjectChildName": "Joanne Clairville","currentLocationType": "Care Arrangement","orderCourt": "Ayr CC","orderType": "14","orderTypeDisplay": "CPO - DO - Other","orderStartDtm": "2019-03-04T00:00:00+10:00","orderExpiryDtm": "2020-03-09T00:00:00+10:00"}]');
        this.testSubsequentThreeMonthsData = [];
    }

    // Count of children 0-3 months: 2
    // Count of children 3-6 months: 1
    // Actual orders in 0-3 months: 2
    // Actual orders in 3-6 months: 1
    // Moved from 3-6 to 0-3 months: 1
    // Displayed in 0-3 months: 3
    // Displayed in 3-6 months: 0
    initialiseTest_2_1_2_1_1_3_0() 
    {
        this.testNextThreeMonthsData = JSON.parse('[{"hasStaff": true,"officerUPN": "someone@communities.qld.gov.au","officerAlternateUPN": "","officerName": "","subjectChildId": "9303200N","subjectChildName": "John Smith","currentLocationType": "Care Arrangement","orderCourt": "Ayr CC","orderType": "15","orderTypeDisplay": "CPO - PSO","orderStartDtm": "2019-03-05T00:00:00+10:00","orderExpiryDtm": "2020-03-10T00:00:00+10:00"}, {"hasStaff": true,"officerUPN": "anotherperson@communities.qld.gov.au","officerAlternateUPN": "","officerName": "","subjectChildId": "9301700N","subjectChildName": "Joanne Clairville","currentLocationType": "Care Arrangement","orderCourt": "Ayr CC","orderType": "14","orderTypeDisplay": "CPO - DO - Other","orderStartDtm": "2019-03-04T00:00:00+10:00","orderExpiryDtm": "2020-03-09T00:00:00+10:00"}]');
        this.testSubsequentThreeMonthsData = JSON.parse('[{"hasStaff": true,"officerUPN": "anotherperson@communities.qld.gov.au","officerAlternateUPN": "","officerName": "","subjectChildId": "9301700N","subjectChildName": "Joanne Clairville","currentLocationType": "Care Arrangement","orderCourt": "Ayr CC","orderType": "12","orderTypeDisplay": "CPO - DO","orderStartDtm": "2020-03-04T00:00:00+10:00","orderExpiryDtm": "2020-06-09T00:00:00+10:00"}]');
    }

    // Count of children 0-3 months: 2
    // Count of children 3-6 months: 2
    // Actual orders in 0-3 months: 2
    // Actual orders in 3-6 months: 2
    // Moved from 3-6 to 0-3 months: 2
    // Displayed in 0-3 months: 4
    // Displayed in 3-6 months: 0
    initialiseTest_2_2_2_2_2_4_0() 
    {
        this.testNextThreeMonthsData = JSON.parse('[{"hasStaff": true,"officerUPN": "someone@communities.qld.gov.au","officerAlternateUPN": "","officerName": "","subjectChildId": "9303200N","subjectChildName": "John Smith","currentLocationType": "Care Arrangement","orderCourt": "Ayr CC","orderType": "15","orderTypeDisplay": "CPO - PSO","orderStartDtm": "2019-03-05T00:00:00+10:00","orderExpiryDtm": "2020-03-10T00:00:00+10:00"}, {"hasStaff": true,"officerUPN": "anotherperson@communities.qld.gov.au","officerAlternateUPN": "","officerName": "","subjectChildId": "9301700N","subjectChildName": "Joanne Clairville","currentLocationType": "Care Arrangement","orderCourt": "Ayr CC","orderType": "14","orderTypeDisplay": "CPO - DO - Other","orderStartDtm": "2019-03-04T00:00:00+10:00","orderExpiryDtm": "2020-03-09T00:00:00+10:00"}]');
        this.testSubsequentThreeMonthsData = JSON.parse('[{"hasStaff": true,"officerUPN": "someone@communities.qld.gov.au","officerAlternateUPN": "","officerName": "","subjectChildId": "9303200N","subjectChildName": "John Smith","currentLocationType": "Care Arrangement","orderCourt": "Ayr CC","orderType": "13","orderTypeDisplay": "CPO - LTG","orderStartDtm": "2020-03-11T00:00:00+10:00","orderExpiryDtm": "2020-06-11T00:00:00+10:00"}, {"hasStaff": true,"officerUPN": "anotherperson@communities.qld.gov.au","officerAlternateUPN": "","officerName": "","subjectChildId": "9301700N","subjectChildName": "Joanne Clairville","currentLocationType": "Care Arrangement","orderCourt": "Ayr CC","orderType": "13","orderTypeDisplay": "CPO - LTG - Other","orderStartDtm": "2020-03-15T00:00:00+10:00","orderExpiryDtm": "2020-06-15T00:00:00+10:00"}]');
    }




    /////////////////////////////////////////////////////////////////////////////////////////
    // TESTING THE 3-6 MONTH TEXT
    /////////////////////////////////////////////////////////////////////////////////////////

    // Count of children 0-3 months: 0
    // Count of children 3-6 months: 1
    // Actual orders in 0-3 months: 0
    // Actual orders in 3-6 months: 1
    // Moved from 3-6 to 0-3 months: 0
    // Displayed in 0-3 months: 0
    // Displayed in 3-6 months: 1
    initialiseTest_0_1_0_1_0_0_1() 
    {
        this.testNextThreeMonthsData = [];
        this.testSubsequentThreeMonthsData = JSON.parse('[{"hasStaff": true,"officerUPN": "someone@communities.qld.gov.au","officerAlternateUPN": "","officerName": "","subjectChildId": "9303200N","subjectChildName": "John Smith","currentLocationType": "Care Arrangement","orderCourt": "Ayr CC","orderType": "13","orderTypeDisplay": "CPO - LTG","orderStartDtm": "2020-03-11T00:00:00+10:00","orderExpiryDtm": "2020-06-11T00:00:00+10:00"}]');
    }

    // initialiseTest_1_1_1_1_1_2_0 (as per 0-3 month tests)
    
    // Count of children 0-3 months: 0
    // Count of children 3-6 months: 1
    // Actual orders in 0-3 months: 0
    // Actual orders in 3-6 months: 2
    // Moved from 3-6 to 0-3 months: 0
    // Displayed in 0-3 months: 0
    // Displayed in 3-6 months: 2
    initialiseTest_0_1_0_2_0_0_2() 
    {
        this.testNextThreeMonthsData = [];
        this.testSubsequentThreeMonthsData = JSON.parse('[{"hasStaff": true,"officerUPN": "someone@communities.qld.gov.au","officerAlternateUPN": "","officerName": "","subjectChildId": "9301700N","subjectChildName": "Joanne Clairville","currentLocationType": "Care Arrangement","orderCourt": "Ayr CC","orderType": "13","orderTypeDisplay": "CPO - LTG","orderStartDtm": "2020-03-11T00:00:00+10:00","orderExpiryDtm": "2020-06-11T00:00:00+10:00"}, {"hasStaff": true,"officerUPN": "anotherperson@communities.qld.gov.au","officerAlternateUPN": "","officerName": "","subjectChildId": "9301700N","subjectChildName": "Joanne Clairville","currentLocationType": "Care Arrangement","orderCourt": "Ayr CC","orderType": "13","orderTypeDisplay": "CPO - LTG - Other","orderStartDtm": "2020-03-15T00:00:00+10:00","orderExpiryDtm": "2020-06-15T00:00:00+10:00"}]');
    }

    // Count of children 0-3 months: 1
    // Count of children 3-6 months: 1
    // Actual orders in 0-3 months: 1
    // Actual orders in 3-6 months: 2
    // Moved from 3-6 to 0-3 months: 1
    // Displayed in 0-3 months: 2
    // Displayed in 3-6 months: 1
    initialiseTest_1_1_1_2_1_2_1() 
    {
        this.testNextThreeMonthsData = JSON.parse('[{"hasStaff": true,"officerUPN": "lruddick@communities.qld.gov.au","officerAlternateUPN": "","officerName": "","subjectChildId": "9303200N","subjectChildName": "JohnSmith","currentLocationType": "Care Arrangement","orderCourt": "Ayr CC","orderType": "15","orderTypeDisplay": "CPO - PSO","orderStartDtm": "2019-03-05T00:00:00+10:00","orderExpiryDtm": "2020-03-10T00:00:00+10:00"}]');
        this.testSubsequentThreeMonthsData = JSON.parse('[{"hasStaff": true,"officerUPN": "someone@communities.qld.gov.au","officerAlternateUPN": "","officerName": "","subjectChildId": "9303200N","subjectChildName": "John Smith","currentLocationType": "Care Arrangement","orderCourt": "Ayr CC","orderType": "13","orderTypeDisplay": "CPO - LTG","orderStartDtm": "2020-03-11T00:00:00+10:00","orderExpiryDtm": "2020-06-11T00:00:00+10:00"}, {"hasStaff": true,"officerUPN": "anotherperson@communities.qld.gov.au","officerAlternateUPN": "","officerName": "","subjectChildId": "9301700N","subjectChildName": "Joanne Clairville","currentLocationType": "Care Arrangement","orderCourt": "Ayr CC","orderType": "13","orderTypeDisplay": "CPO - LTG - Other","orderStartDtm": "2020-03-15T00:00:00+10:00","orderExpiryDtm": "2020-06-15T00:00:00+10:00"}]');
    }

    // initialiseTest_1_1_1_2_2_3_0 (as per 0-3 month tests)

    // Count of children 0-3 months: 1
    // Count of children 3-6 months: 2
    // Actual orders in 0-3 months: 1
    // Actual orders in 3-6 months: 2
    // Moved from 3-6 to 0-3 months: 1
    // Displayed in 0-3 months: 2
    // Displayed in 3-6 months: 1
    initialiseTest_1_2_1_2_1_2_1() 
    {
        this.testNextThreeMonthsData = JSON.parse('[{"hasStaff": true,"officerUPN": "lruddick@communities.qld.gov.au","officerAlternateUPN": "","officerName": "","subjectChildId": "9303200N","subjectChildName": "JohnSmith","currentLocationType": "Care Arrangement","orderCourt": "Ayr CC","orderType": "15","orderTypeDisplay": "CPO - PSO","orderStartDtm": "2019-03-05T00:00:00+10:00","orderExpiryDtm": "2020-03-10T00:00:00+10:00"}]');
        this.testSubsequentThreeMonthsData = JSON.parse('[{"hasStaff": true,"officerUPN": "someone@communities.qld.gov.au","officerAlternateUPN": "","officerName": "","subjectChildId": "9303200N","subjectChildName": "John Smith","currentLocationType": "Care Arrangement","orderCourt": "Ayr CC","orderType": "13","orderTypeDisplay": "CPO - LTG","orderStartDtm": "2020-03-11T00:00:00+10:00","orderExpiryDtm": "2020-06-11T00:00:00+10:00"}, {"hasStaff": true,"officerUPN": "anotherperson@communities.qld.gov.au","officerAlternateUPN": "","officerName": "","subjectChildId": "9301700N","subjectChildName": "Joanne Clairville","currentLocationType": "Care Arrangement","orderCourt": "Ayr CC","orderType": "13","orderTypeDisplay": "CPO - LTG - Other","orderStartDtm": "2020-03-15T00:00:00+10:00","orderExpiryDtm": "2020-06-15T00:00:00+10:00"}]');
    }

    // initialiseTest_2_2_2_2_2_4_0 (as per 0-3 month tests)

    // Count of children 0-3 months: 0
    // Count of children 3-6 months: 2
    // Actual orders in 0-3 months: 0
    // Actual orders in 3-6 months: 3
    // Moved from 3-6 to 0-3 months: 0
    // Displayed in 0-3 months: 0
    // Displayed in 3-6 months: 3
    initialiseTest_0_2_0_3_0_0_3() 
    {
        this.testNextThreeMonthsData = [];
        this.testSubsequentThreeMonthsData = JSON.parse('[{"hasStaff": true,"officerUPN": "someone@communities.qld.gov.au","officerAlternateUPN": "","officerName": "","subjectChildId": "9303200N","subjectChildName": "John Smith","currentLocationType": "Care Arrangement","orderCourt": "Ayr CC","orderType": "13","orderTypeDisplay": "CPO - LTG","orderStartDtm": "2020-03-11T00:00:00+10:00","orderExpiryDtm": "2020-06-11T00:00:00+10:00"}, {"hasStaff": true,"officerUPN": "anotherperson@communities.qld.gov.au","officerAlternateUPN": "","officerName": "","subjectChildId": "9303200N","subjectChildName": "John Smith","currentLocationType": "Care Arrangement","orderCourt": "Ayr CC","orderType": "12","orderTypeDisplay": "CPO - STC","orderStartDtm": "2020-03-15T00:00:00+10:00","orderExpiryDtm": "2020-06-15T00:00:00+10:00"}, {"hasStaff": true,"officerUPN": "anotherperson@communities.qld.gov.au","officerAlternateUPN": "","officerName": "","subjectChildId": "9301700N","subjectChildName": "Joanne Clairville","currentLocationType": "Care Arrangement","orderCourt": "Ayr CC","orderType": "14","orderTypeDisplay": "CPO - STG","orderStartDtm": "2020-03-15T00:00:00+10:00","orderExpiryDtm": "2020-06-15T00:00:00+10:00"}]');
    }
}

