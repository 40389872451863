import * as uriTemplate from 'uri-templates';
import { apiBase } from 'settings';

export const templates = {
  csx: {
    officeStatus: uriTemplate(`${apiBase}/officelockdownstatus/checkifofficeislockeddown`),

    serviceCentreLockdown: uriTemplate(`${apiBase}/ServiceCentre/Lockdown`),
    serviceCentreSafeToReturn: uriTemplate(`${apiBase}/ServiceCentre/SafeToReturn`),

    staffMember: uriTemplate(`${apiBase}/staff/{id}`),
    checkIn: uriTemplate(`${apiBase}/checkinout/checkin/{id}`),
    checkOut: uriTemplate(`${apiBase}/checkinout/checkout/{id}`),
    staffWhereabouts: uriTemplate(`${apiBase}/staff/ServiceCentre/{serviceCentreId}`),
    activityFeed: uriTemplate(`${apiBase}/activityFeed`),

    visitCounts: uriTemplate(`${apiBase}/UpcomingChildHomeVisits/visitcounts?fromDateTimeUtc={fromDateTimeUtc}&toDateTimeUtc={toDateTimeUtc}&page={page}&pageSize={pageSize}`),
    upcomingChildHomeVisits: uriTemplate(`${apiBase}/UpcomingChildHomeVisits/visits?fromDateTimeUtc={fromDateTimeUtc}&toDateTimeUtc={toDateTimeUtc}&page={page}&pageSize={pageSize}`),
    upcomingChildHomeVisitDetail: uriTemplate(`${apiBase}/UpcomingChildHomeVisits/detail/{personId}`),
    overdueChildHomeVisits: uriTemplate(`${apiBase}/OverdueChildHomeVisits/visits?page={page}&pageSize={pageSize}`),

    caseSummaries: uriTemplate(`${apiBase}/CaseSummary/CaseSummaries?page={page}&pageSize={pageSize}`),
    ongoingInterventionDetails: uriTemplate(`${apiBase}/CaseSummary/OngoingIntervention/Detail?personId={personId}`),

    featureLogger: uriTemplate(`${apiBase}/FeatureLogger`),
    personaSwitcher: uriTemplate(`${apiBase}/personaSwitcher`),

    currentCourtOrders: uriTemplate(`${apiBase}/CourtOrder/orders?fromDateTimeUtc={fromDateTimeUtc}&toDateTimeUtc={toDateTimeUtc}`),
  }
};

export const csx = {
  officeStatus: (params: { }) => templates.csx.officeStatus.fillFromObject(params), 

  serviceCentreLockdown: (params: { }) => templates.csx.serviceCentreLockdown.fillFromObject(params), 
  serviceCentreSafeToReturn: (params: { }) => templates.csx.serviceCentreSafeToReturn.fillFromObject(params), 

  staffMember: (params: { id: string }) => templates.csx.staffMember.fillFromObject(params),
  checkIn: (params: { id: string }) => templates.csx.checkIn.fillFromObject(params),
  checkOut: (params: { id: string }) => templates.csx.checkOut.fillFromObject(params),
  staffWhereabouts: (params: { serviceCentreId: string }) => templates.csx.staffWhereabouts.fillFromObject(params),
  activityFeed: (params: {}) => templates.csx.activityFeed.fillFromObject(params),

  visitCounts: (params: { fromDateTimeUtc: string, toDateTimeUtc: string, page: string, pageSize: string }) => templates.csx.visitCounts.fillFromObject(params),
  upcomingChildHomeVisits: (params: { fromDateTimeUtc: string, toDateTimeUtc: string, page: string, pageSize: string }) => templates.csx.upcomingChildHomeVisits.fillFromObject(params),
  upcomingChildHomeVisitDetail: (params: { personId: string }) => templates.csx.upcomingChildHomeVisitDetail.fillFromObject(params),

  overdueChildHomeVisits: (params: { page: string, pageSize: string }) => templates.csx.overdueChildHomeVisits.fillFromObject(params),

  caseSummaries: (params: { page: string, pageSize: string }) => templates.csx.caseSummaries.fillFromObject(params),
  ongoingInterventionDetails: (params: { personId: string }) => templates.csx.ongoingInterventionDetails.fillFromObject(params),

  featureLogger: (params: {}) => templates.csx.featureLogger.fillFromObject(params),
  personaSwitcher: (params: {}) => templates.csx.personaSwitcher.fillFromObject(params),

  currentCourtOrders: (params: { fromDateTimeUtc: string, toDateTimeUtc: string }) => templates.csx.currentCourtOrders.fillFromObject(params),
};
