<form role="form" #filterForm="ngForm" (ngSubmit)="apply(filterForm.form)" *ngIf="visible" novalidate>
    <div id="large-view" *ngIf="largeView">
      <table>
        <tr>
          <td class='left-column'>
            <fieldset class='filter-option'>
              <legend>Select Children</legend>
              <hr />
              <div>
                <select name="SelectedChildren" [ngModel]="formModel.nameSelect.selectedOptions" (ngModelChange)="childNameSelectionChanged($event)" multiple class="filter-select">
                  <option *ngFor="let option of formModel.nameSelect.checkboxOptions" [ngValue]="option.displayName">{{option.displayName}}</option>
                </select>
                <a [routerLink]="[]" [ngClass]="{'disable-link': allChildrenAreSelected}" (click)="clearChildrenNamesFilter()" [attr.aria-label]="selectAllTooltip" [title]="selectAllTooltip">Select All</a>
              </div>
            </fieldset>
          </td>
          <td class='right-column'>
            <fieldset class='filter-option' *ngIf="!priorityColoursAreGloballyDisabled">
              <legend>Priority Level</legend>
              <hr />
              <div *ngIf="!!displayPriorityColours">
                <ng-container *ngFor="let option of formModel.priorityLevels.getOrderedOptions()">
                  <label>
                    <input name="{{ option.displayName }}" type="checkbox" value="{{ option.value }}" [(ngModel)]="option.selected"
                      (change)="selectionChanged(formModel.priorityLevels, option, $event)" tabindex="0" />{{ option.displayName }}</label>
                </ng-container>
              </div>
              <div *ngIf="!displayPriorityColours">
                <h3>You have disabled this feature</h3>
              </div>
            </fieldset>
            <fieldset class='filter-option' *ngIf="!!priorityColoursAreGloballyDisabled">
              <legend>Visit Frequency</legend>
              <hr />
              <div>
                <ng-container *ngFor="let option of formModel.visitFrequencySelect.getOrderedOptions()">
                  <label>
                    <input name="{{ option.displayName }}" type="checkbox" value="{{ option.value }}" [(ngModel)]="option.selected"
                      (change)="selectionChanged(formModel.visitFrequencySelect, option, $event)" tabindex="0" />{{ option.displayName }}</label>
                </ng-container>
              </div>
            </fieldset>
          </td>
        </tr>
        <tr>
          <td class='left-column'>
            <fieldset class='filter-option'>
              <legend>Child's Name</legend>
              <hr />
              <div>
                <div class="mydrp selectiongroup">
                    <input class="text-input" type="text" name="childNameSearch" [(ngModel)]="formModel.nameSearch.childNameSearchValue" (ngModelChange)="childNameSearchChanged($event)" tabindex="0">
                </div>
                <a [routerLink]="[]" [ngClass]="{'disable-link': noChildName}" (click)="clearChildNameFilter()" [attr.aria-label]="clearChildNameTooltip" [title]="clearChildNameTooltip">Clear Filter</a>
              </div>
            </fieldset>
          </td>
          <td class='right-column'>
            <fieldset class='filter-option'>
              <legend>Intervention type</legend>
              <hr />
              <div>
                <ng-container *ngFor="let option of formModel.interventionSelect.getOrderedOptions()">
                  <label>
                    <input name="{{ option.displayName }}" type="checkbox" value="{{ option.value }}" [(ngModel)]="option.selected"
                      (change)="selectionChanged(formModel.interventionSelect, option, $event)" tabindex="0" />{{ option.displayName }}</label>
                </ng-container>
              </div>
            </fieldset>
          </td>
        </tr>
        <tr>
          <td class='left-column'>
            <!-- When Priority Colours is not shown, we will display this Frequency filter in its place higher up the page. -->
            <fieldset class='filter-option' *ngIf="!priorityColoursAreGloballyDisabled">
              <legend>Visit Frequency</legend>
              <hr />
              <div>
                <ng-container *ngFor="let option of formModel.visitFrequencySelect.getOrderedOptions()">
                  <label>
                    <input name="{{ option.displayName }}" type="checkbox" value="{{ option.value }}" [(ngModel)]="option.selected"
                      (change)="selectionChanged(formModel.visitFrequencySelect, option, $event)" tabindex="0" />{{ option.displayName }}</label>
                </ng-container>
              </div>
            </fieldset>
          </td>
          <td class='right-column'>

          </td>
        </tr>
      </table>
    </div>
    <div id="mobile-view" *ngIf="!largeView">
      <p class="clearfix">
          <button class="toggle-details right" (click)="toggleFilters(); $event.preventDefault();"
            [title]="showBaseFilters ? 'View Priority and Intervention Filters' : 'View Child Name Selection'"
            [attr.aria-label]="showBaseFilters ? 'View Priority and Intervention Filters' : 'View Child Name Selection'">
            <span *ngIf="showBaseFilters"><i class="fas fa-angle-right"></i> View Child Name Selection</span>
            <span *ngIf="!showBaseFilters">View Priority and Intervention Filters <i class="fas fa-angle-left"></i></span>
          </button>
        </p>
      
        <fieldset [ngClass]="{'filter-option': true, 'hide': !showBaseFilters }" *ngIf="!priorityColoursAreGloballyDisabled">
          <legend>Priority Level</legend>
          <hr />
          <div *ngIf="!!displayPriorityColours">
            <ng-container *ngFor="let option of formModel.priorityLevels.getOrderedOptions()">
              <label>
                <input name="{{ option.displayName }}" type="checkbox" value="{{ option.value }}" [(ngModel)]="option.selected"
                  (change)="selectionChanged(formModel.priorityLevels, option, $event)" tabindex="0" />{{ option.displayName }}</label>
            </ng-container>
          </div>
          <div *ngIf="!displayPriorityColours">
            <h3>You have disabled this feature</h3>
          </div>
        </fieldset>
      
        <fieldset [ngClass]="{ 'filter-option': true, 'hide': showBaseFilters }">
          <legend>Select Children</legend>
          <hr />
          <div>
            <select name="SelectedChildren" [ngModel]="formModel.nameSelect.selectedOptions" (ngModelChange)="childNameSelectionChanged($event)" multiple class="filter-select">
              <option *ngFor="let option of formModel.nameSelect.checkboxOptions" [ngValue]="option.displayName">{{option.displayName}}</option>
            </select>
            <a [routerLink]="[]" [ngClass]="{'disable-link': allChildrenAreSelected}" (click)="clearChildrenNamesFilter()" [attr.aria-label]="selectAllTooltip" [title]="selectAllTooltip">Select All</a>
          </div>
        </fieldset>
      
        <fieldset [ngClass]="{ 'filter-option': true, 'hide': showBaseFilters }">
          <legend>Child's Name</legend>
          <hr />
          <div>
            <div class="mydrp selectiongroup">
                <input class="text-input" type="text" name="childNameSearch" [(ngModel)]="formModel.nameSearch.childNameSearchValue" tabindex="0">
            </div>
            <a [routerLink]="[]" [ngClass]="{'disable-link': noChildName}" (click)="clearChildNameFilter()" [attr.aria-label]="clearChildNameTooltip" [title]="clearChildNameTooltip">Clear Filter</a>
          </div>
        </fieldset>
      
        <fieldset [ngClass]="{'filter-option': true, 'hide': !showBaseFilters }">
          <legend>Intervention type</legend>
          <hr />
          <div>
            <ng-container *ngFor="let option of formModel.interventionSelect.getOrderedOptions()">
              <label>
                <input name="{{ option.displayName }}" type="checkbox" value="{{ option.value }}" [(ngModel)]="option.selected"
                  (change)="selectionChanged(formModel.interventionSelect, option, $event)" tabindex="0" />{{ option.displayName }}</label>
            </ng-container>
          </div>
        </fieldset>
      
        <fieldset [ngClass]="{'filter-option': true, 'hide': showBaseFilters }">
          <legend>Visit Frequency</legend>
          <hr />
          <div>
            <ng-container *ngFor="let option of formModel.visitFrequencySelect.getOrderedOptions()">
              <label>
                <input name="{{ option.displayName }}" type="checkbox" value="{{ option.value }}" [(ngModel)]="option.selected"
                  (change)="selectionChanged(formModel.visitFrequencySelect, option, $event)" tabindex="0" />{{ option.displayName }}</label>
            </ng-container>
          </div>
        </fieldset>
    </div>
  
  
  
  
    <footer>
      <button (click)="clear(filterForm)" type="button" tabindex="0">Clear Filters</button>
      <button (click)="cancel()" type="button" tabindex="0">Cancel</button>
      <button type="submit" [disabled]="!filterForm.valid" tabindex="0">Apply</button>
    </footer>
  </form>