import { Component, Input, EventEmitter, Output } from '@angular/core';
import { switchApplicationSettings, ApplicationUrls, kicboxMyProfileSettings, KicboxMyProfileSettings } from '../../../../settings';
import { Subscription } from 'rxjs';
import { PersonaSwitcherService } from 'services/api/persona-switcher.service';
import { ShowPriorityNotifyService } from 'services/notifications/show-priority-notify.service';
import { LocalStorageService } from 'services/local-storage.service';
import { PriorityIconService } from 'services/priority-icon.service';
import { paginationTestSettings } from 'settings';

@Component({
  selector: 'app-main-menu',
  templateUrl: './main-menu.component.html',
  styleUrls: ['./main-menu.component.scss']
})
export class MainMenuComponent {
  applicationUrls: ApplicationUrls;
  kicboxMyProfile: KicboxMyProfileSettings;
  @Input() fullName: string;
  @Input() officeIsLockedDown: boolean;
  @Input() showLockdownToggle: boolean;
  @Output() isTLOrManager: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() lockdownHasBeenToggled: EventEmitter<boolean> = new EventEmitter<boolean>();
  personaChangedSubscription: Subscription;
  teams: Api.PersonaTeam[];
  selectedTeamLeaderId: string;
  selectedTeam: Api.ChildSafetyOfficerTeamMemberDto[];
  hasTeamError: boolean;
  hasTeamDataLoaded: boolean;
  currentPersona: Api.ChildSafetyOfficerTeamMemberDto;

  priorityIsGloballyDisabled: boolean = false;
  priorityIsEnabled: boolean = false;

  constructor(private personaSwitcherService: PersonaSwitcherService, 
    private showPriorityNotifyService: ShowPriorityNotifyService, 
    private localStorageService: LocalStorageService, 
    private priorityIconService: PriorityIconService) {
    this.applicationUrls = switchApplicationSettings;
    this.kicboxMyProfile = kicboxMyProfileSettings;
    this.selectedTeamLeaderId = '';
    this.subscribeToPersonaChangedNotifications();
  }

  async ngOnInit() {
    await this.getTeamFromServer();

    this.priorityIsGloballyDisabled = this.priorityIconService.priorityIsGloballyDisabled();
    this.priorityIsEnabled = this.priorityIconService.priorityIconFeatureIsEnabled();
  }

  ngOnDestroy() {
    if (this.personaChangedSubscription) {
      this.personaChangedSubscription.unsubscribe();
    }
  }

  get getOfficeLockdownText(): string {
    return this.officeIsLockedDown ? 
      'Yes. It\'s safe to return'
      :
      'Activate lock down';
  }

  get getDisablePriorityText(): string {
    return this.priorityIsEnabled ? 
      'Disable Priority Icons'
      :
      'Enable Priority Icons';
  }

  get isPersonaSwitchingEnabled(): boolean {
    return this.personaSwitcherService.isEnable();
  }

  public onSubMenuItemFocus(evt: FocusEvent) {
    (<Element>evt.target).parentElement.classList.add('submenu-item-hover');

    let ulParent = this.closestUlParent(<Element>evt.target);
    if (ulParent) {
      ulParent.classList.add('hover-root-menu');
    }
  }

  public onSubMenuItemBlur(evt: FocusEvent) {
    (<Element>evt.target).classList.remove('submenu-item-hover');

    let ulParent = this.closestUlParent(<Element>evt.target);
    if (ulParent) {
      ulParent.classList.remove('hover-root-menu');
    }
  }

  public onSubMenuItemClick(evt: MouseEvent) {
    if (evt instanceof MouseEvent) {
      (<Element>evt.target).classList.remove('submenu-item-hover');

      let ulParent = this.closestUlParent(<Element>evt.target);
      if (ulParent) {
        ulParent.classList.remove('hover-root-menu');
      }
    }
  }

  private closestUlParent(element: Element) {
    let p = element.parentElement;

    while (p && p.tagName && p.tagName.toLocaleUpperCase() !== 'UL') {
      p = p.parentElement;
    }

    return p;
  }

  private subscribeToPersonaChangedNotifications() {
    this.personaSwitcherService.enable();
    if (!this.personaChangedSubscription) {
      this.personaChangedSubscription = this.personaSwitcherService.currentPersonaUpnChanged.subscribe(async (upn) => {
        if (!upn || upn.trim().length === 0) {
          this.currentPersona = null;
        }
      });
    }
  }

  private getTeamFromServer(): Promise<void> {
    try {
      return this.personaSwitcherService.getTeam().then(result => {
        this.hasTeamError = false;
        if (!result || result === null) {
          this.hasTeamError = true;
        } else {
          this.teams = result;

          let isTLOrMngr = (this.teams && this.teams != null);
          if(isTLOrMngr === null) isTLOrMngr = false;
          // console.log('this.teams: ' + JSON.stringify(this.teams) + '. Is a TL or Manager?: ' + isTLOrMngr);

          this.isTLOrManager.emit(isTLOrMngr); // && this.teams.length > 0);
        }
        this.hasTeamDataLoaded = true;
      });
    } catch (error) {
      console.error(error);
      this.hasTeamError = true;
    }
  }

  toggleLockdown()
  {
    this.lockdownHasBeenToggled.emit(true);
  }

  togglePriority()
  {
    this.priorityIsEnabled = this.priorityIconService.togglePriority();
  }

  setSelectedTeamLeader(teamLeader: Api.PersonaTeam)
  {
    if(!!teamLeader && !!teamLeader.team)
    {
      const newTeamLeaderId = (!!teamLeader.teamLeader && !!teamLeader.teamLeader.kicBoxCsoId ? teamLeader.teamLeader.kicBoxCsoId : 'unallocated');
      if(newTeamLeaderId == this.selectedTeamLeaderId)
      {
        // Deselect
        this.selectedTeamLeaderId = '';
        this.selectedTeam = null;
      }
      else
      {
        this.selectedTeamLeaderId = newTeamLeaderId
        this.selectedTeam = teamLeader.team;
      }

      // A manager should be able to invoke Persona Switcher on a Team Leader to view their allocated children
      if(newTeamLeaderId != 'unallocated')
      {
        this.setPersona(teamLeader.teamLeader);
      }
    }
  }

  removePersona() {
    this.personaSwitcherService.clearPersonaUpn();
    this.currentPersona = null;
  }

  setPersona(item: Api.ChildSafetyOfficerTeamMemberDto) {
    this.personaSwitcherService.setPersonaUpn(item.username);
    let foundTeamMember: Api.ChildSafetyOfficerTeamMemberDto;

    for (let t = 0; t < this.teams.length; t++) {
      if(!!this.teams[t].teamLeader && this.teams[t].teamLeader.username == item.username) {
        foundTeamMember = this.teams[t].teamLeader;
        break;
      }

      let teamMembers = this.teams[t].team;

      for (let m = 0; m < teamMembers.length; m++) {
        let teamMember = teamMembers[m];

        if (teamMember.username === item.username) {
          foundTeamMember = teamMember;
          break;
        }
      }
    }

    if (foundTeamMember) {
      this.currentPersona = foundTeamMember;
    }
    else {
      this.personaSwitcherService.clearPersonaUpn();
      this.currentPersona = null;
    }
  }

  trackTeamBy(index: number, item: Api.PersonaTeam) {
    return item.teamLeader ? item.teamLeader.username : '';
  }

  trackTeamMemberBy(index: number, item: Api.ChildSafetyOfficerTeamMemberDto) {
    return item.username;
  }
}
