import { Component } from '@angular/core';

@Component({
  selector: 'app-site-configuration',
  templateUrl: './site-configuration.component.html',
  styleUrls: ['./site-configuration.component.scss']
})
export class SiteConfigurationComponent {
  settings: string = JSON.stringify(window['appConfig'], null, 2);
}
