import { Injectable } from '@angular/core';
import { csx } from 'services/api/urls';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class StaffMemberService {
  constructor(private http: HttpClient) { }

  async get(id: string) {
    let result = await this.http
      .get<Api.StaffWithLocation>(csx.staffMember({ id: id }))
      .asJsonResponse<Api.StaffWithLocation>()
      .toPromise();

    return result;
  }

  async checkIn(id: string)  {
    return await this.http
      .post<void>(csx.checkIn({ id: id }), null)
      .asJsonResponse<void>()
      .toPromise();
  }

  async checkOut(id: string, location: string, locationDetails: string, expectedReturn: string) {
    return this.http
      .post<void>(csx.checkOut({ id: id }), <Api.CheckOutParameters>{
          location: location,
          locationDetails: locationDetails,
          expectedReturn: expectedReturn
        })
      .asJsonResponse<void>()
      .toPromise();
  }
}
