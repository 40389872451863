import { Injectable } from '@angular/core';
import { csx } from 'services/api/urls';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class StaffWhereaboutsService {
  constructor(private http: HttpClient) { }

  async get(serviceCentreId: string) {
    return this.http
      .get<Api.StaffWhereabouts>(csx.staffWhereabouts({ serviceCentreId: serviceCentreId }))
      .asJsonResponse<Api.StaffWhereabouts>()
      .toPromise();
  }
}
