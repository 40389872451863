import {interval as observableInterval,  Observable ,  Subscription } from 'rxjs';
import { Component, OnInit, OnDestroy, AfterViewInit, HostListener, ViewChild, EventEmitter } from '@angular/core';
import { StaffWhereaboutsService, StaffMemberService, RecordChangeDetectionService, FeatureLoggerService, PersonaSwitcherService } from 'services';
import * as moment from 'moment';
import { MsalService } from '../../services/msal.service';
import { Title } from '@angular/platform-browser';
import { staffWhereaboutsSettings } from '../../settings';
import { WorkTracker } from 'app/shared/loading-pane';
import { FocusCheck } from '../shared/FocusCheck';
import { FeatureName } from "api/website_enum";
import { CheckOutComponent } from './check-out/check-out.component';
import { ToastrService } from 'ngx-toastr';
import { ModalService } from '../../services/modal.service';
import { OfficeLockdownNotifyService } from '../../services/notifications/office-lockdown-notify.service';

@Component({
  selector: 'app-staff-whereabouts',
  templateUrl: './staff-whereabouts.component.html',
  styleUrls: ['./staff-whereabouts.component.scss']
})
export class StaffWhereaboutsComponent extends FocusCheck implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild(CheckOutComponent, {static: false})
  private checkoutComponent: CheckOutComponent;

  tracker: WorkTracker;
  serviceCentreName: string;
  staffMembers: Api.StaffLocation[];

  pagedStaffMembers: Api.StaffLocation[];

  recordChangeDetectionService: RecordChangeDetectionService;

  selectedStaffMember: Api.StaffLocation;
  hasError: boolean;
  dataLoaded: boolean;
  staffCheckedOut: number;
  staffOverdueForCheckIn: number;
  isDestroyed: boolean = false;
  refreshIntervalInMilliseconds: number;

  selectedOfficerId: string;

  checkoutAttempted: boolean = false;
  checkoutSucceeded: boolean = false;

  checkinAttempted: boolean = false;
  checkinSucceeded: boolean = false;

  intervalSubscription: Subscription;
  //private checkoutSubscription: Subscription;
  officeLockdownNotifySubscription: Subscription;
  thisOfficersServiceCentre: Api.ServiceCentreViewModel;

  // Pagination settings
  currentPage: number = 0;
  pageSize: number = 10; // This is communicated from the pagination component
  staffWhereaboutsCount: number = 0;
  staffWhereaboutsStyle: string = 'staff-wherabouts';

  editing: boolean = false;

  constructor(
    private titleService: Title,
    private staffWhereaboutsService: StaffWhereaboutsService,
    private staffMemberService: StaffMemberService,
    private adalService: MsalService,
    private featureLogger: FeatureLoggerService,
    private personaSwitcherService: PersonaSwitcherService,
    private toastr: ToastrService,
    private modalService: ModalService,
    private officeLockdownNotifyService: OfficeLockdownNotifyService
  ) {
    super();
    this.refreshIntervalInMilliseconds = staffWhereaboutsSettings.refreshIntervalInSeconds * 1000;
    this.tracker = new WorkTracker(true);
    this.titleService.setTitle('CS Portal - Staff Whereabouts');
    this.selectedStaffMember = null;

    this.officeLockdownNotifySubscription = this.officeLockdownNotifyService.serviceCentreChanged.subscribe((serviceCentre) => {
      if (this.thisOfficersServiceCentre != serviceCentre) {
        this.thisOfficersServiceCentre = serviceCentre;
      }
    });

    this.recordChangeDetectionService = new RecordChangeDetectionService();
    this.recordChangeDetectionService.initialise(
      ['location', 'locationDetails', 'expectedReturn', 'lockDownAlertAckTime'],
      ['id'], 'staff-whereabouts'
    );
  }

  async ngOnInit(): Promise<void> {
    this.personaSwitcherService.disable();
    this.featureLogger.logFeatureUsage(FeatureName.StaffWhereabouts);

    this.checkoutComponent = new CheckOutComponent(this.modalService);
    //this.checkoutSubscription = this.checkoutComponent.checkOutClosed.subscribe(this.handleCheckout.bind(this));

    await this.tracker.track(
      this.getStaffWhereabouts().then(() => {
        this.updatePagedWheraboutsToShow();

        if (!this.intervalSubscription && !this.isDestroyed) {
          this.intervalSubscription = observableInterval(this.refreshIntervalInMilliseconds).subscribe(
            async () => {
              await this.getStaffWhereabouts();
              this.updatePagedWheraboutsToShow();
            }
          );
        }
      })
    );
  }

  async ngAfterViewInit(): Promise<void> {
    this.thisOfficersServiceCentre = this.officeLockdownNotifyService.getServiceCentre();
  }

  ngOnDestroy() {
    // Make sure the periodic api call is stopped.
    if (this.intervalSubscription)
    {
      this.intervalSubscription.unsubscribe();
    }
    //if (this.checkoutSubscription)
    //{
    //  this.checkoutSubscription.unsubscribe();
    //}
    if (this.officeLockdownNotifySubscription) {
      this.officeLockdownNotifySubscription.unsubscribe();
    }
    this.isDestroyed = true;
  }

  onPageChangedEvent(pageNumber: number)
  {
    this.currentPage = pageNumber;
    this.updatePagedWheraboutsToShow();
  }

  onPageSizeChangedEvent(pageSize: number)
  {
    this.pageSize = pageSize;
    this.updatePagedWheraboutsToShow();
  }

  updatePagedWheraboutsToShow()
  {
    var firstElToShow = this.pageSize * this.currentPage;
    var lastElToShow = firstElToShow + this.pageSize;

    setTimeout(() => {
      // Update the values that are displayed. All data is already pre-loaded. Just paginate in-memory
      if(!this.staffMembers || this.staffMembers.length < 1)
      {
        this.pagedStaffMembers = [];
      }
      else
      {
        this.pagedStaffMembers = this.staffMembers.slice(firstElToShow, lastElToShow);
      }
    });
  }

  showCheckInSuccess() {
    this.toastr.success('You\'re checked back in, welcome back!', 'Check In', {progressBar: true, disableTimeOut: false, timeOut: 10000});
  }

  showCheckInFailure()
  {
    this.toastr.error('Something went wrong, please try again.', 'Check In', {progressBar: false, disableTimeOut: true});
  }

  showCheckOutSuccess() {
    if(this.editing === true)
    {
      this.toastr.success('Update done, see you soon!', 'Check Out', {progressBar: true, disableTimeOut: false, timeOut: 10000});
    }
    else
    {
      this.toastr.success('Check out done, see you soon!', 'Check Out', {progressBar: true, disableTimeOut: false, timeOut: 10000});
    }
  }

  showCheckOutFailure() {
    this.toastr.error('Something went wrong, please try again.', 'Check Out', {progressBar: false, disableTimeOut: true});
  }

  edit(staffMember: Api.StaffLocation) {
    if(staffMember.location === null) return;

    this.selectedOfficerId = staffMember.id;

    this.editing = true;
    this.checkoutComponent.offerCheckoutForm(staffMember, true);
  }

  private async getStaffWhereabouts(): Promise<void> {
    if (this.isDestroyed) {
      this.ngOnDestroy();
      return Promise.resolve();
    }

    if (!this.shouldRefreshData()) {
      return Promise.resolve();
    }

    try {
      let results = await this.staffMemberService.get(this.adalService.userInfo.localAccountId);
      if(!results.hasError)
      {
        let staffMember = results.value;
        this.serviceCentreName = staffMember.serviceCentreName;

        return this.staffWhereaboutsService.get(staffMember.serviceCentreId.toString()).then(result => {
          this.hasError = result.hasError;
          if(!!result && !!result.value && !this.hasError)
          {
            this.staffMembers = result.value.staffMembers;
            this.staffCheckedOut = result.value.staffMembers.filter(o => !this.isCheckedIn(o)).length;
            this.staffOverdueForCheckIn = result.value.staffMembers.filter(o => this.isDueForCheckin(o)).length;
            this.dataLoaded = true;
          }
          else
          {
            this.staffMembers = [];
          }
          this.staffWhereaboutsCount = this.staffMembers.length;
          this.recordChangeDetectionService.applyNewDataSet(this.staffMembers);
          this.updatePagedWheraboutsToShow();
        });
      }
      else
      {
        this.hasError = true;
      }

    } catch (error) {
      console.error(error);
      this.hasError = true;
    }
  }

  showUpdateGraphic(staffLocation: Api.StaffLocation): boolean {
    return this.recordChangeDetectionService.showUpdateGraphic(staffLocation);
  }

  hasAcknowledgedSiteLockdown(staffMember: Api.StaffLocation) {
    // Do not show if not currently locked down
    if(!this.thisOfficersServiceCentre || !this.thisOfficersServiceCentre.isCurrentlyLockedDown) {
      return false;
    }

    const hasAcknowledged = !!staffMember.lockDownAlertAckTime;

    // This staff member has not acknowledged a lock down
    if(!hasAcknowledged) {
      return false;
    }

    // Do not show lockdown acknowledged when 'now' is after the end of the day they acknowledged the message
    // That is, the last time they acknowledged was yesterday or even earlier
    var momentLockdownWasAcknowledged = moment(staffMember.lockDownAlertAckTime);
    var endOfSameDay = moment(staffMember.lockDownAlertAckTime).endOf('day');
    var now = moment();
    if (now.isAfter(endOfSameDay))
    {
      return false;
    }

    // Do not show if the time of the lock down is more recent than the time the person acknowledged a lock down
    // This ensures we do not show an 'acknowledgement' that was actually about an earlier lock down that
    // has since been cleared and now a newer lockdown is being shown. This must be acknowledged
    var lockDownDtm = moment(this.thisOfficersServiceCentre.lockedDownDtm);
    if (lockDownDtm.isAfter(momentLockdownWasAcknowledged))
    {
      return false;
    }

    return hasAcknowledged;
  }

  isDueForCheckin(staffMember: Api.StaffLocation) {
    if (!staffMember.location) {
      return;
    }
    const expectedReturn = moment(staffMember.expectedReturn).local();
    const halfAnHourAfterExpectedReturn = expectedReturn.add(30, 'minutes');
    const now = moment().local();
    const isDueForCheckin = now.isAfter(halfAnHourAfterExpectedReturn);
    return isDueForCheckin;
  }

  isCheckedIn(staffMember: Api.StaffLocation) {
    return !staffMember.location;
  }

  trackBy(index: number, item: Api.StaffLocation) {
    return `${item.firstName} ${item.familyName} ${item.phoneNumber}`;
  }

  formatDate(date: string, incrementToNext15MinuteBlock: boolean = false) {
    if(!date || date.length < 1) return '';

    var result = moment(date);
    if(!!incrementToNext15MinuteBlock)
    {
      // Guarantee the value is rounded to the next 15 minute interval. This is required at the moment
      // as CS Xpress also writes these records but currently can write any time, not rounded to 15 minute intervals.
      var givenTimeAsMoment = moment(date, "YYYY-MM-DDThh:mm:ssZZ");
      result = this.getTimeAtNext15MinuteIncrement(givenTimeAsMoment);
    }

    return !!result
      ? moment(result)
        .local()
        .format('h:mm A, D MMM YY')
      : '';
  }

  getTimeAtNext15MinuteIncrement(currentTime)
  {
    const remainder = 15 - (currentTime.minute() % 15);
    // Only change the value if we are not already on a 15 minute period.
    if(remainder != 15)
    {
      currentTime = currentTime.add(remainder, "minutes");
    }
    currentTime.seconds(0);
    currentTime.milliseconds(0);
    return currentTime;
  }

  isEven(item: Api.StaffLocation): boolean {
    if (!this.staffMembers) return false;
    return this.staffMembers.indexOf(item, 0) % 2 === 0;
  }

  private shouldRefreshData() {
    if (this.isDestroyed) return false;

    return !this.dataLoaded || (this.windowGotFocus && !document.hidden);
  }

  async toggleCheckedOutStatus(staffMember: Api.StaffLocation)
  {
    this.selectedOfficerId = staffMember.id;

    if(this.isCheckedIn(staffMember))
    {
      // When the officer checks out the method 'handleCheckout' will be invoked via our subscription
      this.editing = false;
      this.checkoutComponent.offerCheckoutForm(staffMember, false);
    }
    else
    {
      await this.tracker.track(
        this.checkIn().then(async () => {
          // Refresh our view
          await this.tracker.track(
            this.getStaffWhereabouts()
          );
        })
      );
    }
  }

  async checkOutClosed(checkoutValues: Api.CheckOutParameters)
  {
    await this.tracker.track(
      this.checkOut(checkoutValues).then(async () => {
        // Refresh our view
        await this.tracker.track(
          this.getStaffWhereabouts()
        );
      })
    );
  }

  private async checkOut(checkoutValues: Api.CheckOutParameters) {
    if (this.isDestroyed) {
      this.ngOnDestroy();
      return Promise.resolve();
    }

    try {
      this.checkoutAttempted = true;
      return this.staffMemberService.checkOut(this.selectedOfficerId, checkoutValues.location, checkoutValues.locationDetails, checkoutValues.expectedReturn).then(result => {
        if (result.hasError) {
          this.showCheckOutFailure();
        } else {
          this.showCheckOutSuccess();
        }
      });

    } catch (error) {
      console.error('checkOut error: ' + error);
      this.showCheckOutFailure();
    }
  }

  private async checkIn() {
    if (this.isDestroyed) {
      this.ngOnDestroy();
      return Promise.resolve();
    }

    try {
      this.checkinAttempted = true;
      return this.staffMemberService.checkIn(this.selectedOfficerId).then(result => {
        if (result.hasError) {
          this.showCheckInFailure();
        } else {
          this.showCheckInSuccess();
        }
      });

    } catch (error) {
      console.error('checkIn error: ' + error);
      this.showCheckInFailure();
    }
  }

  @HostListener('window:beforeunload ', ['$event'])
  beforeUnload(event: any): void {
    this.ngOnDestroy();
  }
}

