import { Component, Input, OnChanges, SimpleChanges, ChangeDetectionStrategy, ElementRef, ViewChild, AfterViewChecked } from '@angular/core';
import * as moment from 'moment';
import { TaskSubsystemService, RecordChangeDetectionService } from '../../../../services';
import { WorkTracker } from 'app/shared/loading-pane';

interface AlertCount {
  description: string;
  count: number;
}

@Component({
  selector: 'app-home-visit-detail',
  templateUrl: './home-visit-detail.component.html',
  styleUrls: ['./home-visit-detail.component.scss']
})
export class HomeVisitDetailComponent implements OnChanges, AfterViewChecked {
  @Input() shown: boolean;
  @Input() homeVisit: Api.ChildHomeVisit;
  @Input() isExpanded: boolean;
  homeVisitDetail: Api.HomeVisitPersonDetail;
  tracker: WorkTracker;
  dataLoaded: boolean;
  hasError: boolean;
  amberAlerts: AlertCount[];
  redAlerts: AlertCount[];
  @ViewChild('renderArea') renderArea:ElementRef;
  @ViewChild('divToResize') divToResize:ElementRef;
  @ViewChild('headerRow') headerRow:ElementRef;
  @ViewChild('riskArea') riskArea:ElementRef;
  renderAreaHeight: number = 0;
  heightSet: boolean;
  maxDetailsHeight: number = 400;
  recordChangeDetectionService: RecordChangeDetectionService;

  constructor(private taskSubsystem: TaskSubsystemService) {
    this.tracker = new WorkTracker(true);

    this.recordChangeDetectionService = new RecordChangeDetectionService();
    this.recordChangeDetectionService.initialise(
      ['dueDtm', 'currentInterventionType'], 
      ['id'], 'home-visit-detail'
    );
  }

  async ngAfterViewChecked(): Promise<void> {
    // Only conduct processing when the div is expanded and the height has changed
    if(this.isExpanded && this.renderArea && this.renderAreaHeight != this.renderArea.nativeElement.offsetHeight && !this.heightSet)
    {
      // capture the height of the component. This is the height of the inner home visit div. This is the div with class .details
      this.renderAreaHeight = this.renderArea.nativeElement.offsetHeight;
      const rowOneHeight = (this.headerRow ? this.headerRow.nativeElement.clientHeight : 0);

      // Only adjust height of the div if the UL rendering is complete and we have not added the associated 
      // home visit id to our map (heightSet) yet.
      if(this.riskArea && this.riskArea != undefined)
      {
        // Find the ul containing the risks.
        const theUlHeight = this.riskArea.nativeElement.clientHeight;

        // The UL is now fully rendered and possibly obscured by and overflowing the surrounding div

        // Prevent infinite loop as the observer will be triggered again for this object
        this.heightSet = true;
        
        // Up to 6 alerts display well without the need to adjust height.
        var alertCount = this.getAlertCount();
        if(alertCount > 6)
        {
          // Estimate display height for each alert
          var heightPerAlert = theUlHeight / alertCount;
          // Derive the estimated 'extra' space required to be added to the surrounding div to show each extra alert
          var extra = theUlHeight - (6 * heightPerAlert);
          // Remove row-one height. This removes excess spacing.
          var heightToSet = this.renderAreaHeight + extra - rowOneHeight;
          // Don't exceed the max; artifically set to 400
          if(heightToSet > this.maxDetailsHeight) heightToSet = this.maxDetailsHeight;

          // Debugging
          //console.log('renderAreaHeight[' + `${this.renderAreaHeight}px` + ']. rowOneHeight[' + `${rowOneHeight}px` + 
          //  ']. theUlHeight[' + `${theUlHeight}px` + ']. alertCount[' + `${alertCount}` + ']. extra[' + `${extra}px` + 
          //  ']. heightToSet[' + `${heightToSet}px` + '].'
          //);

          // Set the height
          this.divToResize.nativeElement.style.height = `${ heightToSet }px`;
        }
      }
    }
  }

  async ngOnChanges(changes: SimpleChanges) {
    if (changes['homeVisit'])
    {
      this.recordChangeDetectionService.applyNewDataSet([this.homeVisit]);
    }
    else if (changes['isExpanded'] && changes['isExpanded'].currentValue === true) {
      this.hasError = false;
      this.dataLoaded = false;
      await this.tracker.track(this.getUpcomingVisitDetailFromServer(this.homeVisit.subjectChildId));
    }
  }

  getAlertCount() : number {
    var response = this.amberAlerts != undefined ? this.amberAlerts.length : 0;
    response += this.redAlerts != undefined ? this.redAlerts.length : 0;
    return response;
  }

  formatShortDate(date: string) {
    return !!date
      ? moment(date)
        .local()
        .format('DD/MM/YYYY')
      : 'n/a';
  }

  private groupedAlerts(alerts: string[]): AlertCount[] {
    if (!alerts) return [];

    let grouped = alerts.reduce((g: any, alert: string) => {
      g[alert] = g[alert] || [];
      g[alert].push(alert);
      return g;
    }, {});

    return Object.keys(grouped).map(o => ({ count: grouped[o].length, description: o }));
  }

  addressLines(address: string): string[] {
    let parts = (address || '').split(',');
    let partsLength = parts.length;
    if (partsLength === 0) return [];

    return parts.map((o, i) => (i < partsLength - 1) ? `${o},` : `${o}`);
  }

  private getUpcomingVisitDetailFromServer(personId: string): Promise<void> {
    try {
      return this.taskSubsystem.getUpcomingChildHomeVisitDetail(personId).then(result => {
        this.hasError = result.hasError;
        if (!result.hasError) {
          this.homeVisitDetail = result.value;
          this.amberAlerts = this.groupedAlerts(result.value.amberAlerts);
          this.redAlerts = this.groupedAlerts(result.value.redAlerts);
          this.dataLoaded = true;
        }
      });
    } catch (error) {
      console.error(error);
      this.hasError = true;
    }
  }

  showUpdateGraphic(visit: Api.ChildHomeVisit): boolean {
    return this.recordChangeDetectionService.showUpdateGraphic(visit);
  }
}
