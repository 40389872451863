
import {interval as observableInterval,  Observable ,  Subscription } from 'rxjs';
import { MsalService } from '../services/msal.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import * as moment from 'moment';
import { WorkTracker } from 'app/shared/loading-pane';
import { PersonaSwitcherService } from 'services/api/persona-switcher.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  time: string;
  date: string;
  dateWithoutYear: string;
  isAuthenticated: boolean;
  tracker: WorkTracker;
  isDestroyed: boolean = false;

  constructor(
    private adalService: MsalService,
    private personaSwitcherService: PersonaSwitcherService
  ) {
    this.tracker = new WorkTracker(true);
    this.personaSwitcherService.clearPersonaUpn();
  }

  intervalSubscription: Subscription;

  async ngOnInit() {
    await this.adalService.handleCallback();

    await this.load();

    if (!this.intervalSubscription && !this.isDestroyed) {
      const refreshInterval = 60000;
      this.intervalSubscription = observableInterval(refreshInterval).subscribe(async () => {
        await this.load();
      });
    }
  }

  ngOnDestroy() {
    // Make sure the periodic api call is stopped.
    if (this.intervalSubscription) {
      this.intervalSubscription.unsubscribe();
    }

    this.isDestroyed = true;
  }

  private async load() {
    if (this.isDestroyed) {
      this.ngOnDestroy();
      return Promise.resolve();
    }

    this.updateDateDisplay();
  }

  private updateDateDisplay() {
    let now = moment().local();
    this.time = now.format('h:mm a');
    this.date = now.format('ddd, MMM Do YYYY');
    this.dateWithoutYear = now.format('ddd, MMM Do');
  }
}
