import * as moment from 'moment';
import { csExpressSettings } from '../../../settings';

// A utility service to centralise methods common to upcoming and overdue 
// Home Visit tasks.
export class HomeVisitsService {

  
    constructor(
    ) {

    }

    reduceVisitsToThisMembersVisits(visitData: Api.ChildHomeVisit[], thisMembersUpn: string)
    {
      // Reduce to visits where the owner name is the empty string (this occurs when this pers on is the Team Leader)
      return visitData.filter(o => !o.owner || o.owner.length < 1 || o.owner == thisMembersUpn)
    }

    haveStaff(visitData: Api.HomeVisitData): boolean {
        if(!visitData || !visitData.visitsLoaded || !visitData.visits) return false;
    
        var response = false;
        visitData.visits.forEach(visit => {
          if(!!visit.hasStaff)
          {
            response = true;
            return true;
          }
        });
        return response;
      }

    getDestinationPath(item: Api.ChildHomeVisit): string {
        if(csExpressSettings.enableDestinationPathReplacement !== true)
        {
          return item.destinationPath;
        }
    
        if(!this.hasDestinationPath(item)) return '';
        let path = item.destinationPath.toLowerCase();
        var index = path.indexOf('staging.prod.edc.internal');
        if(index >= 0)
        {
          return item.destinationPath.replace(/staging.prod.edc.internal/i, "icms.prod.edc.internal");
        }
        return item.destinationPath;
    }

    hasDestinationPath(item: Api.ChildHomeVisit): boolean {
        return item.destinationPath && item.destinationPath.trim().length > 0;
    }

    formatShortDate(date: string): string {
        return !!date
          ? moment(date)
            .local()
            .format('DD/MM/YYYY')
          : 'n/a';
    }
}