<div class="visually-hidden" attr.title="Header Column {{milestone.milestoneName}}">{{milestone.milestoneName}}</div>
<div [ngClass]="{ 'milestone': true, 'past-milestone': milestone.isInThePast, 'future-milestone': !milestone.isInThePast }">
  <div class="row" role="row">
    <div class="td" role="cell">
        Due&nbsp;<span>{{ milestone.timeToDueDtDescription }}</span>
    </div>
  </div>
  <div class="row" role="row">
    <div class="td" role="cell">
        On&nbsp;<span>{{ milestone.milestoneDueDt }}</span>
    </div>
  </div>
</div>