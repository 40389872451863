import { Injectable } from '@angular/core';
import { LocalStorageService } from 'services/local-storage.service';
import { paginationTestSettings } from 'settings';
import { ShowPriorityNotifyService } from 'services/notifications/show-priority-notify.service';
import { FeatureLoggerService } from 'services/api/feature-logger.service';
import { FeatureName } from "api/website_enum";

@Injectable()
export class PriorityIconService {
  private priorityStorageName: string = 'PriorityIcon';
  private featureIsGloballyDisabled: boolean = false;
  private featureIsEnabled: boolean = true;

  constructor(private localStorageService: LocalStorageService, 
    private showPriorityNotifyService: ShowPriorityNotifyService, 
    private featureLoggerService: FeatureLoggerService) {
    if(!this.seedAndCheckIfFeatureIsGloballyDisabled())
    {
        // This feature is not globally disabled. Check individual officer settings.
        this.checkIfIndividualOfficerHasFeatureEnabled();
    }

    // this.featureIsEnabled has now been set.
    // Update local storage for consumption by other parts of the solution.
    this.localStorageService.set(this.priorityStorageName, this.featureIsEnabled);
  }

  // A publicly visible method to determine whether the feature is enabled or not.
  public priorityIconFeatureIsEnabled() : boolean 
  {
    return this.featureIsEnabled;
  }

  // A publicly visible method to determine whether the feature is globally disabled or not.
  public priorityIsGloballyDisabled() : boolean 
  {
    return this.featureIsGloballyDisabled;
  }

  // A publicly visible method to toggle the priority setting. 
  // This will only have an effect if 'featureIsGloballyDisabled' is false
  // Return the resulting priority value. That is, true when enabled and false when disabled.
  public togglePriority() : boolean 
  {
    // This feature is disabled. Make no change
    if(!!this.featureIsGloballyDisabled) return this.featureIsEnabled;

    // Toggle the setting
    this.featureIsEnabled = !this.featureIsEnabled;

    // Update storage for future access
    this.localStorageService.set(this.priorityStorageName, this.featureIsEnabled);

    // Notify subscribers of the change to the setting
    this.showPriorityNotifyService.ChangeShowPriorities(this.featureIsEnabled);

    // Send new value to reporting layer
    this.featureLoggerService.logFeatureUsage(this.featureIsEnabled ? 
        FeatureName.PriorityIconsEnabled : 
        FeatureName.PriorityIconsDisabled);

    // Inform caller of resulting current value
    return this.featureIsEnabled;
  }

  // This is taken from index.html and is the setting: Pagination.EnablePriorityIcons
  // Return true if the priority icon feature is globally disabled.
  // If not disabled, return false.
  private seedAndCheckIfFeatureIsGloballyDisabled() : boolean
  {
    this.featureIsGloballyDisabled = !paginationTestSettings.enablePriorityIcons;

    // Check if the priority icon feature is 'globally' disabled
    if(!!this.featureIsGloballyDisabled)
    {
        this.featureIsEnabled = false;
    }

    return this.featureIsGloballyDisabled;
  }

  // Read this value from local storage, if any. 
  // This allows officers to enable/disable the feature themselves.
  private checkIfIndividualOfficerHasFeatureEnabled()
  {
    // Priority icons are not globally disabled.
    // Only update if we have a value stored in local storage. Default is 'true' / enabled.
    if(this.localStorageService.check(this.priorityStorageName))
    {
        // The officer has set a value in the past. Seed to this value.
        this.featureIsEnabled = this.localStorageService.get(this.priorityStorageName);
    }
  }
}
