<div class="table">
  <div class="row" role="row">
    <div class="td date" role="cell" data-title="Selected Date Value">
      <app-date-input id="customDateRange" name="customDateRange" placeholderText="When will you be back?" 
        [getDateRange]="!!getDateRange" [(dateRangeModel)]="model" [disableComponent]="false" 
        [disableUntil]=disableUntil [fullWidth]="false" 
        (dateRangeChangedEvent)="dateChangedEvent($event)"
        (validationsReRunEvent)="validationsReRunEvent($event)"
        ></app-date-input>
    </div>
    <div class="td time" role="cell" data-title="Selected Time Value">
      <input class="form-control time" [ngxTimepicker]="timepicker" [value]="currentTimeValue" 
        [format]="12" id="tp" #tpinput (blur)=timepickerblur($event) disabled=disabled 
        [ngClass]="{ 'error': !!showRequireTimeError || !!showPastTimeError }">
      <ngx-material-timepicker #timepicker [minutesGap]="15" [enableKeyboardInput]="true" (timeSet)="timepickerValueSet($event)" (closed)="timepickerClosed()"></ngx-material-timepicker>
      <div class="timeoverlay" role="cell" data-title="Time Tool">
        <button type="button" class="btn btn-secondary input-icon" (click)="openTimePicker()">
          <i class="fas fa-clock fa-lg"></i>
        </button>
      </div>
    </div>
  </div>
  <div class="row" role="row">
    <div class="field-space-top-small" role="cell" data-title="Error Message">
      <div class="text-danger" *ngIf="!!showRequireDateError || !!showRequireTimeError">
        <strong>Please select when you will be back.</strong>
      </div>
      <div class="text-danger" *ngIf="!!showPastDateError">
        <strong>Please select a date on or after today.</strong>
      </div>
      <div class="text-danger" *ngIf="!!showPastTimeError">
        <strong>Please select a time after now.</strong>
      </div>
      <div class="text-danger" *ngIf="!!showInvalidTimeError">
        <strong>Please use the time picker to select a time or enter a value in this format: dd:dd AM|PM. The minute part must be a zero or quarter hour increment.</strong>
      </div>
      <div class="text-danger" *ngIf="!!showInvalidDateError">
        <strong>Please use the calendar to select a date or enter a value in this format: dd MMM yyyy.</strong>
      </div>
    </div>
  </div>
</div>
