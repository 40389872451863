<ng-container>
  <aside [ngClass]="{ 'selected': overdueVisitsTabSelected || upcomingVisitsTabSelected, 'not-selected': !overdueVisitsTabSelected && !upcomingVisitsTabSelected }">
    <h2>Visits to children or young people</h2>
      <div class="panel">
        <loading-pane [tracker]="overdueTracker">
          <div role="button" tabindex="0" [attr.aria-pressed]="!selectedTab || overdueVisitsTabSelected"
            aria-label="What's Overdue" title="What's Overdue"
            [ngClass]="{ 'selected': !selectedTab || overdueVisitsTabSelected }"
            (click)="selectInfoPanel(overdueVisits)" (keyup.Enter)="selectInfoPanel(overdueVisits)">
            <h3><i class="far fa-clock fa-lg"></i> What's Overdue</h3>
            <p>
              <span *ngIf="overdueCountsHasError" class="text-danger" role="alert">Sorry, there was a problem retrieving the
                latest data.</span>
              <ng-container *ngIf="!overdueCountsHasError">{{  getOverdueVisitSummarySentence() }}</ng-container>
            </p>
          </div>
        </loading-pane>
        <loading-pane [tracker]="upcomingTracker">
          <div role="button" tabindex="0" [attr.aria-pressed]="upcomingVisitsTabSelected"
            aria-label="Upcoming Visits" title="Upcoming Visits" (click)="selectInfoPanel(upcomingVisits)"
            (keyup.Enter)="selectInfoPanel(upcomingVisits)"
            [ngClass]="{ 'selected': upcomingVisitsTabSelected, 'mb0': true }">
            <h3><i class="fas fa-home fa-lg"></i> Upcoming Visits</h3>
            <p>
              <span *ngIf="upcomingCountsHasError" class="text-danger" role="alert">Sorry, there was a problem retrieving the
                latest
                data.</span>
              <ng-container *ngIf="!upcomingCountsHasError">{{  getUpcomingHomeVisitSummarySentence() }}</ng-container>
            </p>
          </div>
        </loading-pane>
      </div>
  </aside>
</ng-container>