
import { interval as observableInterval,  Subscription } from 'rxjs';
import { Component, OnInit, OnDestroy, HostListener, EventEmitter, Input, Output } from '@angular/core';
import { homeVisitsSettings } from '../../../settings';
import { WorkTracker } from 'app/shared/loading-pane';
import { IdleDetectionService } from 'services';
import { FocusCheck } from '../../shared/FocusCheck';
import { FeatureName, filterDateRangeSummaryDescription } from "api/website_enum";
import { HomeVisitUpcomingCountNotifyService, HomeVisitOverdueCountNotifyService, TabNotificationService, PromiseNotifyService } from '../../../services/notifications';

@Component({
  selector: 'app-home-visits-summary',
  templateUrl: './home-visits-summary.component.html',
  styleUrls: ['./home-visits-summary.component.scss']
})
export class HomeVisitsSummaryComponent extends FocusCheck implements OnInit, OnDestroy {
  // 'haveHomeVisitsToDisplay' is used in 'hero' to determine whether to display the Home Visits Summary tile
  @Output() haveHomeVisitsToDisplay: EventEmitter<boolean> = new EventEmitter<boolean>();
  
  upcomingCountsHasError: boolean;
  overdueCountsHasError: boolean;
  overdueHomeVisitCount: number;
  childHomeVisitCount: number;
  dateRangeType: number;

  selectedTab: FeatureName;
  overdueVisits: FeatureName = FeatureName.OverdueVisits;
  upcomingVisits: FeatureName = FeatureName.UpcomingVisits;
  refreshIntervalInMilliseconds: number;
  userIsIdle: boolean = false;
  isDestroyed: boolean = false;

  overdueTracker: WorkTracker;
  upcomingTracker: WorkTracker;
  tabNotificationSubscription: Subscription;
  idleDetectionSubscription: Subscription;
  homeVisitUpcomingCountSubscription: Subscription;
  homeVisitOverdueCountSubscription: Subscription;
  promiseSubscription: Subscription;

  constructor(
    private tabNotificationService: TabNotificationService,
    private idleDetectionService: IdleDetectionService,
    private homeVisitUpcomingCountNotifyService: HomeVisitUpcomingCountNotifyService, 
    private homeVisitOverdueCountNotifyService: HomeVisitOverdueCountNotifyService, 
    private promiseNotifyService: PromiseNotifyService
  ) {
    super();
    this.refreshIntervalInMilliseconds = homeVisitsSettings.refreshIntervalInSeconds * 1000;
    this.overdueTracker = new WorkTracker(true);
    this.upcomingTracker = new WorkTracker(true);
    this.upcomingCountsHasError = false;
    this.overdueCountsHasError = false;

    this.subscribeToTabNotifications();
    this.subscribeToUpcomingHomeVisitCountChangeNotifications();
    this.subscribeToOverdueHomeVisitCountChangeNotifications();
    this.subscribeToPromiseNotifications();
  }

  async ngOnInit() {
    this.idleDetectionService.reset();
    this.idleDetectionSubscription = this.idleDetectionService.isIdleChanged.subscribe(isIdle => this.userIsIdle = isIdle);
  }

  get overdueVisitsTabSelected(): boolean {
    return !this.selectedTab || this.selectedTab === FeatureName.OverdueVisits
  }
  get upcomingVisitsTabSelected(): boolean {
    return this.selectedTab && this.selectedTab === FeatureName.UpcomingVisits
  }

  ngOnDestroy() {
    // Make sure the periodic api call is stopped.
    if (this.tabNotificationSubscription) {
      this.tabNotificationSubscription.unsubscribe();
    }
    if (this.idleDetectionSubscription) {
      this.idleDetectionSubscription.unsubscribe();
    }
    if (this.homeVisitUpcomingCountSubscription) {
      this.homeVisitUpcomingCountSubscription.unsubscribe();
    }
    if (this.homeVisitOverdueCountSubscription) {
      this.homeVisitOverdueCountSubscription.unsubscribe();
    }
    if (this.promiseSubscription) {
      this.promiseSubscription.unsubscribe();
    }

    this.idleDetectionService.ngOnDestroy();

    this.isDestroyed = true;
  }

  selectInfoPanel(panel: FeatureName) {
    this.tabNotificationService.ChangeSelectedTab(panel);
  }

  getUpcomingHomeVisitSummarySentence(): string {
    var suffix = filterDateRangeSummaryDescription(this.dateRangeType);
    if (this.childHomeVisitCount === 0) {
      return `No children or young people are due for a visit ${suffix}.`;
    }
    else if (this.childHomeVisitCount === 1) {
      return `1 child or young person is due for a visit ${suffix}.`;
    }
    else if (this.childHomeVisitCount > 1) {
      return `${this.childHomeVisitCount} children or young people are due for a visit ${suffix}.`;
    }
    else if (!this.childHomeVisitCount) {
      return `? children or young people are due for a visit ${suffix}.`;
    }
  }

  getOverdueVisitSummarySentence(): string {
    if (this.overdueHomeVisitCount === 0) {
      return 'No children or young people have a visit overdue.';
    }
    else if (this.overdueHomeVisitCount === 1) {
      return '1 child or young person has a visit overdue.';
    }
    else if (this.overdueHomeVisitCount > 1) {
      return `${this.overdueHomeVisitCount} children or young people have a visit overdue.`;
    }
    else if (!this.overdueHomeVisitCount) {
      return '? children or young people have a visit overdue.';
    }
  }

  private subscribeToUpcomingHomeVisitCountChangeNotifications() {
    if (!this.homeVisitUpcomingCountSubscription) {
      this.homeVisitUpcomingCountSubscription = this.homeVisitUpcomingCountNotifyService.upcomingVisitCountDataChanged.subscribe(async (countData) => {
        this.childHomeVisitCount = countData.recordCount;
        this.upcomingCountsHasError = countData.hasError;
        this.dateRangeType = countData.dateRangeType;
        // This allows the 'hero' component to show that we do/not have a home visit tile to show
        this.haveHomeVisitsToDisplay.emit(this.overdueHomeVisitCount > 0 || this.childHomeVisitCount > 0);
      });
    }
  }

  private subscribeToOverdueHomeVisitCountChangeNotifications() {
    if (!this.homeVisitOverdueCountSubscription) {
      this.homeVisitOverdueCountSubscription = this.homeVisitOverdueCountNotifyService.overdueVisitCountDataChanged.subscribe(async (countData) => {
        this.overdueHomeVisitCount = countData.recordCount;
        this.overdueCountsHasError = countData.hasError;
        // This allows the 'hero' component to show that we do/not have a home visit tile to show
        this.haveHomeVisitsToDisplay.emit(this.overdueHomeVisitCount > 0 || this.childHomeVisitCount > 0);
      });
    }
  }

  upcomingVisitsPromise: Api.HomeVisitPromise;
  overdueVisitsPromise: Api.HomeVisitPromise;
  private subscribeToPromiseNotifications()
  {
    if(!this.promiseSubscription)
    {
      this.promiseSubscription = this.promiseNotifyService.promiseTrackerChanged.subscribe(async (thePromise) => {
        if(thePromise.visitType == 'upcoming-visits')
        {
          if (this.upcomingVisitsPromise != thePromise) {
            this.upcomingVisitsPromise = thePromise;
            await this.upcomingTracker.track(this.upcomingVisitsPromise.thePromise);
          }
        }
        else if(thePromise.visitType == 'overdue-visits')
        {
          if (this.overdueVisitsPromise != thePromise) {
            this.overdueVisitsPromise = thePromise;
            await this.overdueTracker.track(this.overdueVisitsPromise.thePromise);
          }
        }
      });
    }
  }

  private subscribeToTabNotifications() {
    if(!this.tabNotificationSubscription)
    {
      this.tabNotificationSubscription = this.tabNotificationService.selectedTabChanged.subscribe((tab) => {
        if (this.selectedTab != tab) {
          this.selectedTab = tab;
        }
      });
    }
  }

  @HostListener('window:beforeunload ', ['$event'])
  beforeUnload(event: any): void {
    this.ngOnDestroy();
  }
}