import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class HomeVisitOverdueCountNotifyService {

    constructor(
    ) { }

    private overdueVisitCountData = new Subject<Api.OverdueHomeVisitCountData>();
    public overdueVisitCountDataChanged = this.overdueVisitCountData.asObservable();

    ChangeHomeVisitCounts(countData: Api.OverdueHomeVisitCountData) {
        this.overdueVisitCountData.next(countData);
    }
}
