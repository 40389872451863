import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class OfficeLockdownNotifyService {

    constructor(
    ) { }

    private mostRecentOfficeDetails: Api.ServiceCentreViewModel;
    private serviceCentre = new Subject<Api.ServiceCentreViewModel>();
    public serviceCentreChanged = this.serviceCentre.asObservable();

    ChangeServiceCentre(newMessage: Api.ServiceCentreViewModel) {
        this.mostRecentOfficeDetails = newMessage;
        this.serviceCentre.next(newMessage);
    }

    public getServiceCentre() : Api.ServiceCentreViewModel
    {
        return this.mostRecentOfficeDetails;
    }
}
