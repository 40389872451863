import { Injectable } from '@angular/core';
import * as moment from 'moment';

@Injectable()
export class RecordChangeDetectionService {
    lastDataChangeTimestamp: moment.Moment;
    propertiesToMonitorForChange: string[];
    propertiesToIdentifyAUniqueRecord: string[];
    lastDataSet: any[];
    currentDataSet: any[];
    parentComponentName: string;

    // The count of seconds for which a change is valid
    // After this, do not show a graphic. This is related to the value of 'lastDataChangeTimestamp'
    // This is updated to 'now' whenever new data is applied.
    showChangeGraphicForNSeconds: number = 5;

    constructor() {}

    public initialise(propertiesToMonitor: string[], idProperties: string[], parentComponent: string) : void 
    {
        this.propertiesToMonitorForChange = propertiesToMonitor;
        this.propertiesToIdentifyAUniqueRecord = idProperties;
        this.parentComponentName = parentComponent;
    }

    public applyNewDataSet(dataSet: any[]) : void
    {
        this.lastDataSet = (this.currentDataSet ? this.currentDataSet.slice() : []);
        this.currentDataSet = dataSet.slice();
        this.lastDataChangeTimestamp = moment();
    }

    public showUpdateGraphic(record: any) : boolean
    {
        // Check if still within time.
        // We reduce acceptable window by 1 second to ensure a second window does not restart the spinner.
        // So, if showing for 5 seconds, return false if asked again 5 seconds later, do not return true.
        // To prevent double-spinner, return false when now is (n-1) second since last data change.
        var secondsDifference = moment().diff(this.lastDataChangeTimestamp, 'seconds');
        if(secondsDifference > (this.showChangeGraphicForNSeconds - 1)) {
            return false;
        }

        // Check we have all required values
        if(!this.lastDataSet || this.lastDataSet.length < 1 || !this.currentDataSet || this.currentDataSet.length < 1 || !this.propertiesToIdentifyAUniqueRecord || !this.propertiesToMonitorForChange) { 
            return false;
        }

        // Find the given record in the lastDataSet
        var matchingLastRecord = null;
        this.lastDataSet.every(instance => {
            var matchingProperties = 0;
            for(var i = 0; i < this.propertiesToIdentifyAUniqueRecord.length; i++)
            {
                if(instance[this.propertiesToIdentifyAUniqueRecord[i]] == record[this.propertiesToIdentifyAUniqueRecord[i]])
                {
                    matchingProperties++;
                }
            }
            if(matchingProperties == this.propertiesToIdentifyAUniqueRecord.length)
            {
                matchingLastRecord = instance;
                return false; // Exit the 'every' loop
            }
        });

        // If we did not previously have this record we have nothing to compare. Simply exit
        if(!matchingLastRecord || matchingLastRecord == null || matchingLastRecord == undefined) {
            return false;
        }
        
        // We have a record. Check if any of the properties we are monitoring have changed
        var haveAChange = false;
        for(var i = 0; i < this.propertiesToMonitorForChange.length; i++)
        {
            if(matchingLastRecord[this.propertiesToMonitorForChange[i]] != record[this.propertiesToMonitorForChange[i]])
            {
                // A significant change has been detected. Return true.
                haveAChange = true;
                break;
            }
        }
        return haveAChange;
    }
}
