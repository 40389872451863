import { Injectable } from '@angular/core';
import { csx } from 'services/api/urls';
import { HttpClient } from '@angular/common/http';
import { PersonaSwitcherService } from 'services/api/persona-switcher.service';

@Injectable()
export class CaseSummaryService {
  private overrideUpn: string = null;

  constructor(private http: HttpClient,
    private personaSwitcherService: PersonaSwitcherService) {

  }

  async getCaseSummaries(page: number, pageSize: number) {
    let headers = {};
    if (this.personaSwitcherService.getPersonaUpn() || this.overrideUpn) {
      headers = {
        'upn': btoa(this.personaSwitcherService.getPersonaUpn() || this.overrideUpn)
      };
    }

    return this.http
      .get<Api.CaseSummaryData>(
        csx.caseSummaries({
          page: page.toString(),
          pageSize: pageSize.toString()
        }), {
        headers: headers
      })
      .asJsonResponse<Api.CaseSummaryData>()
      .toPromise();
  }

  async getOngoingInterventionDetails(personId: string) {
    let headers = {};
    if (this.personaSwitcherService.getPersonaUpn() || this.overrideUpn) {
      headers = {
        'upn': btoa(this.personaSwitcherService.getPersonaUpn() || this.overrideUpn)
      };
    }

    return this.http
      .get<Api.OngoingInterventionDetail>(
        csx.ongoingInterventionDetails({
          personId: personId
        }), {
        headers: headers
      })
      .asJsonResponse<Api.OngoingInterventionDetail>()
      .toPromise();
  }
}
