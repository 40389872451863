import {interval as observableInterval,  Subscription, Observable } from 'rxjs';
import { Component, OnInit, OnDestroy, Input, HostListener, EventEmitter, Output } from '@angular/core';
import * as moment from 'moment';
import { TaskSubsystemService } from '../../../services/api/task-subsystem.service';
import { courtOrdersSettings } from '../../../settings';
import { WorkTracker } from 'app/shared/loading-pane';
import { IdleDetectionService } from 'services';
import { FocusCheck } from '../../shared/FocusCheck';
import { UserInfoService } from '../../../services/user-info.service';
import { PersonaSwitcherService } from 'services/api/persona-switcher.service';
import { FeatureName } from "api/website_enum";
import { CourtOrderDetails } from './court-order-details';

import { TabNotificationService } from '../../../services/notifications/tab-notification.service';
import { CourtOrderNotifyService } from '../../../services/notifications/court-order-notify.service';
import { CourtOrderPromiseNotifyService } from '../../../services/notifications/court-order-promise-notify.service';

@Component({
  selector: 'app-court-order-summary',
  templateUrl: './court-order-summary.component.html',
  styleUrls: ['./court-order-summary.component.scss']
})
export class CourtOrderSummaryComponent extends FocusCheck implements OnInit, OnDestroy {
  @Output() haveCourtOrdersToDisplay: EventEmitter<boolean> = new EventEmitter<boolean>();

  orderDetails: CourtOrderDetails;
  
  // By default on page load the selectedTab is not a court order tab
  selectedTab: FeatureName;
  nextThreeMonths: FeatureName = FeatureName.CourtOrders3Months;
  nextSixMonths: FeatureName = FeatureName.CourtOrders6Months;
  refreshIntervalInMilliseconds: number;
  officersUpn: string = '';
  personaSwitcherSelectedUpn: string = null;
  tracker: WorkTracker;
  intervalSubscription: Subscription;
  tabNotificationSubscription: Subscription;
  idleDetectionSubscription: Subscription;
  personaChangedSubscription: Subscription;
  userIsIdle: boolean = false;
  isDestroyed: boolean = false;

  constructor(
    private taskSubsystem: TaskSubsystemService,
    private tabNotificationService: TabNotificationService,
    private courtOrderNotifyService: CourtOrderNotifyService,
    private idleDetectionService: IdleDetectionService,
    private userInfoService: UserInfoService,
    private personaSwitcherService: PersonaSwitcherService, 
    private courtOrderPromiseNotifyService: CourtOrderPromiseNotifyService
  ) {
    super();
    this.orderDetails = new CourtOrderDetails();
    this.officersUpn = userInfoService.upn();
    this.refreshIntervalInMilliseconds = courtOrdersSettings.refreshIntervalInSeconds * 1000;
    this.tracker = new WorkTracker(true);
    this.tabNotificationSubscription = this.tabNotificationService.selectedTabChanged.subscribe((tab) => {
      if (this.selectedTab != tab) {
        this.selectedTab = tab;
        this.updateDisplayData();
      }
    });
    this.subscribeToPersonaChangedNotifications();
  }


  async ngOnInit() {
    this.idleDetectionService.reset();
    this.idleDetectionSubscription = this.idleDetectionService.isIdleChanged.subscribe(isIdle => this.userIsIdle = isIdle);

    let courtOrdersPromise = this.loadData();
    this.courtOrderPromiseNotifyService.ChangeCourtOrderPromise(courtOrdersPromise);

    await this.tracker.track(
      courtOrdersPromise.then(() => {
        //this.orderDetails.SetOfficerHasStaff();
        this.orderDetails.filterOrdersToDisplay(this.personaSwitcherSelectedUpn);
        this.updateDisplayData();

        if (!this.intervalSubscription && !this.isDestroyed) {
          this.intervalSubscription = observableInterval(this.refreshIntervalInMilliseconds).subscribe(
            async () => {
              courtOrdersPromise = this.loadData();
              this.courtOrderPromiseNotifyService.ChangeCourtOrderPromise(courtOrdersPromise);
              await courtOrdersPromise;
              //this.orderDetails.SetOfficerHasStaff();
              this.orderDetails.filterOrdersToDisplay(this.personaSwitcherSelectedUpn);
              this.updateDisplayData();
            }
          );
        }
      })
    );
  }

  get expiryInThreeMonthsTabSelected(): boolean {
    return this.selectedTab && this.selectedTab === FeatureName.CourtOrders3Months
  }
  get expiryInSixMonthsTabSelected(): boolean {
    return this.selectedTab && this.selectedTab === FeatureName.CourtOrders6Months
  }

  get courtOrdersInNextThreeMonthsHasError(): boolean {
    return this.orderDetails.courtOrdersInNextThreeMonthsHasError
  }
  get courtOrdersInSubsequentThreeHasError(): boolean {
    return this.orderDetails.courtOrdersInSubsequentThreeHasError
  }

  ngOnDestroy() {
    // Make sure the periodic api call is stopped.
    if (this.intervalSubscription) {
      this.intervalSubscription.unsubscribe();
    }
    if (this.tabNotificationSubscription) {
      this.tabNotificationSubscription.unsubscribe();
    }
    if (this.idleDetectionSubscription) {
      this.idleDetectionSubscription.unsubscribe();
    }
    if (this.personaChangedSubscription) {
      this.personaChangedSubscription.unsubscribe();
    }

    this.idleDetectionService.ngOnDestroy();

    this.isDestroyed = true;
  }

  async loadData() {
    if (this.isDestroyed) {
      this.ngOnDestroy();
      return Promise.resolve();
    }

    return await Promise.all([
      this.getCourtOrders(true),
      this.getCourtOrders(false)
    ]);
  }

  selectInfoPanel(panel: FeatureName) {
    this.tabNotificationService.ChangeSelectedTab(panel);
  }

  // This constructs an object structure which is what is emitted to and used by dashboard/court-order pages
  updateDisplayData()
  {
    let data = this.orderDetails.generateDisplayInformation(this.expiryInThreeMonthsTabSelected);

    // Set the orders to display. This is the result of possible persona switcher filtering, and also based on the currently selected tab
    this.courtOrderNotifyService.ChangeCourtOrderData(data);

    // Also, inform more generally whether we have any court order data at all
    var haveSomeData = !!this.orderDetails && 
    (
      this.orderDetails.countOfCourtOrdersInNextThreeMonths > 0 || 
      this.orderDetails.countOfCourtOrdersInSubsequentThreeMonths > 0
    );

    this.haveCourtOrdersToDisplay.emit(haveSomeData);
  }

  getCourtOrdersDueSoonSentence(): string {
    return this.orderDetails.getCourtOrdersDueSoonSentence();
  }

  getCourtOrdersDueInSixMonthsSentence(): string {
    return this.orderDetails.getCourtOrdersDueInSixMonthsSentence();
  }

  private getCourtOrders(immediate: boolean): Promise<void> {
    if (!this.shouldRefreshData()) {
      return Promise.resolve();
    }

    try {
      let finishOffset = (immediate ? 3 : 6);
      let fromDate = null;
      let fromDateMoment = moment()
      .local()
      .startOf('day');
      if(immediate)
      {
        // To ensure tasks don't drop off the moment they are due, include 
        // court orders that expired up to 5 days ago.
        fromDate = fromDateMoment
          .subtract(5, 'day')
          .utc()
          .toDate();
      }
      else
      {
        fromDate = fromDateMoment
        .add(3, 'month')
        .add(1, 'day')
        .utc()
        .toDate();
      }

      let toDate = moment()
        .local()
        .endOf('day')
        .add(finishOffset, 'month')
        .utc()
        .toDate();

      return this.taskSubsystem.getCurrentCourts(fromDate, toDate).then(result => {
        if (result.hasError) {
          this.orderDetails.dataRetrievalError(immediate);

        } else {
          this.orderDetails.storeData(immediate, result.value);
        }
      });

    } catch (error) {
      console.error(error);
      this.orderDetails.dataRetrievalError(immediate);
    }
  }

  private subscribeToPersonaChangedNotifications() {
    if (!this.personaChangedSubscription) {
      this.personaChangedSubscription = this.personaSwitcherService.currentPersonaUpnChanged.subscribe(async (upn) => {
        this.personaSwitcherSelectedUpn = upn; // When persona switcher is de-activated this will be called with a null upn
        this.orderDetails.filterOrdersToDisplay(this.personaSwitcherSelectedUpn);

        let courtOrdersPromise = this.loadData();
        this.courtOrderPromiseNotifyService.ChangeCourtOrderPromise(courtOrdersPromise);

        await this.tracker.track(courtOrdersPromise);
        this.orderDetails.filterOrdersToDisplay(this.personaSwitcherSelectedUpn);
        this.updateDisplayData();
      });
    }
  }

  private shouldRefreshData() {
    if (this.isDestroyed) return false;

    return this.orderDetails.dataIsNotLoaded() || (!this.userIsIdle && this.windowGotFocus && !document.hidden);
  }

  @HostListener('window:beforeunload ', ['$event'])
  beforeUnload(event: any): void {
    this.ngOnDestroy();
  }
}
