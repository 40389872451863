import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class PromiseNotifyService {

    constructor(
    ) { }

    private promiseTracker = new Subject<Api.HomeVisitPromise>();
    public promiseTrackerChanged = this.promiseTracker.asObservable();

    TrackThisPromise(thePromiseToTrack: Api.HomeVisitPromise) {
        this.promiseTracker.next(thePromiseToTrack);
    }
}
