import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { LoadingPaneComponent } from "./loading-pane.component";

export { LoadingPaneComponent } from "./loading-pane.component";
export { WorkTracker } from "./work-tracker.service";

@NgModule({
  declarations: [
    LoadingPaneComponent
  ],
  exports: [
    LoadingPaneComponent
  ],
  imports: [
    BrowserModule
  ],
  providers: []
})
export class LoadingPaneModule {
}
