import { Component, OnInit, OnDestroy, HostListener
} from '@angular/core';
import * as moment from 'moment';
import { Title } from '@angular/platform-browser';
import { Subscription } from 'rxjs';
import { TabNotificationService } from '../../../services/notifications/tab-notification.service';
import { CourtOrderNotifyService } from '../../../services/notifications/court-order-notify.service';
import { WorkTracker } from 'app/shared/loading-pane';
import { FeatureLoggerService } from 'services';
import { UserInfoService } from '../../../services/user-info.service';
import { FocusCheck } from '../../shared/FocusCheck';
import { PersonaSwitcherService } from 'services/api/persona-switcher.service';
import { CourtOrderService } from 'services/court-order.service';
import { FeatureName } from "api/website_enum";
// import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-court-order',
  templateUrl: './court-order.component.html',
  styleUrls: ['./court-order.component.scss']
})
export class CourtOrderComponent extends FocusCheck implements OnInit, OnDestroy {
  selectedTab: FeatureName;
  
  // This data is updated via notifications
  orderData: Api.SelectedCourtOrderData;

  // This is the data we will display in relation to pagination
  ordersToShow: Api.GroupedCourtOrders[];

  // The collection of orders that have been toggled to full view. This is just used to track what is/not open.
  selectedOrders: Api.CourtOrder[] = [];
  personaSwitcherSelectedUpn: string = null;
  tracker: WorkTracker;
  intervalSubscription: Subscription;
  tabNotificationSubscription: Subscription;
  courtOrderNotifySubscription: Subscription;
  personaChangedSubscription: Subscription;
  isDestroyed: boolean = false;
  officersUpn: string = '';

  // Pagination settings
  currentPageExpiryInThreeMonths: number = 0;
  pageSizeExpiryInThreeMonths: number = 10;
  orderCountExpiryInThreeMonths: number = 0;

  currentPageExpiryInSixMonths: number = 0;
  pageSizeExpiryInSixMonths: number = 10;
  orderCountExpiryInSixMonths: number = 0;

  courtOrderStyle: string = 'court-order';

  get expiryInThreeMonthsTabSelected(): boolean {
    return this.selectedTab && this.selectedTab === FeatureName.CourtOrders3Months
  }
  get expiryInSixMonthsTabSelected(): boolean {
    return this.selectedTab && this.selectedTab === FeatureName.CourtOrders6Months
  }
  get errorMsgAppSectionText(): string {
    return (this.expiryInThreeMonthsTabSelected ? 'court orders due in 3 months' : 'court orders due in 3 months');
  }
  get errorMsgHeaderText(): string {
    if(this.expiryInThreeMonthsTabSelected === true) return 'There are no children or young people with court orders expiring in the next 3 months.';

    if(!this.orderData.countOfOrdersMovedFromSubsequentToNextThreeMonths || this.orderData.countOfOrdersMovedFromSubsequentToNextThreeMonths === 0)
    {
      return 'There are no children or young people with court orders expiring between 3 to 6 months in the future.';
    }
    else if(this.orderData.countOfOrdersMovedFromSubsequentToNextThreeMonths === 1)
    {
      return 'There is one order expiring between 3 to 6 months in the future. This was moved to the 0 to 3 month tab to be shown with the associated child.';
    }
    else if(this.orderData.countOfOrdersMovedFromSubsequentToNextThreeMonths > 1 && 
      this.orderData.countOfUniqueChildrenInNextThreeMonths === 1)
    {
      return 'There are ' + this.orderData.countOfOrdersMovedFromSubsequentToNextThreeMonths + ' orders expiring between 3 to 6 months in the future. These were moved to the 0 to 3 month tab to be shown with the associated child.';
    }
    else // > 1 moved and > 2 child
    {
      return 'There are ' + this.orderData.countOfOrdersMovedFromSubsequentToNextThreeMonths + ' orders expiring between 3 to 6 months in the future. These were moved to the 0 to 3 month tab to be shown with the associated children.';
    }
  }

  constructor(
    private titleService: Title,
    private tabNotificationService: TabNotificationService,
    private courtOrderNotifyService: CourtOrderNotifyService,
    private featureLogger: FeatureLoggerService,
    private personaSwitcherService: PersonaSwitcherService, 
    private courtOrderService: CourtOrderService,
    private userInfoService: UserInfoService,
    // private toastr: ToastrService
  ) {
    super();
    this.tracker = new WorkTracker(true);
    this.officersUpn = userInfoService.upn();
    this.orderCountExpiryInThreeMonths = 0;
    this.orderCountExpiryInSixMonths = 0;
    this.titleService.setTitle('CS Portal - Dashboard');
    this.subscribeToPersonaChangedNotifications();
  }

  async ngOnInit(): Promise<void> {
    this.updatePagedOrdersToShow();

    this.tabNotificationSubscription = this.tabNotificationService.selectedTabChanged.subscribe((tab) => {
      if (this.selectedTab != tab) {
        this.selectedTab = tab;
        this.updatePagedOrdersToShow();
      }
    });

    this.courtOrderNotifySubscription = this.courtOrderNotifyService.courtOrdersChanged.subscribe((orders) => {
      this.orderData = orders;
      this.updatePagedOrdersToShow();
    });
  }

  ngOnDestroy() {
    // Make sure the periodic api call is stopped.
    if (this.intervalSubscription) {
      this.intervalSubscription.unsubscribe();
    }
    if (this.tabNotificationSubscription) {
      this.tabNotificationSubscription.unsubscribe();
    }
    if (this.personaChangedSubscription) {
      this.personaChangedSubscription.unsubscribe();
    }

    this.isDestroyed = true;
  }

  haveStaff(): boolean {
    if(!!this.personaSwitcherSelectedUpn && this.personaSwitcherSelectedUpn != this.officersUpn)
    {
      // We are masquerading. Even if masquerading as a team leader, return false
      return false;
    }

    return this.orderData.officerHasStaff;
  }

  onPageChangedEvent(pageNumber: number)
  {
    if(this.expiryInThreeMonthsTabSelected)
    {
      this.currentPageExpiryInThreeMonths = pageNumber;
    }
    else 
    {
      this.currentPageExpiryInSixMonths = pageNumber;
    }
    this.updatePagedOrdersToShow();
  }

  onPageSizeChangedEvent(pageSize: number)
  {
    if(this.expiryInThreeMonthsTabSelected)
    {
      this.pageSizeExpiryInThreeMonths = pageSize;
    }
    else 
    {
      this.pageSizeExpiryInSixMonths = pageSize;
    }
    this.updatePagedOrdersToShow();
  }

  updatePagedOrdersToShow()
  {
    var pageSizeToUse = this.expiryInThreeMonthsTabSelected ? this.pageSizeExpiryInThreeMonths : this.pageSizeExpiryInSixMonths;
    var currentPageToUse = this.expiryInThreeMonthsTabSelected ? this.currentPageExpiryInThreeMonths : this.currentPageExpiryInSixMonths;
    var firstElToShow = pageSizeToUse * currentPageToUse;
    var lastElToShow = firstElToShow + pageSizeToUse;

    if(this.expiryInThreeMonthsTabSelected)
    {
      this.orderCountExpiryInThreeMonths = !!this.orderData && this.orderData.groupedOrders ? this.orderData.groupedOrders.length : 0;
    }
    else
    {
      this.orderCountExpiryInSixMonths = !!this.orderData && this.orderData.groupedOrders ? this.orderData.groupedOrders.length : 0;
    }

    // Update the values that are displayed. All data is already pre-loaded. Just paginate in-memory
    if(!this.orderData || !this.orderData.groupedOrders || this.orderData.groupedOrders.length < 1) 
    {
      this.ordersToShow = [];  
    }
    else
    {
      this.ordersToShow = this.orderData.groupedOrders.slice(firstElToShow, lastElToShow);
    }
  }

  trackBy(index: number, item: Api.CourtOrder) {
    return item.orderExpiryDtm;
  }

  // When court orders are of type ‘TAO’, ‘CAO’ or ‘TCO’ then do not present a ‘View More’ button.
  enableViewMore(courtOrder: Api.CourtOrder)
  {
    return this.courtOrderService.enableViewMore(courtOrder);
  }

  formatShortDate(date: string): string {
    return !!date
      ? moment(date)
        .local()
        .format('DD/MM/YYYY')
      : 'n/a';
  }

  nextMilestone(courtOrder: Api.CourtOrder)
  {
    return this.courtOrderService.getNextMilestone(courtOrder);
  }

  isEven(item: Api.GroupedCourtOrders): boolean {
    if (!this.orderData) return false;

    let index = this.orderData.groupedOrders.indexOf(item, 0);

    return index % 2 === 0;
  }
  
  // Add or remove the selected court order from the collection of orders that should be open

  // Add or remove the selected court order from the collection of orders that should be open
  toggleSelectCourtOrder(order: Api.CourtOrder): void {
    let matchedSelectedOrders = this.getMatchingSelectedCourtOrders(order);
    if (matchedSelectedOrders && matchedSelectedOrders.length > 0) {
      let foundOrder = matchedSelectedOrders[0];
      if (foundOrder) {
        const index = this.selectedOrders.indexOf(foundOrder, 0);
        if (index > -1) {
          // Selected and already shown. Close.
          this.selectedOrders.splice(index, 1);
        }
        return;
      }
    }

    // Selected and not yet shown. Show
    if(this.selectedTab === FeatureName.CourtOrders3Months)
    {
      this.featureLogger.logFeatureUsage(FeatureName.CourtOrders3MonthsShowMore);
    }
    else
    {
      this.featureLogger.logFeatureUsage(FeatureName.CourtOrders6MonthsShowMore);
    }
    this.selectedOrders.push(order);
  }

  private subscribeToPersonaChangedNotifications() {
    this.personaSwitcherService.enable();
    if (!this.personaChangedSubscription) {
      this.personaChangedSubscription = this.personaSwitcherService.currentPersonaUpnChanged.subscribe(async (upn) => {
        this.personaSwitcherSelectedUpn = upn; // When persona switcher is de-activated this will be called with a null upn
        this.clearSelectedOrders();
        this.updatePagedOrdersToShow();
      });
    }
  }

  private clearSelectedOrders() {
    this.selectedOrders = [];
  }

  isOrderSelected(order: Api.CourtOrder): boolean {
    if(!order || !this.selectedOrders) return false;
    let matches = this.getMatchingSelectedCourtOrders(order);
    return (matches && matches.length > 0);
  }

  getMatchingSelectedCourtOrders(order: Api.CourtOrder): Api.CourtOrder[] {
    if (!order || !this.selectedOrders) return null;

    let app = this;
    return this.selectedOrders.filter(o => app.ordersMatch(o, order));
  }

  ordersMatch(orderA: Api.CourtOrder, orderB: Api.CourtOrder): boolean
  {
    return (orderA.subjectChildId === orderB.subjectChildId 
      && orderA.orderType === orderB.orderType 
      && orderA.orderTypeDisplay === orderB.orderTypeDisplay 
      && orderA.orderExpiryDtm === orderB.orderExpiryDtm);
  }

  @HostListener('window:beforeunload ', ['$event'])
  beforeUnload(event: any): void {
    this.ngOnDestroy();
  }
}
