<aside [ngClass]="{ 'full-aside': activityFeedIsExpanded }">
  <loading-pane [tracker]="tracker">
    <div class="row" role="row">
      <div class="td" role="cell">
        <div>
          <button [ngClass]="{ 'toggle-details': true, 'highlight-button': activityFeedIsExpanded }" (click)="toggleActivityFeed()" data-button-icon="+">
            <span *ngIf="!activityFeedIsExpanded"><h2>Activity Feed <span>( {{ activityFeedItems ? activityFeedItems.length : 0 }} )</span> <i class="fas fa-angle-down"></i></h2></span>
            <span *ngIf="activityFeedIsExpanded"><h2>Activity Feed <span>( {{ activityFeedItems ? activityFeedItems.length : 0 }} )</span> <i class="fas fa-angle-up"></i></h2></span>
          </button>
        </div>
      </div>
    </div>
    <div class="feed scroll-pane" *ngIf="activityFeedIsExpanded">
      <ng-container *ngIf="!hasError">
        <ul>
          <li *ngFor="let item of activityFeedItems" [ngClass]="{ 'new-activity-feed-item': item.isNew }">
            <div>
              <i class="fas fa-quote-right"></i>
            </div>
            <div>
              <p>
                <span [innerHTML]="item.text | unsafeHtml"></span>
                <a href="{{ item.url }}" target="_blank" class="activity-feed-item-url" *ngIf="item.url"
                  [attr.aria-label]="'View in ' + item.appName" [title]="'View in ' + item.appName"><i
                    class="fas fa-external-link-square-alt"></i>View in {{item.appName}}</a>
              </p>
              <div>
                {{ formatDate(item.occurredDateTimeUtc) }}
              </div>
            </div>
          </li>
        </ul>
        <br /><br /><br />
      </ng-container>

      <ng-container *ngIf="!activityFeedItems && !hasError">
        <div>
          <p>Loading activity feed items...</p>
        </div>
      </ng-container>

      <ng-container *ngIf="activityFeedItems && activityFeedItems.length === 0 && !hasError">
        <div>
          <p>There are no activity feed items to show at the moment.</p>
        </div>
      </ng-container>
      <ng-container *ngIf="hasError">
        <app-error-message [includeTroubleshooting]="false" appSection="activity feed"></app-error-message>
        <div>Contact <a href="mailto:CSPortal@csyw.qld.gov.au"
            aria-label="Email for further assistance.">CSPortal@csyw.qld.gov.au</a> for further assistance.
        </div>
      </ng-container>
    </div>
  </loading-pane>
</aside>