import { Component, OnInit, OnDestroy, EventEmitter, Input, Output } from '@angular/core';
import { TabNotificationService } from '../../../services/notifications';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss']
})
export class PaginationComponent implements OnInit, OnDestroy {
  private _recordCount: number;
  @Input() set recordCount(value: number) {
    this._recordCount = value;
    this.updatePageCount();
  }
  get recordCount(): number {
    return this._recordCount;
  }
  @Input() style: string;
  @Output() pageChangedEvent: EventEmitter<number> = new EventEmitter<number>();
  @Output() pageSizeChangedEvent: EventEmitter<number> = new EventEmitter<number>();
  
  // Pagination settings
  currentPage: number = 0;
  pageSize: number = 10;
  pageCount: number = 0;
  paginatorNumbersShownCount: number = 5;

  selectedPageSize: string;
  pageSizeOptions: string[];
  showingFromRecord: number = 1;
  showingToRecord: number = this.pageSize;

  tabNotificationSubscription: Subscription;

  constructor(private tabNotificationService: TabNotificationService) { }

  ngOnInit(): void {
    this.pageSizeChangedEvent.emit(this.pageSize);
    this.pageSizeOptions = [];
    this.pageSizeOptions.push("10");
    this.pageSizeOptions.push("20");
    this.pageSizeOptions.push("30");
    this.pageSizeOptions.push("40");
    this.selectedPageSize = "10";

    this.tabNotificationSubscription = this.tabNotificationService.selectedTabChanged.subscribe((tab) => {
      this.selectPage(0);
    });
  }


  ngOnDestroy() {
    // Make sure the periodic api call is stopped.
    if (this.tabNotificationSubscription) {
      this.tabNotificationSubscription.unsubscribe();
    }
  }

  isHidden() : boolean {
    var response = this.recordCount <= 10;
    // console.log('isHidden: ' + response + '. recordCount: ' + this.recordCount);
    return response;
  }

  updatePageCount()
  {
    this.pageCount = Math.ceil(this._recordCount / this.pageSize);
    this.autoSelectPage();
  }

  onPageSizeChange(selection: string) {
    this.selectedPageSize = selection;
    this.pageSize = parseInt(selection);
    this.updatePageCount();
    this.pageSizeChangedEvent.emit(this.pageSize);
    this.autoSelectPage();
  }

  // This is called when the page count is updated or when the page size is changed
  // If the current page is lower than the derived count of available pages, we 
  // remain on that page. Otherwise, we auto-reset to page 0 (1).
  autoSelectPage()
  {
    if(this.currentPage > this.pageCount - 1) this.selectPage(0);
    this.selectPage(this.currentPage);
  }
  
  async selectPage(page: number) {
    // console.log('selectPage: ' + page);
    this.currentPage = page;
    this.showingFromRecord = 1 + (this.currentPage * this.pageSize);
    var toRecord = (this.showingFromRecord + this.pageSize - 1);
    if(toRecord > this.recordCount)
    {
      toRecord = this.recordCount;
    }
    this.showingToRecord = toRecord;
    this.pageChangedEvent.emit(this.currentPage);

    // console.log('pagination. currentPage: ' + this.currentPage + '. pageSize: ' + this.pageSize + '. pageCount: ' + this.pageCount + '. recordCount: ' + this.recordCount);
  }

  firstPage() {
    this.selectPage(0);
  }

  prevPage() {
    this.selectPage(this.currentPage - 1);
  }

  prevPageGroup() {
    this.selectPage(Math.floor((this.currentPage - this.paginatorNumbersShownCount) / this.paginatorNumbersShownCount) * this.paginatorNumbersShownCount);
  }

  nextPageGroup() {
    this.selectPage(Math.floor((this.currentPage + this.paginatorNumbersShownCount) / this.paginatorNumbersShownCount) * this.paginatorNumbersShownCount);
  }

  nextPage() {
    this.selectPage(this.currentPage + 1);
  }

  lastPage() {
    this.selectPage(this.pageCount - 1);
  }

  pager(): any {
    let startPage = Math.floor(this.currentPage / this.paginatorNumbersShownCount) * this.paginatorNumbersShownCount;
    let endPage = startPage + this.paginatorNumbersShownCount - 1; //startPage + this.pageSize - 1; // The count of entries being shown is equal to the page size... Probably not what we want
    let showNext = endPage < (this.pageCount - 1);
    let showLast = this.currentPage > (this.paginatorNumbersShownCount - 1); // this.currentPage > this.pageSize - 1;

    let pages:number[] = [];

    for (let index = startPage; index < Math.min(startPage + this.paginatorNumbersShownCount, this.pageCount); index++) {
      pages.push(index);
    }

    let pagingData = {
      "startPage": startPage,
      "endPage": endPage,
      "showNext": showNext,
      "showLast": showLast,
      "pages": pages
    };

    return pagingData;
  }
}
