import * as moment from 'moment';
import * as TestData from './court-order-test-data';

export class CourtOrderDetails {
    officerHasStaff: boolean;

    countOfOrdersMovedFromSubsequentToNextThreeMonths: number;

    courtOrdersInNextThreeMonthsToDisplay: Api.CourtOrder[];
    courtOrdersInNextThreeMonths: Api.CourtOrder[];
    countOfCourtOrdersInNextThreeMonths: number;
    countOfUniqueChildrenInNextThreeMonths: number;
    courtOrdersInNextThreeMonthsLoaded: boolean;
    courtOrdersInNextThreeMonthsHasError: boolean = false;
    
    courtOrdersInSubsequentThreeMonthsToDisplay: Api.CourtOrder[];
    courtOrdersInSubsequentThreeMonths: Api.CourtOrder[];
    countOfCourtOrdersInSubsequentThreeMonths: number;
    countOfUniqueChildrenInSubsequentThreeMonths: number;
    countOfUniqueChildrenMovedFromSubsequentToNextThreeMonths: number;
    courtOrdersInSubsequentThreeLoaded: boolean;
    courtOrdersInSubsequentThreeHasError: boolean = false;

    responseDataNextThreeMonths: Api.SelectedCourtOrderData;
    responseDataSubsequentThreeMonths: Api.SelectedCourtOrderData;

    useTestData: boolean;
    testData: TestData.CourtOrderTestData;

    orderedCourtOrders = [
        { 'order': 'tao', index: 0 },
        { 'order': 'tao - extend', index: 1 },
        { 'order': 'tco', index: 2 },
        { 'order': 'tco - extend', index: 3 },
        { 'order': 'cao', index: 4 },
        { 'order': 'cao - extend', index: 5 },
        { 'order': 'cao - interim order(s)', index: 6 },
        { 'order': 'cao - extend and vary', index: 7 },
        { 'order': 'cpo', index: 8 },
        { 'order': 'cpo - interim order(s)', index: 9 },
        { 'order': 'cpo - interim and procedural order(s)', index: 10 },
        { 'order': 'cpo - section 99', index: 11 },
        { 'order': 'cpo - section 99 and procedural order(s)', index: 12 },
        { 'order': 'cpo - section 99 and interim order(s)', index: 13 },
        { 'order': 'cpo - section 99, interim and procedural order(s)', index: 14 }, 
        { 'order': 'cpo - stc', index: 15 },
        { 'order': 'cpo - stc - other', index: 16 },
        { 'order': 'cpo - stg', index: 17 },
        { 'order': 'cpo - ltg', index: 18 },
        { 'order': 'cpo - ltg - other', index: 19 },
        { 'order': 'cpo - pso', index: 20 },
        { 'order': 'cpo - do - other', index: 21 },
        { 'order': 'cpo - do - contact', index: 22 }
      ];
  
    constructor(
    ) {
        // Set this to true when in dev to use test data. That is, data shown rather than unit testing.
        // Refer to court-order-test-data.ts. Update the scenario in the 'initialiseTestData' method for a given scenario.
        this.useTestData = false;
        if(this.useTestData)
        {
            this.testData = new TestData.CourtOrderTestData();
        }
    }

    generateDisplayInformation(expiryInThreeMonthsTabSelected: boolean) : Api.SelectedCourtOrderData
    {
        if(this.useTestData === true)
        {
            this.testData.initialiseTestData();
            this.courtOrdersInNextThreeMonths = (!!this.testData.testNextThreeMonthsData && this.testData.testNextThreeMonthsData.length > 0 ? this.testData.testNextThreeMonthsData.slice() : []);
            this.courtOrdersInSubsequentThreeMonths = (!!this.testData.testSubsequentThreeMonthsData && this.testData.testSubsequentThreeMonthsData.length > 0 ? this.testData.testSubsequentThreeMonthsData.slice() : []);
            this.filterOrdersToDisplay(null);
        }

        // We generate both every time in order to guarantee the counts for the tab display
        this.responseDataNextThreeMonths = this.generateGroupedOrders(this.courtOrdersInNextThreeMonthsToDisplay, this.courtOrdersInNextThreeMonthsHasError, this.courtOrdersInNextThreeMonthsLoaded);
        this.responseDataSubsequentThreeMonths = this.generateGroupedOrders(this.courtOrdersInSubsequentThreeMonthsToDisplay, this.courtOrdersInSubsequentThreeHasError, this.courtOrdersInSubsequentThreeLoaded);

        this.countOfUniqueChildrenInNextThreeMonths = (this.responseDataNextThreeMonths && this.responseDataNextThreeMonths.groupedOrders ? this.responseDataNextThreeMonths.groupedOrders.length : 0);
        this.countOfUniqueChildrenInSubsequentThreeMonths = (this.responseDataSubsequentThreeMonths && this.responseDataSubsequentThreeMonths.groupedOrders ? this.responseDataSubsequentThreeMonths.groupedOrders.length : 0);

        let groupedOrders = expiryInThreeMonthsTabSelected ? this.responseDataNextThreeMonths : this.responseDataSubsequentThreeMonths;

        //var t0 = performance.now();
        
        this.sortInnerChildCourtOrders(groupedOrders);
        this.sortOuterChildCourtOrdersByExpiry(groupedOrders);
        
        //var t1 = performance.now();
        //console.log("Call to sort took " + (t1 - t0) + " milliseconds.");
        
        // Include derived counts in response
        groupedOrders.countOfOrdersMovedFromSubsequentToNextThreeMonths = this.countOfOrdersMovedFromSubsequentToNextThreeMonths;
        groupedOrders.countOfUniqueChildrenInNextThreeMonths = this.countOfUniqueChildrenInNextThreeMonths;
        groupedOrders.countOfUniqueChildrenInSubsequentThreeMonths = this.countOfUniqueChildrenInSubsequentThreeMonths;
        groupedOrders.countOfCourtOrdersInNextThreeMonths = this.countOfCourtOrdersInNextThreeMonths;
        groupedOrders.countOfCourtOrdersInSubsequentThreeMonths = this.countOfCourtOrdersInSubsequentThreeMonths;

        /*console.log(
            'countOfOrdersMovedFromSubsequentToNextThreeMonths[' + this.countOfOrdersMovedFromSubsequentToNextThreeMonths + ']. ' + 
            'countOfCourtOrdersInNextThreeMonths[' + this.countOfCourtOrdersInNextThreeMonths + ']. ' + 
            'countOfUniqueChildrenInNextThreeMonths[' + this.countOfUniqueChildrenInNextThreeMonths + ']. ' + 
            'countOfCourtOrdersInSubsequentThreeMonths[' + this.countOfCourtOrdersInSubsequentThreeMonths + ']. ' + 
            'countOfUniqueChildrenInSubsequentThreeMonths[' + this.countOfUniqueChildrenInSubsequentThreeMonths + ']. ');*/

        return groupedOrders;
    }
    
    // Generate new structure to allow simple display of multiple court orders related to a single child
    generateGroupedOrders(orderCollection: Api.CourtOrder[], ordersHasError: boolean, ordersLoaded: boolean) : Api.SelectedCourtOrderData
    {
        // See the response object
        let responseData = {
            ordersHasError: ordersHasError,
            ordersLoaded: ordersLoaded,
            groupedOrders: [], // An object with a collection of 'orders'
            officerHasStaff: this.officerHasStaff, 
            countOfOrdersMovedFromSubsequentToNextThreeMonths: 0,
            countOfUniqueChildrenInNextThreeMonths: 0,
            countOfUniqueChildrenInSubsequentThreeMonths: 0,
            countOfCourtOrdersInNextThreeMonths: 0,
            countOfCourtOrdersInSubsequentThreeMonths: 0
        };
        if(orderCollection == null || orderCollection.length < 1) return responseData;

        let ordersByChildId = this.getOrderedOptions(orderCollection, 'subjectChildId');
        let lastId = ordersByChildId[0].subjectChildId;
        let index = 0;
        responseData.groupedOrders[0] = {
            toggleIndexChange: 0,
            nextOrderExpiryDtm: '',
            orders: []
        }

        ordersByChildId.forEach(o => {
            if(o.subjectChildId !== lastId)
            {
                // Seed a new GroupedOrder object
                responseData.groupedOrders[++index] = {
                    toggleIndexChange: 0,
                    nextOrderExpiryDtm: '',
                    orders: []
                }
            }

            // Add the current order at this index
            responseData.groupedOrders[index].orders.push(o);

            // Update lastId
            lastId = o.subjectChildId;
        });

        return responseData;
    }

    getOrderedOptions(orderCollection: Api.CourtOrder[], sortField: string): Api.CourtOrder[] {
        return orderCollection.sort((x: Api.CourtOrder, y: Api.CourtOrder) => {
          return x[sortField].localeCompare(y[sortField]);
        });
    }

    dataRetrievalError(nextThreeMonths: boolean): void
    {
        if(nextThreeMonths)
        {
            this.courtOrdersInNextThreeMonthsHasError = true;
        }
        else
        {
            this.courtOrdersInSubsequentThreeHasError = true;
        }
    }

    storeData(nextThreeMonths: boolean, orders: Api.CourtOrder[]): void
    {
        if(nextThreeMonths)
        {
            this.courtOrdersInNextThreeMonths = orders;
            //console.log('Settings "courtOrdersInNextThreeMonths" to newly received payload at: ' + moment());
            //console.log(JSON.stringify(orders));
            this.courtOrdersInNextThreeMonthsHasError = false;
            this.courtOrdersInNextThreeMonthsLoaded = true;
        }
        else
        {
            this.courtOrdersInSubsequentThreeMonths = orders;
            //console.log('Settings "courtOrdersInSubsequentThreeMonths" to newly received payload at: ' + moment());
            //console.log(JSON.stringify(orders));
            this.courtOrdersInSubsequentThreeHasError = false;
            this.courtOrdersInSubsequentThreeLoaded = true;
        }

        this.SetOfficerHasStaff();
    }

    // Any order for a child in the subsequent three months who also has a court order in the first 
    // three months should have the order moved from the subsequent three months to the first three 
    // months. That is, if a child has more than one court order and one or more are in each 
    // period collection then move them so that they are all in the first three months, regardless 
    // of expiry date
    moveOrdersForDuplicateChildren()
    {
        //console.log('moveOrdersForDuplicateChildren at: ' + moment());
        if(!this.courtOrdersInNextThreeMonthsToDisplay || !this.courtOrdersInSubsequentThreeMonthsToDisplay) return;

        let temporaryCollection = [];
        this.courtOrdersInNextThreeMonthsToDisplay.forEach(o => {
            for(var i = 0; i < this.courtOrdersInSubsequentThreeMonthsToDisplay.length; i++) {
                if (this.courtOrdersInSubsequentThreeMonthsToDisplay[i].subjectChildId === o.subjectChildId) {
                    // This court order needs to be moved to the first three collection
                    temporaryCollection.push(this.courtOrdersInSubsequentThreeMonthsToDisplay[i]);

                    this.courtOrdersInSubsequentThreeMonthsToDisplay.splice(i, 1);
                    i--;
                }
            }
        });

        // Add the temporary collection to the first three month collection
        this.courtOrdersInNextThreeMonthsToDisplay = this.courtOrdersInNextThreeMonthsToDisplay.concat(temporaryCollection);

        // Track the count of moved orders
        this.countOfOrdersMovedFromSubsequentToNextThreeMonths = temporaryCollection.length;

        // Get the count of unique children moved
        let distinctNames = [];
        temporaryCollection.forEach(o => {
            if(!distinctNames.find(n => n == o.subjectChildId)) {
                distinctNames.push(o.subjectChildId);
            }
        });
        this.countOfUniqueChildrenMovedFromSubsequentToNextThreeMonths = distinctNames.length;
    }

    // Court orders may move from their natural date-ordered tab in order to combine with a given subject user.
    // Update counts to ensure we formulate well-phrased sentences for the display.
    SetOrderCounts()
    {
        this.countOfCourtOrdersInNextThreeMonths = (this.courtOrdersInNextThreeMonthsToDisplay ? this.courtOrdersInNextThreeMonthsToDisplay.length : 0);
        this.countOfCourtOrdersInSubsequentThreeMonths = (this.courtOrdersInSubsequentThreeMonthsToDisplay ? this.courtOrdersInSubsequentThreeMonthsToDisplay.length : 0);
    }

    // Sort the collection of Api.CourtOrder objects in a given GroupedCourtOrders
    // The sort will be based on property orderExpiryDtm of each Api.CourtOrder for a given GroupedCourtOrders
    sortInnerChildCourtOrders(orderData: Api.SelectedCourtOrderData)
    {
        // Sort first by order expiry dtm and secondarily based on order types
        var sortedOrderDictionary = this.orderedCourtOrders;
        orderData.groupedOrders.forEach(o => {
            o.orders.sort(function (orderA, orderB) {
                // First, compare by dtm
                let result = orderA.orderExpiryDtm.localeCompare(orderB.orderExpiryDtm);
            
                if(result == 0)
                {
                    // The due dtms are equal. Defer to secondary sort on order type
                    let orderASortMatch = sortedOrderDictionary.find(x => x.order === orderA.orderTypeDisplay.toLowerCase());
                    let orderBSortMatch = sortedOrderDictionary.find(x => x.order === orderB.orderTypeDisplay.toLowerCase());
                    //console.log('orderASortMatch: ' + (orderASortMatch != null ? JSON.stringify(orderASortMatch) : '') + 
                    //    '. orderBSortMatch: ' + (orderBSortMatch != null ? JSON.stringify(orderBSortMatch) : ''));
                    if(orderASortMatch == null)
                    {
                        return 1;
                    }
                    if(orderBSortMatch == null)
                    {
                        return -1;
                    }

                    let orderAPriority = orderASortMatch.index;
                    let orderBPriority = orderBSortMatch.index;
                    // console.log('order A[' + orderA.orderTypeDisplay + ']:index[' + orderAPriority + '], order B[' + orderB.orderTypeDisplay + ']:index[' + orderBPriority + ']');
                    return (orderAPriority < orderBPriority) ? -1 : 
                        (orderAPriority > orderBPriority) ? 1 : 0;
                }
                else
                {
                    return result;
                }
            });
        });

        // Now, ensure the minimum (first) order expiry value is set on each outer GroupedCourtOrders object
        orderData.groupedOrders.forEach(o => {
            if(o.orders && o.orders.length > 0)
            {
                o.nextOrderExpiryDtm = o.orders[0].orderExpiryDtm;
            }
        });
    }

    // Sort the collection of GroupedCourtOrders by their earliest expiry dtm
    // The sort will be based on property nextOrderExpiryDtm of each GroupedCourtOrders
    sortOuterChildCourtOrdersByExpiry(orderData: Api.SelectedCourtOrderData)
    {
        orderData.groupedOrders.sort((x: Api.GroupedCourtOrders, y: Api.GroupedCourtOrders) => {
            return x.nextOrderExpiryDtm.localeCompare(y.nextOrderExpiryDtm);
        });
    }

    // Use the complete domain of court orders retrieved to determine if this person has staff
    SetOfficerHasStaff()
    {
        if(this.courtOrdersInNextThreeMonthsLoaded !== true || this.courtOrdersInSubsequentThreeLoaded !== true)
        {
            return;
        }

        this.officerHasStaff = false;
        this.SetOfficerHasStaffByList(this.courtOrdersInNextThreeMonths);
        if(!this.officerHasStaff)
        {
            this.SetOfficerHasStaffByList(this.courtOrdersInSubsequentThreeMonths);
        }
    }
    
    SetOfficerHasStaffByList(listToCheck)
    {
        if(!listToCheck || listToCheck == null) return;
        listToCheck.forEach(r => {
            if (r.hasStaff)
            {
                this.officerHasStaff = true;
            }
        });
    }

    filterOrdersToDisplay(personaSwitcherSelectedUpn: string): void
    {
      //console.log('filterOrdersToDisplay at: ' + moment());
      //console.log('filterOrdersToDisplay. courtOrdersInNextThreeMonths: ' + JSON.stringify(this.courtOrdersInNextThreeMonths));

      // At this point we have lost the 3-6 month data for the person being persona viewed?
      //console.log('filterOrdersToDisplay. courtOrdersInSubsequentThreeMonths: ' + JSON.stringify(this.courtOrdersInSubsequentThreeMonths));

      // Test for when persona switcher context is ended
      if(personaSwitcherSelectedUpn == null)
      {
        // Display everything
        this.courtOrdersInNextThreeMonthsToDisplay = (!!this.courtOrdersInNextThreeMonths && this.courtOrdersInNextThreeMonths.length > 0 ? this.courtOrdersInNextThreeMonths.slice() : []);
        this.courtOrdersInSubsequentThreeMonthsToDisplay = (!!this.courtOrdersInSubsequentThreeMonths && this.courtOrdersInSubsequentThreeMonths.length > 0 ? this.courtOrdersInSubsequentThreeMonths.slice() : []);
      }
      else // We have a persona upn to utilise. Filter the full collections to the subset with equal upns
      {
        this.courtOrdersInNextThreeMonthsToDisplay = [];
        this.courtOrdersInSubsequentThreeMonthsToDisplay = [];
  
        // Commence filtering by selected persona
        if(!this.courtOrdersInNextThreeMonths) {
            this.courtOrdersInNextThreeMonths = [];
        }
        this.courtOrdersInNextThreeMonths.forEach(r => {
          if(
            (r.officerUPN && r.officerUPN.length > 0 && r.officerUPN == personaSwitcherSelectedUpn) || 
            (r.officerAlternateUPN && r.officerAlternateUPN.length > 0 && r.officerAlternateUPN == personaSwitcherSelectedUpn)
          )
          {
            this.courtOrdersInNextThreeMonthsToDisplay.push(r);
          }
        });
        this.courtOrdersInSubsequentThreeMonths.forEach(r => {
          if(
            (r.officerUPN && r.officerUPN.length > 0 && r.officerUPN == personaSwitcherSelectedUpn) || 
            (r.officerAlternateUPN && r.officerAlternateUPN.length > 0 && r.officerAlternateUPN == personaSwitcherSelectedUpn)
          )
          {
            this.courtOrdersInSubsequentThreeMonthsToDisplay.push(r);
            //console.log('Adding [' + JSON.stringify(r) + '] to this persons subsequent list');
          }
          else
          {
              //console.log('Not adding [' + JSON.stringify(r) + '] to this persons subsequent list');
          }
        });

        //console.log('personaSwitcherSelectedUpn: ' + personaSwitcherSelectedUpn);
        //console.log('filterOrdersToDisplay. Persona enabled. courtOrdersInNextThreeMonthsToDisplay: ' + JSON.stringify(this.courtOrdersInNextThreeMonthsToDisplay));
        //console.log('filterOrdersToDisplay. Persona enabled. courtOrdersInSubsequentThreeMonthsToDisplay: ' + JSON.stringify(this.courtOrdersInSubsequentThreeMonthsToDisplay));
      }
      this.moveOrdersForDuplicateChildren();
      this.SetOrderCounts();
    }

    dataIsNotLoaded(): boolean{
        return !this.courtOrdersInNextThreeMonthsLoaded || !this.courtOrdersInSubsequentThreeLoaded;
    }

    getCourtOrdersDueSoonSentence(): string {
        return this.generateSentence(this.countOfUniqueChildrenInNextThreeMonths, this.countOfCourtOrdersInNextThreeMonths, 3);
    }
      
    getCourtOrdersDueInSixMonthsSentence(): string {
        let countOfChildren = undefined;
        if(this.countOfUniqueChildrenInSubsequentThreeMonths !== null || this.countOfUniqueChildrenMovedFromSubsequentToNextThreeMonths !== null)
        {
            countOfChildren = (this.countOfUniqueChildrenInSubsequentThreeMonths ? this.countOfUniqueChildrenInSubsequentThreeMonths : 0) + 
                (this.countOfUniqueChildrenMovedFromSubsequentToNextThreeMonths ? this.countOfUniqueChildrenMovedFromSubsequentToNextThreeMonths : 0);
        }
        
        let response = this.generateSentence(countOfChildren, 
            this.countOfCourtOrdersInSubsequentThreeMonths + this.countOfOrdersMovedFromSubsequentToNextThreeMonths, 6);

        if(this.countOfOrdersMovedFromSubsequentToNextThreeMonths > 0)
        {
            response += ' See the 3 month tab for ';
            if(this.countOfCourtOrdersInSubsequentThreeMonths === 0)
            {
                response += ' details.';
            }
            else
            {
                response += ' some details.';
            }
        }

        return response;
    }

    // Generate sentences such as:
    //   Example 1: No children or young people have an order expiring in the next 3 months.
    //   Example 2: 2 children have orders due to expire in the next 6 months
    generateSentence(countOfUniqueChildren: number, countOfCourtOrders: number, monthCount: number) : string 
    {
        if (countOfCourtOrders === 0) {
            return 'No children or young people have an order expiring in the next ' + monthCount + ' months.';
        }

        let response = '' + (countOfUniqueChildren === null || countOfUniqueChildren === undefined ? '?' : countOfUniqueChildren) + ' '; // '1 '

        // Determine prefix
        if(countOfUniqueChildren === 1)
        {
            response += 'child has '; // '1 child has '
        }
        else
        {
            response += 'children have '; // '2 children have '
        }

        if(countOfCourtOrders === 1)
        {
            response += 'an order '; // '2 children have an order '
        }
        else // > 1 
        {
            //response += countOfCourtOrders + ' orders '; // '2 children have orders '
            response += 'orders '; // '2 children have orders '
        }

        response += 'due to expire in the next ' + monthCount + ' months.';

        return response;
    }
}