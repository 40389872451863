import { Component, Input, EventEmitter, Output, AfterViewInit, Renderer2, ElementRef, HostListener, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { FilterSettings, MultiChoiceOption, MultiChoiceSearch } from '../../filter-settings';
import { LocalStorageService } from 'services/local-storage.service';
import { IMyDateModel, IMyDate } from 'angular-mydatepicker';
import { DateInputComponent } from 'app/shared/date-input/date-input.component';
import { PersonaSwitcherService } from 'services/api/persona-switcher.service';
import * as moment from 'moment';
import { FilterDateRange, filterDateRangeDescription } from "api/website_enum";
import { ShowPriorityNotifyService } from 'services/notifications';
import { PriorityIconService } from 'services/priority-icon.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-overdue-filter',
  templateUrl: './overdue-filter.component.html',
  styleUrls: ['./overdue-filter.component.scss']
})
export class OverdueFilterComponent implements AfterViewInit, OnInit, OnDestroy {
  @ViewChild('customDateRange') customDateRange: DateInputComponent;

  storagePrefix: string = 'overdue-visits';

  @Input() visible: boolean;
  @Output() filterClosed: EventEmitter<boolean> = new EventEmitter<boolean>();
  nativeElement: HTMLElement;
  instigatingButtonRect: ClientRect;
  formModel: FilterSettings;
  showBaseFilters: Boolean;

  disableUntil: IMyDate;
  private largeView: boolean;
  selectAllTooltip: string;
  allChildrenAreSelected: boolean;
  noChildName: boolean;
  clearChildNameTooltip: string;
  externalClicksCanCloseFilter: boolean = false;

  showPrioritySubscription: Subscription;
  displayPriorityColours: boolean = true;
  priorityColoursAreGloballyDisabled: boolean = false;

  constructor(
    private elementRef: ElementRef, 
    private renderer: Renderer2, 
    private localStorageService: LocalStorageService, 
    private showPriorityNotifyService: ShowPriorityNotifyService, 
    private priorityIconService: PriorityIconService,
    private personaSwitcherService: PersonaSwitcherService
  ) {
    this.disableUntil = { year: moment().year(), month: moment().month() + 1, day: moment().date() -1 };
    let personaUpn = this.personaSwitcherService.getPersonaUpn();
    personaUpn = !!personaUpn && personaUpn.length > 0 ? '_' + personaUpn : '';
    this.formModel = new FilterSettings(localStorageService, false, this.storagePrefix + personaUpn);
    this.nativeElement = this.elementRef.nativeElement;
    this.showBaseFilters = true;
    this.selectAllTooltip = '';
    this.allChildrenAreSelected = true;
    this.noChildName = true;
    this.clearChildNameTooltip = '';
    this.displayPriorityColours = this.priorityIconService.priorityIconFeatureIsEnabled();
    this.priorityColoursAreGloballyDisabled = this.priorityIconService.priorityIsGloballyDisabled();

    this.subscribeToPriorityNotifications();
  }

  @HostListener('document:click', ['$event.target'])
  public onPageClick(targetElement) {
    // Initially clicking the 'filter' button should not instantly re-close it.
    if(targetElement.id == 'filter' && !this.externalClicksCanCloseFilter) 
    {
      // Subsequent clicks can close the filter.
      this.externalClicksCanCloseFilter = true;
      return;
    }

    // If the person has clicked the 'filter' button now but we are in small mode, do not close the filter
    if(!this.largeView)
    {
      return;
    }

    if(!this.externalClicksCanCloseFilter || !this.visible) return;
    const clickedInside = this.elementRef.nativeElement.contains(targetElement);
    if (!clickedInside) {
      this.cancel();
    }
  }

  public get next4Weeks(): number {
    return FilterDateRange.Next4Weeks; 
  }

  public get next4WeeksDescription(): string {
    return filterDateRangeDescription(FilterDateRange.Next4Weeks); 
  }

  public get thisWeek(): number {
    return FilterDateRange.ThisWeek; 
  }

  public get thisWeekDescription(): string {
    return filterDateRangeDescription(FilterDateRange.ThisWeek); 
  }

  public get custom(): number {
    return FilterDateRange.Custom; 
  }

  public get customDescription(): string {
    return filterDateRangeDescription(FilterDateRange.Custom); 
  }

  ngAfterViewInit(): void {
    this.setFilterPositionUnderneathFilterButton();

    this.nativeElement.getElementsByTagName("input")[0].focus();
  }

  ngOnInit(): void {
    this.largeView = window.innerWidth >= 900;
  }

  ngOnDestroy() {
    // Make sure the periodic api call is stopped.
    if (this.showPrioritySubscription) {
      this.showPrioritySubscription.unsubscribe();
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.largeView = window.innerWidth >= 900;
  }

  open(instigatingButtonRect: ClientRect) {
    this.instigatingButtonRect = instigatingButtonRect;
  }

  toggleFilters() {
    this.showBaseFilters = !this.showBaseFilters;
  }

  dataLoaded() {
    this.synchroniseChildNameValues();
    this.synchroniseChildNameSelectionValues();
    this.visible = true;
  }

  updateDisplayPriorityColours(showColoursFilter: boolean)
  {
    this.displayPriorityColours = showColoursFilter;
  }
 
  clear(form: NgForm) {
    form.reset();
    this.formModel.defaultValues = true;
    this.formModel.initialiseValues(this.storagePrefix);
    this.hideFilter(true);
  }

  cancel() {
    this.formModel.defaultValues = false;
    this.hideFilter(false); 
  }

  apply(form: NgForm) {
    this.formModel.defaultValues = false;
    if (!this.formIsValid(form) || 
        (
          this.formModel.selectedDateRange.useCustomDateValue() && (!this.formModel.selectedDateRange.haveDateValue() || !this.customDateRange.valueValid)
        )
      ) 
    {
      this.customDateRange.setValueValidFalse();
      return false;
    }

    // Persist current values to storage
    this.formModel.persistSessionFilterSettings(this.localStorageService);

    this.hideFilter(true);
    return true;
  }

  rangeTypeChanged() {
    this.customDateRange.clearDateInput((this.formModel.selectedDateRange.dateRangeType === FilterDateRange.Custom));
  }

  filterSettingsDateChanged(newDateRange: IMyDateModel)
  {
    this.formModel.selectedDateRange.customDateRange = newDateRange;
  }

  // This prevents a person from de-selecting the last option
  selectionChanged(filter: MultiChoiceSearch, option: MultiChoiceOption, event: Event) {
    if (filter.noneSelected()) {
      option.selected = true;
      (<any>event.target).checked = true;
    }
  }

  private subscribeToPriorityNotifications() {
    if (!this.showPrioritySubscription) {
      this.showPrioritySubscription = this.showPriorityNotifyService.showPrioritiesChanged.subscribe(async (show) => {
        this.displayPriorityColours = show;
      });
    }
  }

  // #region Child Name Select List

  childNameSelectionChanged(options: string[], event: Event) {
    this.formModel.nameSelect.setSelections(options);
    this.synchroniseChildNameSelectionValues();
  }

  clearChildrenNamesFilter() {
    this.formModel.nameSelect.selectAll(this.localStorageService);
    this.synchroniseChildNameSelectionValues();
  }
  synchroniseChildNameSelectionValues() {
    if(this.formModel.nameSelect.checkboxOptions.length != this.formModel.nameSelect.selectedOptions.length)
    {
      this.selectAllTooltip = 'Remove Child Name Filter';
      this.allChildrenAreSelected = false;
    } else {
      this.selectAllTooltip = '';
      this.allChildrenAreSelected = true;
    }
  }
  
  // #endregion Child Name Select List

  // #region Child Name Select List

  childNameSearchChanged(value: string, event: Event)
  {
    this.formModel.nameSearch.childNameSearchValue = value;
    this.synchroniseChildNameValues();
  }

  clearChildNameFilter() {
    this.formModel.nameSearch.clear(this.localStorageService);
    this.synchroniseChildNameValues();
  }

  synchroniseChildNameValues() {
    if(!!this.formModel.nameSearch && !!this.formModel.nameSearch.childNameSearchValue && this.formModel.nameSearch.childNameSearchValue.length > 0)
    {
      this.selectAllTooltip = 'Remove Child Name Filter';
      this.noChildName = false;
    } else {
      this.selectAllTooltip = '';
      this.noChildName = true;
    }
  }

  // #endregion Child Name Select List

  formIsValid(form: NgForm) {
    return form.valid;
  }

  hideFilter(useNewFilters: boolean) {
    this.visible = false;

    if(!!this.filterClosed.observers && this.filterClosed.observers.length > 0)
    {
      this.filterClosed.emit(useNewFilters);
    }
  }

  setFilterPositionUnderneathFilterButton() {
    let filterHost = <HTMLElement>this.nativeElement;

    let x = this.instigatingButtonRect.left; //  - 22.5

    const agent = window.navigator.userAgent.toLowerCase();
    var isIE = (agent.indexOf('trident') > -1);

    if (document.documentElement.clientWidth >= 900) {
      x = this.instigatingButtonRect.left + this.instigatingButtonRect.width - filterHost.offsetWidth;
      if(!isIE)
      {
        this.renderer.setStyle(filterHost, 'left', `${x}px`);
      }
      else
      {
        x = (this.instigatingButtonRect.width / 3) + 2;
        this.renderer.setStyle(filterHost, 'right', `${x}px`);
      }
    }
    
    // -----------------------------------------------------------
    // Set y position of filter. Page width > 900
    // -----------------------------------------------------------
    let y = 0;
    if(document.documentElement.clientWidth > 900 && !isIE)
    {
      y = this.instigatingButtonRect.top + this.instigatingButtonRect.height + window.pageYOffset + 15;
    }
    else if(document.documentElement.clientWidth > 900 && isIE)
    {
      y = (this.instigatingButtonRect.height * 2) + 10;
    }

    // -----------------------------------------------------------
    // Set y position of filter. Page width <= 900
    // -----------------------------------------------------------
    if(document.documentElement.clientWidth <= 900)
    {
      if(window.pageYOffset < 290) {
        y = 197;
      }
      else
      {
        y = 197 + window.pageYOffset - 290;
      }
    }
    this.renderer.setStyle(filterHost, 'top', `${y}px`);

    // console.log('this.instigatingButtonRect.top: ' + this.instigatingButtonRect.top + '. this.instigatingButtonRect.height: ' + this.instigatingButtonRect.height + '. window.pageYOffset: ' + window.pageYOffset + '. Result.top: ' + y);
    // console.log('this.instigatingButtonRect.left: ' + this.instigatingButtonRect.left + '. this.instigatingButtonRect.width: ' + this.instigatingButtonRect.width + '. filterHost.offsetWidth: ' + filterHost.offsetWidth + '. Result.left: ' + x);
  }

  @HostListener('window:resize', ['$event'])
  onWindowResize(event: Event): void {
    this.cancel();
  }
}
