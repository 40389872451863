import { Component, Input, DoCheck, HostBinding } from '@angular/core';
import { WorkTracker } from "./work-tracker.service";
import * as moment from 'moment';

@Component({
  selector: 'loading-pane',
  templateUrl: 'loading-pane.component.html',
  styleUrls: ['loading-pane.component.scss']
})
export class LoadingPaneComponent implements DoCheck {

  @HostBinding('class.loading') public loading: boolean;
  @HostBinding('attr.aria-busy') get ariaRole(): string {
    return (this.loading ? 'true' : null);
  }
  @HostBinding('attr.aria-live') get ariaLive(): string {
    return (this.loading ? 'polite' : null);
  }

  @Input()
  public tracker: WorkTracker;

  @Input()
  public tracker_2: WorkTracker;

  @Input()
  public enabled: boolean;

  ngDoCheck(): void {
    if(!!this.enabled)
    {
      if(!this.loading)
      {
        //console.log('Starting spinny at: ' + moment().format("dddd, MMMM Do YYYY, h:mm:ss a"));
        this.loading = true;
        setTimeout(() => {
          //console.log('Stopping spinny at: ' + moment().format("dddd, MMMM Do YYYY, h:mm:ss a"));
          this.loading = false;
        }, 5000);
      }
    }
    else
    {
      this.loading = !!(this.tracker && !this.tracker.complete) || !!(this.tracker_2 && !this.tracker_2.complete);
    }
  }
}